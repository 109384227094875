
export default {
	name: 'feedback-button',

	props: {
		status: {
			type: String,
			default: 'idle',
			required: true,
		},
		buttonStyle: {
			type: String,
			default: 'primary',
			required: false,
		},
		idleText: {
			type: String,
			required: false,
		},
		doneText: {
			type: String,
			required: false,
		},
		doneRevertDelay: {
			type: Number,
			default: 3.5,
			required: false,
		}
	},
	data(){
		return {
			isDone: false,
			isLoading: false,
			isDisabled: this.status === 'error' || this.status === 'disabled' ? true : false, // COMMENT: In case the initial status is error or disabled..
			visualButtonRevertTimeout: null, // COMMENT: Placeholder for timeout
		};
	},
	watch: {
		status(newValue){
			clearTimeout(this.visualButtonRevertTimeout);

			switch(newValue){
				case 'idle':
					this.isDone = false;
					this.isLoading = false;
					this.isDisabled = false;
					break;

				case 'error':
				case 'disabled':
					this.isDone = false;
					this.isLoading = false;
					this.isDisabled = true;
					break;

				case 'loading':
					this.isDone = false;
					this.isLoading = true;
					this.isDisabled = true;
					break;

				case 'done':
					this.isDone = true;
					this.isLoading = false;
					this.isDisabled = false;
					this.visualRevertButtonFeedback();
					break;
			}
		},
	},
	methods: {
		visualRevertButtonFeedback(){
			this.visualButtonRevertTimeout = setTimeout(()=>{
				this.$emit('done-delay');
			}, this.doneRevertDelay * 1000);
		},
	},
};
