//
// https://developers.google.com/tag-manager/enhanced-ecommerce
//
// Note: purchase registration is handled by server side generated code, that is placed on confirmation page.
// See method: GtmAddPurchase

function _registerGtmDataLayer(obj, dontClear) {
    if (!obj) {
        console.error("Object is missing");
        return;
    }

    window.dataLayer = ( typeof window.dataLayer != "undefined" && window.dataLayer instanceof Array ) ? window.dataLayer : [];
    if (!dontClear) {
        window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(obj);
}

function _parseCurrencyString(price){
    // input samples:
    // DKK 4.595,00
    // GBP 555,00
    // USD 1,234.00
    // NOK 4 195,00

    if (!price || price.length < 8) { // smallest price "DKK 0,00"
        // console.error("Price is empty");
        return {
            "currencyCode": "???",
            "price": 0.00
        }
    }

    const currencyCode = price.substring(0,3);
    let priceParsed = (parseFloat(price.replaceAll(/[A-z ,\.]/g,""))/100).toFixed(2);

    return {
        "currencyCode": currencyCode,
        "price": priceParsed
    }
}

function _getProductFullName(primaryProductName, secondaryProductName) {
    if (secondaryProductName){
        return `${primaryProductName} ${secondaryProductName}`;
    }
    return primaryProductName;
}

function registerAddToCart(productName, variantName, id, price, quantity){
    var p = _parseCurrencyString(price);

    const obj = {
        "event": "addToCart",
        "ecommerce": {
            "currencyCode": p.currencyCode,
            "add": {
              "products": [{
                "name": productName,
                "id": id,
                "price": p.price,
                "brand": "",
                "category": "",
                "variant": variantName,
                "quantity": quantity
               }]
            }
        }
    };
    _registerGtmDataLayer(obj);
}

function registerRemoveFromCart(productName, variantName, id, price, quantity){
    var p = _parseCurrencyString(price);

    const obj = {
        "event": "removeFromCart",
        "ecommerce": {
            "currencyCode": p.currencyCode,
            "remove": {
              "products": [{
                "name": productName,
                "id": id,
                "price": p.price,
                "brand": "",
                "category": "",
                "variant": variantName,
                "quantity": quantity
               }]
            }
        }
    };

    _registerGtmDataLayer(obj);
}

function registerProductDetails(productName, variantName, id, price){
    var p = _parseCurrencyString(price);

    const obj = {
      "ecommerce": {
        "currencyCode": p.currencyCode,
        "detail": {
          "actionField": {},    // "detail" actions have an optional list property.
          "products": [{
            "name": productName,         // Name or ID is required.
            "id": id,
            "price": p.price,
            "brand": "",
            "category": "",
            "variant": variantName
           }]
         }
       }
    };

    _registerGtmDataLayer(obj);
}

function registerViewAddon(productName){

    const obj = {
        "event": "trackPDP",
        "eventData": {
            "category": "Product detail page",
            "action": "View addons",
            "label": productName
        }
    };
    _registerGtmDataLayer(obj);
}

function registerCustomizer(productName){

    const obj = {
        "event": "trackPDP",
        "eventData": {
            "category": "Product detail page",
            "action": "Customizer",
            "label": productName
        }
    };
    _registerGtmDataLayer(obj);
}

function registerPictureClick(productName){

    const obj = {
        "event": "trackPDP",
        "eventData": {
            "category": "Product detail page",
            "action": "picture click",
            "label": productName
        }
    };
    _registerGtmDataLayer(obj);
}



function _getProductsFromString(productsAsString, listTypeName) {
    let products = [];
    try {
        products = JSON.parse(productsAsString);

    } catch(e) {
        console.error(e)
        return null;
    }

    if (!products || products.length < 1) return;

    let impressions = []
    let currencyCode = ""

    for (let i = 0; i < products.length; i++) {
        if (products[i].type !== "variant") continue

        let currentPrice = _parseCurrencyString(products[i].formattedPrice)
        currencyCode = currentPrice.currencyCode;
        let productName = _getProductFullName(products[i].productPrimaryName, products[i].productSecondaryName)
        let variantName = `${products[i].variantName}`
        let impression = {
            "name": productName,
            "id": products[i].code,
            "price": currentPrice.price,
            "brand": null,
            "category": null,
            "variant": variantName,
            "list": listTypeName,
            "position": i + 1
        }
        impressions.push(impression)
    }

    return (!impressions || impressions.length < 1) ? null : { impressions, "currencyCode": currencyCode };
}

function _getProductsFromQuickSearch(products, listTypeName) {
    let impressions = []
    let currencyCode = ""

    for (let i = 0; i < products.length; i++) {
        if (!products[i].sku) continue

        let currentPrice = _parseCurrencyString(products[i].unitPrice)
        currencyCode = currentPrice.currencyCode;
        let impression = {
            "name": products[i].name,
            "id": products[i].sku,
            "price": currentPrice.price,
            "brand": null,
            "category": null,
            "variant": products[i].variantName,
            "list": listTypeName,
            "position": i + 1
        }
        impressions.push(impression)
    }

    return (!impressions || impressions.length < 1) ? null : { impressions, "currencyCode": currencyCode };
}

function _getProductsFromSearchTileData(products, listTypeName) {
    let impressions = []
    let currencyCode = ""

    for (let i = 0; i < products.length; i++) {
        if (!products[i].code) continue

        let currentPrice = _parseCurrencyString(products[i].formattedPrice)
        let productName = _getProductFullName(products[i].productPrimaryName, products[i].productSecondaryName)
        currencyCode = currentPrice.currencyCode;
        let impression = {
            "name": productName,
            "id": products[i].code,
            "price": currentPrice.price,
            "brand": null,
            "category": null,
            "variant": products[i].variantName,
            "list": listTypeName,
            "position": i + 1
        }
        impressions.push(impression)
    }

    return (!impressions || impressions.length < 1) ? null : { impressions, "currencyCode": currencyCode };
}

function _getProductsFromCartItems(products) {
    if (!products || products.length < 1) return;

    let results = []
    let currencyCode = ""

    for (let i = 0; i < products.length; i++) {
        if (!products[i].sku) continue

        let currentPrice = _parseCurrencyString(products[i].unitPrice)
        currencyCode = currentPrice.currencyCode;
        let productName = _getProductFullName(products[i].primaryProductName, products[i].secondaryProductName);
        let variantName = products[i].variantName
        let result = {
            "name": productName,
            "id": products[i].sku,
            "price": currentPrice.price,
            "brand": null,
            "category": null,
            "variant": variantName,
            "quantity": products[i].quantity
        }
        results.push(result)
    }

    return (!results || results.length < 1) ? null : { "products": results, "currencyCode": currencyCode };
}

// productsInput: can be various depending on who sends the data [striing, array]
// listTypeName:
//  - recommendation-block
//  - search-overlay
//  - search-result
function registerImpression(productsInput, listTypeName){

    let result = []

    switch (listTypeName) {
        case "recommendation-block":
            result = _getProductsFromString(productsInput, listTypeName);
            break;
        case "search-overlay":
            result = _getProductsFromQuickSearch(productsInput, listTypeName);
            break;
        case "product-search-tile":
            result = _getProductsFromSearchTileData(productsInput, listTypeName);
            break;
        default:
            return;
    }

    if (!result || !result.impressions || result.impressions.length < 1) return;

    let obj = {
        "ecommerce": {
          "currencyCode": result.currencyCode,
          "impressions": result.impressions
        }
      };

    _registerGtmDataLayer(obj);
}

function registerCheckOut(productsInput, redirectUrl){
    try {
        var { products, currencyCode } = _getProductsFromCartItems(productsInput);

        let obj = {
            "event": "checkout",
            "ecommerce": {
                "currencyCode": currencyCode,
                "checkout": {
                    "actionField": {"step": 1, "option": null},
                    "products": products
                }
           },
           "eventCallback": () => {
              document.location = redirectUrl;
           }
        }

        _registerGtmDataLayer(obj);
    } catch(e) {
        console.error(e);
        document.location = redirectUrl;
    }
}

export {  registerAddToCart
        , registerRemoveFromCart
        , registerProductDetails
        , registerImpression
        , registerCheckOut
        , registerPictureClick
        , registerCustomizer
        , registerViewAddon
};
