import ProductLineSearch from "../../components/product-line/product-line-search";
import { registerImpression } from "../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer";
import { mapActions, mapMutations, mapState } from "vuex";
import Store from "@store/store";

import debounce from "lodash/debounce";

const debouncedPerformSearch = debounce(() => {Store.dispatch("performSearch");}, 500);

export default {
	name: "search-overlay",
	components: {
		ProductLineSearch,
	},
	props: {
		preSearchTerm: {
			type: String,
			required: false,
			default: "",
		},
		searchPageUri: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			searchTerm: "",
			searchTermSuggestionActive: false,
			minimumCharacters: 2,
			isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
			showGhostContent: false,
			showEmptySearch: false, //JPL: Set to true to show No results label
		};
	},
	watch: {
		searchTerm: function () {
			// do search when searchTerm is updated
			this.doSearch();
		},
	},
	computed: {
		...mapState({
			searchOverlayOpen: (state) => state.searchOverlayOpen,
		}),
		productsTotalLink() {
			return this.$store.state.search.productsTotalLink;
		},
		productsTotalCount() {
			return this.$store.state.search.productsTotalCount;
		},
		tagResults() {
			return this.$store.state.search.tagResults;
		},
		searchLoading() {
			return this.$store.state.search.searchLoading;
		},
		isMobile() {
			return this.$store.state.isMobile;
		},
		suggestionShow: function () {
			return this.searchTermSuggestion.length > 0;
		},
		showPopularSearches: function () {
			return this.searchTerm.length < this.minimumCharacters;
		},
		isActive: function () {
			if (this.searchOverlayOpen === true) {
				// COMMENT: When opening, set focus on input
				// Also fixes esc keypress issues

				this.$refs.searchInput.focus();

				if (this.isSafari && window.matchMedia("(max-width: 1200px)").matches) {
					setTimeout(() => {
						this.$refs.searchInput.focus();
						this.$el.querySelector(".search-overlay__inner").scrollTop = 0;
						window.scrollTop = 0;
						if (document.scrollingElement) {
							document.scrollingElement.scrollTop = 0; // ios fix for input out of view when opening search
						}
					}, 50); // COMMENT: Tiny delay so focus can be set first
				}

				this.showOverlayBackdrop(); // show backdrop action

				setTimeout(() => {
					this.$refs.searchInput.focus();
				}, 100);
			} else {
				// COMMENT: When closing, ex. via backdrop-click, reset results
				this.$nextTick(this.resetSearch);
			}
			return this.searchOverlayOpen;
		},
		searchTermSuggestion() {
			return this.$store.state.search.autoCompleteSuggestion;
		},
		productResults() {
			var products = this.$store.state.search.productResults;
			if (products && products.length > 1) {
				registerImpression(products, "search-overlay");
			}

			return products;
		},
	},
	mounted: function () {
		if (this.preSearchTerm != "") {
			this.searchTerm = this.$refs.searchInput.value = this.preSearchTerm; // COMMENT: If template supplies a searchTerm from backend
		}
	},
	methods: {
		...mapActions(["closeMainSearch", "showOverlayBackdrop", "hideOverlayBackdrop"]),
		...mapMutations(["updateSearchTerm", "updateAutoCompleteSuggestion", "clearResults", "updateUrlString"]),

		handlePopularClick(clickEvent, popularTerm) {
			this.searchTerm = popularTerm;
			this.$refs.searchInput.focus();
		},
		handleKeyinput(inputEvent) {
			if (this.searchTermSuggestion.length) {
				switch (inputEvent.key) {
					case "Enter":
						this.submit();
						break;
					case "Backspace":
						// COMMENT: Clear suggestion on backspace, and prevent clearing a charater
						inputEvent.preventDefault();
						this.updateAutoCompleteSuggestion("");
						break;
					case "Tab":
						this.searchTerm = this.searchTerm + this.searchTermSuggestion;
						this.updateAutoCompleteSuggestion("");
						break;
					case "ArrowRight":
						this.searchTerm = this.searchTerm + this.searchTermSuggestion;
						this.updateAutoCompleteSuggestion("");
						break;
				}
			}
		},
		handleTermSuggestionClick() {
			if (this.searchTermSuggestion.length) {
				this.searchTerm = this.searchTerm + this.searchTermSuggestion;
				this.updateAutoCompleteSuggestion("");

				if (this.isMobile) {
					this.$refs.searchInput.blur(); // unfocus input, to better show results on mobile
				}
			}
		},
		doSearch() {
			this.showGhostContent = true;
			this.showEmptySearch = true;

			this.clearResults();
			if (this.searchTerm.length >= this.minimumCharacters) {
				this.updateUrlString(`?q=${this.searchTerm}`);
				this.updateSearchTerm(this.searchTerm);
				debouncedPerformSearch();
			} else {
				this.updateSearchTerm("");
				this.updateUrlString(``);
				debouncedPerformSearch.cancel();
			}
		},
		submit() {
			window.location = `${this.searchPageUri}?q=${this.searchTerm}`;
		},
		close() {
			this.showGhostContent = false;
			this.closeMainSearch(); // action
			this.hideOverlayBackdrop();
			setTimeout(() => {
				// COMMENT: A tiny delay for cleared(after animation is done), to prevent ui jumping
				this.resetSearch();
			}, 550);
		},
		resetSearch() {
			this.showGhostContent = false;
			// this.$refs.searchInput.value = '';
			// this.searchTerm = '';

			// QUESTION: Empty products on reset?
			// this.clearResults(); // TODO - Consider if results should be cleared, in all scenarios
		},
	},
};
