var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cta && _vm.cta.type === "action"
    ? _c(
        "button",
        {
          class: _vm.tileClass,
          style: _vm.tileStyle,
          on: { click: _vm.clickHandler },
        },
        [
          _vm.media
            ? _c("figure", { staticClass: "inspiration-tile-v2__media" }, [
                _vm.media.type === "image"
                  ? _c("img", {
                      attrs: {
                        src: _vm.media.src,
                        alt: _vm.media.alt,
                        srcset: _vm.media.srcset,
                        sizes: _vm.media.sizes,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.media.type === "video"
                  ? _c("video", {
                      attrs: {
                        src: _vm.media.src,
                        muted: "",
                        autoplay: "",
                        loop: "",
                        playsinline: "",
                      },
                      domProps: { muted: true },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.trumpet
            ? _c("h6", { staticClass: "inspiration-tile-v2__trumpet" }, [
                _c("span", [_vm._v(_vm._s(_vm.trumpet))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.headline
            ? _c("h4", {
                staticClass: "inspiration-tile-v2__headline",
                domProps: { innerHTML: _vm._s(_vm.headline) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.bodyText
            ? _c("p", { staticClass: "inspiration-tile-v2__bodytext" }, [
                _vm._v(_vm._s(_vm.bodyText)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cta.text
            ? _c(
                "div",
                {
                  staticClass: "inspiration-tile-v2__cta",
                  class: _vm.cta.class,
                  style: _vm.cta.style,
                },
                [_c("span", [_vm._v(_vm._s(_vm.cta.text))])]
              )
            : _vm._e(),
        ]
      )
    : _vm.cta && _vm.cta.type === "navigation"
    ? _c(
        "a",
        {
          class: _vm.tileClass,
          style: _vm.tileStyle,
          attrs: { href: _vm.cta.value },
        },
        [
          _vm.media
            ? _c("figure", { staticClass: "inspiration-tile-v2__media" }, [
                _vm.media.type === "image"
                  ? _c("img", {
                      attrs: {
                        src: _vm.media.src,
                        alt: _vm.media.alt,
                        srcset: _vm.media.srcset,
                        sizes: _vm.media.sizes,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.media.type === "video"
                  ? _c("video", {
                      attrs: {
                        src: _vm.media.src,
                        muted: "",
                        autoplay: "",
                        loop: "",
                        playsinline: "",
                      },
                      domProps: { muted: true },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.trumpet
            ? _c("h6", { staticClass: "inspiration-tile-v2__trumpet" }, [
                _c("span", [_vm._v(_vm._s(_vm.trumpet))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.headline
            ? _c("h4", {
                staticClass: "inspiration-tile-v2__headline",
                domProps: { innerHTML: _vm._s(_vm.headline) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.bodyText
            ? _c("p", { staticClass: "inspiration-tile-v2__bodytext" }, [
                _vm._v(_vm._s(_vm.bodyText)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cta.text
            ? _c(
                "div",
                {
                  staticClass: "inspiration-tile-v2__cta",
                  class: _vm.cta.class,
                  style: _vm.cta.style,
                },
                [_c("span", [_vm._v(_vm._s(_vm.cta.text))])]
              )
            : _vm._e(),
        ]
      )
    : _c("article", { class: _vm.tileClass, style: _vm.tileStyle }, [
        _vm.media
          ? _c("figure", { staticClass: "inspiration-tile-v2__media" }, [
              _vm.media.type === "image"
                ? _c("img", {
                    attrs: {
                      src: _vm.media.src,
                      alt: _vm.media.alt,
                      srcset: _vm.media.srcset,
                      sizes: _vm.media.sizes,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.media.type === "video"
                ? _c("video", {
                    attrs: {
                      src: _vm.media.src,
                      muted: "",
                      autoplay: "",
                      loop: "",
                      playsinline: "",
                    },
                    domProps: { muted: true },
                  })
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.trumpet
          ? _c("h6", { staticClass: "inspiration-tile-v2__trumpet" }, [
              _c("span", [_vm._v(_vm._s(_vm.trumpet))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.headline
          ? _c("h4", {
              staticClass: "inspiration-tile-v2__headline",
              domProps: { innerHTML: _vm._s(_vm.headline) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.bodyText
          ? _c("p", { staticClass: "inspiration-tile-v2__bodytext" }, [
              _vm._v(_vm._s(_vm.bodyText)),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }