var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "variant-options" }, [
    !_vm.isMobile
      ? _c(
          "span",
          { staticClass: "variant-options__inner" },
          [
            _vm.variantLists && _vm.variantLists.length > 1
              ? _c("tabs-bar", {
                  attrs: {
                    "tab-click-handler": _vm.variantCategoryChangeHandler,
                    "active-index": _vm.activeVariantCategoryIndex,
                    items: _vm.variantLists,
                  },
                  inlineTemplate: {
                    render: function () {
                      var _vm = this
                      var _h = _vm.$createElement
                      var _c = _vm._self._c || _h
                      return _c("nav", { staticClass: "tabs-bar" }, [
                        _c(
                          "div",
                          { staticClass: "tabs-bar__inner" },
                          _vm._l(_vm.items, function (item, index) {
                            return _c(
                              "button",
                              {
                                key: index,
                                staticClass: "tabs-bar__button",
                                class: {
                                  "is-active": index === _vm.activeIndex,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.tabClickHandler(item, index)
                                  },
                                },
                              },
                              [
                                !_vm.isMobile && index === _vm.activeIndex
                                  ? _c(
                                      "svg",
                                      {
                                        staticClass: "svg-icon",
                                        attrs: { viewBox: "0 0 100 100" },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "/dist/svg/symbols.svg#svg-angle-arrow",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.name))]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    },
                    staticRenderFns: [],
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "variant-options__name" }, [
              _c("strong", [_vm._v(_vm._s(_vm.labelText) + ":")]),
              _vm._v(" " + _vm._s(_vm.selectedVariantName)),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { key: _vm.listKey, staticClass: "variant-options__list" },
              [
                _vm._l(_vm.currentVariantListItems, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: _vm.currentVariantList.name + index,
                      staticClass: "variant-options__item",
                      class: {
                        "is-active": item.isActive,
                        "is-hidden":
                          _vm.currentVariantListItems.length > 4 && index > 2,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: item.href },
                          on: {
                            mouseover: function ($event) {
                              return _vm.variantHoverHandler(index)
                            },
                            mouseout: function ($event) {
                              return _vm.variantUnhoverHandler(index)
                            },
                          },
                        },
                        [_c("img", { attrs: { src: item.image.src } })]
                      ),
                      _vm._v(" "),
                      _vm.currentVariantList.isEditable
                        ? _c(
                            "button",
                            {
                              staticClass: "variant-options__item__delete",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItemHandler(item)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { viewbox: "0 0 100 100" },
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/dist/svg/symbols.svg#svg-close",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.currentVariantListItems.length > 4
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "variant-options__item variant-options__item--more",
                      },
                      [
                        _c("button", { on: { click: _vm.showAllVariants } }, [
                          _c("span", [
                            _vm._v(
                              "+ " +
                                _vm._s(_vm.currentVariantListItems.length - 3)
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "button",
          {
            staticClass: "variant-options__inner",
            on: { click: _vm.showAllVariants },
          },
          [
            _c(
              "ul",
              { staticClass: "variant-options__list" },
              _vm._l(_vm.currentVariantListItems, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "variant-options__item",
                    class: {
                      "is-active": item.isActive,
                      "is-hidden":
                        _vm.currentVariantListItems.length > 3 && index > 3,
                    },
                  },
                  [_c("img", { attrs: { src: item.image.src } })]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "variant-options__item variant-options__item--more",
              },
              [
                _vm.currentVariantListItems.length > 3
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.currentVariantListItems.length - 3)),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "variant-options__icon svg-icon",
                attrs: { viewbox: "0 0 100 100" },
              },
              [
                _c("use", {
                  attrs: {
                    "xlink:href": "/dist/svg/symbols.svg#svg-plus-mini",
                  },
                }),
              ]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }