import Swiper from "swiper/dist/js/swiper.js";
import { inView } from "../../basics/js/methods/inView/inView";
import ImageProductsButton from "../image-products/image-products-button.vue"

/**
 * @prop cssClass {String} - 'slider--inspiration' will turn it into an inspirational slider
 * @prop settings {Object} - swiper option object, will overwrite default options
 */

export default {
	name: "slider",
	components: {
        ImageProductsButton,
	},
	props: {
		options: {
			type: Object,
			required: false,
		},
		slideCount: 0,
		type: String,
		slidesPerView: String,
	},
	data() {
		return {
			slider: null,
			showNavigation: true,

			defaultSettings: {
				slidesPerView: 1,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				pagination: true,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				preloadImages: false,
				watchOverflow: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 3,
				},
			},
		};
	},
	computed: {
		settings() {
			return Object.assign({}, this.defaultSettings, this.options);
		},
	},
	mounted() {
		const productName = this.$el.getAttribute('data-tracking-title');

		if (this.settings.pagination) {
			Object.assign(this.settings, {
				pagination: {
					el: this.$refs["slider-pagination"],
				},
			});
		}
		if (productName && productName !== "") {
			Object.assign(this.settings, {
				on: {
					slideChange: function(){
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							"event": "trackPDP",
							"eventData": {
								"category": "Product detail page",
								"action": "Carousel slider click",
								"label": this.activeIndex + 1
							}
						});
					},
					click: function (e) {
						const { target } = e;
						if (
							!target.classList.contains('swiper-button-next') &&
							!target.classList.contains('swiper-button-prev')
						) {
							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push({
								"event": "trackPDP",
								"eventData": {
									"category": "Product detail page",
									"action": "Carousel click",
									"label": productName
								}
							});
						}
					}
				}
			});
		}
		if (this.slideCount < 2) {
			this.showNavigation = false;

			Object.assign(this.settings, {
				slidesPerView: 1,
				navigation: {},
				loop: false,
			});
		}
		this.slider = new Swiper(this.$refs.slider, this.settings);
		this.$emit("ready", this.slider);

		inView({
			el: this.$refs.slider,
			once: true,
			threshold: 0.2
		});
	},
};
