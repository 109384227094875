/** 
 * this file contains reusable validation rules
 * https://vuelidate.netlify.com/
 */

import { email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';

// mapping to backend-generated names
const userInput = {
    ownReference: "DataModel_UserInput_OwnReference",
    comment: "DataModel_UserInput_Comment",
    action: "action",
    campaignCode: "campaign-code",
    referencePerson: {
        externalId: "DataModel_UserInput_ReferencePerson_ExternalId",
        isNewPerson: "DataModel_UserInput_ReferencePerson_IsNewPerson",
        name: "DataModel_UserInput_ReferencePerson_Name",
        email: "DataModel_UserInput_ReferencePerson_Email"
    },
    address: {
        externalId: "DataModel_UserInput_Address_ExternalId",
        isNewAddress: "DataModel_UserInput_Address_IsNewAddress",
        addressLine: "DataModel_UserInput_Address_AddressLine",
        zipcode: "DataModel_UserInput_Address_Zipcode",
        country: "DataModel_UserInput_Address_Country",
        attention: "DataModel_UserInput_Address_Attention"
    }
  };
  
Object.freeze(userInput);

export default {
    form: {

        [userInput.action]: {},
        [userInput.ownReference]: {},
        [userInput.campaignCode]: {},
        // Referenced person
        [userInput.referencePerson.externalId]: {
            required: requiredIf(function() {
                return !this.form.DataModel_UserInput_ReferencePerson_IsNewPerson;
            })
        },
        [userInput.referencePerson.isNewPerson]: {},
        [userInput.referencePerson.name]: {
            required: requiredIf(function(){
                return this.form.DataModel_UserInput_ReferencePerson_IsNewPerson;
            })
        },
        [userInput.referencePerson.email]: {
            required: requiredIf(function(){
                return this.form.DataModel_UserInput_ReferencePerson_IsNewPerson;
            }),
            email
        },
        // Address
        [userInput.address.isNewAddress]: {},
        [userInput.address.externalId]: {
            required: requiredIf(function(){
                return !this.form.DataModel_UserInput_Address_IsNewAddress;
            })
        },
        [userInput.address.addressLine]: {
            required: requiredIf(function(){
                return this.form.DataModel_UserInput_Address_IsNewAddress;
            }),
            minLength: minLength(4),
            maxLength: maxLength(120)
        },
        [userInput.address.zipcode]: {
            required: requiredIf(function(){
                return this.form.DataModel_UserInput_Address_IsNewAddress;
            }),
            minLength: minLength(4),
            maxLength: maxLength(50)
        },
        [userInput.address.country]: {
            required: requiredIf(function(){
                return this.form.DataModel_UserInput_Address_IsNewAddress;
            }),
            minLength: minLength(2),
            maxLength: maxLength(50)
        },
        [userInput.address.attention]: {
            required: requiredIf(function(){
                return this.form.DataModel_UserInput_Address_IsNewAddress;
            }),
            minLength: minLength(2),
            maxLength: maxLength(50)
        },
        // Comment
        [userInput.comment]: {
            maxLength: maxLength(400)
        },

    },
    validationFields: userInput
};