import TileFilter from "../product-filter/tile-filter";
import TileList from "../tile-list/tile-list";
import ProductTile from "../product-tile/product-tile";
import InspirationTile from "../inspiration-tile/inspiration-tile";
import InspirationTileV2 from "../inspiration-tile-v2/inspiration-tile-v2";
import UspSpot from "../usp-spot/usp-spot";
import DataTableScroll from "../data-table/data-table-scroll";

export default {
	name: "horizontal-collapsible",
	components: {
		TileFilter,
		TileList,
		ProductTile,
		InspirationTile,
		InspirationTileV2,
		UspSpot,
		DataTableScroll,
	},
	data() {
		return {
			testActive: false,
		};
	},
	props: {
		stateKey: {
			type: String,
			required: true,
		},
	},
	computed: {
		isExpanded() {
			return this.$store.state[this.stateKey];
		},
	},
};
