
import CartCore from './cart-core';
import { mapActions } from 'vuex';
import { GET_CART_ITEMS } from '@store/static';

export default {
	name: 'mini-cart',
	extends: CartCore,
	data(){
		return {
			addCommentMode: false,
			storedItemHeight: 0,
		};
	},
	computed: {
		isLoaded: function(){
			return this.$store.state.cartLoaded && this.isActive;
		},
		isLoading: function() {
			this.$store.state[GET_CART_ITEMS.loadingKey];
		},
		isActive: function(){
			if(this.$store.state.miniCartActive === true){ // when opening
				this.$el.focus(); // in order to make esc keypress work
				this.showOverlayBackdrop('behindHeader'); // COMMENT: Sets the backdrop at the correct z-index
			}
			return this.$store.state.miniCartActive;
		},
	},
	mounted(){
		// console.warn('mini cart isActive: ', this.isActive);
	},
	methods: {
		...mapActions([
			'closeMiniCart',
			'showOverlayBackdrop',
			'hideOverlayBackdrop',
		]),
		close(){
			this.hideOverlayBackdrop(); // action
			this.closeMiniCart(); // action
		},
	},
};
