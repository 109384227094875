import { mapState, mapActions } from "vuex";

import cloneDeep from "lodash/cloneDeep";

import Modal from "../../components/modal/modal";
import DataTableLabel from "../../components/data-table/data-table-label";
import Gtm from "../../basics/js/methods/gtmlDataLayer/GoogleTagManager"
import _ from "lodash";
import urlComposer from "../../basics/js/methods/urlComposer/urlComposer";

const notSortedValue = "not-sorted-by";

const createHeaderData = function (headerObj, defaultSort) {
	const returnArrayData = [];

	for (const header in headerObj) {
		returnArrayData.push({
			key: header,
			label: headerObj[header].toUpperCase(),
			sort: defaultSort.sortBy === header ? defaultSort.sortDirection : notSortedValue,
		});
	}

	return returnArrayData;
};

const highlight = ({ substr, string, color }) => {
	return string.replace(new RegExp(substr, "gi"), (match) => {
		return `<span style='background: ${color}'>${match}</span>`;
	});
};

export default {
	name: "table-component",
	components: {
		Modal,
		DataTableLabel,
	},
	data() {
		return {
			headerData: this.header
				? createHeaderData(JSON.parse(this.header), { sortBy: this.sortBy, sortDirection: this.sortDirection })
				: {},
			isLoading: this.data ? false : true, // not in use, but nice to have
			parsedData: this.data ? JSON.parse(this.data) : this.fetchEndpoint(this.endpoint),

			allowSorting: !!(this.sortBy && this.sortDirection),
			currentPage: 1,
			sortUrlParams: "",
			tableParameters: {
				skip: 0,
				take: this.pageSize,
				sortBy: this.sortBy,
				sortDirection: this.sortDirection,
				nextPage: this.currentPage,
			},
			selectedRow: null,
			selectedRowPayload: null,
			searchInput: "",
			debouncedFetchComposedEndpoint: _.debounce(this.fetchComposedEndpoint, 750),
		};
	},
	props: {
		endpoint: String,
		useSearchFacets: Boolean,
		data: {
			type: String,
			// required: true,
		},
		header: {
			type: String,
			required: true,
		},
		sortBy: {
			type: String,
			required: true,
		},
		sortDirection: {
			type: String,
			required: true,
		},
		recordLink: {
			type: String,
		},
		highlightSearchResults: {
			type: Boolean,
			default: true,
		},
		searchHighlightColor: {
			type: String,
			default: "yellow",
		},
		searchableHeaders: {
			type: String,
		},
		pageSize: {
			type: Number,
			default: 10
		},
		parameters:{
			type: String,
			default: "",
		},
		eventName:{
			type: String,
			default: ""
		},
		searchAboveTable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({
			selectedFacets: (state) => state.search.selectedFacets,
			searchQuery: (state) => state.search.searchQuery,
		}),
		tableData() {
			return this.parsedData instanceof Promise ? [] : this.parsedData.data;
		},
		totalRows() {
			return this.parsedData instanceof Promise ? 0 : this.parsedData.total;
		},
		filteredDataBasedOnSearchInput() {
			if (this.endpoint) {
                this.tableParameters.skip = 0;

				return this.tableData; // client side filtering is not in use, so just return tableData
			}
			if (!this.tableData) {
				console.error("No table data recieved, or it has the wrong format. Type recieved: ", this.tableData);
				return [];
			}
			// return data unaltered when no searchbar/searchinput
			if (this.searchInput === "") return this.tableData;
			return this.tableData.filter((row) => {
				return Object.entries(row).find(([key, value]) => {
					// if searchableHeaders is activated,
					// don't accept any key/header that's not searchable,
					if (this.searchableHeaders && !this.searchableHeaders.includes(key)) {
						return;
					}
					// don't search nested values
					if (typeof value !== "string") return;
					return value.toLowerCase().includes(this.searchInput.toLowerCase());
				});
			});
		},
		highlightedValuesBasedOnSearchInput() {
			// return data unaltered if highlighting is disabled
			if (!this.highlightSearchResults || this.searchInput === "") return this.filteredDataBasedOnSearchInput;

			return this.filteredDataBasedOnSearchInput.map((rowsWithSearchHit) => {
				// rowsWithSearchHit:
				// {
				// 	orderNr: '340598',
				// 	total: '4857,-'
				//  ...
				// }
				return Object.entries(rowsWithSearchHit).reduce((acc, [key, value]) => {
					// don't highlight a non-searchable column, even if it's a hit
					if (this.searchableHeaders && !this.searchableHeaders.includes(key)) {
						return { ...acc, [key]: value };
					}
					// key === orderNr
					// value === '340598',
					const highlighted =
						typeof value === "string"
							? highlight({
									substr: this.searchInput,
									string: value,
									color: this.searchHighlightColor,
							})
							: value;
					// build the object back up, where any searchhit is wrapped in a <span>
					return { ...acc, [key]: highlighted };
				}, {});
			});
		},
		// table data may not be in the correct order. Sort order of table data to match header col position.
		// Important: Any cols not present in the header will not be shown
		tabelDataMappedToHeader() {
			const originalData = cloneDeep(this.highlightedValuesBasedOnSearchInput);
			const colSequence = Object.keys(JSON.parse(this.header));
			const mappedToHeaderSequence = originalData.map((originalRow) => {
				const mappedRow = {};
				for (let col of colSequence) {
					mappedRow[col] = originalRow[col];
				}
				return mappedRow;
			});
			return mappedToHeaderSequence;
		},
		uniqueRowLinks() {
			const originalData = cloneDeep(this.highlightedValuesBasedOnSearchInput);
			const rowLinkList = originalData.map((originalRow) => {
				let rowLink = "";
				for (let col in originalRow) {
					if (col === "rowLink") {
						rowLink = originalRow[col];
						return rowLink;
					}
				}

				if (this.recordLink !== undefined) {
					rowLink = this.recordLink;
					for (let col in originalRow) {
						rowLink = rowLink.replace("{" + col + "}", originalRow[col]);
					}
				}

				return rowLink;
			});
			return rowLinkList;
		},
		totalPageCount() {
			return Math.ceil(this.totalRows / this.pageSize);
		},
		showPager() {
			return this.totalPageCount > 1;
		},
		pagerData() {
			const pagerData = [];
			const maxPageDisplayCount = 3;
			let firstVisiblePageNumber = 1;
			let lastVisiblePageNumber = 4;
			let showDotsBefore = false;
			let showDotsAfter = this.totalPageCount > 4;
			// what page numbers are we to show
			// should there be dots at the beginning and after
			if (this.currentPage > 3) {
				firstVisiblePageNumber = this.currentPage - 2;
				showDotsBefore = true;
			}
			if (showDotsAfter && this.currentPage >= this.totalPageCount - 1) {
				showDotsAfter = false;
			}
			lastVisiblePageNumber =
				this.totalPageCount < maxPageDisplayCount ? this.totalPageCount : firstVisiblePageNumber + maxPageDisplayCount;
			if (lastVisiblePageNumber >= this.totalPageCount) {
				lastVisiblePageNumber = this.totalPageCount;
				firstVisiblePageNumber =
					this.totalPageCount <= maxPageDisplayCount
						? firstVisiblePageNumber
						: this.totalPageCount - maxPageDisplayCount;
			}
			// pager requires a data object to render from
			if (showDotsBefore) {
				pagerData.push({ pagerType: "dotsBefore" });
			}
			for (let page = firstVisiblePageNumber; page <= lastVisiblePageNumber; page++) {
				pagerData.push({
					pagerType: "goToPage",
					target: page,
					displayedPage: this.currentPage === page,
				});
			}
			if (showDotsAfter) {
				pagerData.push({ pagerType: "dotsAfter" });
			}

			return pagerData;
		},
		pagerArrowBefore() {
			return this.currentPage > 1;
		},
		pagerArrowAfter() {
			return this.totalPageCount > this.currentPage;
		},
	},
	methods: {
		...mapActions(["generalError"]),
		async fetchEndpoint(endpoint) {
			if (!endpoint) {
				console.error("Endpoint must be a string. Reciever endpoint:", endpoint);
				this.isLoading = false;
				return {};
			}
			const data = await fetch(endpoint)
				.then((res) => res.json())
				.catch(console.warn);
			this.isLoading = false;
			this.parsedData = data;
		},
		actionCell(config, tableRow) {
			switch (config.type) {
				case "link":
					window.location = config.data;
					break;
				case "tab":
					window.open(config.data, "_blank");
					break;
				case "modal":
					this.selectedRow = tableRow;
					this.openModal(config.data);
					break;
				case "modalwithpayload":
					this.selectedRow = tableRow;
					this.selectedRowPayload = config.payload;
					this.openModal(config.data);
					break;
				default:
					console.error("No matching type for actionCell. Type recieved: ", config.type);
			}
		},
		sortData(colKey) {
			// reset all sort indicators
			this.headerData = this.headerData.map((header) => {
				header.sort = header.key !== colKey ? notSortedValue : header.sort;
				return header;
			});

			// locate reference of col we want to sort and set correct order
			const headerCol = this.headerData.find((header) => header.key === colKey);
			headerCol.sort = headerCol.sort === "ascending" ? "descending" : "ascending";

			this.tableParameters = Object.assign({}, this.tableParameters, {
				take: this.pageSize,
				skip: 0,
				sortBy: colKey,
				sortDirection: headerCol.sort,
				nextPage: 1, // forced reset to first page when sorting
			});
		},
		goToPage(requestedPagenumber) {
			const skip = (requestedPagenumber - 1) * this.pageSize;
			const take = this.pageSize;

			this.tableParameters = Object.assign({}, this.tableParameters, {
				skip,
				take,
				nextPage: requestedPagenumber,
			});
		},

		fetchComposedEndpoint() {
			let composedEndpointUrl = `${this.endpoint}?skip=${this.tableParameters.skip}&take=${this.pageSize}&sortBy=${this.tableParameters.sortBy}&sortDirection=${this.tableParameters.sortDirection}&q=${this.searchInput}&${this.parameters}`;

			if ( this.useSearchFacets ) {
				let queryString = urlComposer({facets:this.selectedFacets, searchQuery:this.searchQuery})
				queryString = queryString.slice(1);
				composedEndpointUrl += queryString;
			}

			Gtm.PushSearchEvent(this.eventName, this.searchInput, this.tableParameters.skip, this.pageSize);
			fetch(composedEndpointUrl, {
				credentials: "include",
			})
				.then((res) => {
					if (res.ok) {
						return res.json();
					}
					throw new Error("Table data fetching failed, no data loaded");
				})
				.then((json) => {
					this.parsedData = json;
					this.currentPage = this.tableParameters.nextPage;
				})
				.catch((err) => {
					console.error(err);
					this.generalError();
				});
		},
	},
	watch: {
		selectedFacets() {
			this.fetchComposedEndpoint();
		},
		tableParameters() {
			this.fetchComposedEndpoint();
		},
		searchInput() {
			if (this.endpoint) {
				this.debouncedFetchComposedEndpoint();
			}
		},
	},
};
