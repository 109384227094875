var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "model-selector" }, [
    _c(
      "ul",
      { staticClass: "model-selector__list" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "li",
          { key: item.id, staticClass: "model-selector__list-item" },
          [
            _c(
              "button",
              {
                staticClass: "model-selector__button",
                class: { "is-active": _vm.isItemActive(item.id) },
                on: {
                  click: function ($event) {
                    return _vm.selectModel(item)
                  },
                },
              },
              [
                item.image
                  ? _c("img", {
                      attrs: { src: item.image, alt: item.imageAlt },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { viewBox: "0 0 100 100" },
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href": "/dist/svg/symbols.svg#svg-angle-arrow",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.name || item.value))]),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }