export default [
    {
        imageSrcDefault: "/static/images/product/fiber-armchair-700w.png",
        imageSrcAlternative: "/static/images/hero-portrait.jpg",
        name: "Outline",
        url:"/#"
    },
    {
        imageSrcDefault: "/static/images/product/fiber-armchair-700w.png",
        imageSrcAlternative: "/static/images/hero-portrait.jpg",
        name: "Oslo",
        url:"/#"
    },
    {
        imageSrcDefault: "/static/images/product/fiber-armchair-700w.png",
        imageSrcAlternative: "/static/images/hero-portrait.jpg",
        name: "In Situ",
        url:"/#"
    },
    {
        imageSrcDefault: "/static/images/product/fiber-armchair-700w.png",
        imageSrcAlternative: "/static/images/hero-portrait.jpg",
        name: "Lorem",
        url:"/#"
    },
    {
        imageSrcDefault: "/static/images/product/fiber-armchair-700w.png",
        imageSrcAlternative: "/static/images/hero-portrait.jpg",
        name: "Ipsum",
        url:"/#"
    },
    {
        imageSrcDefault: "/static/images/product/fiber-armchair-700w.png",
        imageSrcAlternative: "/static/images/hero-portrait.jpg",
        name: "Dolor",
        url:"/#"
    },
]