import mapResultData from '../methods/mapResultData/mapResultData'
import urlComposer from '../../methods/urlComposer/urlComposer'
import { registerImpression } from "../../methods/gtmlDataLayer/registerGtmDataLayer"

const state = {
    results: {},
    searchTerm: "",
    autoCompleteSuggestion: "",
    searchLoading: false,
    filteringInProgress: false,
    facets: [],
    selectedFacets: [],
    productResults: [],
    productsTotalLink: "",
    productsTotalCount: 0,
    tagResults: [],
    urlString: "",
    tileListData: [],
    availableTotalResults: 0,
    filterSkip: 0,
    filterTake: 0,
    visibleResults: 0,
    pageId: "",
    filterEndpoint: "",
    searchQuery: "",
    excludeEditorPicks: false
}

const mutations = {
    updateFilteringInprogress(state,status){
        state.filteringInProgress = status
    },
    updatePageId(state,pageId){
        state.pageId = pageId
    },
    updateFilterEndpoint(state,filterEndpoint){
        state.filterEndpoint = filterEndpoint
    },
    updateFilterTake(state,take){
        state.filterTake = take
    },
    updateFilterSkip(state,skipCount){
        state.filterSkip = skipCount
    },
    updateExcludeEditorPicks(state,exclude){
        state.excludeEditorPicks = exclude
    },
    updateVisibleResults(state,count){
        state.visibleResults = count
    },
    updateResults(state,results){
        state.results = results;
        state.productResults = results.products
        state.autoCompleteSuggestion = results.autoCompleteSuggestion
        state.tagResults = results.tags
        state.productsTotalLink = results.productsTotalLink
        state.productsTotalCount = results.productsTotalCount

    },
    updateTileListData(state,results){
        state.tileListData = state.filterSkip
            ? state.tileListData.concat(results)
            : results
    },
    updateAvailableTotalResults(state,count){

        state.availableTotalResults = count
    },
    clearResults(state){
        state.results = {};
        state.productResults = []
        state.autoCompleteSuggestion = ""
        state.tagResults = []
        state.productsTotalLink = ""
        state.productsTotalCount = 0

    },
    updateSearchTerm(state,searchTerm){
        state.searchTerm = searchTerm;
    },
    updateSearchLoading(state,status){
        state.searchLoading = status
    },
    updateAutoCompleteSuggestion(state,suggestion){
        state.autoCompleteSuggestion = suggestion
    },
    updateUrlString(state,string){
        state.urlString = string
    },
    updateFacets(state,facets){
        state.facets = facets
    },
    mapFacets(state,jsonFacets){

		const parseFilters = (facetValues) => {
			return facetValues.map(value => {
				return {
					name: value.name,
					id: value.id,
					tooltip: value.tooltip,
					separated: !!value.separated,
					active: value.active ? value.active : true //to ensure default value is true
				}
			});
		};

        if(state.facets.length){
            console.log("facets already there, compare with new facets from service and deactivate the ones not present there")
        } else {
            state.facets = jsonFacets.map(facet => {
				let categories = [];
				if ( !facet.categories ) {
					categories.push({id: null, name: null, filters: parseFilters(facet.facetValues)});
				} else {
					categories = facet.categories.map(category => {
						const categoryValue = {
							id: category.id,
							name: category.name,
							tooltip: category.tooltip,
							filters: parseFilters(facet.facetValues.filter(facetValue => facetValue.category === category.id))
						}
						if ( facet.showAllValue ) {
							categoryValue.filters.unshift({id: "all-value", name: facet.allValue});
						}
						return categoryValue;
					});
				}

                return {
                    id: facet.id,
                    title: facet.name,
                    active: facet.active,
					hasSearch: !!facet.hasSearch,
					controlType: facet.controlType || "checkbox",
					showAll: facet.showAllValue ? facet.allValue : null,
                    categories: categories
                }
            })
        }
    },
    updateSelectedFacets(state,updatedFacets){
        state.selectedFacets = updatedFacets
    },
    updateSearchQuery(state,updatedSearchQuery){
        state.searchQuery = updatedSearchQuery
    },
    hydrateSelectedFacets(state){

        // apply label to selected facets
        // if selectedFacets are generated from url, the labels will be missing
        // run this mutation to apply labels from facet store

		console.log("hydrating selected facets");

        const facets = state.facets
        const selectedFacets = state.selectedFacets

        const hydratedSelectedFacets = selectedFacets.map(selectedFacet => {

			//use find to find exact match

            const matchingFacet = facets.find(facet => facet.id === selectedFacet.id)

            if(matchingFacet){
				selectedFacet.facetValues.map(value => {
					//extract facetValue name from inside facets.categories.filters using find to find exact match
					const matchingFilter = matchingFacet.categories.map(category => {
						return category.filters.find(filter => filter.id === value.id)
					}).find(filter => filter)

					if(matchingFilter){
						value.name = matchingFilter.name
					} else {
						value.name = "VALUE NOT FOUND"
					}
					return value;
                })
            }

            return selectedFacet
        })

        state.selectedFacets = hydratedSelectedFacets

    }

}

const actions = {
    filterInitialLoad(context,{initialFacets,initialResults,initialAvailableTotalResults,initialSelectedFacets,initialSearchQuery,initialPageSize,excludeEditorPicks,initialTake, pageId, filterEndpoint,}){
        if(pageId){
            context.commit('updatePageId',pageId)
        }
        if(filterEndpoint){
            context.commit('updateFilterEndpoint', filterEndpoint)
        }
        if(initialFacets){
            context.commit('mapFacets',initialFacets)
        }
        if(initialSelectedFacets){
            context.commit('updateSelectedFacets',initialSelectedFacets)
        }
        if(initialResults){
            context.commit('updateTileListData',initialResults)
        }
        if(initialAvailableTotalResults){
            context.commit('updateAvailableTotalResults',initialAvailableTotalResults)
        }
        if(initialPageSize) {
            context.commit('updateVisibleResults',initialPageSize)
        }
        if(initialTake){
            context.commit('updateFilterTake',initialTake)
        }
        if(initialSearchQuery){
            context.commit('updateSearchQuery',initialSearchQuery)
        }
        context.commit('updateExcludeEditorPicks',excludeEditorPicks)
        // add skip and take values
    },
    performSearch(context){

        context.commit('updateSearchLoading',!context.state.searchLoading)

        const composedUrl = `${process.env.SEARCH_ENDPOINT}${context.state.urlString}`

        fetch(composedUrl,{
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        .then(res => {
            if(res.ok){
                return res
            } else {
                throw new Error("Search failed on endpoint");
            }
        })
        .then(res => res.json() )
        .then(json => {
            context.commit('updateResults',mapResultData(json,context.state.searchTerm));
            context.commit('updateSearchLoading',!context.state.searchLoading);
        }).catch(err => {
            context.commit('updateSearchLoading',!context.state.searchLoading);
            context.dispatch('stickyError',err)
        });

    },
    performProductFiltering(context){
        const endpointUrl = context.state.filterEndpoint ? context.state.filterEndpoint : process.env.LIST_ENDPOINT
        const endpoint = endpointUrl + "?pageId=" + context.state.pageId;
        const url = urlComposer({
            endpoint,
            facets:context.state.selectedFacets,
            skip: context.state.filterSkip,
            take: context.state.filterTake,
            searchQuery: context.state.searchQuery,
            excludeEditorPicks: context.state.excludeEditorPicks
        })

        context.commit('updateFilteringInprogress',true)

        fetch(url,{
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        .then(res => {
            if(res.ok){
                return res
            } else {
                throw new Error("Filtering failed on endpoint");
            }
        })
        .then(res => res.json() )
        .then(json => {
            context.commit('mapFacets',json.facets)
            context.commit('hydrateSelectedFacets')
            context.commit('updateTileListData',json.results)
            context.commit('updateAvailableTotalResults',json.totalHits)
            context.commit('updateVisibleResults',context.state.visibleResults+json.hits)
            context.commit('updateFilteringInprogress',false)
            context.dispatch('registerItemsForDataLayer', json.results)
        })
        .catch(err => {
            context.commit('updateFilteringInprogress',false)
            context.dispatch('stickyError',err)
        });

    },
    registerItemsForDataLayer(context, listOfProducts){
        registerImpression(listOfProducts, "product-search-tile")
    }
}


export default { state, actions, mutations }
