import getSuggestion from './methods/getSuggestion'
import mapProducts from './methods/mapProducts'
import mapSuggestions from './methods/mapSuggestions'
import sortSuggestions from './methods/sortSuggestions'

const mapResultData = function(data,searchTerm){
    
    const searchAsYouType = data.searchAsYouType;
    const autoCompleteSuggestions = sortSuggestions(data.autoComplete)
    const searchResultPageUrl = data.searchResultPageUrl || "/search/"

    const mappedData = {
        autoCompleteSuggestion: getSuggestion(autoCompleteSuggestions,searchTerm),
        productsTotalCount: searchAsYouType.totalHits,
        productsTotalLink: searchResultPageUrl + `?q=${searchTerm}`,
        products: mapProducts(searchAsYouType.queryResult),
        tags: mapSuggestions(autoCompleteSuggestions, searchResultPageUrl)
    }

    return mappedData

 }

 export default mapResultData