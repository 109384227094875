<script>

import { inView } from "../../../basics/js/methods/inView/inView";

export default {
    name: "story-slide",
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        slideIndex: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        slideStyle() {
			if ( !this.item ) return {};
            const { textColor, textColor2, backgroundColor } = this.item;
            return {
                "--story-slider-text1-color": textColor || null,
				"--story-slider-text2-color": textColor2 || textColor,
				"--story-slider-bg-color": backgroundColor || null,
			};
		},
        buttonStyle() {
            if ( !this.item ) return {};
            const { textColor, textColor2, backgroundColor } = this.item;
            return {
                "--custom-color-1": textColor2 || textColor,
                "--custom-color-2": backgroundColor,
                "--custom-color-3": textColor2 || textColor,
                "--custom-color-4": textColor2 || textColor
            };
        },
        buttonHref() {
            return this.item && this.item.buttonHref;
        },
        buttonText() {
            return this.item && this.item.buttonLabel;
        },
    },
	mounted() {
        inView({
			el: this.$el,
			threshold: 0.5
		});
	}
};
</script>
