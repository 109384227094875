// inspiration
// https://jsfiddle.net/adamwathan/xcoefffj/
//
//
import AccordionGroup from '../../components/accordion/accordion-group';
const hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

export default {
	name: 'modal',
	template: '#modal-template',
	components: {
		AccordionGroup
	},
	props: {
		name: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: false
		},
		body: {
			type: String,
			requred: false
		},
		footer: {
			type: String,
			requred: false,
			default: null
		},
		type: {
			type: String,
			required: false,
			default: 'modal'
		},
		size: {
			type: String,
			required: false,
			default: 'normal'
		},
		acceptButton: {
			type: String,
			required: false,
			default: null
		},
		cancelButton: {
			type: String,
			required: false,
			default: null
		},
		cssClass: {
			type: String,
			default: ''
		},
		backdropColor: {
			type: String,
			default: "#000000"
		},
		bodyColor: {
			type: String,
			default: "#ffffff"
		}
		
	},
	data() {
		return {
			
		}
	},
	computed: {
		css() {
			return {
				[this.cssClass]: true,
				'modal--dialog': this.modalType === 'dialog',
				'modal--body-bottom-space': !this.hasFooter

			}
		},
		colors() {
			return `--modal-backdrop-color: rgba(${hexToRgb(this.backdropColor)}, 0.8); --modal-body-color: ${this.bodyColor};`;
		},
		status() {
			return this.$store.state.modalStatus.status;
		},
		activeName() {
			return this.$store.state.modalStatus.name;
		},
		showModal() {
			const open = this.$store.state.modalStatus.name === this.name;
			// if a callback is binded we call it on open
			if(open) this.open();
			return open;
		},
		modalType() {
			return this.acceptButton ? 'dialog' : 'modal';
		},
		hasFooter() {
			return this.$slots['footer'] || this.acceptButton || this.cancelButton;
		}
	},
	methods: {
		open() {
			this.$emit('before-open');
		},
		close() {
			this.$emit('before-close'); 
			this.$store.dispatch('closeModal');
		},
		onAccept() {
			this.close();
			this.$emit('on-accept');
		},
		onCancel() {
			this.close();
			this.$emit('on-cancel');
		},
		afterEnter() {
			this.$refs.closeButton.focus();
		},
		dialogAccept() {
			this.$emit('before-close'); 
			this.$emit('dialog-accept'); 
			this.$store.dispatch('closeModal');
		}
	},
};
