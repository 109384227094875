import { mapState } from "vuex";

import { rInterval } from "./../../basics/js/utils";

export default {
	name: "stores-navigation",
	props: {
		backgroundHoverColor: {
			type: String,
		},
		storesLabel: {
			type: String,
			default: "Showrooms",
		},
		links: {
			type: Array,
			default() {
				return [
					{
						href: "#copenhagen",
						title: "Copenhagen",
						address: "Vejvej 256<br/>12345 City<br/>Denmark",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/400/600",
					},
					{
						href: "#stockholm",
						title: "Stockholm",
						address: "Streetstreet 14<br/>12345 City<br/>Stockholm",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/600/300",
					},
					{
						href: "#hamburg",
						title: "Hamburg",
						address: "Old street 8<br/>12345 City<br/>Germany",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/404/400",
					},
					{
						href: "#london",
						title: "London",
						address: "Testroad 2<br/>12345 City<br/>England",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/500/600",
					},
					{
						href: "#paris",
						title: "Paris",
						address: "Lorem ipsum square 46<br/>12345 City<br/>France",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/600/600",
					},
					{
						href: "#aarhus",
						title: "Aarhus",
						address: "Roadname 14<br/>12345 City<br/>Denmark",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/600/500",
					},
					{
						href: "#shanghai",
						title: "Shanghai",
						address: "Roadname 14<br/>12345 City<br/>Shanghai",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/430/530",
					},
					{
						href: "#oslo",
						title: "Oslo",
						address: "Roadname 14<br/>12345 City<br/>Norway",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/400/210",
					},
					{
						href: "#cologna",
						title: "Cologna",
						address: "Roadname 14<br/>12345 City<br/>Germany",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/560/410",
					},
					{
						href: "#chicago",
						title: "Chicago",
						address: "Roadname 14<br/>12345 City<br/>USA",
						hours: "Monday 7–8<br/>Tuesday–Sunday: closed",
						imageSrc: "https://picsum.photos/200/300",
					},
				];
			},
		},
	},
	data() {
		return {
			bodyRef: null,
			bodyBackgroundColor: null,

			activeItem: null,
			activeIndex: -1,
			imageLoaded: false,

			imageX: 0,
			imageY: 0,

			offsetTop: 0,
			offsetHeight: 0,

			labelHeight: 0,
			listHeight: 0,

			mobileBehaviour: false,

			lineHeight: 0,
			scrollPanelHeight: 0,

			currentLine: 0,

			clearInterval: null,

			linksLength: 0,

			isFixed: false,

			scrollPosTranslated: 0,

			imageTimeoutHandle: -1,
		};
	},
	computed: {
		...mapState({
			windowH: (state) => state.windowH,
			windowW: (state) => state.windowW,
			windowY: (state) => state.windowY,
		}),
		moduleStyle() {
			let baseStyle = `
				--label-height: ${this.labelHeight}px;
				--list-height: ${this.listHeight}px;
				--line-height: ${this.lineHeight}px;
				--scroll-pos: ${this.scrollPosTranslated};
				--stores-navigation-image-x: ${this.imageX};
				--stores-navigation-image-y: ${this.imageY};`;

			// if ( this.activeItem ) {
			// 	baseStyle += `
			// 		--stores-navigation-bg-color: ${this.activeItem.backgroundColor};
			// 		--stores-navigation-text-color: ${this.activeItem.textColor};
			// 		background-color: ${this.activeItem.backgroundColor};
			// 		color: ${this.activeItem.textColor};`;
			// }

			return baseStyle;
		},
	},
	watch: {
		windowW: function (newVal) {
			this.mobileBehaviour = newVal < 1150;

			this.offsetTop = this.$el.offsetTop;
			this.offsetHeight = this.$el.offsetHeight;

			this.labelHeight = this.$refs.label.offsetHeight;

			this.linksLength = this.links.length;

			//height: 5 link line heights
			let fontSize = 0.13;
			if (newVal >= 768) fontSize = 0.11;
			if (newVal >= 950) fontSize = 0.09;
			if (newVal >= 1024) fontSize = 0.08;

			this.lineHeight = fontSize * newVal * 1.111;
			this.listHeight = this.$refs.list.offsetHeight;

			if (this.mobileBehaviour) {
				this.addMobileHandlers();
			} else {
				this.activeItem = null;
				this.activeIndex = -1;
				this.lineHeight = 0;
				this.removeMobileHandlers();
			}
		},
	},
	methods: {
		mouseOverHandler: function (item, index) {
			if (this.mobileBehaviour) return false;

			this.imageLoaded = false;
			this.activeItem = item;
			this.activeIndex = index;

			this.bodyRef.style = `background-color: ${this.backgroundHoverColor};`;
		},
		mouseOutHandler: function () {
			if (!this.mobileBehaviour) {
				this.activeItem = null;
				this.activeIndex = -1;

				this.bodyRef.style = `background-color: ${this.bodyBackgroundColor};`;
			}
		},
		imageLoadedHandler: function () {
			this.imageLoaded = true;
		},
		mouseMoveHandler: function (event) {
			if (!this.mobileBehaviour) {
				this.imageX = event.clientX;
				this.imageY = event.clientY;
			}
		},
		updateScrollPosition: function () {


			this.offsetTop = this.$el.offsetTop;
			this.offsetHeight = this.$el.offsetHeight;

			let scrollPosAdjusted = this.windowY - this.offsetTop + this.windowH / 2;
			this.scrollPosTranslated = (scrollPosAdjusted - this.lineHeight / 2 - this.labelHeight) / this.listHeight;
			this.scrollListTo(this.scrollPosTranslated * this.linksLength);
		},

		scrollHandler: function () {
			if (this.imageTimeoutHandle) clearTimeout(this.imageTimeoutHandle);
			this.imageTimeoutHandle = setTimeout(
				function () {
					this.imageY = this.windowH / 2 + this.scrollPosTranslated * (this.listHeight - this.windowH * 0.1);
				}.bind(this),
				50
			);
		},

		scrollListTo: function (index) {
			this.activeIndex = Math.round(index);

			if (this.activeIndex >= 0 && this.activeIndex < this.linksLength) {
				this.activeItem = this.links[this.activeIndex];
				this.bodyRef.style = `background-color: ${this.backgroundHoverColor};`;
			} else {
				this.activeItem = null;
				this.bodyRef.style = `background-color: ${this.bodyBackgroundColor};`;
			}
		},

		addMobileHandlers: function () {
			try {
				this.removeMobileHandlers();
			} catch (err) {
				//DO NOTHING
			}
			this.clearInterval = rInterval(this.updateScrollPosition.bind(this), 50).clear;
			window.addEventListener("scroll", this.scrollHandler.bind(this));
		},
		removeMobileHandlers: function () {
			if (this.clearInterval) this.clearInterval();
			window.removeEventListener("scroll", this.scrollHandler.bind(this));
		},
		getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;

			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;

				current = current.offsetParent;
			}

			return actualOffset;
		},
	},
	mounted() {
		this.bodyRef = document.querySelector("body");
		this.bodyBackgroundColor = window.getComputedStyle(this.bodyRef).getPropertyValue("background-color");

		window.addEventListener("mousemove", this.mouseMoveHandler.bind(this));
	},
};
