import { mapActions, mapState } from 'vuex';

import ProductTileProd from '../../components/product-tile/product-tile'

export default {
	name: 'room-explorer-product',
	components: {
		ProductTileProd
	},
	data() {
		return {
			isActive: false,
			slideContent: false,
		}
	},
	computed: {
		...mapState({
			roomExplorerSingleProduct: state => state.roomExplorerSingleProduct
		}),
		item() {
			return this.roomExplorerSingleProduct
		}
	},
	watch:{
		roomExplorerSingleProduct(newVal) {
			if (newVal) {
				this.open();
			} else {
				this.close();
			}
		}
	},
	methods: {
		...mapActions([
			'showOverlayBackdrop',
			'hideOverlayBackdrop',
			'roomExplorerSingleProductChosen'
		]),
		open() {
			this.isActive = true;
			this.showOverlayBackdrop();
		},
		close(){
			this.isActive = false;
			this.slideContent = false;

		},
		showContent() {
			this.slideContent = true;
		},
		backdropSlidedOut() {
			this.hideOverlayBackdrop();
			this.roomExplorerSingleProductChosen(null);
		}
	},
};
