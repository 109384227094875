import urlComposer from '../urlComposer/urlComposer'
import Store from '@store/store';

export default function({method, historyStateAction = 'performProductFiltering', historyUnused = 'product-filter'}){

    const queryString = urlComposer({facets:Store.state.search.selectedFacets, searchQuery:Store.state.search.searchQuery})

    const manipulateHistoryMethod = method === 'replaceState' ? method : 'pushState'

    history[manipulateHistoryMethod](
        {
            action: historyStateAction,
            searchTerm:'',
            selectedFacets:Store.state.search.selectedFacets,
            searchQuery: Store.state.search.searchQuery,
            excludeEditorPicks: Store.state.search.excludeEditorPicks
        },
        historyUnused,
        location.pathname+queryString
    )

}
