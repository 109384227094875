import { mapState } from "vuex";
import mockData from "./material-explorer-data-2";
import { mapActions } from "vuex";
import Swiper from "swiper/dist/js/swiper.js";

export default {
	name: "material-explorer",
	props: {
		titleNumber: {
			type: String,
			default: "3.",
		},
		titleText: {
			type: String,
			default: "Materials & qualities",
		},

		headline: {
			type: String,
			default: "3 practical considerations:",
		},

		bodyText: {
			type: String,
			default:
				"When choosing upholstery for your sofa, there are a few practical points you may want to consider: durability, exposure to sunlight, and pilling. To the right, you can read about these terms and what they mean for your choice of material.",
		},
		categoryList: {
			type: Array,
			default() {
				return mockData.categories;
			},
		},
		featuresLabel: {
			type: String,
			default: "Featured",
		},
		featuresList: {
			type: Array,
			default() {
				return mockData.features;
			},
		},
	},
	computed: {
		...mapState({
			windowW: (state) => state.windowW,
			windowH: (state) => state.windowH,
		})
	},
	watch: {
		windowW() {
			this.resizeListener();
		},
		windowH() {
			this.resizeListener();
		},
	},
	data() {
		const itemLength = this.featuresList.length;

		return {
			slider: null,
			settings: {
				slidesPerView: 3,
				spaceBetween: 0,
				freeMode: true,

				observer: true,
				observeParents: true,

				breakpoints: {
					565: {
						slidesPerView: 1.1,
						spaceBetween: 0,
					},
					767: {
						slidesPerView: 1.25,
						spaceBetween: 0,
					},
					1023: {
						slidesPerView: 1.5,
						spaceBetween: 0,
					},
					1279: {
						slidesPerView: 1.75,
						spaceBetween: 0,
					},
					1923: {
						slidesPerView: itemLength <= 3 ? 3 : 2.25,
						spaceBetween: 0,
					},
				},
			},
		};
	},
	methods: {
		...mapActions(["setActiveVideo", "openMaterialOverlay", "showOverlayBackdrop", "openExplanationOverlay"]),
		featureClickHandler: function (item) {
			this.showOverlayBackdrop();
			this.openExplanationOverlay(item.explanationData);
		},
		categoryClickHandler: function (index) {
			this.showOverlayBackdrop();
			this.openMaterialOverlay({ data: this.categoryList, activeIndex: index });
		},
		openVideo(item) {
			this.setActiveVideo({ type: item.videoType, value: item.videoValue });
		},
		resizeListener() {
			if (this.windowW > 768 && this.featuresList.length <= 3) {
				const test = document.querySelectorAll(".material-explorer__features__slider .swiper-wrapper .swiper-slide");
				test.three = true;
			}
			if (!this.slider) {
				Object.assign(this.settings, {
					navigation: {
						nextEl: this.$refs["nextButton"],
						prevEl: this.$refs["prevButton"],
					},
				});
				this.slider = new Swiper(this.$refs.slider, this.settings);
			}
		},
	},
};
