export default [
    {
        headline: "Interior design ideas",
        bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet",
        img1Src: "/static/images/product/plp-inspirational-image1.jpg",
        img2Src: "/static/images/product/master-product-image-sofa.jpg",
        backgroundColor: "#A9B5D6",
        textColor: "#976F38"
    },
    {
        headline: "Ipsum dolor sit amet consec&shy;tetur",
        bodyText: "Sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet",
        img1Src: "/static/images/product/master-product-image-sofa.jpg",
        img2Src: "/static/images/mega-menu-life-style-image.jpg",
        backgroundColor: "#3E5659",
        textColor: "#E4E4DC"
    },
    {
        headline: "Dolor sit amet consec&shy;tetur",
        bodyText: "Tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet",
        img1Src: "/static/images/roomexplorer-3_4.jpg",
        img2Src: "/static/images/product/plp-inspirational-image1.jpg",
        backgroundColor: "#EEFF88",
        textColor: "#77FFEE"
    },
    {
        headline: "Amet consec&shy;tetur",
        bodyText: "Consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet",
        img1Src: "/static/images/roomexplorer-3_2.jpg",
        img2Src: "/static/images/portrait-image.jpg",
        backgroundColor: "#AA77AA",
        textColor: "#EE77FF"
    },
    {
        headline: "Lorem ipsum dolor",
        bodyText: "Consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet",
        img1Src: "/static/images/product/master-product-image-sofa.jpg",
        img2Src: "/static/images/master-product-image_4_5.jpg",
        backgroundColor: "#4455EE",
        textColor: "#229955"
    }
]