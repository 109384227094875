export default {
    name: "usp-spot",
    props: {
		name: {
			type: String,
            required: false
        },
        description: {
			type: String,
            required: false
        },
        linkUrl: {
			type: String,
            required: false
        },
        linkText: {
			type: String,
            required: false
        },
        textColor: {
			type: String,
            required: false
        },
        backgroundColor: {
			type: String,
            required: false
        },
        modifier: {
			type: String,
            required: false
        },
		media: {
			type: Object,
			required: false
		},
    },
    computed: {
        getModifiers: function() {
            if ( this.modifier ) {
                return {[this.modifier]: true};
            } else {
                return {'usp-spot--image-icon': true};
            }
        },
        getColorStyle: function() {
            let style = '';
            if ( this.backgroundColor ) {
				style += `background-color: ${this.backgroundColor}; --usp-spot-bg-color: ${this.backgroundColor};`;
            }
            if ( this.textColor ) {
				style += `color: ${this.textColor}; --usp-spot-text-color: ${this.textColor}`;
			}
            return style;
        }
    },
}
