export default [
    {
        headline:"Guided tour",
        bodyText:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
        position: "TOP_RIGHT",
        button: {
            class: "ar-link",
            text: "Try sofa in AR",
            href: "#"
        }
    },
    {
        headline:"The comfort 2",
        bodyText:"Sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
        position: "BOTTOM_LEFT",
        button: {
            class: "link link--persistent",
            text: "Try sofa in AR",
            href: "#"
        }
    },
	{
		headline:"The comfort",
		bodyText:"Sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
		position: "TOP_RIGHT",
		button: {
			class: "button button--primary",
			text: "Try sofa in AR",
			href: "#"
		}
	},
    {
        headline:"Be playful",
        bodyText:"Sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
        position: "BOTTOM_LEFT",
        button: {
			class: "button button--primary button--custom-colors",
			style: "--custom-color-1: pink; --custom-color-2: black; --custom-color-3: black; --custom-color-4: pink;",
            text: "Try sofa in AR",
            href: "#"
        }
    },
    {
        headline:"Message 4",
        bodyText:"Eiusmod tempor Lorem ipsum dolor sit amet, consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        position: "BOTTOM_LEFT",
        button: {
            class: "button button--secondary button--custom-colors",
			style: "--custom-color-1: tomato; --custom-color-2: lime; --custom-color-3: tomato; --custom-color-4: tomato;",
            text: "Try sofa in AR",
            href: "#"
        }
    }
];
