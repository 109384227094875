let products = [
	{
		imageSrc:
			"/static/images/product/Fiber-barstool-w-back-75-woodbase-black-silk-leather-black-MUUTO-low-res.png",
		productName: "Fiber Bar Stool",
		productVariant: "Wood Base",
		productId: "product0",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/fiber-armchair-400w.png",
		productName: "Fiber Armchair",
		productVariant: "Wood Base",
		productId: "product1",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/Five_pouf_Remix_123-low-res.png",
		productName: "Base Table Incredibly Nice",
		productVariant: "Wood Base",
		productId: "product2",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/product-line-image-1.png",
		productName: "Fiber Armchair",
		productVariant: "Wood Base",
		productId: "product3",
		productHref: "#product"
	},
	{
		imageSrc:
			"/static/images/product/Fiber-barstool-w-back-75-woodbase-black-silk-leather-black-MUUTO-low-res.png",
		productName: "Fiber Bar Stool",
		productVariant: "Wood Base",
		productId: "product4",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/fiber-armchair-400w.png",
		productName: "Fiber Armchair",
		productVariant: "Wood Base",
		productId: "product5",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/Five_pouf_Remix_123-low-res.png",
		productName: "Base Table",
		productVariant: "Wood Base",
		productId: "product6",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/product-line-image-1.png",
		productName: "Fiber Armchair With Deluxe Dowels",
		productVariant: "Wood Base",
		productId: "product7",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/product-line-image-1.png",
		productName: "Fiber Armchair",
		productVariant: "Wood Base",
		productId: "product8",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/fiber-armchair-400w.png",
		productName: "Fiber Armchair",
		productVariant: "Wood Base",
		productId: "product9",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/Five_pouf_Remix_123-low-res.png",
		productName: "Base Table",
		productVariant: "Wood Base",
		productId: "product10",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/product-line-image-1.png",
		productName: "Fiber Armchair With Deluxe Dowels",
		productVariant: "Wood Base",
		productId: "product11",
		productHref: "#product"
	},
	{
		imageSrc: "/static/images/product/product-line-image-1.png",
		productName: "Fiber Armchair",
		productVariant: "Wood Base",
		productId: "product12",
		productHref: "#product"
	}
];

export default {
	chapters: [
		{
			title: "",
			startIndex: 0,
			endIndex: 1,
		},
		{
			title: "Content slides",
			startIndex: 2,
			endIndex: 4,
		},
		{
			title: "Product slide 1-6",
			startIndex: 5,
			endIndex: 10,
		},
		{
			title: "Product slides 7-X",
			startIndex: 11,
			endIndex: 16,
		},
	],
	stories: [
		{
			type: "cover",
			titlePart1: "meet",
			titlePart2: "fiber",
			description:
				"<p>The unique shape of the Fiber Armchair marries friendly, embracing curves with an innovative wood fiber composite, giving the design its distinctly tactile texture.</p><p>Iskos-Berlin lorem ipsum dolor sit amet ...</p>",
			imageSrc: "/static/images/product/master-product-image.jpg",
			textColor: "#C5DBF3",
			backgroundColor: "#3F8B69",
			cursorBackgroundColor: "#C5DBF3",
			cursorTextColor: "#3F8B69",
		},
		{
			type: "cover",
			titlePart1: "10 different models",
			videoSrc: "/static/video/test3.mp4",
			textColor: "#004B89",
			backgroundColor: "#E6D8CB",
			cursorBackgroundColor: "#004B89",
			cursorTextColor: "#E6D8CB",
			buttonHref: "#",
			buttonLabel: "Go explore",
			isAssembled: true,
		},
		{
			type: "content",
			titlePart1: "words from",
			titlePart2: "the",
			titlePart3: "designer",
			description:
				"<p>“ One of the most difficult and noble disciplines of design is to create simple, well-functioning and almost ordinary objects that nevertheless have a strong identity. We designed the Fiber Conference Armchair to do just that. The chair’s sculptural language, paired with its wide seat, refuses to compromise on comfort and invites the user to be seated for hours on end. Through soft upholstery and the fixed cushion of its seat, the Fiber Conference armchair enhances the idea of grand comfort. The chair’s inner shell is made using recycled plastic for a conscious and innovative perspective on materials.”</p>",
			imageSrc1: "/static/images/designer-portrait-img1.jpg",
			isDesigner: true,
			buttonHref: "#",
			buttonLabel: "Go explore",
			textColor: "#282828",
			textColor2: "#FFFDE6",
			backgroundColor: "#BA9BEE",
			cursorBackgroundColor: "#FFFDE6",
			cursorTextColor: "#282828",
		},
		{
			type: "content",
			titlePart1: "made of recycled plastic",
			description:
				"<p>The Fiber Chair Family, designed by Iskos-Berlin was born from a wish to make a forward-thinking take on the archetypal shell chair.<br>This was achieved by creating an innovative shell of plastic and wood fibers that makes for a soft inviting touch with a matte and tactile surface.</p>",
			imageSrc1: "/static/images/hero-portrait.jpg",
			imageSrc2: "/static/images/hero-portrait2.jpg",
			textColor: "#E7E498",
			backgroundColor: "#3F8B69",
			cursorBackgroundColor: "#E7E498",
			cursorTextColor: "#3F8B69",
			buttonHref: "#",
			buttonLabel: "Go explore",
		},
		{
			type: "video",
			titlePart1: "giving new life",
			titlePart2: "to plastic waste",
			description:
				"<p>Muuto’s Fiber design by Copenhagen duo Iskos-Berlin came from a desire to not just create another plastic chair but rather bring something new to the design that resonated with how we perceive the world today and at the same time had a unique expression. This is the story of Fiber.</p>",
			videoPosterSrc: "/static/images/meet-designer.jpg",
			// videoId: "258092024",
			videoSrc: "/static/video/test2.mp4",
			videoDuration: "2:30",
			videoTitlePart1: "MUUTO LOREM IPSUM",
			videoTitlePart2: "ISKOS BERLIN",
			buttonHref: "#",
			buttonLabel: "Go explore",
			textColor: "#D64712",
			backgroundColor: "#FFF19F",
			cursorBackgroundColor: "#D64712",
			cursorTextColor: "#FFF19F",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 1",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,1),
			textColor: "#3F8B69",
			backgroundColor: "#C5DBF3",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 2",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,2),
			textColor: "red",
			backgroundColor: "#C5DBF4",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 3",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,3),
			textColor: "blue",
			backgroundColor: "#C5DBF5",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 4",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,4),
			textColor: "yellow",
			backgroundColor: "#C5DBF6",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 5",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,5),
			textColor: "orange",
			backgroundColor: "#C5DBF7",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 6",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,6),
			textColor: "magenta",
			backgroundColor: "#C5DBF8",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 7",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,7),
			textColor: "firebrick",
			backgroundColor: "#C5DBF9",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 8",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,8),
			textColor: "turquoise",
			backgroundColor: "#C5DBFA",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Connect Modular",
			titlePart2: "Sofa Family",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,9),
			textColor: "#3F8B69",
			backgroundColor: "#C5DBFB",
			cursorBackgroundColor: "#3F8B69",
			cursorTextColor: "#C5DBFB",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 10",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,10),
			textColor: "firebrick",
			backgroundColor: "#C5DBFC",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 11",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,11),
			textColor: "slateblue",
			backgroundColor: "#C5DBFC",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members 12",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products.slice(0,12),
			textColor: "coral",
			backgroundColor: "#C5DBFC",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
		{
			type: "products",
			titlePart1: "The Fiber",
			titlePart2: "Members X",
			buttonHref: "#",
			buttonLabel: "Go explore",
			productList: products,
			textColor: "steelblue",
			backgroundColor: "#C5DBFC",
			cursorBackgroundColor: "#8844FF",
			cursorTextColor: "#005500",
		},
	],
};
