import { rInterval } from "./../../basics/js/utils";

export default {
	name: "sliding-presentation",
	data: () => ({
		yLerpPos: 0,
	}),
	methods: {
		transform() {
			this.yLerpPos += (window.pageYOffset - this.yLerpPos) * 0.15;
			const limited = Math.max(0.0001, this.yLerpPos);

			const browsers = ["webkitTransform", "MozTransform", "transform"];

			const leftPos = Math.min(limited / 4, 100);
			const textScale = Math.min(1, limited / 1300 + 0.7);
			const textLeftStyle = `translate(calc(${leftPos / 1.01}vw - ${leftPos}%), ${-limited / 3.5}px) scale(${textScale})`;
			const textRightStyle = `translateY(${-limited / 100}px) scale(${textScale})`;
			const mediaStyle = `translateY(${window.pageYOffset / 37}vmax)`;
			const trumpetStyle = `translate(${-limited / 12}vmax, ${window.pageYOffset}px)`;

			browsers.forEach((browser) => {
				this.$refs["text-left"].style[browser] = textLeftStyle;
				this.$refs["text-right"].style[browser] = textRightStyle;
				this.$refs["trumpet"].style[browser] = trumpetStyle;

				if (this.$refs["media"]) {
					this.$refs["media"].style[browser] = mediaStyle;
				}
			});
		},

		getPositionOnPage() {
			const transformY = this.$el.parentElement.style.transform.replace(/[^\d.]/g, "");
			return transformY;
		},
	},
	mounted() {
		document.body.style.overflow = "hidden";

		window.scrollTo(0, 0);

		const styles = getComputedStyle(this.$el);
		const animationDuration = parseFloat(styles.getPropertyValue("--dur")) * 1000;

		setTimeout(() => {
			document.body.style.removeProperty("overflow");
		}, animationDuration);

		//Reset scroll top
		history.scrollRestoration = "manual";

		rInterval(this.transform, 16);

		/**
		 * safari can't transform an element,
		 * if it has an animation on it.
		 */
		const cssAnimatedElements = [this.$refs["text-left"], this.$refs["text-right"], this.$refs["media"]];
		cssAnimatedElements.forEach((element) => {
			element.addEventListener("animationend", () => {
				element.style.animation = "none";
			});
		});

		/**
		 * todo: better init?
		 */
		setTimeout(() => {
			this.$refs["sliding-presentation"].classList.add("is-animating");
		}, 1000);
	},
};
