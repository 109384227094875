import mockData from "./ar-banner-data";
import { mapActions } from 'vuex';
import HasImageProducts from "../../image-products/has-image-products";
import ImageProductsButton from "../../image-products/image-products-button.vue"

export default {
	name: "ar-banner",
    extends: HasImageProducts,
    components: {
        ImageProductsButton
    },
	props: {
        labelText: {
            type: String,
            default: "AR Experience"
        },
        headline: {
            type: String,
            default: "Try it in your home"
        },
        bodyText: {
            type: String,
            default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem."
        },
        ctaHref: {
            type: String
        },
        ctaTarget: {
            type: String
        },
        ctaText: {
            type: String
        },
        imageSrc: {
            type: String,
            default: "/static/images/phone-ar.png"
        },
        iconName: {
            type: String
            // ,default: 'ar'
        },
        iconSrc: {
            type: String
            ,default: "/static/svg/ar-framed.svg"
        },
        configureShortcutsOverlayData: {
            type: Object,
            default() {
                return mockData;
            }
        } 
    },
    methods: {
        ...mapActions([
			'openShortcutsOverlay',
			'showOverlayBackdrop',
		]),
        activateClickHandler: function() {
           this.openShortcutsOverlay(this.configureShortcutsOverlayData);  
           this.showOverlayBackdrop();
        },
        getXlinkHref: function(icon) {
			return `/Static/dist/svg/symbols.svg#svg-${icon}`;
		}
    }
}