import mockData from "./series-overview-data";

const VIEW_STATE = {DEFAULT: "DEFAULT", ALTERNATIVE: "ALTERNATIVE"};

export default {
    name: "series-overview",
    props: {
        itemList: {
            type: Array,
            default() {
                return mockData;
            }
        },
        toggleButtonText: {
            type: String,
            default: "Room"
        },
        titleText: {
            type: String,
            default: "Explore our sofa series"
        }
    },
    data() {
        return {
            viewState: VIEW_STATE.DEFAULT
        }
    },
    methods: {
        toggleViewHandler: function() {
            this.viewState = this.viewState === VIEW_STATE.DEFAULT ? VIEW_STATE.ALTERNATIVE : VIEW_STATE.DEFAULT;
        }
    }
}