import * as types from "./static";
import {
	getRequest,
	postRequest,
	postRequestWithFileUpload,
} from "./integrations";
import mapProducts from "./methods/mapResultData/methods/mapProducts";

export default {
	setIsMobile({ commit }, isMobile) {
		commit("setIsMobile", isMobile);
	},


	setIsIOS({ commit }, isIOS) {
		commit("setIsIOS", isIOS);
	},


	setWindowH({ commit }, wH) {
		commit("setWindowH", wH);
	},

	setWindowW({ commit }, wW) {
		commit("setWindowW", wW);
	},

	setWindowY({ commit }, wY) {
		commit("setWindowY", wY);
	},

	setBodyFixed({ commit, dispatch }, payload) {
		commit("setBodyFixed", payload);
	},

	async getInitialOptionGroups(store, url) {
		const optionGroups = await getRequest(store, {
			url,
			mutationTypes: types.GET_OPTION_GROUP_ASYNC,
		});
		store.commit(types.GET_OPTION_GROUP_ASYNC.SUCCESS, optionGroups);
	},

	// Main search actions
	openMainSearch({ commit, dispatch }) {
		dispatch("closeAddonsOverlay");
		dispatch("closeMainMenu");
		dispatch("closeMiniCart");
		dispatch("closeAccountMenu");
		// dispatch('setBodyFixed', { transitionDelay: 550, setClass: true, onlyMobile: true }); // ori
		dispatch("setBodyFixed", {
			transitionDelay: 250,
			setClass: true,
			onlyMobile: false,
		});
		commit("openMainSearch");
	},

	closeMainSearch({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeMainSearch");
	},

	// Add-ons overlay actions (PDP)
	openAddonsOverlay({ commit, dispatch }) {
		dispatch("closeMainMenu");
		dispatch("closeMainSearch");
		dispatch("closeAccountMenu");
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("openAddonsOverlay");
	},
	// (PDP)
	closeAddonsOverlay({ commit }) {
		commit("closeAddonsOverlay");
		commit(types.CYLINDO_OVERLAY.mutation, types.HIDDEN);
	},

	// Main menu actions
	openMainMenu({ commit, dispatch }) {
		dispatch("closeAddonsOverlay");
		dispatch("closeMiniCart");
		dispatch("closeMainSearch");
		dispatch("closeAccountMenu");
		// dispatch('closeMainServiceNav');

		commit("openMainMenu");
	},

	closeMainMenu({ commit }) {
		commit("closeMainMenu");
	},

	openMainServiceNav({ commit, dispatch }) {
		dispatch("closeAddonsOverlay");
		// dispatch('closeMainMenu');
		dispatch("closeMiniCart");
		dispatch("closeAccountMenu");
		// dispatch('setBodyFixed', { transitionDelay: 550, setClass: true, onlyMobile: true }); // ori
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("openMainServiceNav");
	},

	closeMainServiceNav({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeMainServiceNav");
	},

	openAccountMenu({ commit, dispatch }) {
		dispatch("closeAddonsOverlay");
		dispatch("closeMiniCart");
		dispatch("closeMainMenu");
		dispatch("closeMainSearch");
		commit("openAccountMenu");
	},

	closeAccountMenu({ commit }) {
		commit("closeAccountMenu");
	},

	openDashboard({ commit, dispatch }) {
		dispatch("closeAllOverlays");

		dispatch("setBodyFixed", { transitionDelay: 550, setClass: true });

		commit("openDashboard");
	},

	closeDashboard({ commit, dispatch }) {
		commit("closeDashboard");
		dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
	},

	openCountrySelector({ commit, dispatch }) {
		dispatch("closeAllOverlays");
		dispatch("setBodyFixed", { transitionDelay: 550, setClass: true });
		commit("openCountrySelector");
	},

	closeCountrySelector({ commit, dispatch }) {
		commit("closeCountrySelector");
		dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
	},

	openMosaicSliderOverlay({ commit, dispatch }, activeIndex) {
		dispatch("closeAllOverlays");
		dispatch("setBodyFixed", { transitionDelay: 550, setClass: true });
		commit("openMosaicSliderOverlay", activeIndex);
	},

	closeMosaicSliderOverlay({ commit, dispatch }) {
		commit("closeMosaicSliderOverlay");
		dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
	},

	showOverlayBackdrop({ commit, dispatch }, context) {
		commit("showOverlayBackdrop", context);
	},

	hideOverlayBackdrop({ commit, dispatch }) {
		dispatch("closeAllOverlays");
		commit("hideOverlayBackdrop");
	},

	closeAllOverlays({ commit, dispatch }) {
		dispatch("closeAddonsOverlay");
		dispatch("closeConfigurator");
		dispatch("closeMainMenu");
		dispatch("closeMainServiceNav");
		dispatch("closeMainSearch");
		dispatch("closeMiniCart");
		dispatch("closeModal");
		dispatch("closeDashboard");
		dispatch("closeAccountMenu");
		dispatch("closeCountrySelector");
		dispatch("closeMaterialOverlay");
		dispatch("closeShortcutsOverlay");
		dispatch("closeExplanationOverlay");
		dispatch("closeMosaicSliderOverlay");
		dispatch("closeImageProductsOverlay");
		dispatch("setActiveVideo", null);


		commit("updateGuidedTour", "closed");
		commit("roomExplorerSingleProductChosen", null);
		commit("roomExplorerOverlayProductsChosen", null);

		commit("closeZoomOverlay", null);
		commit("closeOptionsOverlay", null);
		commit("closeMaterialGroupInfoOverlay", null);
		commit("closeReConfigurator", null);
		commit("closeToolTip", null);

	},

	// CART
	async [types.GET_CART_ITEMS.action](store) {
		const results = await getRequest(store, {
			url: process.env.CART_ITEMS_ENDPOINT,
			mutationTypes: types.GET_CART_ITEMS,
		});
		store.commit(types.GET_CART_ITEMS.SUCCESS, results);
	},

	removeCartItem({ commit, dispatch }, cartItem) {
		commit("removeCartItem", cartItem);
		dispatch(types.GET_CART_ITEMS.action);
		dispatch(types.GET_CART_QUANTITY.action);
	},

	showNotificationPanel({ commit }, panel) {
		// panel "payload" example can be found in NotificationPanel.js
		commit("showNotificationPanel", panel);
	},

	removeNotificationPanel({ commit }, panelId) {
		commit("removeNotificationPanel", panelId);
	},

	setTriggerExperienceResize({ commit }, newVal) {
		commit("setTriggerExperienceResize", newVal);
	},

	roomExplorerSingleProductChosen({ commit, dispatch }, json) {
		if (json) {
			dispatch("closeMainMenu");
			dispatch("closeMainSearch");
			dispatch("closeAccountMenu");

			dispatch("setBodyFixed", {
				transitionDelay: 550,
				setClass: true,
				onlyMobile: false,
			});
		} else {
			dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
		}
		commit("roomExplorerSingleProductChosen", json);
	},

	roomExplorerOverlayProductsChosen({ commit, dispatch }, json) {
		if (json) {
			dispatch("closeMainMenu");
			dispatch("closeMainSearch");
			dispatch("closeAccountMenu");

			dispatch("setBodyFixed", {
				transitionDelay: 550,
				setClass: true,
				onlyMobile: false,
			});
		} else {
			dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
		}
		commit("roomExplorerOverlayProductsChosen", json);
	},

	// Mini-cart overlay actions
	openMiniCart({ commit, dispatch }) {
		dispatch("closeMainMenu");
		dispatch("closeMainSearch");
		dispatch("closeAddonsOverlay");
		dispatch("closeAccountMenu");
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		dispatch(types.GET_CART_ITEMS.action);
		commit("openMiniCart");
	},

	closeMiniCart({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeMiniCart");
	},

	userEscKeypress({ dispatch }) {
		dispatch("hideOverlayBackdrop");
	},
	openModal({ commit, dispatch }, name) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("updateModalState", {
			status: types.OPEN_MODAL,
			name,
		});
	},

	closeModal({ commit, dispatch }) {
		dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
		commit("updateModalState", {
			status: types.CLOSE_MODAL,
			name: null,
		});
	},

	getAddonsForBlock({ commit }, product) {
		const productId = product.productId;
		const variantId = product.variantId;
		const endpointUrl = process.env.PRODUCT_ADDONS_ENDPOINT.replace("{productId}", productId).replace("{variantId}", variantId);
		fetch(endpointUrl)
			.then(function (response) {
				return response.json();
			})
			.then(function (dataJson) {
				if (!endpointUrl.endsWith(".json")) {
					dataJson = mapProducts(dataJson);
				}
				commit("getAddonsForBlock", dataJson);
			})
			.catch(function (err) {
				console.error("Cart items fetch failed: ", err);
			});
	},

	getAddonsForOverlay({ commit }, product) {
		const productId = product.productId;
		const variantId = product.variantId;
		const endpointUrl = process.env.PRODUCT_ADDONS_ENDPOINT.replace("{productId}", productId).replace("{variantId}", variantId);

		fetch(endpointUrl)
			.then(function (response) {
				return response.json();
			})
			.then(function (dataJson) {
				if (!endpointUrl.endsWith(".json")) {
					dataJson = mapProducts(dataJson);
				}
				commit("getAddonsForOverlay", dataJson);
			})
			.catch(function (err) {
				console.error("Cart items fetch failed: ", err);
			});
	},

	guidedTourAcceptInvitation({ commit }) {
		commit("updateGuidedTour", "accepted");
	},



	openMaterialOverlay({ commit, dispatch }, data) {

		commit("openMaterialOverlay", data);
		dispatch("closeMainMenu");
		dispatch("closeMainSearch");
		dispatch("closeAccountMenu");
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
	},
	setActiveMaterialCategory({commit}, index) {
		commit("setActiveMaterialCategory", index);
	},
	closeMaterialOverlay({ commit }) {
		commit("closeMaterialOverlay");
	},


	openShortcutsOverlay({ commit, dispatch }, data) {

		commit("openShortcutsOverlay", data);

		dispatch("closeMainMenu");
		dispatch("closeMainSearch");
		dispatch("closeAccountMenu");
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
	},
	closeShortcutsOverlay({ commit }) {
		commit("closeShortcutsOverlay");
	},


	openExplanationOverlay({ commit, dispatch }, data) {

		commit("openExplanationOverlay", data);

		dispatch("closeMainMenu");
		dispatch("closeMainSearch");
		dispatch("closeAccountMenu");
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
	},
	closeExplanationOverlay({ commit }) {
		commit("closeExplanationOverlay");
	},

	openProductFilters({ commit }) {
		commit("openProductFilters");
	},

	closeProductFilters({ commit }) {
		commit("closeProductFilters");
	},


	openToolTip({ commit }, data) {
		commit("openToolTip", data);
	},

	closeToolTip({ commit }) {
		commit("closeToolTip");
	},



	handleError({ commit, dispatch }, err) {
		console.warn("handle error", err);
		const panel = {
			type: "error",
			message: err,
			showClose: true,
			autoRemove: true,
			maxWidth: true, // COMMENT: Setting the message max-width to 350px by css
		};
		dispatch("showNotificationPanel", panel);
	},

	generalError({ commit, dispatch }, err) {
		console.warn("general error", err);
		const panel = {
			type: "error",
			message: err,
			showClose: true,
			autoRemove: true,
			maxWidth: true, // COMMENT: Setting the message max-width to 350px by css
		};
		dispatch("showNotificationPanel", panel);
	},

	stickyError({ commit, dispatch }, err) {
		console.warn("sticky error", err);
		const panel = {
			type: "error",
			message: err,
			showClose: true,
			autoRemove: false,
			maxWidth: true, // COMMENT: Setting the message max-width to 350px by css
		};
		dispatch("showNotificationPanel", panel);
	},

	[types.SET_FILTER_COLOR.action]({ commit }, color) {
		commit(types.SET_FILTER_COLOR.mutation, { color });
	},

	async [types.CART_ITEM_ADD_ENDPOINT_ASYNC.action](store, payload) {
		const result = await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.CART_ITEM_ADD_ENDPOINT,
			mutationTypes: types.CART_ITEM_ADD_ENDPOINT_ASYNC,
			body: payload.body,
		});
		console.log("loadingKey??")
		if ( result ) {
			this.commit(types.CART_ITEM_ADD_ENDPOINT_ASYNC.SUCCESS, result);

			store.dispatch(types.GET_CART_QUANTITY.action);

			if (payload && payload.body && payload.body.shouldRefreshCartItems)
				store.dispatch(types.GET_CART_ITEMS.action);
		} else {
			this.commit(types.CART_ITEM_ADD_ENDPOINT_ASYNC.FAILURE, null);
		}

	},

	async [types.UPDATE_QUANTITY_ASYNC.action](store, payload) {
		const result = await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.CART_ITEM_UPDATE_QUANTITY_ENDPOINT,
			method: "PUT",
			body: payload.body,
			mutationTypes: types.UPDATE_QUANTITY_ASYNC,
		});
		this.commit(types.UPDATE_QUANTITY_ASYNC.SUCCESS, result);
		store.dispatch(types.GET_CART_ITEMS.action);
		store.dispatch(types.GET_CART_QUANTITY.action);
	},

	async [types.REQUEST_QUOTE_ASYNC.action](store, payload) {
		await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.CART_REQUEST_QUOTE_ENDPOINT,
			mutationTypes: types.REQUEST_QUOTE_ASYNC,
			body: payload.body,
		});
		store.dispatch("showNotificationPanel", payload.notification);
		this.commit(types.REQUEST_QUOTE_ASYNC.SUCCESS);
	},

	async [types.EMPTY_CART_ASYNC.action](store, payload) {
		await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.CART_EMPTY_ENDPOINT,
			mutationTypes: types.EMPTY_CART_ASYNC,
			body: payload.body,
			method: "DELETE",
		});
		store.dispatch("showNotificationPanel", payload.notification);
		store.dispatch(types.GET_CART_ITEMS.action);
	},

	async [types.IMPORT_CART_ASYNC.action](store, payload) {
		var response = await postRequestWithFileUpload(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.IMPORT_CART_ENDPOINT,
			mutationTypes: types.IMPORT_CART_ASYNC,
			body: payload.body,
			method: "POST",
		});
		if(response.hasOwnProperty('url')){
			window.location.href = response.url;
			return;
		}
		if (response && response.added && response.notAdded) {
			if (response.added.length > 0 && response.notAdded.length < 1) {
				//Everything added with success
				store.dispatch("showNotificationPanel", payload.notification);
			} else {
				//Something failed in the add
				store.dispatch(
					"showNotificationPanel",
					payload.notificationOnError
				);
			}
		} else {
			//Something failed in the add
			store.dispatch(
				"showNotificationPanel",
				payload.notificationOnError
			);
		}

		store.dispatch(types.GET_CART_ITEMS.action);
		store.dispatch(types.GET_CART_QUANTITY.action);
	},

	async [types.SHARE_SAVED_LIST_ASYNC.action](store, payload) {
		store.dispatch("setCartShareCartIsActive", true);

		await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.CART_SHARE_ENDPOINT,
			mutationTypes: types.SHARE_SAVED_LIST_ASYNC,
			body: payload.body,
		});
		store.dispatch("showNotificationPanel", payload.notification);
		store.dispatch(types.GET_CART_ITEMS.action);

		store.dispatch("setCartShareCartIsActive", false);
	},

	async setCartShareCartIsActive(store, payload) {
		store.commit("setCartShareCartIsActive", payload);
	},

	async [types.IMPORT_SAVED_LIST_ASYNC.action](store, payload) {
		await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.CART_IMPORT_ENDPOINT,
			mutationTypes: types.IMPORT_SAVED_LIST_ASYNC,
			body: payload.body,
		});
		if (payload.notification)
			store.dispatch("showNotificationPanel", payload.notification);
	},

	async [types.EDIT_ORDER_ASYNC.action](store, payload) {
		//JPL: url was referenced freely here, not sure why but it triggered an error
		const postUrl = payload.url.replace(
			"{orderNumber}",
			payload.body.orderNumber
		);
		await postRequest(store, {
			url: postUrl,
			mutationTypes: types.EDIT_ORDER_ASYNC,
			body: payload.body,
		});
		store.dispatch("showNotificationPanel", payload.notification);
		this.commit(types.EDIT_ORDER_ASYNC.SUCCESS);
	},

	async [types.SELECT_COUNTRY.action](store, payload) {
		await postRequest(store, {
			url:
				payload && payload.url
					? payload.url
					: process.env.SELECT_COUNTRY,
			mutationTypes: types.SELECT_COUNTRY,
			body: { countryCode: payload.value },
			callback: () => window.location.reload(true),
		});
	},

	async [types.SELECT_USER_TYPE.action](store, payload) {
		console.log("call user type endpoint", process.env.SELECT_USER_TYPE, payload)
		const endpointUrl = process.env.SELECT_USER_TYPE.replace("{currentPageId}", payload.epiParrentPageId).replace("{blockId}", payload.blockId).replace("{index}", payload.userTypeId).replace("{path}", payload.path);
		console.log("call user type endpoint", endpointUrl)
		fetch(endpointUrl)
		.then(response => response.json())
		.then(data =>
			{
				if ( data.reloadUrl && data.reloadUrl.length > 0 ) {
					window.location = data.reloadUrl;
				}
				else{
					window.location.reload();
				}
			})
			.catch(function (err) {
				console.error("User types fetch failed: ", err);
			});
	},
};
