import mockData from "./shortcuts-bar-data";
import { mapActions, mapState } from 'vuex';
import { inView } from "../../../basics/js/methods/inView/inView";


export default {
    name: "shortcuts-bar",
    props: {
        viewportsBeforeActivation: {
            type: Number,
            default: 1
        },
        findInStoreText: {
            type: String,
            default: "Find in store"
        },
        productLabel: {
            type: String,
            default: "The Oslo Sofa"
        },
        findInStoreHref: {
            type: String,
            default: "/#findinstore"
        },
        findInStoreTarget: {
            type: String,
            default: "none"
        },
        backText: {
            type: String,
            default: "Back to Sofa Experience front page"
        },
        backHref: {
            type: String,
            default: "#frontpage"
        },
        tryWithARText: {
            type: String,
            default: "Try with AR"
        },
        configureText: {
            type: String,
            default: "Configure"
        },
        configureShortcutsOverlayData: {
            type: Object,
            default() {
                return mockData.configureData;
            }
        }, 
        arShortcutsOverlayData: {
            type: Object,
            default() {
                return mockData.arData;
            }
        } 
    },
    computed: {
		...mapState({
            isMobile: state => state.isMobile,
            windowH: state => state.windowH,
            windowW: state => state.windowW,
            windowY: state => state.windowY
        }),
        getOffsetStyle() {
            if ( this.isMobile ) {
                if ( this.isActive ) {
                    return `bottom: -2px;`;
                } else {
                    return `bottom: -${this.height + 2}px;`;
                }
            } else {
                if ( this.isActive ) {
                    return "bottom: -2px;";
                } else {
                    return `bottom: -${this.height + 2}px;`;
                }
            }
        }
    },
    watch: {
        windowH: function() {
            this.resizeListener();
        },
        windowW: function() {
            this.resizeListener();
        },
        windowY: function(newVal) {
            if ( newVal > this.triggerPoint ) {
                //not scrolled to page bottom yet
                this.isActive = true;
            }
            else {
                //scrolled too far down
                this.isActive = false;
            }
        },
    },
    data() {
        return {
            isActive: false,
            offset: 0,
            height: 70,
            
            triggerPoint: 0,
            isStuck: false
        }
    },
    methods: {
        ...mapActions([
			'openShortcutsOverlay',
			'showOverlayBackdrop',
		]),
        openConfigureOverlay: function() {
           this.openShortcutsOverlay(this.configureShortcutsOverlayData);  
           this.showOverlayBackdrop();
        },
        openAROverlay: function() {
           this.openShortcutsOverlay(this.arShortcutsOverlayData);  
           this.showOverlayBackdrop();
        },
        scrollListener() {
            if ( this.windowY > this.triggerPoint ) {
                //not scrolled to page bottom yet
                this.isActive = true;
            }
            else {
                //scrolled too far down
                this.isActive = false;
            }
        },
        resizeListener() {
            this.triggerPoint = this.viewportsBeforeActivation * this.windowH;
            
            if ( this.windowW < 1024 ) {
                this.height = 70;
            } else {
                this.height = 50;
            }
        }
    },
    mounted() {    

        inView({
			el: this.$el,
			threshold: 1
		});
    }

}