
export default {
	methods: {
		copyText(e, attrToCopy){
			// const text = e.currentTarget.getAttribute('data-sku');
			const text = e.currentTarget.getAttribute(attrToCopy);

			if(text){
				e.currentTarget.classList.add('is-copied');
				console.log('text to copy: ', text);
				this.copyToClipboard(e.currentTarget, text);
			} else {
				console.warn('No text to copy..');
			}
		},
		copyToClipboard(element, str){
			const el = document.createElement('textarea');
			el.classList.add('visually-hidden');
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			console.log('copy done!');
			element.classList.remove('is-copied');
		},
	},
};