import { mapActions } from 'vuex';
import Cookies from '../../basics/js/methods/cookies/cookies'

export default {
	name: 'invitation-banner',
	components: {
	},
	computed: {
		guidedTourStatus: function() {
			return this.$store.state.guidedTourStatus;
		}
	},
	data() {
		return {
			isShown: false
		};
	},
	props: {
		tour: {
			type: String,
			required: true
		}
	},
	mounted() {
		this.isMounted = true;
	},
	watch: {
		guidedTourStatus: function() {
			this.isShown = this.$store.state.guidedTourStatus == 'invitation';
		}
	},
	methods: {
		...mapActions([
			'guidedTourAcceptInvitation'
		]),
		close(){
			this.$store.commit('updateGuidedTour','done');
			Cookies.setItem(this.tour,'done', Infinity);
		}
	}
};
