export default [
    {
        category: "All styles",
        slides: [

            {
                name:"Outline",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Oslo",
                url: "/#",
                imageSrc:"/static/images/designer-portrait-img2.jpg"
            },
            {
                name:"In Situ",
                url: "/#",
                imageSrc:"/static/images/product/plp-inspirational-image1.jpg"
            },
            {
                name:"Dolor",
                url: "/#",
                imageSrc:"/static/images/designer-portrait-img2.jpg"
            },
            {
                name:"Sit amet",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Model 6",
                url: "/#",
                imageSrc:"/static/images/product/plp-inspirational-image1.jpg"
            }
        ]
    },
    {
        category: "Lounge sofas",
        slides: [
            {
                name:"Lounge sofas",
                description: "Iquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum.",
                url: null,
                imageSrc: null,
                textColor: '#E6D8CB',
                backgroundColor: '#6D727C'
            },
            {
                name:"Outline",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Lorem",
                url: "/#",
                imageSrc:"/static/images/product/plp-inspirational-image1.jpg"
            },
            {
                name:"Ipsum",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Dolor",
                url: "/#",
                imageSrc:"/static/images/designer-portrait-img2.jpg"
            },
            {
                name:"Sit amet",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Model 6",
                url: "/#",
                imageSrc:"/static/images/product/plp-inspirational-image1.jpg"
            }
        ]
    },
    {
        category: "Minimalistic",
        slides: [
            {
                name:"Minimalist",
                description: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempoccaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum.",
                url: null,
                imageSrc:"/static/svg/tree.svg",
                textColor: '#DBE8F3',
                backgroundColor: '#3F8B69'
            },
            {
                name:"Oslo",
                url: "/#",
                imageSrc:"/static/images/designer-portrait-img2.jpg"
            },
            {
                name:"Lorem",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Ipsum",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            }
        ]
    },
    {
        category: "Tuxedo",
        slides: [
            {
                name:"Tuxedo",
                description: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident sunt in culpa qui.",
                url: null,
                imageSrc: null,
                textColor: '#C2AFCD',
                backgroundColor: '#A06219'
            },
            {
                name:"In Situ",
                url: "/#",
                imageSrc:"/static/images/product/plp-inspirational-image1.jpg"
            },
            {
                name:"Lorem",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Ipsum",
                url: "/#",
                imageSrc:"/static/images/product/master-product-image-sofa.jpg"
            },
            {
                name:"Sit amet",
                url: "/#",
                imageSrc:"/static/images/designer-portrait-img2.jpg"
            }
        ]
    },
];
