import Quantity from '../../components/quantity/quantity';
import { CART_ITEM_ADD_ENDPOINT_ASYNC } from '../../basics/js/store/static';
import { mapActions, mapState } from 'vuex';
import { registerAddToCart } from '../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer'

export default {
	components: {
		Quantity,
	},
	props: {
		loggedIn: {
			type:  Boolean,
			default: false
		},
		navLinkUrl: {
			type: String,
			required: false,
		},
		navLinkText: {
			type: String,
			required: false,
		},
		unitPrice: {
			type: String,
			required: true,
			default: '0'
		},
		productName: { // COMMENT: name is used for added-to-cart feedback in the top banner
			type: String,
			required: true,
		},
		productTypeTitle: { // COMMENT: name is used for added-to-cart feedback in the top banner
			type: String,
			required: false,
		},
		sku: {
			type: String,
			required: false,
		},
		vatLabel: {
			type: String,
			required: false,
		},
		vatLabelAdditional: {
			type: String,
			required: false,
		},
		deliveryLabel: {
			type: String,
			required: false,
		},
		dispatchInfo: {
			type: String,
			required: false,
		},
		isColliRestricted: {
			type: Boolean,
			required: false,
			default: false
		},
		colli: {
			type: Number,
			required: false,
			default: 1
		},
		colliDescription: {
			type: String,
			required: false
		},
		switchModeNotice: {
			type: String,
			required: false
		},
		shouldRefreshCartItems: {
			type: Boolean,
			required: false
		},
		initialQuantity: {
			type: Number,
			required: false
		},
		minimumOrderQuantity: {
			type: Number,
			required: false,
			default: 1
		},
		addedToCartText: {
			type: String,
			default: 'Added to cart'
		},
		addToCartText: {
			type: String,
			default: 'Add to cart'
		},
		quantityInButton: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isLoaded: false,
			isDisabled: false,
			isAddedToCart: false,
			price: this.unitPrice, // COMMENT: Sub-total (sub-total multiplied by quantity)
			quantity: this.isColliRestricted
				? this.colli < this.minimumOrderQuantity
					? this.minimumOrderQuantity
					: this.colli
				:  this.minimumOrderQuantity || this.initialQuantity || 1,
			addedToCartStillDuration: 3000, // 4000ms if only text - if bg green = less time is neccessary
			visualButtonRevertTimeout: null,
			quantityInvalidBool: false, // disabled state controlled by quantity
			parentDisableButton: false, // disable state controlled by buy-section parent
			vatLabelShown: true,
		}
	},
	computed: {
		...mapState([
			CART_ITEM_ADD_ENDPOINT_ASYNC.stateKey,
			CART_ITEM_ADD_ENDPOINT_ASYNC.loadingKey
		]),
		doingBasketUpdate() {
			// COMMENT: Loading state
			return this.$store.state[CART_ITEM_ADD_ENDPOINT_ASYNC.loadingKey];
		}
	},
	beforeMounted(){},
	mounted(){
		this.isLoaded = true;
	},
	methods: {
		...mapActions([
			'showNotificationPanel',
			'openToolTip',
			CART_ITEM_ADD_ENDPOINT_ASYNC.action
		]),
		addToCart(){
			// prevent doubleclick and stuff
			if(this.doingBasketUpdate) return;

			// clear potential hanging timeout
			clearTimeout(this.visualButtonRevertTimeout);

			const body = {
				sku: this.sku,
				quantity: this.quantity,
				shouldRefreshCartItems: this.shouldRefreshCartItems
			};
			this[CART_ITEM_ADD_ENDPOINT_ASYNC.action]({ body });



			this.visualRevertAddToCart();

			this.showNotificationPanel( { type: 'atc', atcText: 'Added to cart', showClose: false, title: this.productName, message: this.productTypeTitle } ); // COMMENT: Action

			registerAddToCart(this.productName, this.productTypeTitle, this.sku, this.price, this.quantity);
		},
		getGtmContext() {
			return this.$attrs && this.$attrs.context
			? this.$attrs.context
			: this.context
			? this.context
			: "buy-section-core";
		},
		visualRevertAddToCart(){
			this.isAddedToCart = true;

			this.visualButtonRevertTimeout = setTimeout(()=>{ // COMMENT: Timeout for letting "added to cart" stand for a while
				console.log("visualButtonRevertTimeout DONE")
				this.isAddedToCart = false;
			}, this.addedToCartStillDuration);
		},
		checkDisabledState(){
			let shouldDisable = false;
			if(this.quantityInvalidBool){
				shouldDisable = true;
			}
			if(this.parentDisableButton){
				shouldDisable = true;
			}
			return shouldDisable;
		},
	},
};
