var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "story-slide-video", style: _vm.slideStyle },
    [
      _c("div", { staticClass: "story-slide-video__content" }, [
        _c("h2", { staticClass: "story-slide-video__title" }, [
          _c("span", [_vm._v(_vm._s(_vm.titlePart1))]),
          _vm._v(" "),
          _vm.titlePart2
            ? _c("span", [_vm._v(_vm._s(_vm.titlePart2))])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.description
          ? _c("div", { staticClass: "story-slide-video__description" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } }),
              _vm._v(" "),
              _vm.buttonHref && _vm.buttonText
                ? _c(
                    "a",
                    {
                      staticClass: "link link--persistent",
                      attrs: { href: _vm.buttonHref },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "story-slide-video__player video-player video-player--vimeo",
          },
          [
            _c("div", { staticClass: "video-player__inner" }, [
              _vm.videoSrc
                ? _c(
                    "button",
                    {
                      staticClass: "video-player__start",
                      on: { click: _vm.playClickHandler },
                    },
                    [
                      _c("span", { staticClass: "icon" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "byline" }, [
                        _c("div", [_vm._v(_vm._s(_vm.videoTitlePart1))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.videoTitlePart2))]),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v(_vm._s(_vm.videoDuration))]),
                      _vm._v(" "),
                      _vm.videoPosterSrc
                        ? _c("img", {
                            attrs: { src: _vm.videoPosterSrc, alt: "poster" },
                          })
                        : _vm._e(),
                    ]
                  )
                : _c("div", { staticClass: "video-player__start" }, [
                    _vm.videoPosterSrc
                      ? _c("img", {
                          attrs: { src: _vm.videoPosterSrc, alt: "poster" },
                        })
                      : _vm._e(),
                  ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }