import ProductTile from '../product-tile/product-tile'
import ProductTileProd from '../product-tile/product-tile'
import InspirationTile from '@components/inspiration-tile/inspiration-tile'
import clearTileFilters from '../../basics/js/methods/updateTileFilters/updateTileFilters'

export default {
    name: 'tile-list',
    computed:{
        tileListData(){
            return this.$store.state.search.tileListData
        },
		showLoadMore(){
            return (this.$store.state.search.availableTotalResults > 0 && this.$store.state.search.availableTotalResults > this.$store.state.search.visibleResults)
        },
        noResults(){
            return this.$store.state.search.availableTotalResults === 0;
        },
        filteringInProgress(){
            return this.$store.state.search.filteringInProgress
        }
    },
    methods:{
        loadMoreResults(){

            const skipValue = (this.$store.state.search.filterSkip === 0)
                ? this.$store.state.search.visibleResults
                : (this.$store.state.search.filterSkip+this.$store.state.search.filterTake)


            this.$store.commit('updateFilterSkip',skipValue)
            this.$store.dispatch('performProductFiltering')
        },
        clearTileFilters(){
            clearTileFilters([])
        }
    },
    components: {
        ProductTile,
        ProductTileProd,
        InspirationTile
    }

}
