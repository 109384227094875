/**
 * Store Locator module
 */

// import Vue from 'vue/dist/vue.esm';
import { getRequest } from "../integrations";
import { FETCH_STORES } from "../static";
import debounce from "lodash/debounce";

const filterByLatLng = (lat, lng, params) => {
	return (
		lat > params.mapBounds.south &&
		lat < params.mapBounds.north &&
		lng > params.mapBounds.west &&
		lng < params.mapBounds.east
	);
};
const state = {
	storeLocatorId: null,
	mapReady: false,
	storeLocatorReady: false,

	mapOptions: {
		mapTypeId: "client_style",
		mapTypeControl: false,
		zoom: 8,
		center: {
			lat: 58.26,
			lng: 12,
		},
	},

	mapIdle: true,
	mapDrag: false,
	mapZoom: false,
	mapPlaceSearch: false,

	zoomLevel: 8,

	bypassFetch: false,
	useCachedStores: true,
	emptySearch: false,

	[FETCH_STORES.stateKey]: [],
	cachedStores: [],

	sortedStores: [],
	filteredStores: [],

	nearestStores: [],
	storesInBound: [],

	selectedStore: {
		data: null,
		selected: false,
	},

	hoveredStore: {
		data: null,
		hover: false,
	},

	chosenPlace: null,
	geoLocationCoords: null,

	chosenAssortmentKey: "all",
	onlyFlagShips: false,

	numberOfStores: 0,
	noStoresFound: false,
	lastInteraction: null,
	fetching: false,
	fetchedInitialStores: false,
	userGeoLocation: {
		userLocation: null,
		granted: false,
		asking: false,
	},
	showZoomNotice: false,
	showGlobalProgress: true,

	dialog: {
		show: false,
		content: null,
		accept: {
			action: null,
			label: null,
		},
		cancel: {
			action: null,
			label: null,
		},
	},
	searchTerm: "",

	animationDuration: 200,
	fakeLoadingDelay: 5000,
	messageDelay: 1500,
	bounceTimeout: 2000,

	zoomLevelFilterStoresInBound: 7,
	transitionIn: "on",
	transitionOut: "off",
	initialFitBounds: true,
	centerOffsetOnSelection: false,
	storeIdKey: "sId",
	fallbackStoreAttribute: "S2",
	markerDropIn: false,

	google: {
		// apiKey: 'AIzaSyDUefidILao9rf4_EMau8qe6KAaQZvCWbE',
		apiKey: "AIzaSyCaLJv6scz60_1Xcr6A8vpgQ9oHHraErls",
		uri: "//maps.googleapis.com/maps/api/js",
		libraries: ["places", "geometry"],
		zoomLevelOnListClick: 12,
		countryFilterOnInitialize: null,
		countryFilterOnInitializeKey: null,
		markerClusterer: true,
		markerClustererStart: 8,
		maxZoomLevelClusterer: 8,
		onSetSelectionZoom: 10,
		askForGeoLocation: null,
		onGetGeolocationZoom: 12,
		onGoToStoreZoom: 10,
		apiDataKey: null,
	},

	copy: {
		gpsFailMessage:
			"In order to determine your location, we need your permission to do so. The information will only be used for this purpose.",
		gpsFailAcceptLabel: "OK",
		directions: "Get directions",
		storesFound: "Store found",
		storesFoundPlenum: "Stores founddd",
		noStoresHead: "No stores found",
		noStoresMessage:
			"There are no stores in the current map view. You can try expanding the search.",
		noStoresButtonLabel: "Expand search",
		goToStore: "Go to website",
		youAreHere: "You are here",
		filterValues: "all|furniture|accessories|lighting",
	},
};

const mutations = {
	setStorelocatorId(state, id) {
		state.storeLocatorId = id;
	},
	setDefaults(state, defaults) {
		if (defaults.zoom) {
			state.mapOptions.zoom = defaults.zoom;
		}
		if (defaults.center) {
			state.mapOptions.center = defaults.center;
		}
		state.copy = Object.assign(state.copy, defaults.copy);
	},
	mapReady(state) {
		state.mapReady = true;
	},
	mapZoomChange(state, zoomLevel, mapCenter) {
		state.zoomLevel = zoomLevel;
		state.mapCenter = mapCenter;

		state.mapZoom = true;
		state.mapIdle = false;
	},
	mapDragChange(state, mapCenter) {
		state.mapCenter = mapCenter;

		state.mapDrag = true;
		state.mapIdle = false;
	},
	mapIdleChange(state, mapIdle) {
		state.mapIdle = mapIdle;

		if (state.mapIdle) {
			state.mapZoom = false;
			state.mapDrag = false;
			state.mapPlaceSearch = false;
		}
	},
	setBounds(state, bounds) {
		state.bounds = bounds;
	},
	setBypassFetch(state, value) {
		//console.log("STORELOCATOR | state.bypassFetch="+value);
		state.bypassFetch = value;
	},
	hoverStore(state, params) {
		state.hoveredStore = params;
	},
	selectStore(state, params) {
		state.selectedStore = params;
	},
	placeChosen(state, place) {
		state.chosenPlace = place;
		state.mapPlaceSearch = true;
	},
	geoLocationChange(state, coords) {
		state.geoLocationCoords = coords;
	},
	assortmentFilterChange(state, value) {
		state.chosenAssortmentKey = value;
	},
	flagShipFilterChange(state, value) {
		state.onlyFlagShips = value;
	},
	setSearchTerm(state, value) {
		state.searchTerm = value;
	},

	[FETCH_STORES.PENDING](state, status) {
		state[FETCH_STORES.loadingKey] = status;
	},

	[FETCH_STORES.SUCCESS](state, payload) {
		// getting products
		const result = [].concat(payload);
		if (state.useCachedStores && !state.fetchedInitialStores) {
			state.cachedStores = result;
			state[FETCH_STORES.stateKey] = result.filter((store) =>
				filterByLatLng(
					parseFloat(store.latitude),
					parseFloat(store.longitude),
					state.bounds
				)
			);
		} else {
			state[FETCH_STORES.stateKey] = result;
		}
		state.fetchedInitialStores = true;
		state.storeLocatorReady = true;
	},
	cachedStoresSucces(state, stores, params) {
		const cachedStores = stores.filter((store) =>
			filterByLatLng(
				parseFloat(store.latitude),
				parseFloat(store.longitude),
				params
			)
		);

		state[FETCH_STORES.stateKey] = cachedStores;
	},

	storesSorted(state, stores) {
		state.sortedStores = stores;
	},
	storesFiltered(state, stores) {
		state.filteredStores = stores;
	},
	emptySearch(state, emptySearch) {
		state.emptySearch = emptySearch;
	},
};

const actions = {
	mapReady(context) {
		context.commit("mapReady");
	},
	mapZoomChange(context, zoomLevel, mapCenter) {
		context.commit("mapZoomChange", zoomLevel, mapCenter);
	},
	mapDragChange(context, mapCenter) {
		context.commit("mapDragChange", mapCenter);
	},
	mapIdleChange(context, mapIdle) {
		context.commit("mapIdleChange", mapIdle);
	},
	selectStore(context, params) {
		context.commit("selectStore", params);
	},
	hoverStore(context, params) {
		context.commit("hoverStore", params);
	},
	setBypassFetch(context, value) {
		context.commit("setBypassFetch", value);
	},
	placeChosen(context, place) {
		context.commit("placeChosen", place);
	},
	geoLocationChange(context, coords) {
		context.commit("geoLocationChange", coords);
	},
	assortmentFilterChange(context, value) {
		context.commit("assortmentFilterChange", value);
	},
	flagShipFilterChange(context, value) {
		context.commit("flagShipFilterChange", value);
	},
	placeSearch(context, value) {
		context.commit("setSearchTerm", value);
	},

	statusCheck(context, bounds) {
		//console.log("STORELOCATOR | statusCheck","state.mapDrag:"+state.mapDrag,"state.mapZoom:"+state.mapZoom);

		if ((state.mapDrag || state.mapZoom) && state.fetchedInitialStores) {
			// context.dispatch('getUpdatedStores');
		}

		context.commit("setBounds", bounds);
		context.dispatch("mapIdleChange", true, bounds);
	},

	storesSortedByDistance(context, stores) {
		context.commit("storesSorted", stores);
	},
	storesSortedByFilters(context, stores) {
		context.commit("storesFiltered", stores);
	},

	getInitialStores(context) {
		context;
		if (state[FETCH_STORES.loadingKey]) {
			console.warn("STORE LOCATOR | Fetch ongoing, can't fetch");
			return false;
		}

		const options = {
			endPoint: process.env.STORE_LIST,
			storeLocatorId: context.state.storeLocatorId,
		};
		context.dispatch(FETCH_STORES.action, options);
	},

	// getUpdatedStores(context) {
	// 	//console.log("STORELOCATOR | getUpdatedStores");

	// 	context;
	// 	if (state[FETCH_STORES.loadingKey]) {
	// 		console.warn("STORE LOCATOR | Fetch ongoing, can't fetch");
	// 		return false;
	// 	}

	// 	const options = {
	// 		endPoint: process.env.STORE_LIST,
	// 		storeLocatorId: context.state.storeLocatorId
	// 	};
	// 	debounce((options) => {context.dispatch(FETCH_STORES.action, options)},300)(options);
	// },

	getStoresFromBound(context, params) {
		//console.log("STORELOCATOR | getStoresFromBound");

		const { state } = context;

		if (state[FETCH_STORES.loadingKey]) {
			console.warn("STORE LOCATOR | Fetch ongoing, can't fetch");
			return false;
		}
		if (state.useCachedStores) {
			const cachedStores = state.cachedStores.filter((store) =>
				filterByLatLng(
					parseFloat(store.latitude),
					parseFloat(store.longitude),
					params
				)
			);

			context.commit("emptySearch", cachedStores.length === 0);

			context.commit(FETCH_STORES.SUCCESS, cachedStores, params);
			return;
		}

		const options = {
			endPoint: process.env.STORE_LIST,
			storeLocatorId: context.state.storeLocatorId,
			params: {
				topLeftLat: params.mapBounds.north,
				topLeftLong: params.mapBounds.west,
				bottomRightLat: params.mapBounds.south,
				bottomRightLong: params.mapBounds.east,
				centerLat: params.mapCenter.lat,
				centerLon: params.mapCenter.lng,
			},
		};
		if (state)
			debounce((options) => {
				context.dispatch(FETCH_STORES.action, options);
			}, 300)(options);
	},

	async [FETCH_STORES.action](store, options = {}) {
		let searchParams = new URLSearchParams();
		if (options.params) {
			for (const key in options.params) {
				if (options.params.hasOwnProperty(key)) {
					searchParams.set(key, options.params[key]);
				}
			}
		}
		let url = options.endPoint;
		url +=
			"?" +
			searchParams.toString() +
			"CategoryId=" +
			options.storeLocatorId;

		const result = await getRequest(store, {
			url: url,
			mutationTypes: FETCH_STORES,
			delay: 0,
		});
		store.commit(FETCH_STORES.SUCCESS, result);
	},
};

export default { state, actions, mutations };
