export default {
	name: 'data-table-label',
	props: {
		text: {
			type: String
		},
		defaultState: {
			type: String,
			default: 'DEFAULT'
		}
	},
	data: function() {
		return {
			states: {
				DEFAULT: 'DEFAULT',
				ASCENDING: 'ASCENDING',
				DESCENDING: 'DESCENDING'
			},
			state: this.defaultState
		}
	},
	methods: {
		toggle: function() {
			let newState;
			switch (this.state) {
				case this.states.DEFAULT: newState = this.states.ASCENDING; break;
				case this.states.ASCENDING: newState = this.states.DESCENDING; break;
				default: newState = this.states.DEFAULT; break;
			}
			this.state = newState;
		}
	}
}
