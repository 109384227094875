import BuySectionCore from '../../components/buy-section/buy-section-core';
import { mapActions } from 'vuex';
import { UPDATE_QUANTITY_ASYNC } from '../../basics/js/store/static';
import { registerAddToCart, registerRemoveFromCart } from '../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer'

export default {
	name: 'buy-section-product-line',
	extends: BuySectionCore,
	props: {
		product: {
			type: Object,
			default: null,
			required: true,
		},
		cartUrl: {
			type: String,
			default: ""
		},
		shouldQuantityTriggerUpdate: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			price: '0',
		}
	},
	watch: {
		product(){
			this.onProductChange();
		}
	},
	mounted(){
		this.isLoaded = true;	
		this.price = this.product.price;
	},
	methods: {
		...mapActions([
			'showNotificationPanel',
			UPDATE_QUANTITY_ASYNC.action
		]),
		onQuantityInvalid(quantityInvalidBool){ // COMMENT: Gets trigged by quantity-component's emitted custom-event
			this.quantityInvalidBool = quantityInvalidBool;
			this.isDisabled = this.checkDisabledState();
		},
		onQuantityUpdate({quantity, quantityChange, actionType}){ // COMMENT: Gets trigged by quantity-component's emitted custom-event
			
			if(this.shouldQuantityTriggerUpdate && quantity && quantity > -1) {
				const url = this.cartUrl;
				const body = {
					code: this.product.sku,
					quantity: quantity
				}
	
				this.$store.dispatch(UPDATE_QUANTITY_ASYNC.action, {
					url,
					body
				});
			}
			
			this.quantity = quantity;
			
			if(this.shouldQuantityTriggerUpdate && quantity && quantity > -1) {
				this.registerInDataLayer(actionType, quantityChange);
			}
		},
		registerInDataLayer(clickType, quantityChange){
			const product = this.product;
			
			if (clickType === 'plus') {
				registerAddToCart(product.name, product.variantName, product.sku, product.unitPrice, quantityChange);
			} else {
				registerRemoveFromCart(product.name, product.variantName, product.sku, product.unitPrice, quantityChange);
			}
		},
		onProductChange() {
			this.price = this.product.price;
			this.quantity = this.product.quantity;
		},
	},
};