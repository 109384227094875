import ProductLineCart from '../../components/product-line/product-line-cart';
import ProductLineAddons from '../../components/product-line/product-line-addons';
import ProductLineSearch from '../../components/product-line/product-line-search';

export default {
	name: 'product-line-examples',
	components: {
        ProductLineCart,
        ProductLineSearch,
        ProductLineAddons,
    },
};