import Vue from 'vue/dist/vue.esm';
import * as types from '../static';

const state = {
	[types.CYLINDO_OVERLAY.stateKey]: "hidden",
}

const mutations = {
	[types.CYLINDO_OVERLAY.mutation](state, payload){
		Vue.set(state, [types.CYLINDO_OVERLAY.stateKey], payload);
	},
}

const actions = {
	[types.CYLINDO_OVERLAY.action]({commit}, payload){
		commit([types.CYLINDO_OVERLAY.mutation], payload)
	},
}

export default { state, actions, mutations }