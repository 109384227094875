import Accordion from '../../components/accordion/accordion';

export default {
    name: 'accordion-flow-item',
    extends: Accordion,
	components: {
    },
    props: {
        // selectedFilters: Array
    },
	methods: {
		next: function () {
			this.$parent.$emit('nextAccordion');
		},
		goToStep: function () {
			this.$parent.$emit('goToStep', this.item.id);
		},
    },

	data() {
		return {
        };
    }
};
