import FeedbackButton from '../../components/feedback-button/feedback-button';
import { mapActions } from 'vuex';
import * as types from '../../basics/js/store/static';

export default {
	name: 'order-summary-original',
	components: {
		FeedbackButton,
	},
	data(){
		return {
			buttonStatus: 'idle',
			campaignCodeDisabled: false,
			campaignCodeModel: '',
			campaignCodeIsAdded: false, // add-button state

			currency: this.$store.state.currency,
		};
	},
	computed: {
		isLoaded: function(){
		},
		total: function(){
		},
		cartItems: function(){
			return this.$store.state.cartItems;
		},
		campaignCodeIsLoading() {
			this.$store.state[types.POST_CAMPAIGN_CODE_ASYNC.loadingKey];
			this.buttonStatus = 'loading';
		},
		campaignCode(){
			const isAdded = !!this.$store.state[types.POST_CAMPAIGN_CODE_ASYNC.stateKey].code;
			this.campaignCodeDisabled = isAdded;
			this.campaignCodeIsAdded = isAdded;
			this.buttonStatus = isAdded ? 'disabled' : 'idle';

			// console.log('getting in here for campaign code changes?');
			return this.$store.state[types.POST_CAMPAIGN_CODE_ASYNC.stateKey];
		},
	},
	methods: {
		...mapActions([
			'checkoutRemoveCampaignCode',
			'getCouponCode'
		]),
		buttonTextReverted(){
			console.log('caught emit event from feedback-button');
			this.addCampaignIsDone = false;
			this.buttonStatus = 'idle';
		},
		addCampaignCode(){
			if(this.campaignCodeModel !== ''){
				this.getCouponCode({ code: this.campaignCodeModel });
			} else {
				// TODO: Handle input validation
				console.warn('no campaign code supplied');
			}
		},
		removeCampaignCode(){
			this.campaignCodeDisabled = false;
			this.checkoutRemoveCampaignCode();
		},
		placeOrder(){
			console.log('placeOrder called');
			this.$emit('form-action-checkout', 'ActionPlaceOrder')
		},

	},
};
