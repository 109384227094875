import ProductLineRoomExplorer from '../../components/product-line/product-line-room-explorer';

import { mapActions, mapState} from 'vuex';

export default {
	name: 'room-explorer-overlay',
	components: {
		ProductLineRoomExplorer,
	},
	data() {
		return {
			isActive: false
		}
	},
	computed: {
		...mapState({
			roomExplorerOverlayProducts: state => state.roomExplorerOverlayProducts
		})
	},
	watch:{
		roomExplorerOverlayProducts(newVal) {
			if (newVal) {
				this.open();
			} else {
				this.close();
			}
		}
	},
	methods: {
		...mapActions([
			'showOverlayBackdrop',
			'hideOverlayBackdrop',
			'roomExplorerOverlayProductsChosen'
		]),
		open() {
			this.isActive = true;
			this.showOverlayBackdrop();
		},
		close(){
			this.isActive = false;
			this.hideOverlayBackdrop();
			this.roomExplorerOverlayProductsChosen(null);
		},
	},
};
