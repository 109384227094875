import { mapState } from "vuex";
import Toolbox from "../../components/toolbox-hero/toolbox";

export default {
	name: 'toolbox-hero',
    components: {
        Toolbox
    },
	props: {
        headlinePartOne: {
            type: String,
        },
        headlinePartTwo: {
            type: String,
        },
        headlineUnbroken: {
            type: String,
        },
        heroVideoSrc: {
            type: String,
            default: null
        },
        heroImageSrc: {
            type: String,
            default: null
        },
        navigationItems: {
            type: Array,
        }
    },
    data: function() {
        return {
            windowTrigger: 0,
            scrolledIntoBanner: false,
            scrolledPastBanner: false
        }
    },
    computed: {
        ...mapState({
            windowH: state => state.windowH,
            windowW: state => state.windowW,
            windowY: state => state.windowY
        }),
        toolboxHeroStyle() {
            return `--parallax: ${0.25 * (Math.max(0, this.windowY - this.windowTrigger))};`;
        },
        bgMediaStyle() {
            let speed = 0.25; //0.25 px pr window scroll px
            let windowOffset = speed * (this.windowY - this.windowTrigger); //scroll pos - trigger point * speed
            let overlap = 0;
            let maxOffset = overlap * this.windowH;
            if ( windowOffset < 0 ) windowOffset = 0;
            if ( windowOffset > maxOffset ) windowOffset = maxOffset;

            return `--video-offset: -${windowOffset}px`;
        }
    },
    watch: {
        windowY() {
            this.update();
        }
    },
    methods: {
        update: function() {
            this.windowTrigger = this.getOffsetTopRecursive(this.$el);

			if ( this.windowTrigger < 10 ) this.windowTrigger = 10;
			if ( this.windowTrigger > this.windowH ) this.windowTrigger -= this.windowH/3;

            if (this.windowY > this.windowTrigger) {
                this.scrolledPastBanner = true;
                this.scrolledIntoBanner = false;
            } else {
                this.scrolledPastBanner = false;
                this.scrolledIntoBanner = true;
            }
        },
        getOffsetTopRecursive: function (element) {
            var actualOffset = element.offsetTop;
            var current = element.offsetParent;
            while (current != null) {
                actualOffset += current.offsetTop;
                current = current.offsetParent;
            }
            return actualOffset;
        },
    },
	mounted() {
		this.update();
	}

};
