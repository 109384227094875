import { email, required, maxLength } from 'vuelidate/lib/validators';

// mapping to backend-generated names for modal share saved list
const userInput = {
    email: "UserInput_ShareSavedList_Email",
    comment: "UserInput_ShareSavedList_Comment",
  };
  
Object.freeze(userInput);

export default {
    form: {
		[userInput.email]: {
            required,
            email
        },
        [userInput.comment]: {
            maxLength: maxLength(400)
        },
    },
    validationFields: userInput
};