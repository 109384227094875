/*jslint ignore:start*/
import "core-js";
import "regenerator-runtime/runtime";
import Vue from 'vue/dist/vue.esm';
import 'whatwg-fetch';
import objectFitImages from 'object-fit-images';
import 'intersection-observer'

import store from "./store/store";
import { mapActions, mapState } from "vuex";
import PortalVue from "portal-vue";
import Vuelidate from "vuelidate";

import VueLazyload from "vue-lazyload";
import VueVimeoPlayer from "vue-vimeo-player";
import vSelect from "vue-select";

import components from '../../components';
import { GET_CART_QUANTITY } from './store/static';
// import storelocator from '../../components/storelocator/storelocator';

import "./methods/getUrlSearchParameters/getUrlSearchParametres";
import "./methods/popStateHandler/popStateHandler";

import { rInterval } from "./utils";

// import throttle from "lodash/throttle";
import "lazysizes";

import Gta from '../js/methods/gtmlDataLayer/GoogleTagManager'	
import Ph from '../js/methods/PriceHelper'	


window.PushEvent = Gta.PushEvent;
window.ChangePriceView = Ph.ChangePriceView;

Vue.mixin({
	computed: mapState(["isMobile"]),
	methods: mapActions(["openModal"]),
	components: {
		Modal: components.Modal,
	},
});
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(VueLazyload);
Vue.use(VueVimeoPlayer);

vSelect.props.components.default = () => ({
	OpenIndicator: Vue.component("my-component", {
		template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M226 49l-98 98-98-98L0 79l128 128L256 79z"/></svg>`,
	}),
});
Vue.component("v-select", vSelect);

// todo: make it async
// Vue.component("storelocator", storelocator);

vSelect.props.components.default = () => ({
	OpenIndicator: Vue.component("my-component", {
		template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M226 49l-98 98-98-98L0 79l128 128L256 79z"/></svg>`,
	}),
});
Vue.component("v-select", vSelect);

const vueApp = {
	el: "#app",
	store,
	components,
	delimiters: ["${", "}"],
	// data(){ return {} },
	beforeMount() {
		// COMMENT: In order for the isMobile to be set before components is using it
		
		let isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
		this.setIsIOS(isIOS);

		if ( isIOS ) {
			const htmlTag = document.getElementsByTagName('html')[0];
			htmlTag.classList.add('is-ios');
		}

		if (window.matchMedia("(max-width: 950px)").matches) {
			// MQ SCREEN-SM-PLUS (in css)
			this.setIsMobile(true);
		}
		window.onresize = () => {

			this.setWindowH(window.innerHeight);
			this.setWindowW(window.innerWidth);

			const htmlTag = document.getElementsByTagName('html')[0];
			htmlTag.style = `--vh: ${window.innerHeight/100}`;

			if (window.matchMedia("(max-width: 950px)").matches) {
				this.setIsMobile(true);
			} else {
				this.setIsMobile(false);
			}
		};

	},
	mounted() {
		// COMMENT: Set app loaded state
		this.$store.state.appLoaded = true;
		// COMMENT: load cartitems
		this.$store.dispatch(GET_CART_QUANTITY.action);
		// COMMENT: Change html-tag js/no-js class, for styling purposes
		const nojsElem = document.querySelector(".no-js");
		if (nojsElem) {
			nojsElem.classList.add("js");
			nojsElem.classList.remove("no-js");
		}

		window.addEventListener("keyup", (event) => {
			if (event.key === "Escape") {
				this.$store.dispatch("userEscKeypress");
			}
		});
		if (!window.matchMedia("(max-width: 950px)").matches) {
			this.openProductFilters();
		}
		// form label position hack
		if (this.$el.querySelectorAll) {
			const inputs = this.$el.querySelectorAll(
				".form__field--text input, .search-bar__form input"
			);
			for (let i = 0; i < inputs.length; i++) {
				if (inputs[i].value.length > 0)
					inputs[i].classList.add("has-value");
				inputs[i].addEventListener("blur", (event) => {
					event.target.classList.toggle(
						"has-value",
						event.target.value.length > 0
					);
				});
			}
		}
		this.$nextTick(this.secondaryInit);

		this.setWindowH(window.innerHeight);
		this.setWindowW(window.innerWidth);


		const htmlTag = document.getElementsByTagName('html')[0];
		htmlTag.style = `--vh: ${window.innerHeight/100}`;


        rInterval(()=>{
			const scrollY = window.scrollY;
			this.setWindowY(scrollY);
		}, 50);
	},
	methods: {
		...mapActions([
			"setIsMobile",
			"setIsIOS",
			
			"setWindowH",
			"setWindowW",
			"setWindowY",

			"openMainSearch",
			"openProductFilters",
			"closeProductFilters",
		]),
		secondaryInit() {
			objectFitImages(); // Activate "object-fit" css polyfill
		},
	},
};
new Vue(vueApp);
/*jslint ignore:end*/

// ONLY FOR DESIGN SYSTEM SHOULD BE REMOVED
const dsApps = document.querySelectorAll(".js--vue-app-instance");
if (dsApps) {
	dsApps.forEach((el) => {
		vueApp.el = el;
		new Vue(vueApp);
	});
}
