import { TweenLite, Power4 } from 'gsap';
import AccountMenu from '../account-menu/account-menu';
import ViewingAs from '../viewing-as/viewing-as';
import AccordionGroup from '../accordion/accordion-group';
import { GET_CART_QUANTITY } from '@store/static';

import { mapActions, mapState } from 'vuex';

export default {
	name: 'main-menu',
	components: {
		AccountMenu,
		ViewingAs,
		AccordionGroup
	},
	data() {
		return {
			stickyScrollTopOffset: 20,
			isOpen: false,
			activeItem: '',
			activeSubItem: [],
			activeSubRootItem: [],
			dir: '',
			scrollPos: 0,
			deviceHeight: 0,
			docHeight: 0,
			isScrolledToTop: false,
			hasAccountMenu: false,
			simulateUser: false,

			pauseSticky: false,

			isMounted: false,

			stickyItems: {
				logo: {
					height: 0,
					pos: 0,
					lastDirChangePos: 0,
					el: '.main-menu__logo',
					stickyFrom: 'top',
				},
				mobileMenu: {
					height: 0,
					pos: 0,
					lastDirChangePos: 0,
					el: '.main-menu__actions',
					stickyFrom: 'top',
				},
				desktopMenu: {
					height: 0,
					pos: 0,
					lastDirChangePos: 0,
					el: '',
					stickyFrom: 'top',
				},
			},
		};
	},

	computed: {
		...mapState({
			cartItemsCount: state => state.cart[GET_CART_QUANTITY.stateKey],
			mainMenuOpen: state => state.mainMenuOpen,
			mainServiceNavOpen: state => state.mainServiceNavOpen,
			isConfiguring: state => state.reconfigurator.reconfiguratorOpen,
		})
	},

	watch: {
		mainMenuOpen(newValue){ // COMMENT: Called by other actions(and thereby components)
			this.isOpen = newValue;

			if ( !this.isOpen && this.activeItem ) this.activeItem = null;

			if(this.subRootLevelActive){
				this.closeSubRootLevels();
			}

			this.setPauseSticky(this.isOpen);

		},
		mainServiceNavOpen(newValue) {
			if(newValue === true){
				this.isOpen = false;
				this.activeItem = null;
			}
			else {
				this.closeSubRootLevels();
			}
		}
	},

	methods: {
		...mapActions([
			'openMiniCart', // used on mini-cart icon button
			'openMainSearch', // used on se arch icon button
			'openMainMenu',
			'closeMainMenu',
			'openMainServiceNav',
			'closeMainServiceNav',
			'showOverlayBackdrop',
			'hideOverlayBackdrop'
		]),

		open(){
			this.openMainMenu(); // action
		},
		close(){
			this.closeMainMenu(); // action
			this.hideOverlayBackdrop();
		},



		toggleTopLevel (subLevel) {

			if (this.isOpen) {

				this.setPauseSticky(false);

				if (this.activeItem == subLevel) {
					this.activeItem = null;

					this.closeMainMenu();
					this.hideOverlayBackdrop();
				}
				else {
					if(this.isMobile === false){
						let menuItem = this.getLevelItem( this.activeItem );
						TweenLite.to(menuItem, 0.25, { opacity:0.5, onComplete:()=> {
							this.activeItem = subLevel;
							this.animateTopLevel();
						}, ease: Power4.easeIn });
					}
					else {
						this.activeItem = subLevel;
						this.animateTopLevel();
					}
				}
			}
			else {

				this.activeItem = subLevel;

				this.showOverlayBackdrop('behindHeader');

				this.setPauseSticky(true);

				this.animateBackToSticky();

				TweenLite.to(this.stickyItems.desktopMenu.el, 0.25, { top: 0 });

				this.animateTopLevel();
				this.openMainMenu(); // action

			}
		},
		animateTopLevel() {
			let menuItem = this.getLevelItem( this.activeItem );
			if(this.isMobile === false){
				if ( this.isOpen ) {
					TweenLite.set(menuItem, { opacity:0.5 });
					TweenLite.to(menuItem, 0.5, { opacity:1, ease: Power4.easeOut });
				}
				else {
					TweenLite.set(menuItem, { opacity:1 });
					TweenLite.to(menuItem, 0.5, { opacity:1, ease: Power4.easeInOut });
				}
			}
		},

		isActive (subLevel) {
			return this.activeItem === subLevel;
		},
		isSubLevelActive (subLevel) {
			return this.activeSubItem.indexOf(subLevel) >= 0;
		},

		toggleSubRootLevel (subLevel) {
			if ( subLevel == null ) return false;

			let clickedItem = this.getLevelItem( subLevel.toString() );
			// COMMENT: Sub Root open, active item clicked, close menu
			if (clickedItem === this.subRootLevelItem) {
				// console.log("SUB ROOT OPEN. Active item clicked")
				this.closeSubRootLevels();

				if(this.isMobile === false){
					this.hideOverlayBackdrop();
				}
			}
			// COMMET: Sub Root open, new item clicked
			else if ( this.subRootLevelActive === true ) {
				TweenLite.to(this.subRootLevelItem, 0.1, { height: 0, onComplete: ()=>{
					this.subRootLevelItem.classList.remove('is-active');

					this.subRootLevelItem = clickedItem;
					this.setSubRootLevelActive(true);

					TweenLite.set(this.subRootLevelItem, { height: 'auto' });
					TweenLite.from(this.subRootLevelItem, 0.25, { height:0 });
				}});



			}
			// COMMENT: Sub Root not open, open menu
			else {
				this.subRootLevelItem = clickedItem;

				if ( this.isMobile === false ) {
					this.closeMainMenu(); // action - setting main menu open state (so that SubRoot can be controlled by other actions)
					this.openMainServiceNav();
					this.showOverlayBackdrop('behindHeader');
				}
				TweenLite.set(clickedItem, { height: 'auto' });
				TweenLite.from(clickedItem, 0.25, { height: 0, onComplete: ()=>{

					this.setSubRootLevelActive(true);
				}});

				// COMMENT: Set active states
				this.activeSubRootItem.push(subLevel.toString());
				clickedItem.classList.add('is-active');
			}
		},

		closeSubRootLevels () {
			if (this.subRootLevelItem != null) {
				TweenLite.to(this.subRootLevelItem, 0.25, { height: 0, onComplete: ()=>{
					this.subRootLevelItem.classList.remove('is-active');
					if ( this.activeItem ) this.activeItem.blur();
					this.activeItem = null;
					this.subRootLevelItem = null;
					this.setSubRootLevelActive(false);
				}});
			}

			this.setPauseSticky(false);
		},

		closeSubLevel () {
			// COMMENT: Close open sub levels
			if (this.activeItem != '') {
				let openSubLevels = this.$refs[this.activeItem].querySelectorAll('.main-menu__sub-root-nav.is-active');
				let openSubLevelsIcons = this.$refs[this.activeItem].querySelectorAll('.toggle-icon.is-active');
				TweenLite.to(openSubLevelsIcons, 0, { className: '-=is-active'});
				TweenLite.to(openSubLevels, 0.2, { height: 0, className: '-=is-active'});
			}

			// COMMENT: Reset active item
			this.animateBackToSticky();

			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
			this.setupStickyItems();

			this.activeItem = null;
			if(!this.isMobile){
				// COMMENT: If not mobile, set isOpen false. On mobile the overlay does not close when sublevels do..
				this.closeMainMenu();
			}


			this.setPauseSticky(false);
		},

		getLevelItem(item) {
			return this.$refs[item.toString()];
		},

		//-------------------------------------------------


		animateBackToSticky () {
			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;

			Object.keys(this.stickyItems).forEach(key => {
				this.stickyItems[key].pos = 0;
			});
		},
		loadContentBackgrounds(){
			if(!this.isMobile){
				// COMMENT: The twig/template loop index should start at one!
				for(var i = 0; i < 10; i++){
					var ref = this.$refs['contentBackground' + i];
					if(ref != undefined){
						ref.style = ref.getAttribute('data-style');
						ref.removeAttribute('data-style');
					}
				}
			}
		},
		handleSearchClick(){
			this.openMainSearch();
		},
		handleMiniCartClick(e){
			if(!this.isMobile && !this.$store.state.miniCartActive && this.cartItemsCount > 0){
				// COMMENT: only open minicart on desktop and when the minicart is not active, otherwise the initiator should go to cart-page
				e.preventDefault();
				this.openMiniCart();
			}
		},

		onScroll(){
			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
			this.deviceHeight = window.screen.height;
			this.docHeight = document.body.scrollHeight;

			this.dir = (this.scrollPos > this.lastScrollTop) ? 'down' : 'up';
			this.lastScrollTop = this.scrollPos;

			if (!this.pauseSticky) {
				this.animateTop(this.stickyItems.logo);
				this.animateTop(this.stickyItems.desktopMenu);
				this.animateTop(this.stickyItems.mobileMenu);
			}
		},

		animateTop(item){
			let currentPos = parseInt(item.el.style.top);
			// console.log("this.scrollPos",this.scrollPos,"this.stickyScrollTopOffset",this.stickyScrollTopOffset)

			if (this.scrollPos <= this.stickyScrollTopOffset) {
				item.el.style.top = '0px';
				this.isScrolledToTop = true;
				return;
			} else {
				this.isScrolledToTop = false;
			}

			if (this.scrollPos + this.deviceHeight >= this.docHeight) {
				item.el.style.top = - (item.height+1) + 'px';
				return;
			}

			item.pos = item.lastDirChangePos - this.scrollPos;

			if (item.pos > 0) {
				item.pos = 0;
			}
			if (item.pos < -item.height) {
				item.pos = -item.height;
			}

			item.el.style.top = item.pos + 'px';

			if (this.dir == 'up' && item.pos == -item.height && currentPos == -item.height) {
				item.lastDirChangePos = this.scrollPos - item.height;
			}
			if (this.dir == 'down' && item.pos == 0 && currentPos == 0) {
				item.lastDirChangePos = this.scrollPos;
			}
		},
		animateBottom(item){
			let currentPos = parseInt(item.el.style.bottom);

			if (this.scrollPos <= 0) {
				item.el.style.bottom = '0px';
				return;
			}

			if (this.scrollPos >= this.docHeight) {
				item.el.style.bottom = - item.height + 'px';
				return;
			}

			item.pos = item.lastDirChangePos - this.scrollPos;

			if (item.pos > 0) {
				item.pos = 0;
			}
			if (item.pos < -item.height) {
				item.pos = -item.height;
			}

			item.el.style.bottom = item.pos + 'px';

			if (this.dir == 'up' && item.pos == -item.height && currentPos == -item.height) {
				item.lastDirChangePos = this.scrollPos - item.height;
			}
			if (this.dir == 'down' && item.pos == 0 && currentPos == 0) {
				item.lastDirChangePos = this.scrollPos;
			}
		},
		setupStickyItems(){
			Object.keys(this.stickyItems).forEach(key => {
				const item = this.stickyItems[key];

				// COMMENT: Setup el
				if (typeof item.el === 'string') {
					if (item.el != '') {
						this.stickyItems[key].el = this.$el.querySelector(item.el);
					} else {
						this.stickyItems[key].el = this.$el;
					}
				}

				// COMMENT: Setup height
				this.stickyItems[key].height = this.stickyItems[key].el.offsetHeight + 3;
				this.stickyItems[key].height += parseInt(window.getComputedStyle(this.stickyItems[key].el).getPropertyValue('margin-top'));
				this.stickyItems[key].height += parseInt(window.getComputedStyle(this.stickyItems[key].el).getPropertyValue('margin-bottom'));

				// COMMENT: Setup el pos
				this.stickyItems[key].pos = this.stickyItems[key].el.style.top;
			});
		},

		setPauseSticky(val) {
			// console.log("setPauseSticky to "+val)
			this.pauseSticky = val;
		},

		setSubRootLevelActive(val) {
			// console.log("setSubRootLevelActive to "+val)
			this.subRootLevelActive = val;
		}
	},


	mounted() {
		this.hasAccountMenu = this.$children.findIndex((item)=>{
			return item.$options.name === 'account-menu'
		}) > -1;
		this.simulateUser = this.$children.findIndex((item)=>{
			return item.$options.name === 'viewing-as'
		}) > -1;

		// COMMENT: Initiate sticky menu
		this.setupStickyItems();

		this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
		this.deviceHeight = window.innerHeight;
		this.docHeight = document.body.scrollHeight;
		this.lastDirChangePos = 0;
		this.dir = (this.scrollPos > this.lastScrollTop) ? 'down' : 'up';

		this.onScroll();

		window.addEventListener('scroll', this.onScroll, false);

		window.addEventListener('resize', function(){
			// Set new core calculations
			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
			this.deviceHeight = window.innerHeight;
			this.docHeight = document.body.scrollHeight;

			// Set new element calculations
			this.setupStickyItems();

		}.bind(this), false);

		this.isMounted = true;

		try {
			this.$nextTick(this.loadContentBackgrounds);
		}
		catch(er) {
			//do nothing?
		}
	}

};
