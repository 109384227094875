/*\
|*|
|*|	:: cookies.js ::
|*|
|*| Modified for this project, but based on:
|*|	https://github.com/madmurphy/cookies.js
|*|
|*|	Syntaxes:
|*|
|*|	* docCookies.setItem(name, value[, end[, path[, domain[, secure[, same-site]]]]])
|*|	* docCookies.hasItem(name)
|*|
\*/

	const makeSetterString = function (sKey, sValue, vEnd, sPath, sDomain, bSecure, vSameSite) {

		var sExpires = "";

		if (vEnd) {

			switch (vEnd.constructor) {

				case Number:

					/*
					Note: Despite officially defined in RFC 6265, the use of `max-age` is not compatible with any
					version of Internet Explorer, Edge and some mobile browsers. Therefore passing a number to
					the end parameter might not work as expected. A possible solution might be to convert the the
					relative time to an absolute time. For instance, replacing the following line with:
					*/
					/*
					sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; expires=" + (new Date(vEnd * 1e3 + Date.now())).toUTCString();
					*/

					sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
					break;

				case String:

					sExpires = "; expires=" + vEnd;
					break;

				case Date:

					sExpires = "; expires=" + vEnd.toUTCString();
					break;

			}

		}

		return	encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "") + (!vSameSite || vSameSite.toString().toLowerCase() === "no_restriction" || vSameSite < 0 ? "" : vSameSite.toString().toLowerCase() === "lax" || Math.ceil(vSameSite) === 1 || vSameSite === true ? "; samesite=lax" : "; samesite=strict");

	}

	const reURIAllowed = /[\-\.\+\*]/g, reCNameAllowed = /^(?:expires|max\-age|path|domain|secure|samesite|httponly)$/i;

	export default {

		"setItem": function (sKey, sValue, vEnd, sPath, sDomain, bSecure, vSameSite) {

			if (!sKey || reCNameAllowed.test(sKey)) { return false; }

			document.cookie = makeSetterString(sKey, sValue, vEnd, sPath, sDomain, bSecure, vSameSite);
			return true;

		},

		"hasItem": function (sKey) {

			if (!sKey || reCNameAllowed.test(sKey)) { return false; }

			return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(reURIAllowed, "\\$&") + "\\s*\\=")).test(document.cookie);

		}

	};

