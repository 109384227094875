import BuySectionCore from '../../components/buy-section/buy-section-core';
import debounce from 'lodash/debounce';

export default {
	name: 'buy-section', //// COMMENT::::: THIS BUY-SECTION IS FOR PDP, CONFIGURATOR AND TILES
	extends: BuySectionCore,
	props: {
		context: { // COMMENT: Set by template
			type: String,
			required: true,
		},
		dispatchInfo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			price: this.unitPrice, // COMMENT: Sub-total (sub-total multiplied by quantity)
			quantityStep: this.isColliRestricted ? this.colli : 1
		}
	},
	beforeMounted() {
		this.price = this.unitPrice;
	},
	mounted() {
		this.quantityInput = this.$refs.quantityInput && this.$refs.quantityInput[0] ? this.$refs.quantityInput[0] : this.$refs.quantityInput;
		this.isLoaded = true;
		if (this.context === 'configurator') { // COMMENT: Context is set in template..
			// COMMENT: For any parent component who needs to be able to set the disabled state of the add-to-cart button
			this.$parent.$on('update-buy-button-disabled', this.onBuyButtonDisabled); // COMMENT: Trigged in Configurator's "updateBuySection" method
		}
		this.setupInputKeyEvents();
	},
	methods: {
		decrement() {
			const newQuantity = +this.quantity - this.quantityStep
			this.quantity = newQuantity ? newQuantity : this.quantityStep
			if (this.minimumOrderQuantity && this.quantity < this.minimumOrderQuantity){
				this.quantity = this.minimumOrderQuantity
			}
		},

		increment() {
			this.quantity = +this.quantity + this.quantityStep
		},

		validateQuantityInput(e) {
			const validKeys = [
				48, 49, 50, 51, 52, 53, 54, 55, 56, 57, // top keyboard 0-9
				96, 97, 98, 99, 100, 101, 102, 103, 104, 105, // numpad 0-9
				8, // backspace
				13, // enter
				27 // esc
			]

			if (validKeys.indexOf(e.keyCode) === -1) {
				e.preventDefault()
				return false;
			}
			return true;
		},
		onBuyButtonDisabled(isDisabled) { // COMMENT: Gets trigged by configurator's emitted custom-event
			this.parentDisableButton = isDisabled;
			this.isDisabled = this.checkDisabledState();
		},
		emitQuantityUpdate() {
			const quantity = this.quantity ? + this.quantity : this.quantityStep;
			if (quantity % this.quantityStep !== 0) {
				this.quantity = quantity < this.quantityStep ? this.quantityStep : Math.ceil(quantity / this.quantityStep) * this.quantityStep
			}
			if (this.minimumOrderQuantity && this.quantity < this.minimumOrderQuantity){
				this.quantity = this.minimumOrderQuantity
			}
		},
		setupInputKeyEvents() {
			if (this.quantityInput) {
				this.quantityInput.addEventListener('keyup', debounce(e => {
					if (this.validateQuantityInput(e)) {
						this.emitQuantityUpdate();
					} else {
						e.preventDefault();
						return;
					}
				}, 5000));
			}
		}
	},
};
