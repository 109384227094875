import { mapActions } from "vuex";
import ImageProductsButton from "../image-products/image-products-button";

export default {
	name: "hero",
	components: {
		ImageProductsButton
	},
	data() {
		return {
			muted: true,
		};
	},
	methods: {
		...mapActions(["setActiveVideo"]),
		toggleSound() {
			let videoRef = this.$refs["hero-video"];

			let muted = videoRef.muted;
			if (muted) {
				videoRef.muted = false;
			} else {
				videoRef.muted = true;
			}

			this.muted = videoRef.muted;
		},
		openVideo(event) {
			let dataset = event.currentTarget.dataset;
			if (dataset) {
				let videoType = dataset.videoType;
				let videoValue = dataset.videoValue;
				console.log("HERO BUTTON CLICK", videoType, videoValue);

				this.setActiveVideo({ type: videoType, value: videoValue });
			}
		},
	},
};
