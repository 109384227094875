import ProductTileProd from '../../components/product-tile/product-tile';
import InspirationTile from '@components/inspiration-tile/inspiration-tile'
import { mapState } from 'vuex';
import { uid } from '../../basics/js/utils';
import { registerImpression } from "../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer";

export default {
	name: 'recommendation',
	components: {
		ProductTileProd,
		InspirationTile
    },
    props: {
		limit: {
			type: Number,
			required: false,
			default: 4
		},
		tileDataJson: {
			type: String,
			required: false
		},
		type: {
			type: String,
			required: true
		},
		loadMoreUrl: {
			type: String,
			required: false
		},
		loadMoreText: {
			type: String,
			required: true
		},
		totalNumberOfProducts: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			localLimit: this.limit	
		};
	},    
	computed: {
		...mapState({
			isMobile: state => state.isMobile
		}),
		showLoadMoreButton(){
			return this.localLimit < this.numberOfProducts;
		},
		tileData(){
			return JSON.parse(this.tileDataJson)
		},
		numberOfProducts(){
			return this.totalNumberOfProducts > 0 ? this.totalNumberOfProducts : this.tileData.length
		},
		loadMoreCount(){
			return this.totalNumberOfProducts > 0 ? this.totalNumberOfProducts : this.numberOfProducts - this.localLimit
		}	
	},
	methods: {
		uid,
		onLoadMore() {
			if(this.loadMoreUrl) {
				document.location.href = this.loadMoreUrl;
			} else {
				// show the rest of the products
				this.localLimit = this.numberOfProducts;
			}
		}
		
	},
	mounted(){
		registerImpression(this.tileDataJson, "recommendation-block")
	}
};