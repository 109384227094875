<script>
    import { mapState, mapActions } from "vuex";
    import { rInterval } from "../../../../basics/js/utils";

    export default {
        name: "zoom-overlay",
       data() {
            return {
                intervalClear:-1
            }
        },
        computed: {
            ...mapState({
                bodyFixed: state => state.bodyFixed,
                windowH: state => state.windowH,
                windowW: state => state.windowW,
                
                isOpen: state => state.reconfigurator.zoomOverlayOpen,
                overlayImage: state => state.reconfigurator.zoomOverlayImage
            }),
        },
        watch: {
            overlayImage(newVal) {
                if ( newVal ) {
		            this.intervalClear = rInterval(this.imageLoadListener.bind(this), 100).clear;
                }
            }
        },
        methods: {
            ...mapActions([
                "closeZoomOverlay",
                "hideOverlayBackdrop",
            ]),
            imageLoadListener: function () {
                
                // console.log("this.$refs.image.naturalWidth", this.$refs.image.naturalWidth)
                // console.log("this.$refs.image.complete", this.$refs.image.complete)

                if ( this.$refs.image.naturalWidth > 0 && this.$refs.image.naturalHeight > 0 ) {
                    this.intervalClear();
                    this.intervalClear = null;
                    // console.log("LOADED");

                    this.imageLoaded();
                }
            },
            close: function() {
                this.hideOverlayBackdrop();
                this.closeZoomOverlay();
            },
            imageLoaded: function() {
                // console.log("loaded", this.$refs.image.width, this.$refs.image.height,this.windowW,this.windowH)
                this.$refs.inner.scrollLeft = this.$refs.image.width / 2 - this.windowW / 2;
                this.$refs.inner.scrollTop = this.$refs.image.height / 2 - this.windowH / 2;
            }
        }
    };
</script>


<template>
    <div class="zoom-overlay" :class="{'is-active': overlayImage && isOpen}" v-on:keydown.esc="close" tabindex="0">
        
        <div class="zoom-overlay__inner" ref="inner">
            <img v-if="overlayImage" :src="(overlayImage.srcLrg || overlayImage.src)" :alt="overlayImage.title" ref="image"/>
            <img v-else src="" ref="image"/>
        </div>
        
        <button class="zoom-overlay__close" v-on:click="close">
            <svg viewbox="0 0 100 100" class="svg-icon">
                <use xlink:href="/dist/svg/symbols.svg#svg-close"></use>
            </svg>            
        </button>
    </div>
</template>