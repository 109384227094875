import Swiper from "swiper/dist/js/swiper.js";

import mockData from "./mock/story-slider-mock";

import StorySlideCover from "./partials/story-slide-cover/story-slide-cover.vue";
import StorySlideContent from "./partials/story-slide-content/story-slide-content.vue";
import StorySlideVideo from "./partials/story-slide-video/story-slide-video.vue";
import StorySlideProducts from "./partials/story-slide-products/story-slide-products.vue";

import StorySlide from "./partials/story-slide.vue";


export default {
	name: "story-slider",
	components: {
		StorySlide,
		StorySlideCover,
		StorySlideContent,
		StorySlideVideo,
		StorySlideProducts,
	},
	props: {
		stories: {
			type: Array,
			default() {
				return mockData.stories;
			},
		},
		chapters: {
			type: Array,
			default() {
				return mockData.chapters;
			},
		},
		dragText: {
			type: String,
			default: "Drag",
		},
		useDragCursor: {
			type: Boolean,
			default: false,
		},
		useAutoPlay: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			slider: null,
			activeChapter: null,

			activeStoryIndex: null,
			activeStoryItem: null,

			showPagination: false,
			showNavigation: true,
			autoplayProgress: 0,
			autoplayStarttime: Date.now(),

			sliderSettings: {
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				// autoplay: {
				// 	delay: 4000,
				// },
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				on: {
					slideChange: this.slideChangeHandler.bind(this),
				},
				preloadImages: true,
				watchOverflow: true,
				touchStartPreventDefault: false, //"thanks" emil
			},

			cursorX: 0,
			cursorY: 0,

			cursorTargetX: 0,
			cursorTargetY: 0,

			cursorActive: false,

			cursorFocused: false,

			navButtonsVisible: false,
		};
	},
	computed: {
		slideStyle() {
			if (this.activeStoryItem === null) return {};
			//get story colors, add as custom props
			const { textColor, textColor2, backgroundColor, cursorTextColor, cursorBackgroundColor } = this.activeStoryItem;
			return {
				"--story-slider-text1-color": textColor,
				"--story-slider-text2-color": textColor2 || null,
				"--story-slider-bg-color": backgroundColor,
				"--story-slider-cursor-text-color": cursorTextColor,
				"--story-slider-cursor-bg-color": cursorBackgroundColor,
				"--story-slider-active-bullet-progress": this.autoplayProgress,
			};
		},
		cursorStyle() {
			return {
				"--cursor-y": this.cursorY + "px",
				"--cursor-x": this.cursorX + "px",
			};
		},
		footerClass() {
			return {
				"story-slider__footer--nav-visible": this.navButtonsVisible,
			};
		}
	},
	methods: {
		slideChangeHandler() {
			const activeIndex = this.slider.activeIndex;
			this.activeChapter = this.chapters.find((chapter) => {
				return chapter.startIndex <= activeIndex && chapter.endIndex >= activeIndex;
			});
			this.activeStoryIndex = activeIndex;
			this.activeStoryItem = this.stories[activeIndex];
			this.autoplayStarttime = Date.now();
			this.autoplayProgress = this.useAutoPlay ? 0 : 1;
		},
		chapterClickHandler(index) {
			this.slider.slideTo(index);
		},
		lerp(start, end, percentage) {
			const differenceBetween = end - start;
			const lerpedValue = differenceBetween * percentage;
			return lerpedValue;
		},
		getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;

			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;

				current = current.offsetParent;
			}

			return actualOffset;
		},
	},
	mounted() {
		this.activeChapter = this.chapters[0];

		this.activeStoryIndex = 0;
		this.activeStoryItem = this.stories[0];

		this.sliderSettings.pagination = {
			el: this.$refs.pagination,
			clickable: true,
		};
		this.sliderSettings.navigation = {
			nextEl: this.$refs.nextButton,
			prevEl: this.$refs.prevButton,
		};

		this.slider = new Swiper(this.$refs.slider, this.sliderSettings);

		if (this.useDragCursor) {
			this.$refs["cursor-target"].addEventListener("mousemove", (e) => {
				this.cursorTargetX = e.pageX;
				this.cursorTargetY = e.pageY - this.getOffsetTopRecursive(this.$el);
			});

			//same function but for drag
			this.$refs["cursor-target"].addEventListener("touchmove", (e) => {
				this.cursorTargetX = e.pageX;
				this.cursorTargetY = e.pageY - this.getOffsetTopRecursive(this.$el);
			});

			this.$refs["cursor-target"].addEventListener("mouseenter", () => {
				this.cursorActive = true;
			});
			this.$refs["cursor-target"].addEventListener("mouseover", (e) => {
				// hide cursor if mouse is over button or link
				if (e.target.tagName === "BUTTON" || e.target.tagName === "A") {
					this.cursorActive = false;
				} else {
					this.cursorActive = true;
				}
			});
			this.$refs["cursor-target"].addEventListener("mouseleave", () => {
				this.cursorActive = false;
			});
		}
		//else make it so when a user mouse enters the slider, the navButtonsVisible is set to true and if the mouse stands still for 1 sec, the navButtonsVisible is set to false
		else {
			let timeoutHandle = setTimeout(() => {
				this.navButtonsVisible = false;
			}, 0);

			this.$refs["cursor-target"].addEventListener("mouseleave", () => {
				this.navButtonsVisible = false;
			});
			this.$refs["cursor-target"].addEventListener("mousemove", () => {
				this.navButtonsVisible = true;
				clearTimeout(timeoutHandle);
				timeoutHandle = setTimeout(() => {
					this.navButtonsVisible = false;
				}, 1500);
			});
			this.$refs["cursor-target"].addEventListener("mouseup", () => {
				this.navButtonsVisible = true;
				clearTimeout(timeoutHandle);
				timeoutHandle = setTimeout(() => {
					this.navButtonsVisible = false;
				}, 1500);
			});
		}

		//run on all animation frames
		const loop = () => {
			this.cursorX += this.lerp(this.cursorX, this.cursorTargetX, 0.2);
			this.cursorY += this.lerp(this.cursorY, this.cursorTargetY, 0.2);

			// request the next animation frame
			requestAnimationFrame(loop);

			// set autoplay progress using autoplayStarttime
			if (this.slider.autoplay.running) {
				this.autoplayProgress = (Date.now() - this.autoplayStarttime) / this.sliderSettings.autoplay.delay;
				if (this.autoplayProgress > 1) this.autoplayProgress = 1;
				if (this.autoplayProgress < 0) this.autoplayProgress = 0;
			} else {
				this.autoplayProgress = 1;
			}
		};

		requestAnimationFrame(loop);
	},
};
