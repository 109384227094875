import {TweenLite} from 'gsap';
import ProductTile from '../../components/product-tile/product-tile';

export default {
	name: 'preview-tiles',
	components: {
		ProductTile,
	},
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		toggle () {
			const toggleContent = this.$el.getElementsByClassName('accordion__content')[0];
			const trigger = this.$el.getElementsByClassName('js--accordion-trigger')[0];

			if (!this.isOpen) { // Open accordion item
				// Set aria attributes
				toggleContent.setAttribute('aria-hidden', 'false');
				trigger.setAttribute('aria-expanded', 'true');

				// Animate
				TweenLite.set(toggleContent, { height: 'auto' });
				TweenLite.from(toggleContent, 0.2, { height: 0 });

				// Toggle active class
				trigger.getElementsByClassName('icon')[0].classList.add('is-active');
			} else { // Close accordion item
				// Animate
				TweenLite.to(toggleContent, 0.2, { height: 0, onComplete: ()=>{
					// Set aria attributes
					trigger.setAttribute('aria-expanded', 'false');
					toggleContent.setAttribute('aria-hidden', 'true');
				}});

				trigger.getElementsByClassName('icon')[0].classList.remove('is-active');
				// Toggle active class
			}

			// Toggle state
			this.isOpen = !this.isOpen;
		}
	},
};