<script>
import { mapState, mapActions } from "vuex";
import TabsBar from "../../../experience/tabs-bar/tabs-bar"
import Cookies from 'js-cookie'
import { CookiePrefix } from "../constants";

export default {
    name: "options-overlay",
    components: {
        TabsBar,
    },
    props: {
        systemText: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeOptionListIndex: 0,
            listKey: 0,
            isDeleting: false,
            currentDeleteItem: null
        }
    },
    computed: {
        ...mapState({
            bodyFixed: state => state.bodyFixed,
            currentProductId: state => state.reconfigurator.currentProductId,
            isOpen: state => state.reconfigurator.optionsOverlayOpen,
            overlayItems: state => state.reconfigurator.optionsOverlayItems,
            overlayActiveListIndex: state => state.reconfigurator.optionsOverlayActiveListIndex
        }),
        currentOptionList() {
            if (this.overlayItems && this.overlayItems.length) {
                return this.overlayItems[this.activeOptionListIndex];
            } else {
                return this.overlayItems || null;
            }
        },
        currentOptionListItems() {
            return this.currentOptionList ? this.currentOptionList.items : [];
        },
    },
    watch: {
        overlayActiveListIndex(index) {
            this.activeOptionListIndex = index;
        }
    },
    methods: {
        ...mapActions([
            "closeOptionsOverlay",
            "hideOverlayBackdrop",
        ]),
        close: function () {
            this.hideOverlayBackdrop();
            this.closeOptionsOverlay();
        },
        optionListChangeHandler: function (item, newIndex) {
            this.activeOptionListIndex = newIndex;
        },
        deleteItemHandler: function (item) {
            this.currentDeleteItem = item;
            this.isDeleting = true;
        },
        deleteCancelHandler: function () {
            this.currentDeleteItem = null;
            this.isDeleting = false;
        },
        deleteConfirmHandler: function () {

            this.currentOptionListItems.splice(this.currentOptionListItems.indexOf(this.currentDeleteItem), 1);

            if (this.currentOptionListItems.length === 0) {
                let index = this.overlayItems.indexOf(this.currentOptionList);
                console.log("index", index)
                //if list empty, remove it
                this.overlayItems.splice(index, 1);
                this.activeOptionListIndex = 0;
            }

            // deleted item is the current option
            if (this.currentDeleteItem.isActive) {
                if (this.currentOptionListItems) {
                    window.open(this.currentOptionListItems.href);
                }
            } else {
                //TODO: do something?
            }

            if (this.currentDeleteItem.variantCode) {

                let currentSaved = Cookies.get(CookiePrefix + this.currentProductId);
                console.log("DELETE | cookie before", currentSaved);

                if (currentSaved) {
                    currentSaved = currentSaved.replace(this.currentDeleteItem.variantCode, '');
                    currentSaved = currentSaved.replace('||', '|');
                    Cookies.set(CookiePrefix + this.currentProductId, currentSaved, { expires: 60 });

                    console.log("DELETE | cookie after", Cookies.get(CookiePrefix + this.currentProductId))
                }
            }

            this.listKey++;
            this.currentDeleteItem = null;
            this.isDeleting = false;
        }
    }
};
</script>


<template>
    <div class="options-overlay" :class="{ 'is-active': isOpen && currentOptionList }" v-on:keydown.esc="close"
        tabindex="0">

        <button class="options-overlay__close" v-on:click="close">
            <svg viewbox="0 0 100 100" class="svg-icon">
                <use xlink:href="/dist/svg/symbols.svg#svg-close"></use>
            </svg>
        </button>

        <tabs-bar inline-template v-if="overlayItems && overlayItems.length > 1"
            :tab-click-handler="optionListChangeHandler" :active-index="activeOptionListIndex" :items="overlayItems">
            <nav class="tabs-bar">
                <div class="tabs-bar__inner">
                    <button class="tabs-bar__button" v-for="(item, index) in items" :key="index"
                        :class="{ 'is-active': index === activeIndex }" v-on:click="tabClickHandler(item, index)" v-cloak>
                        <span>{{ item.name }}</span>
                    </button>
                </div>
            </nav>
        </tabs-bar>

        <div class="options-overlay__inner">

            <ul class="options-overlay__list" v-if="currentOptionListItems && currentOptionListItems.length > 0"
                :key="listKey" :class="{ 'is-editable': currentOptionList.isEditable }">
                <li v-for="item in currentOptionListItems" :key="item.name + '_' + item.id"
                    class="options-overlay__list-item"
                    :class="{ 'is-active': item.isActive, 'is-deleting': (isDeleting && currentDeleteItem.variantCode === item.variantCode) }">

                    <a class="options-overlay__button" :href="item.href">
                        <svg viewBox="0 0 100 100" class="svg-icon">
                            <use xlink:href="/dist/svg/symbols.svg#svg-angle-arrow"></use>
                        </svg>
                        <span>{{ item.name }}</span>
                        <img :src="item.image.src" :alt="item.name">
                    </a>

                    <button class="options-overlay__list-item__delete" v-on:click="deleteItemHandler(item)"
                        v-if="currentOptionList.isEditable && (isMobile || !isDeleting || isDeleting && currentDeleteItem.variantCode === item.variantCode)">
                        <svg viewbox="0 0 100 100" class="svg-icon">
                            <use xlink:href="/dist/svg/symbols.svg#svg-close"></use>
                        </svg>
                    </button>

                    <div class="options-overlay__list-item__confirmation"
                        v-if="isDeleting && currentDeleteItem.variantCode === item.variantCode">
                        <p>{{ systemText.variantDeleteHeadline }}</p>
                        <button v-on:click="deleteConfirmHandler">{{ systemText.variantDeleteConfirm }}</button>
                        <button v-on:click="deleteCancelHandler">{{ systemText.variantDeleteCancel }}</button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>