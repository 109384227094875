import mockData from "./inspiration-tile-shortcuts-data";
import { mapActions } from 'vuex';

export default {
    name: 'InspirationTile',
    props: {
        data: {
            type:Object,
            required: false,
            default() {
                return {
                    bodyText:"The final of our three creatives is Christiane Spangsberg, a Copenhagen-based painter, speaking on her workshop.",
                    code:"312312 DRED",
                    colli:1,
                    color:"#000FFF",
                    designer:null,
                    dispatchInfo:"2-4 weeks",
                    formattedPrice:"€450",
                    headline:"AN INVITING SEAT:<br>COVER LOUNGE",
                    hoverImageUrl:null,
                    imageAltText:"Modern chair in modern color",
                    imageUrl:" /static/images/product/fiber-armchair-700w.png",
                    isConfigurable:null,
                    productPrimaryName:"Fiber armchair",
                    productSecondaryName:"Tube base",
                    trumpet:"Designer portrait",
                    type:"Variant",
                    url:"/shop/product/fiber-side-chair---tube-base/fiber-side-chair---tube-base-dusty-red/25003/",
                    variantName:"Dusty red/Dusty red",
                }
            }
        },
        hasShortcuts: {
            type: Boolean,
            default: false,
            required: false,
        },        
        configureShortcutsOverlayData: {
            type: Object,
            required: false,
            default() {
                return mockData.configureData;
            }
        }, 
    },
    computed: {
		tiletypeClass(){
			const typeAndVariant = this.data.isLargeTile ? "inspiration-tile--large" : "inspiration-tile--inset";
			return `${typeAndVariant}`
        }
    },
    methods: {
        ...mapActions([
			'openShortcutsOverlay',
			'showOverlayBackdrop',
		]),
        openConfigureOverlay: function() {
           this.openShortcutsOverlay(this.configureShortcutsOverlayData);  
           this.showOverlayBackdrop();
        },
    }
}