import Swiper from "swiper/dist/js/swiper.js";

import TabsBar from "../tabs-bar/tabs-bar"
import mockData from "./design-explorer-data"

import UspSpot from "./../../usp-spot/usp-spot";

export default {
    name: "design-explorer",
    components: {
        TabsBar,
        UspSpot
    },
    props: {
        titleNumber: {
            type: String,
            default: '1.'
        },
        titleText: {
            type: String,
            default: 'Whats your style?'
        },
        bodyText: {
            type: String,
            default: 'Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.'
        },
        styleExplorerData: {
            type: Array,
            default() {
                return mockData;
            }
        }
    },
    computed: {
        getStyleList: function() {
            let list = [];
            list = this.styleExplorerData.map(item=>{ return {label: item.category}; });
            return list;
        },

    },
    data() {
        return {
            activeStyleIndex: 0,
            activeCategory: this.styleExplorerData[0],

            slider: null,
			slidesCount: 0,
			slidesRef: null,

			settings: {
				simulateTouch: true,
				allowTouchMove: true,

				slidesPerView: 3.25,
                spaceBetween: 40,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				preloadImages: false,
				watchOverflow: true,
                breakpoints: {
                    // when window width is >= 320px
                    560: {
                        slidesPerView: 1.25,
                        spaceBetween: 10
                    },
                    768: {
                      slidesPerView: 1.75,
                      spaceBetween: 20
                    },
                    // when window width is >= 480px
                    1024: {
                      slidesPerView: 2.25,
                      spaceBetween: 20
                    },
                    1920: {
                      slidesPerView: 2.5,
                      spaceBetween: 30
                    },
                  }
			},
        }
    },
    methods: {
        styleChangeHandler: function(item,index) {
            this.activeStyleIndex = index;
            this.activeCategory = this.styleExplorerData[this.activeStyleIndex];


            setTimeout(function() {
                this.slider.update();
                this.slider.slideTo(0);
            }.bind(this),10);
        },
        getSlideStyle: function(item) {
            if ( item ) {
				return `background-color: ${item.backgroundColor}; --design-explorer-slide-bg-color: ${item.backgroundColor};color: ${item.textColor}; --design-explorer-slide-text-color: ${item.textColor}`;
			}
        }
    },
    mounted() {
		this.slider = new Swiper(this.$refs.slider, this.settings);

		this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");

		this.slidesCount = this.slidesRef.length;
    }
}
