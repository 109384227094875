
import { mapActions } from 'vuex';

/*
{ COMMENT: "PANEL" AVAILABLE OPTIONS
	id: 			is automatically generated in mutations
	type: 			atc / info / warning / error
	atcText: 		Translated text of "Added to cart". Only shown when type "atc".
	title: 			Optional. Text is visually bold. Ex, the product name shown in "atc".
	message: 		Required. Regular Text.
	bgColor: 		Otional. Overwrite css-class bgColor definitions.
	stallDuration: 	Optional. Defaults to 3000ms. Time of which the panel is shown, NOT including animation of 400ms(css duration) each way.
	autoRemove: 	Optional. Default is true.
	showClose: 		Optional. Default is true. Type "atc" should be the only one setting this to false.
	maxWidth: 		Optional. Default is false. Sets the max-width og message to 350px on mobile and 50% of panel on desktop(in the css).
}
*/

export default {
	name: 'notification-panel',
	props: {
		id: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: false,
			default: '',
		},
		message: {
			required: false,
			default: null,
		},
		atcText: {
			type: String,
			required: false,
			default: 'Added to cart',
		},
		atcLink: {
			type: String,
			required: false,
			default: 'Go to cart',
		},
		bgColor: {
			type: String,
			required: false,
		},
		stallDuration: {
			type: Number,
			required: false,
			default: 3000, // COMMENT: For type 'atc' this should be the same duration as Buy-Section stall duration (which is 3000)
		},
		showClose: {
			type: Boolean,
			required: false,
			default: true,
		},
		showLink: {
			type: Boolean,
			required: false,
			default: false,
		},
		autoRemove: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxWidth: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			cssTransitionDuration: 400,
			removalTimeout: null,
			bgColorCss: '',
		}
	},
	mounted(){
		this.bgColorCss = this.bgColor ? `background-color: ${this.bgColor};` : '';

		this.$nextTick(this.showPanel);

		this.$el.addEventListener('mouseover', this.clearRemovalDelay.bind(this) );
		this.$el.addEventListener('mouseout', this.startRemovalDelay.bind(this, 1000) );
	},
	methods: {
		...mapActions([
			'removeNotificationPanel',
		]),
		showPanel(){
			setTimeout(()=>{
				this.$el.classList.remove('is-collapsed');
			}, 50); // COMMENT: A tiny delay, otherwise the animation will not be shown

			this.startRemovalDelay();
		},
		clearRemovalDelay() {
			if ( this.removalTimeout ) {
				clearTimeout(this.removalTimeout);
			}
		},
		startRemovalDelay(delay) {
			//console.log("startRemovalDelay")
			if ( !delay ) delay = this.stallDuration + this.cssTransitionDuration
			if(this.autoRemove){
				this.removalTimeout = setTimeout(()=>{
					this.removePanel();
				}, delay);
			}
		},
		removePanel(){
			// COMMENT: Start removal transition and then remove..
			if(this.autoRemove && this.removalTimeout){
				clearTimeout(this.removalTimeout);
			}
			this.$el.classList.add('is-collapsed');
			setTimeout(()=>{
				this.removeNotificationPanel(this.id); // COMMENT: Mutation, by action
			}, this.cssTransitionDuration + 50); // ~400ms is the transition duration set by css. Added a little extra just to make sure the ui is done animating before removing
		},
	}
};
