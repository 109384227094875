import throttle from "lodash/throttle";

export default {
	name: "sticky-gallery",
	props: {
		content: { type: Array, default: () => [] },
	},
	data: () => ({
		imageHeight: 0, // calculated on scroll
		aspectRatio: 0, // how heigh is the images (defined in css)
		curActive: 0, // what image is in view
		amount: 60, // setting, how much effect? 0 = none, 100 = sticky image
	}),
	computed: {
		effect() {
			return this.amount * this.aspectRatio * -1;
		},
	},
	methods: {
		onScroll() {
			throttle(this.update, 16)();
		},
		setActive(idx, top) {
			if (top < 0 && -top < this.imageHeight) {
				this.curActive = idx + 1;
			} else if (idx === 0 && top > 0) {
				this.curActive = 0;
			}
		},
		update() {
			const {
				top: componentTop,
				bottom,
			} = this.$el.getBoundingClientRect();

			if (
				(componentTop > 0 && bottom > 0) ||
				(componentTop < 0 && bottom < 0)
			) {
				// component is not in view
				return;
			}

			const { innerWidth, innerHeight } = window;
			const [, ...tail] = this.$refs["sticky-gallery__item"];

			this.imageHeight = Math.max(
				innerHeight,
				innerWidth * this.aspectRatio
			);

			tail.forEach((image, idx) => {
				const top = componentTop + this.imageHeight * (idx + 1);
				const parallaxAmount = (top / innerHeight) * this.effect;

				image.firstChild.style.transform =
					top > innerHeight || top < 0
						? `translate(0)`
						: `translate3d(0, ${parallaxAmount}%, 0)`;

				this.setActive(idx, top);
			});
		},
		setHeaderColor() {
			const { backgroundColor } = getComputedStyle(document.body);

			const headerColor =
				backgroundColor !== "rgba(0, 0, 0, 0)"
					? backgroundColor
					: "#ffffff";

			this.$refs[
				"sticky-gallery__header"
			].style.backgroundColor = headerColor;
		},
		getAspectRatio() {
			const { paddingBottom } = getComputedStyle(
				this.$refs["sticky-gallery__item"][0]
			);

			this.aspectRatio = parseInt(paddingBottom) / window.innerWidth;
		},
	},
	mounted() {
		this.onScroll();
		// this.setHeaderColor();
		this.getAspectRatio();

		window.addEventListener("scroll", this.onScroll.bind(this));
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll.bind(this));
	},
};
