// import TweenLite from 'gsap/umd/TweenLite';
import { TweenLite } from "gsap";
import { mapState, mapActions } from 'vuex';

import AccordionItemFilter from "./accordion-item-filter";
import AccordionItem from "../../components/accordion/accordion";
import { SET_FILTER_COLOR } from "../../basics/js/store/static";

export default {
	name: "product-filter",
	components: {
		AccordionItemFilter,
		AccordionItem,
	},
	props: {
		numberOfProducts: {
			type: Number,
			default: 0,
			required: false,
		},
	},
	methods: {
		...mapActions([
			"openProductFilters",
			"closeProductFilters",
		]),
		toggle () {			
			if (this.isOpen == true) {
				this.closeProductFilters();
			} else {
				this.openProductFilters();
			}
		},
		animateContent() {
			if ( this.hideContent ) return;

			const filters = this.$refs['filter-content'];

			if (this.isOpen == true) {
				console.log("anim open")
				TweenLite.set(filters, { height: 'auto' });
				TweenLite.from(filters, 0.3, { height: 0 });
			} else {
				console.log("anim close")
				TweenLite.to(filters, 0.3, { height: 0});
			}
		},
		clear() {
			this.selectedFilters = [];
			// TODO: this should be refactored, it's only to show how filters should work
			// it would be better if the filter intacted directly with the state
			this.$emit("change", this.selectedFilters);
		},
		addFilter() {
			console.log("add filter");
			this.selectedFilters.push({
				name: "New Filter - lorem ipsum dolor sit amet",
				id: Math.random(),
			});
		},
		removeFilter(id) {
			this.selectedFilters = this.selectedFilters.filter(
				(obj) => obj.id !== id
			);
		},
		getFilterItems(items, id) {
			return items.filter((item) => item.id === id);
		},
		onSelect(checkedFilter) {
			const index = this.selectedFilters.findIndex(
				(e) => e.id === checkedFilter.id
			);

			if (index === -1) {
				this.selectedFilters.push(checkedFilter);
			} else {
				this.selectedFilters = this.selectedFilters.filter(
					(item) => item.id !== checkedFilter.id
				);
			}

			this.doApiStuff();
		},
		doApiStuff() {
			// TODO: this should be refactored, it's only to show how filters should work
			// it would be better if the filter intacted directly with the state
			this.$emit("change", this.selectedFilters);
		},
	},
	computed: {
		...mapState([SET_FILTER_COLOR.stateKey, "isMobile", "productFiltersOpen"]),
		isOpen(){
			return this.$store.state.productFiltersOpen
		},
		backgroundColor() {
			return this[SET_FILTER_COLOR.stateKey]
				? `--product-filter-bg-color-active: ${
						this[SET_FILTER_COLOR.stateKey]
				}`
				: "--product-filter-bg-color-active: #fff";
		},
		availableTotalResults() {
			var number = 23218;
			return number.toLocaleString("da-dk");
		},
	},
	watch: {
		isOpen(){
			this.animateContent();
		},
		checkedFilters() {
			console.log("Checked filters: ", this.checkedFilters);
		},
		searchTerm() {
			// TODO/deprecated: add functionality to filter the filters
			// TODO: Add functionality to make the search-term a selected filter
			console.log("Filter products with searchTerm: ", this.searchTerm);
		},
	},
	mounted() {
		this.isMounted = true;

		this.$on("toggleAccordion", () => {
			// Close already open accordions
			this.$refs["accordion"].forEach(function (accordion) {
				if (accordion.isOpen) {
					accordion.close();
				}
			});
		});

		this.animateContent();
	},
	data() {
		return {
			searchTerm: "",
			checkedFilters: [],
			selectedFilters: [],
			filterGroups: [
				{
					id: 0,
					title: "Type",
					filters: [
						{
							name: "Dining chair",
							id: 0,
							active: true,
						},
						{
							name: "Office chair",
							id: 1,
							active: true,
						},
					],
				},
				{
					id: 1,
					title: "Family",
					filters: [
						{
							name: "Family 1",
							id: 3,
							active: false,
						},
						{
							name: "Family 2",
							id: 4,
							active: true,
						},
						{
							name: "Family 3",
							id: 41,
							active: true,
						},
						{
							name: "Family 4",
							id: 42,
							active: true,
						},
						{
							name: "Family 5",
							id: 43,
							active: true,
						},
						{
							name: "Family 5",
							id: 44,
							active: true,
						},
					],
				},
				{
					id: 2,
					title: "Material",
					filters: [
						{
							name: "Eating chair",
							id: 5,
							active: true,
						},
						{
							name: "Office dog chair",
							id: 6,
							active: true,
						},
					],
				},
				{
					id: 3,
					title: "Basetype",
					filters: [
						{
							name: "Eating chair",
							id: 7,
							active: true,
						},
						{
							name: "Office dog chair",
							id: 8,
							active: true,
						},
					],
				},
				{
					id: 4,
					title: "Height",
					filters: [
						{
							name: "Eating chair",
							id: 9,
							active: true,
						},
						{
							name: "Office dog chair",
							id: 10,
							active: true,
						},
					],
				},
				{
					id: 5,
					title: "Color",
					filters: [
						{
							id: 11,
							active: false,
							name: "Cyan",
							color: "#ddeedd",
						},
						{
							id: 12,
							active: true,
							name: "Red",
							color: "#eedddd",
						},
						{
							id: 13,
							active: true,
							name: "Purple",
							color: "#ddddee",
						},
						{
							id: 14,
							active: true,
							name: "Yellow",
							color: "#ffeedd",
						},
						{
							id: 15,
							active: true,
							name: "Cyan",
							color: "#ddeedd",
						},
						{
							id: 16,
							active: true,
							name: "Red",
							color: "#eedddd",
						},
						{
							id: 17,
							active: true,
							name: "Purple",
							color: "#ddddee",
						},
						{
							id: 18,
							active: true,
							name: "Yellow",
							color: "#ffeedd",
						},
						{
							id: 19,
							active: true,
							name: "Cyan",
							color: "#ddeedd",
						},
						{
							id: 20,
							active: true,
							name: "Red",
							color: "#eedddd",
						},
						{
							id: 21,
							active: true,
							name: "Purple",
							color: "#ddddee",
						},
						{
							id: 22,
							active: true,
							name: "Yellow",
							color: "#ffeedd",
						},
					],
				},
				{
					id: 6,
					title: "Designer",
					filters: [
						{
							name: "Andersen & Voll",
							id: 0,
							active: true,
						},
						{
							name: "Cecilie Manz",
							id: 1,
							active: true,
						},
						{
							name: "David Geckeler",
							id: 2,
							active: true,
						},
						{
							name: "Iskos-Berlin",
							id: 3,
							active: true,
						},
						{
							name: "Mika Tolvanen",
							id: 4,
							active: true,
						},
						{
							name: "Thomas Bentzen",
							id: 5,
							active: true,
						},
					],
				},
			],
			isMounted: false,
		};
	},
	
};
