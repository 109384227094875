export default {
	name: "form-password",
	data() {
		return {
			passwordHidden: true,
			password: "",
		};
	},
	methods: {
		toggleHidden() {
			this.passwordHidden = !this.passwordHidden;
		},
	},
};
