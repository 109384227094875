import { mapState } from "vuex";
import { rInterval } from "../../basics/js/utils";
import TableComponent from "../table-component/table-component";


export default {
	name: "data-table-scroll",
	data: () => ({
		scrolledToStart: true,
		scrolledToEnd: false,
		scrollIncrement: 200,
		scrollYDelta: 0,
	}),
	components: {
		TableComponent,
	},
	computed: {
		...mapState({
            windowY: state => state.windowY,
			windowH: state => state.windowH,
        }),
	},
	watch: {
		windowY(newVal) {
			let rect = {top: this.$el.scrollTop, bottom: this.$el.clientHeight - this.$el.scrollTop - this.windowH/2};

			if ( newVal > rect.top && newVal < rect.bottom ) {
				this.scrollYDelta = newVal - rect.top;
			}
		}
	},
	methods: {
		handleScroll() {
			this.scrolledToStart = this.$refs.scrollable.scrollLeft < 10;
			this.scrolledToEnd = this.$refs.scrollable.scrollLeft >= this.$refs.scrollable.scrollWidth - this.$refs.scrollable.clientWidth - 10;

			this.scrollIncrement = (this.$refs.scrollable.scrollWidth - this.$refs.scrollable.clientWidth)/4;
			if ( this.scrollIncrement < 200 ) this.scrollIncrement = 200;
		},
		scrollLeft() {
			this.$refs.scrollable.scrollLeft -= this.scrollIncrement;
		},
		scrollRight() {
			this.$refs.scrollable.scrollLeft += this.scrollIncrement;
		}
	},
	mounted() {
		if ( this.$refs.scrollable ) rInterval(this.handleScroll, 100);
	}
};
