var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "presentation-tile",
      class: {
        "is-double": _vm.isDouble,
        "is-packshot": _vm.isPackshot,
        "is-cylindo": _vm.isCylindo,
        "is-content": _vm.isContent,
        "is-disabled": !_vm.isInteractive,
      },
      attrs: { id: _vm.anchor },
    },
    [
      _vm.isCylindo
        ? _c(
            "div",
            {
              staticClass: "presentation-tile__inner",
              style: _vm.getSlideStyle,
            },
            [
              _c("cylindo-viewer", {
                attrs: {
                  "cylindo-sku": _vm.cylindoSku,
                  "cylindo-features": _vm.cylindoFeatures,
                  texts: _vm.cylindoTexts,
                  "use-simple-guide": true,
                  "no-message": true,
                  "no-zoom": true,
                  "start-frame": _vm.startFrame,
                  "has-a-r-callback": _vm.hasARCallback,
                },
              }),
            ],
            1
          )
        : !_vm.isContent
        ? _c(
            "button",
            {
              staticClass: "presentation-tile__inner",
              style: _vm.getSlideStyle,
              on: { click: _vm.imageClickHandler },
            },
            [
              _vm.media && _vm.media.type === "image" && _vm.media.src
                ? _c(
                    "figure",
                    {
                      staticClass: "presentation-tile__media",
                      class: {
                        "is-dynamic": _vm.media.srcLrg,
                        "has-error":
                          _vm.imageLoadError || _vm.imageLrgLoadError,
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imageLoadSuccess && !_vm.imageLoadError,
                            expression: "imageLoadSuccess && !imageLoadError",
                          },
                        ],
                        attrs: { src: _vm.media.src },
                        on: {
                          load: function () {
                            return _vm.imageLoadHandler()
                          },
                          error: function () {
                            return _vm.imageErrorHandler()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.media.srcLrg
                        ? _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.imageLrgLoadSuccess &&
                                  !_vm.imageLrgLoadError,
                                expression:
                                  "imageLrgLoadSuccess && !imageLrgLoadError",
                              },
                            ],
                            attrs: { src: _vm.media.srcLrg },
                            on: {
                              load: function () {
                                return _vm.imageLoadHandler(true)
                              },
                              error: function () {
                                return _vm.imageErrorHandler(true)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.imageLoadError || _vm.imageLrgLoadError
                        ? _c("p", [_vm._v("⚠")])
                        : _vm._e(),
                    ]
                  )
                : _vm.media && _vm.media.type === "video" && _vm.media.src
                ? _c("figure", { staticClass: "presentation-tile__media" }, [
                    _c("video", {
                      attrs: {
                        src: _vm.media.src,
                        muted: "",
                        autoplay: "",
                        loop: "",
                        playsinline: "",
                      },
                      domProps: { muted: true },
                    }),
                  ])
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            { staticClass: "presentation-tile__inner" },
            [
              _c("usp-spot", {
                attrs: {
                  name: _vm.name,
                  description: _vm.description,
                  media: _vm.media,
                  "text-color": _vm.textColor,
                  "background-color": _vm.backgroundColor,
                },
                inlineTemplate: {
                  render: function () {
                    var _vm = this
                    var _h = _vm.$createElement
                    var _c = _vm._self._c || _h
                    return _c(
                      "div",
                      { staticClass: "usp-spot usp-spot--image-icon" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "usp-spot__inner",
                            style: _vm.getColorStyle,
                          },
                          [
                            _vm.media
                              ? _c(
                                  "figure",
                                  { staticClass: "usp-spot__media" },
                                  [
                                    _vm.media.type === "image"
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.media.src,
                                            alt: _vm.media.alt,
                                            srcset: _vm.media.srcset,
                                            sizes: _vm.media.sizes,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.media.type === "video"
                                      ? _c("video", {
                                          attrs: {
                                            src: _vm.media.src,
                                            muted: "",
                                            autoplay: "",
                                            loop: "",
                                            playsinline: "",
                                          },
                                          domProps: { muted: true },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("h3", { staticClass: "usp-spot__name" }, [
                              _vm._v(_vm._s(_vm.name)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "usp-spot__description" }, [
                              _vm._v(_vm._s(_vm.description)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  },
                  staticRenderFns: [],
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }