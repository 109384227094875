import mockData from "./image-products-data";
import { mapActions } from "vuex";

import { inView } from '../../basics/js/methods/inView/inView';

export default {
    name: "image-products-button",
    props: {
        buttonText: {
            type: String,
            default: "Shop this image",
        },
        skuList: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: "#FFFFFF"
        },
        imageProductsOverlayData: {
            type: Object,
            default() {
                return mockData.imageProductsOverlayData;
            }
        }
    },
    data() {
        return {
            isInview: false,
            isAnimated: false,
        };
    },
    methods: {
        ...mapActions([
            'setImageProductsOverlayData',
            'openImageProductsOverlay',
            'showOverlayBackdrop'
        ]),
        clickHandler() {
            this.setImageProductsOverlayData(this.imageProductsOverlayData);
            this.showOverlayBackdrop();
            this.openImageProductsOverlay(this.skuList);
        },
        animateButton() {
            if (this.isAnimated) return;
            this.isInview = true;
            setTimeout(() => {
                this.isAnimated = true;
            }, 2000);
        }
    },
    mounted() {
        inView({
            el: this.$el,
            once: true,
            threshold: 0.2,
            callback: this.animateButton.bind(this)
        });
    },
}