var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cylindo-viewer",
      class: {
        "is-zooming": _vm.cylindoZoomMode,
        "is-panning": _vm.cylindoPanMode,
        "is-focused": _vm.isMobile && _vm.isFocused,
        "is-loading": _vm.cylindoIsLoading,
      },
    },
    [
      _c("div", {
        staticClass: "cylindo-viewer__container",
        attrs: { id: _vm.containerID },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "cylindo-viewer__zoom",
        attrs: { id: _vm.containerID + "-zoom" },
      }),
      _vm._v(" "),
      _vm.useFocusToggle && _vm.isBlurred
        ? _c(
            "button",
            {
              ref: "toggler",
              staticClass:
                "cylindo-viewer__toggler cylindo-viewer__toggler--focus",
              on: { click: _vm.focusViewer },
            },
            [
              _c(
                "svg",
                { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
                [
                  _c("use", {
                    attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-plus" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.useFocusToggle && _vm.isFocused
        ? _c(
            "button",
            {
              staticClass:
                "cylindo-viewer__toggler cylindo-viewer__toggler--blur",
              on: { click: _vm.blurViewer },
            },
            [
              _c(
                "svg",
                { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
                [
                  _c("use", {
                    attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-close" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.guideVisible
        ? _c("span", [
            _vm.useSimpleGuide
              ? _c(
                  "p",
                  {
                    staticClass: "cylindo-viewer__guide",
                    class: { "is-active": _vm.cylindoGuideActive },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.texts.dragToRotate))])]
                )
              : _c(
                  "div",
                  {
                    staticClass: "cylindo-viewer__guide",
                    class: { "is-active": _vm.cylindoGuideActive },
                  },
                  [
                    !_vm.isMobile
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.texts.dragToRotate) +
                              " / " +
                              _vm._s(_vm.texts.clickToZoom)
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMobile && !_vm.isFocused
                      ? _c("span", [_vm._v(_vm._s(_vm.texts.tapToInteract))])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMobile && _vm.isFocused
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.texts.dragToRotate) +
                              " / " +
                              _vm._s(_vm.texts.doubleTapToZoom)
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.noMessage
        ? _c(
            "div",
            {
              staticClass: "configurator-message",
              class: { "is-active": _vm.messageShown },
            },
            [
              !_vm.messageShown
                ? _c(
                    "button",
                    {
                      staticClass: "configurator-message__icon",
                      on: { click: _vm.toggleMessage },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.texts.messageLabel))]),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewbox: "0 0 17.97 17.97",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M9,.8A8.19,8.19,0,1,1,.8,9,8.2,8.2,0,0,1,9,.8M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M8.09,11.8a.9.9,0,0,1,.37-.73h1a.93.93,0,0,1,.38.73.9.9,0,0,1-1.79,0Zm.32-3.58V5.28H9.56V8.22l-.22,2h-.7Z",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.messageShown
                ? _c(
                    "div",
                    {
                      staticClass: "configurator-message__inner",
                      on: { click: _vm.closeMessage },
                    },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.texts.messageBody))]),
                      _vm._v(" "),
                      _c("button", [
                        _c("span", [_vm._v(_vm._s(_vm.texts.messageButton))]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.cylindoIsLoading
        ? _c("div", { staticClass: "cylindo-viewer__loader" }, [
            _c("div", { staticClass: "spinner spinner--large" }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }