import { TweenLite, Power4 } from 'gsap';
import { mapActions, mapState } from 'vuex';
import { uid } from '../../basics/js/utils';

const AccountSubmenu = {
	name: 'account-submenu',
	props: {
		initialOpenState: {
			type: Boolean,
			default: false
		},
		activeMenuId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			isOpen: this.initialOpenState,
			uid: ''
		}
	},

	watch: {
		activeMenuId() {
			this.isOpen = this.activeMenuId === this.uid;
		},
		isOpen(val) {
			if(val) {
				this.open();
				return;
			}
			this.close();
		}
	},

	mounted() {
		this.menu = this.$refs.menu;
		this.uid = uid();
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen;
			this.$emit('togglemenu', !this.isOpen ? '' : this.uid);
		},
		open(){
			TweenLite.set(this.menu, { height: 'auto' });
			TweenLite.from(this.menu, 0.25, { height: 0 });
			this.$store.commit('openAccountMenu');
		},
		close() {
			TweenLite.to(this.menu, .5, { height: 0, ease: Power4.easeInOut});
		}
	}
}





export default {
	name: 'account-menu',
	components: {
		AccountSubmenu
	},

	mounted() {

	},

	data() {
		return {
			// isOpen: false,
			activeMenuId: ''
		};
	},
	watch: {
		mainMenuOpen(val) {
			if(val) this.activeMenuId = '';
		},
		accountMenuOpen(state) { // accountMenuOpen: true
			if(!state) {
				this.activeMenuId = '';
				return;
			}
			this.showOverlayBackdrop('behindHeader');
		}
	},
	computed: {
		...mapState([
			'mainMenuOpen',
			'accountMenuOpen'
		])
	},
	methods: {
		...mapActions([
			'closeAccountMenu',
			'showOverlayBackdrop',
			'hideOverlayBackdrop',
		]),
		toggleMenu(id) {
			if(id == ''){
				this.hideOverlayBackdrop();
			}
			this.activeMenuId = id;
		}
	},



};
