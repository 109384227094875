import { mapActions, mapState} from 'vuex';
import ProductTileProd from '../product-tile/product-tile';

export default {
    name: "image-products-overlay",
	components: {
		ProductTileProd,
	},
    computed: {
		...mapState({
			productsData: state => state.imageProductsOverlay.productsData,
			contentData: state => state.imageProductsOverlay.contentData,
			overlayOpen: state => state.imageProductsOverlay.overlayOpen,
		}),
		colorStyle() {
            if ( this.contentData && this.contentData.backgroundColor ) {
				return `background-color: ${this.contentData.backgroundColor}; --image-products-overlay-bg-color: ${this.contentData.backgroundColor};`;
			}
			return '';
		},
	},
	props: {
		titleText: {
			type: String,
			default: "Shop products"
		},
		countText: {
			type: String,
			default: "items"
		}
	},
    methods: {
		...mapActions([
			'closeImageProductsOverlay',
			'hideOverlayBackdrop',	
		]),
		close(){
            this.closeImageProductsOverlay();
			this.hideOverlayBackdrop();
		}
	}

}