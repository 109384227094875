import Vue from "vue/dist/vue.esm.js";
import Vuex from "vuex";

import actions from "./actions";
import mutations from "./mutations";
import * as types from "./static";

// Vuex Modules
import pdp from "./modules/pdp";
import configurator from "./modules/configurator-module";
import cart from "./modules/cart";
import search from "./modules/search";
import plp from "./modules/plp-module";
import storeLocator from "./modules/store-locator";
import recommendation from "./modules/recommendation-module";
import globalVideo from "./modules/global-video";
import imageProductsOverlay from "./modules/image-products-overlay";

import reconfigurator from "./modules/reconfigurator";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		env: {
			dev: window.location.host === "localhost:3000" || window.location.host === "muuto.sorthvid.design",
			basePath: "",
			mockData: "https://sorthvid-json-server.herokuapp.com",
		},
		appLoaded: false,

		isMobile: false,
		isIOS: false,

		windowH: 0,
		windowW: 0,
		windowY: 0,

		country: "dk",
		language: "da",

		showModal: false,
		sniffedScrollPos: 0, // COMMENT: Used in mutations.js when applying "is-fixed"
		isFixed: false,
		cylindoAccountId: 4928,

		roomExplorerSingleProduct: null,
		roomExplorerOverlayProducts: null,

		pdpAddonsProducts: null,
		relevantAddonsProducts: null,

		notificationPanels: [],
		shownAtcFeedbackProductName: "",
		shownAtcFeedbackTypeTitle: "",

		bodyFixed: false,

		dashboardOpen: false,
		searchOverlayOpen: false,
		addonsOverlayOpen: false,
		countrySelectorOpen: false,
		mosaicSliderOverlayOpen: false,
		mosaicSliderOverlayActiveIndex: -1,

		mainMenuOpen: false,
		mainServiceNavOpen: false,
		accountMenuOpen: false,
		overlayBackdropActive: false,
		overlayBackdropContext: "", // if context is behindHeader z-index should be lower! ARGHH"#!€!

		miniCartActive: false,
		cartSubTotal: 0, // COMMENT: Subtotal price shown on cart page, before fee's and delivery cost
		cartItems: [],
		cartItemsCount: 0,
		cartLoaded: false,
		cartShareCartIsActive: false,

		materialsData: null,
		activeMaterialIndex: -1,
		materialOverlayOpen: false,

		triggerExperienceResize: false,

		campaignCodePending: false,
		campaignCodeData: {
			code: "",
			discountAmount: 0,
		},
		modalStatus: {
			status: "closed",
			name: null,
		},

		guidedTourStatus: "waiting", // invitation | accepted |

		shortcutsData: null,
		shortcutsOverlayOpen: false,

		explanationData: null,
		explanationOverlayOpen: false,

		productFiltersOpen: false,

		tooltipText: null,
		tooltipPosX: -1,
		tooltipPosY: -1,

		[types.SET_FILTER_COLOR.stateKey]: "",
	},
	mutations,
	actions,
	modules: {
		pdp,
		configurator,
		cart,
		plp,
		recommendation,
		search,
		storeLocator,
		globalVideo,
		imageProductsOverlay,
		reconfigurator,
	},
});
