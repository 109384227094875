import BuySection from '../../components/buy-section/buy-section';
import { mapState, mapActions } from 'vuex';

export default {
	name: 'product-tile-prod',
	components: {
		BuySection,
	},
	data() {
		return {
			isLoaded: false,
		};
	},
	props: {
		data: {
			type: Object,
			required: true
		},
		sku: {
			type: String,
			required: true
		},
		noBuySection: {
			type: Boolean,
			default: false
		}
	},
	mounted(){
		this.isLoaded = true;
	},
	computed: {
		...mapState(['isMobile']),
		type(){
			return this.data ? this.data.type.toLowerCase() : '';
		},
		tiletypeClass(){
			const typeAndVariant = this.data && this.data.hoverImageUrl && this.data.type.toLowerCase() === 'product'
									? 'product-tile--master-product muu-product'
									: this.data.type.toLowerCase() === 'variant'
										? 'muu-variant'
										: 'product-tile--master-noimage muu-simple'

			const ready = this.isLoaded ? 'is-ready' : ''
			return `${typeAndVariant} ${ready} ${this.noBuySection ? 'no-buy-section' : ''}`
		},
		srcSetString(){
			return `${this.data.imageUrl} 230w, ${this.data.imageUrl} 350w`
		},
		productUrl() {
			return this.data.url;
		},
		productImageAltText(){
			return `${this.data.productPrimaryName}, ${this.data.productSecondaryName}`
		},
		useHoverImage(){
			return this.data && this.data.hoverImageUrl && this.data.type.toLowerCase() === 'product' ? true : false
		}
	},
	methods: {
		...mapActions([
			'roomExplorerProductChosen'
		]),
		updateReceiverMethodProductTile(variationItem){
			console.log('updating product tile with: ', variationItem);
		},
		setMasterHoverImage(){
			// COMMENT: Deferred load of the master image used in hover

			if(!this.isMobile && this.type === 'product' && this.$refs.masterImageHover){
					const ref = Array.isArray(this.$refs.masterImageHover)
						? this.$refs.masterImageHover[0]
						: this.$refs.masterImageHover

					ref.setAttribute('src', ref.getAttribute('data-src'));
			}
		},
		closeHandler() {
			this.roomExplorerProductChosen(null);
		}
	},
};
