import { mapActions } from "vuex";

export default {
	name: "room",
	props: {
		allProductsJSON: {
			type: Object,
		},
		markers: {
			type: Array,
			default: null,
		},
	},
	methods: {
		...mapActions([
			"roomExplorerSingleProductChosen",
			"roomExplorerOverlayProductsChosen",
		]),
		productMarkerHandler(productJSON) {
			this.roomExplorerSingleProductChosen(productJSON);
		},
		allProductsHandler() {
			this.roomExplorerOverlayProductsChosen(this.allProductsJSON);
		},
	},
};
