/* eslint-disable no-undef */
import { mapState, mapActions } from "vuex";

import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
	name: "video-player-global",
	components: { vueVimeoPlayer },
	computed: {
		...mapState({
			activeVideoVimeoId: (state) => state.globalVideo.activeVimeoVideoId,
			activeVideoSrc: (state) => state.globalVideo.activeVideoSrc,
		}),
		showHTMLPlayer: function () {
			return typeof this.activeVideoSrc == "string" && this.activeVideoSrc.length > 0;
		},
		showVimeoPlayer: function () {
			return typeof this.activeVideoVimeoId == "string" && this.activeVideoVimeoId.length > 0;
		},
		vimeoId: function () {
			return this.showVimeoPlayer ? this.activeVideoVimeoId : "";
		},
		videoSrc: function () {
			return this.showHTMLPlayer ? this.activeVideoSrc : "";
		},
	},
	methods: {
		...mapActions(["setActiveVideo"]),
		closeHandler: function () {
			this.setActiveVideo({ type: null, value: null });
		},
	},
	mounted() {
		// console.log("MOUNTED", this.vimeoId, this.type);
	},
};
