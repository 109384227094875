import { render, staticRenderFns } from "./swatch-selector.vue?vue&type=template&id=736de90a&"
import script from "./swatch-selector.vue?vue&type=script&lang=js&"
export * from "./swatch-selector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Code\\dept_muuto_forntend\\master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('736de90a')) {
      api.createRecord('736de90a', component.options)
    } else {
      api.reload('736de90a', component.options)
    }
    module.hot.accept("./swatch-selector.vue?vue&type=template&id=736de90a&", function () {
      api.rerender('736de90a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reconfigurator/partials/swatch-selector/swatch-selector.vue"
export default component.exports