const loadingDelay = (ms) => (x) => new Promise(resolve => setTimeout(() => resolve(x), ms));

/**
 *
 * @param { VUEXStore*} store
 * @param {Object} option.url - Required
 * @param {Object} option.mutationTypes - Required
 * @param {Number} option.delay - loading delay default:0
 *
 */

export const getRequest = (store, { url, mutationTypes, delay = 0 }) => {
    store.commit(mutationTypes.PENDING, true);
    return fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(loadingDelay(delay))
        .then(response => {
            // 404 doesn't resolve info a rejection
            if (response.ok) {
                store.commit(mutationTypes.PENDING, false);
                return response;
            }
            store.dispatch('handleError', 'Network error: ' + response.status);
            throw new Error('Network error: ' + response.status);
        })
        .then(res => res.json())
        .catch(err => {
            store.dispatch('handleError', err)
            store.commit(mutationTypes.PENDING, false);
            return {error: err};
        })
}

/**
 *
 * @param { VUEXStore*} store
 * @param {Object} option.url - Required
 * @param {Object} option.method - PUT, PATCH, DELETE or POST default: POST
 * @param {Object} option.mutationTypes - Required
 * @param {Object} option.body - body to be stringifyed and dispatched to the server
 * @param {Number} option.delay - loading delay default:0
 *
 */

export const postRequest = (store, { url, mutationTypes, body, callback, delay = 0, method = 'POST'  }) => {
    console.log("POST",url,body);

    store.commit(mutationTypes.PENDING, true);
    const postObj = {
        method,
        body: JSON.stringify(body),
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }        
    };

    console.log(postObj)

    return fetch(url, postObj)
        .then(loadingDelay(delay))
        .then(response => {
            // 4** doesn't resolve info a rejection
            console.log("response",response)
            
            if (response.ok) {
                store.commit(mutationTypes.PENDING, false);
                return response;
            }
            store.dispatch('handleError', 'Network error: ' + response.status)
            throw new Error('Network error: ' + response.status);
        })
        .then(res => {            
            if ( res.status === 204 ) {
                return; //no content
            }
            else if ( typeof callback === 'function' ) {
                callback();
            } 
            else return res.json();
        })
        .catch(err => {
            // 5** catched
            store.dispatch('generalError', err);
            store.commit(mutationTypes.PENDING, false);
            return {error: err};
        })
}

/**
 *
 * @param { VUEXStore*} store
 * @param {Object} option.url - Required
 * @param {Object} option.method - PUT, PATCH, DELETE or POST default: POST
 * @param {Object} option.mutationTypes - Required
 * @param {Object} option.body - body to be stringifyed and dispatched to the server
 * @param {Number} option.delay - loading delay default:0
 *
 */

export const postRequestWithFileUpload = (store, { url, mutationTypes, body, delay = 0, method = 'POST' }) => {
    store.commit(mutationTypes.PENDING, true);
    let formData = new FormData();
    formData.append('file', body);
    const options = {
        method: method,
        body: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        credentials: 'include',
        mode: 'cors'
    }
    delete options.headers["Content-Type"];
    return fetch(url, options)
        .then(loadingDelay(delay))
        .then(response => {
            // 4** doesn't resolve info a rejection
            if (response.ok) {
                store.commit(mutationTypes.PENDING, false);
                return response;
            }
            store.dispatch('handleError', 'Network error: ' + response.status);
            throw new Error('Network error: ' + response.status);
        })
        .then(res => res.json())
        .catch(err => {
            // 5** catched
            store.dispatch('generalError', err)
        })
}

