<script>
import mockData from "./image-products-data";
import { mapActions } from "vuex";

import { inView } from '../../basics/js/methods/inView/inView';

export default {
	name: "image-products-button",
	props: {
        buttonText: {
            type: String,
            default: "Shop this image",
        },
        skuList: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: "#FFFFFF"
        },
        imageProductsOverlayData: {
            type: Object,
            default() {
                return mockData.imageProductsOverlayData;
            }
        }
    },
    data() {
        return {
            isInview: false,
            isAnimated: false,
        };
    },
    methods: {
		...mapActions([
			'setImageProductsOverlayData',
			'openImageProductsOverlay',
			'showOverlayBackdrop'
		]),
        clickHandler(event) {
			event.stopPropagation();
			event.preventDefault();

            this.showOverlayBackdrop();
            this.setImageProductsOverlayData(this.imageProductsOverlayData);
            this.openImageProductsOverlay(this.skuList);
        },
        animateButton() {
            if ( this.isAnimated ) return;
            this.isInview = true;
            setTimeout(() => {
                this.isAnimated = true;
            }, 2000);
        }
    },
	mounted() {
        inView({
			el: this.$el,
			once: true,
			threshold: 0.2,
            callback: this.animateButton.bind(this)
		});
	},
}
</script>
<template>
    <button class="image-products__button" :class="{'is-animated': isAnimated, 'in-view': isInview}" v-on:click="clickHandler" :style="{'--image-products-button-bg-color': backgroundColor}">
        <svg viewBox="0 0 100 100" class="svg-icon "><use xlink:href="/dist/svg/symbols.svg#svg-plus-small"></use></svg>
        <span>{{buttonText}}</span>
    </button>
</template>
