export default {
	name: 'receipt',
	data() {
		return {
			isProductsExpanded: false,
			isSummaryExpanded: false
		}
	},
	methods: {
		toggleSummary() {
			this.isSummaryExpanded = !this.isSummaryExpanded;
		},
		toggleProducts() {
			this.isProductsExpanded = !this.isProductsExpanded;
		}
	},
};
