var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "story-slide-products", style: _vm.slideStyle },
    [
      _c("div", { staticClass: "story-slide-products__content" }, [
        _c("h2", { staticClass: "story-slide-products__title" }, [
          _c("span", [_vm._v(_vm._s(_vm.titlePart1))]),
          _vm._v(" "),
          _vm.titlePart2
            ? _c("span", [_vm._v(_vm._s(_vm.titlePart2))])
            : _vm._e(),
          _vm._v(" "),
          _vm.buttonHref && _vm.buttonText
            ? _c(
                "a",
                {
                  staticClass: "button button--secondary button--custom-colors",
                  style: _vm.buttonStyle,
                  attrs: { href: _vm.buttonHref },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.buttonText)),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "nav",
          {
            staticClass: "story-slide-products__list",
            class:
              "story-slide-products__list--items-" +
              (_vm.productList.length <= 12 ? _vm.productList.length : "X"),
          },
          _vm._l(_vm.productList, function (product) {
            return _c(
              "a",
              { key: product.productId, attrs: { href: product.productHref } },
              [
                _c("figure", [_c("img", { attrs: { src: product.imageSrc } })]),
                _vm._v(" "),
                _c("div", [
                  _c("h3", [_vm._v(_vm._s(product.productName))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(product.productVariant)),
                    _vm.productsHaveVariants
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }