
import { mapActions } from 'vuex';

export default {
	name: 'notification-panel-examples-wrapper',
	methods: {
		...mapActions([
			'showNotificationPanel',
		]),
		showAtc(){
			this.showNotificationPanel({ type: 'atc', title: 'Added to cart', message: '– Fiber armchair', showClose: false, showLink:true });
		},
		showAtcWithButton(){
			this.showNotificationPanel({ type: 'atc', title: 'Fiber armchair', message: '– Swivel base', showClose: true });
		},
		showErrorPanel(){
			this.showNotificationPanel({ type: 'error', message: 'Error message' });
		},
		showErrorTitlePanel(){
			this.showNotificationPanel({ type: 'error', title: 'Error title', message: 'Error message' });
		},
		showInfoPanel(){
			this.showNotificationPanel({ type: 'info', title: 'Info title', message: 'Info message' });
		},
		showCustomColorInfoPanel(){
			this.showNotificationPanel({ type: 'info', message: 'Info message', bgColor: 'lime' });
		},
		showWarningPanelMessage(){
			this.showNotificationPanel({ type: 'warning', message: 'Message' });
		},
		showWarningPanelTitle(){
			this.showNotificationPanel({ type: 'warning', title: 'Title' });
		},
		showWarningPanelTitleMessage(){
			this.showNotificationPanel({ type: 'warning', title: 'Title', message: 'Message' });
		},
	},
}
