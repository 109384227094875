var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "story-slide-cover",
      class: { "story-slide-cover--assembled": _vm.isAssembled },
      style: _vm.slideStyle,
    },
    [
      _vm.imageSrc
        ? _c("img", {
            staticClass: "story-slide-cover__media",
            attrs: { src: _vm.imageSrc },
          })
        : _vm.videoSrc
        ? _c("video", {
            staticClass: "story-slide-cover__media",
            attrs: {
              autoplay: "",
              playsinline: "",
              muted: "",
              loop: "",
              preload: "auto",
              src: _vm.videoSrc,
            },
            domProps: { muted: true },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "story-slide-cover__content" }, [
        _c("h2", { staticClass: "story-slide-cover__title" }, [
          _c("span", [_vm._v(_vm._s(_vm.titlePart1))]),
          _vm._v(" "),
          _vm.titlePart2
            ? _c("span", [_vm._v(_vm._s(_vm.titlePart2))])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAssembled && _vm.buttonHref && _vm.buttonText
            ? _c(
                "a",
                {
                  staticClass: "button button--secondary button--custom-colors",
                  style: _vm.buttonStyle,
                  attrs: { href: _vm.buttonHref },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.buttonText)),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.description
          ? _c("div", { staticClass: "story-slide-cover__description" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } }),
              _vm._v(" "),
              !_vm.isAssembled && _vm.buttonHref && _vm.buttonText
                ? _c(
                    "a",
                    {
                      staticClass:
                        "button button--secondary button--custom-colors",
                      style: _vm.buttonStyle,
                      attrs: { href: _vm.buttonHref },
                    },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.buttonText)),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }