<script>
    import { mapState, mapActions } from "vuex";

    export default {
        name: "attribute-options",
        props: {
            attributeList: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapState({
                isMobile: state => state.isMobile,
            }),
            selectedAttributeName() {            
                let foundAttribute = this.attributeList.items.find(attribute => attribute.isActive);
                return foundAttribute ? foundAttribute.name : '';
            }
        },
        methods: {   
            ...mapActions([
                "openOptionsOverlay",
                "showOverlayBackdrop",
            ]),
            showAllAttributes: function () {
                
                window.scrollTo({ top: 0 }); //to ensure no overflow hidden on body visual glitches
                this.openOptionsOverlay({items:this.attributeList});
                this.showOverlayBackdrop();
            }
        }
    };
</script>

<template>     
    <div class="attribute-options">
        <button class="attribute-options__button" v-on:click="showAllAttributes">
            <p><strong>{{isMobile ? attributeList.nameShort : attributeList.name + ':'}}</strong> <em>{{selectedAttributeName}}</em></p>
            
            <svg viewbox="0 0 100 100" class="attribute-options__icon svg-icon" v-if="!isMobile">
                <use xlink:href="/dist/svg/symbols.svg#svg-select-chevron"></use>
            </svg>            
            <svg viewbox="0 0 100 100" class="attribute-options__icon svg-icon" v-else>
                <use xlink:href="/dist/svg/symbols.svg#svg-plus-mini"></use>
            </svg>
        </button>
    </div>
</template>