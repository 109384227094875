var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "material-group-info-overlay",
      class: { "is-active": _vm.overlayData && _vm.isOpen },
      attrs: { tabindex: "0" },
    },
    [
      _c(
        "button",
        {
          staticClass: "material-group-info-overlay__close",
          on: { click: _vm.close },
        },
        [
          _vm.isMobile
            ? _c(
                "svg",
                { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
                [
                  _c("use", {
                    attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-close" },
                  }),
                ]
              )
            : _c(
                "svg",
                { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
                [
                  _c("use", {
                    attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-left" },
                  }),
                ]
              ),
        ]
      ),
      _vm._v(" "),
      _vm.overlayData
        ? _c(
            "div",
            { ref: "inner", staticClass: "material-group-info-overlay__inner" },
            [
              _c("h3", [_vm._v(_vm._s(_vm.overlayData.headline))]),
              _vm._v(" "),
              _vm.overlayData.bodyText
                ? _c("p", [_vm._v(_vm._s(_vm.overlayData.bodyText))])
                : _vm._e(),
              _vm._v(" "),
              _vm.overlayData.properties &&
              _vm.overlayData.properties.length > 0
                ? _c(
                    "dl",
                    _vm._l(_vm.overlayData.properties, function (item, index) {
                      return _c("span", { key: index + "_" + item.name }, [
                        _c("dt", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.value))]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }