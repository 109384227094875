import BuySectionProductLine from '../../components/buy-section/buy-section-product-line';
import copyTextMixin from '../../components/vue-mixins/copyText';
import TextToInput from '../../components/text-to-input/text-to-input';

export default {
	components: {
		BuySectionProductLine,
		TextToInput,
	},
	mixins: [
		copyTextMixin,
	],
	props: {
		product: {
			type: Object,
			default: null,
			required: true,
		},
		context: {
			type: String,
			default: '',
			required: false,
		},
	},
	data() {
		return {
			isLoaded: false,
			quantity: 1, // COMMENT: Gets populated from prop "product"
			sku: null, // COMMENT: Gets populated from prop "product"
			price: 0, // COMMENT: Gets populated from prop "product"
		};
	},
	mounted(){
		this.sku = this.$props.product.sku;
		this.name = this.$props.product.name;
		this.quantity = this.$props.product.quantity;
		
		this.isLoaded = true;
		
	}
};