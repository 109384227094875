// unique id
export const uid = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

// throttled rAF
export const rInterval = (callback, delay) => {
	let dateNow = Date.now,
		requestAnimation = window.requestAnimationFrame,
		start = dateNow(),
		stop,
		intervalFunc = function () {
			dateNow() - start < delay || ((start += delay), callback());
			stop || requestAnimation(intervalFunc);
		};
	requestAnimation(intervalFunc);
	return {
		clear: function () {
			stop = 1;
		},
	};
};


export const minmax = (val, span, lo, hi) => {
	if ( val - span < lo + span  ) return lo + span;
	else if ( val + span > hi - span  ) return hi - span;
	else return val;
};


export const swipeDetect = (el, callback) => {
	if (!el || !callback) return false;
	let tST, tET, cX, cY;

	const tS = (e)=>{
		// e.preventDefault();
		tST = Date.now();
		cY = e.touches[0].clientY;
		cX = e.touches[0].clientX;		
		el.addEventListener("touchend", tE);	
	}
	const tE = (e) => {
		tET = Date.now();
		let eCX = e.changedTouches[0].clientX;
		let eCY = e.changedTouches[0].clientY;

		if (tET - tST <= 500) {
			// The distance the finger moved in 500 ms is 100px
			if (cY - eCY >= 100) {
				callback('up');
			} else if (cY - eCY <= -100) {
				callback('down');
			} else if (cX - eCX <= -100) {
				callback('right');
			} else {				
				callback('left');
			}
		}
		
		el.removeEventListener("touchend", tE);	
	} 

	el.addEventListener("touchstart", tS);
	
	
}