export default {
    name: 'HeroMinimal',
    props: {
		media: {
			type: Object,
			required: true,
		},
		headline: {
			type: String,
			required: true,
		},
		trumpet: {
			type: String,
		},
		bodyText: {
			type: String,
		},
		backgroundColor: {
			type: String,
		},
		textColor: {
			type: String,
		},
	},
	computed: {
		moduleStyle() {
			return (
				`${this.backgroundColor ? "--hero-minimal-bg-color:" + this.backgroundColor + ";" : ""}` +
				`${this.textColor ? "--hero-minimal-text-color:" + this.textColor + ";" : ""}`
			);
		},
	},
}
