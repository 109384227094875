/**
 * Cart module
 * all cart related actions go here
 */

import Vue from 'vue/dist/vue.esm';
import * as types from '../static';
import { getRequest } from '../integrations';

const updateCartData = function(context,json){
    const dispatchDate = json.dispatchDate
    context.commit('updateCalculatedCart',json)
    context.commit('updateDispatchDate', dispatchDate)
    context.commit('updateCartLineItems',json.lineItems)

    if(json.isSuccess){
        context.commit('clearErrorMessage')
    } else {
        context.commit('updateErrorMessage',json.errorMessage)
        context.dispatch('stickyError',json.errorMessage)
    }

}

const state = {
    [types.GET_CART_QUANTITY.stateKey]: 0,
    couponCode: '',
    calculatedCart: {},
    cartLineItems: [],
    orderDispatchDate: "",
    errorMessage: null,
    showOrderSummarySpinner: false
}

const mutations = {
    updateShowOrderSummarySpinner(state){
        state.showOrderSummarySpinner = !state.showOrderSummarySpinner
    },
    updateCalculatedCart(state,cartModel){
        state.calculatedCart = {
            subTotal: cartModel.subTotal,
            subTotalExDiscount: cartModel.subTotalExDiscount,
            discount: cartModel.discount,
            total: cartModel.total,
            shippingFee: cartModel.shippingFee,
            handlingFee: cartModel.handlingFee,
            minimumOrderFee: cartModel.minimumOrderFee,
            minimumOrderValue: cartModel.minimumOrderValue,
            campaign: cartModel.campaign,
            vat: cartModel.vat,
            isSuccess: cartModel.isSuccess,
        }
    },
    updateCartLineItems(state,items){
        state.cartLineItems = items
    },
    updateDispatchDate(state,date){
        state.orderDispatchDate = date
    },
    updateCouponCode(state,code){
        state.couponCode = code
    },
    updateErrorMessage(state,errorMessage){
        state.errorMessage = errorMessage
    },
    clearErrorMessage(state){
        state.errorMessage = null
    },
    [types.GET_CART_QUANTITY.SUCCESS](state, payload) {
        // Get cart items
        Vue.set(state, types.GET_CART_QUANTITY.stateKey, payload ? payload.quantity : 0);
    },
    [types.GET_CART_QUANTITY.PENDING](state, status){
        Vue.set(state, types.GET_CART_QUANTITY.loadingKey, status);
    }
}

const actions = {
    async [types.GET_CART_QUANTITY.action] (store) {
        // Get cart items
        const result = await getRequest(store, {
            url: process.env.CART_ITEMS_QUANTITY,
            mutationTypes: types.GET_CART_QUANTITY,
        })
        store.commit(types.GET_CART_QUANTITY.SUCCESS, result);
    },

    getCalculatedCart(context){

        context.commit('updateShowOrderSummarySpinner')

        fetch(process.env.CALCULATED_CART_ITEMS_ENDPOINT,{
            method: 'post',
            credentials: 'include'
        })
        .then(res => {
            if(res.ok){
                return res.json()
            } else {
                throw new Error("Failed on endpoint");
            }
        })
        .then(json => {
            updateCartData(context,json)
            context.commit('updateShowOrderSummarySpinner')
        })
        .catch(err => {
            context.dispatch('stickyError',err.toString())
            context.commit('updateShowOrderSummarySpinner')
        });
    },

    submitCouponCode(context,code){

        context.commit('updateShowOrderSummarySpinner')

        fetch(process.env.COUPON_CODE,{
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: `{"couponCode":"${code}"}`
        })
        .then(res => {
            if(res.ok){
                return res.json()
            } else {
                throw new Error("Failed on endpoint");
            }
        })
        .then(json => {
            updateCartData(context,json)
            context.commit('updateShowOrderSummarySpinner')
        })
        .catch(err => {
            console.error("Submitting coupon code failed - ",err);
            context.dispatch('stickyError',err)
            context.commit('updateShowOrderSummarySpinner')
        });
    },

    addReferenceId(context,data){

        const refid = typeof data === 'string' ? data : data.refid

        fetch(process.env.CART_ADD_REFFERENCEID_ENDPOINT,{
            method: 'put',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body : JSON.stringify({'ownReference': refid || null})
        })
        .then(res => {
            if(!res.ok){
                throw new Error("Failed on endpoint");
            }
        })
        .catch(err => {
            console.error("Adding reference id failed - ",err);
            context.dispatch('stickyError',err)
        });
    },

    addItemReferenceId(context,data){

        const url = process.env.CART_ADD_ITEMREFFERENCEID_ENDPOINT.replace('{code}',data.code)

        fetch(url,{
            method: 'put',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body : JSON.stringify({'code': data.code, 'ownReference':data.refid || null})
        })
        .then(res => {
            if(!res.ok){
                throw new Error("Failed on endpoint");
            }
        })
        .catch(err => {
            console.error("Adding reference id failed - ",err);
            context.dispatch('stickyError',err)
        });
    },

    placeOrder(context,stringifiedFormData) {
        fetch(process.env.PLACE_ORDER_ENDPOINT,{
            method: 'post',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body : stringifiedFormData
        })
        .then(res => {
            if(res.ok){
                return res.json()
            } else {
                throw new Error("Failed on endpoint");
            }
        })
        .then(json => {
            if(json.isSuccess && json.redirUrl){
                document.location.href = json.redirUrl
            } else {
                context.commit('updateErrorMessage',json.errorMessage)
                context.dispatch('stickyError',json.errorMessage)
            }
        })
        .catch(err => {
            console.error("Getting cart data failed - ",err);
            context.dispatch('stickyError',err)
        });
    }
}

export default { state, actions, mutations }
