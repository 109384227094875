import { mapState } from 'vuex';
import { uid } from '../../basics/js/utils';

export default {
	name: 'onlineshops',
	components: {
    },
    props: {
		tileDataJson: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			tileDataParsed: [],
			categories: new Set(),
			muutoExperience: false,
			filter: "",
			search: ""
		};
	},    
	computed: {
		...mapState({
			isMobile: state => state.isMobile
		}),
		tileData(){
			return JSON.parse(this.tileDataJson)
		},
		getCategories(){
			var categories = new Set();
			JSON.parse(this.tileDataJson).forEach(element => {
				element.categories.split(", ").forEach(ele => {
					categories.add(ele)
				});
			});
			categories.delete("");
			return categories;
		}
		
		

	},
	methods: {
		uid,
		onLoadMore() {
			if(this.loadMoreUrl) {
				document.location.href = this.loadMoreUrl;
			} else {
				// show the rest of the products
				this.localLimit = this.numberOfProducts;
			}
		},
		changeMuutoExperience(){
			this.muutoExperience = !this.muutoExperience
		},
		setFilterValue(value){
			this.filter = value
		},
		setSearchString(value){
			this.search = value;
		},
		onlineShops(){
			var filtered = this.tileData.filter(
				(shop) => {
				if(this.muutoExperience){
					return shop.muuto_experience == "1";
				} return true;
			})
			filtered = filtered.filter(
				(shop) => {
				return shop.categories.includes(this.filter);
			})
			filtered = filtered.filter(
				(shop) => {
				return (shop.company_name + " " + shop.website_name + " " + shop.country).toLowerCase().includes(this.search.toLowerCase());
			});
			this.tileDataParsed = filtered;
		}


		
	},
	mounted(){
		this.tileDataParsed = this.tileData
		this.categories = this.getCategories
	}
};