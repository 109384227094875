var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swatch-selector", style: "--row-size: " + _vm.rowSize },
    [
      _c("h5", { staticClass: "swatch-selector__headline" }, [
        _c("span", [_vm._v(_vm._s(_vm.headline))]),
        _vm._v(" "),
        _vm.hasInfo
          ? _c(
              "button",
              {
                staticClass: "tooltip-button",
                on: { click: _vm.infoClickHandler },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { viewBox: "0 0 100 100" },
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href": "/dist/svg/symbols.svg#svg-question",
                      },
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { key: _vm.updateKey, staticClass: "swatch-selector__list" },
        [
          _vm._l(_vm.loopableItems, function (item) {
            return _c(
              "li",
              {
                key: item.value + item.index,
                staticClass: "swatch-selector__list-item",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "swatch-selector__button",
                    class: { "is-active": _vm.isItemActive(item.id) },
                    on: {
                      click: function ($event) {
                        return _vm.selectSwatch(item)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.lazyLoading
                        ? _c("img", {
                            attrs: { "data-src": item.image, alt: item.value },
                          })
                        : _c("img", {
                            attrs: { src: item.image, alt: item.value },
                          }),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "li",
            { ref: "expandItem", staticClass: "swatch-selector__expand-item" },
            [
              _c(
                "button",
                {
                  ref: "expandButton",
                  staticClass: "swatch-selector__button",
                  on: { click: _vm.expandClickHandler },
                },
                [_vm._v("+ " + _vm._s(_vm.remainingItemsCount))]
              ),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }