import { mapActions } from 'vuex';

import NewsLetter from '../../components/news-letter/news-letter';

export default {
	name: 'main-footer',
	components: {
		NewsLetter
	},
	methods: {
		...mapActions([
			'openCountrySelector',
			'openMainSearch'
		]),
		handleCountrySelectorClick() {
			this.openCountrySelector();
		},
		handleSearchClick(){
			this.openMainSearch();
		},
	}
};
