import VariationDropdown from "@components/variation-dropdown/variation-dropdown";
import CountrySelectorInput from "@components/country-selector/country-selector-input";
import { mapActions, mapState } from "vuex";
import { SELECT_COUNTRY } from "../../basics/js/store/static";

export default {
	name: "country-selector",
	components: {
		VariationDropdown,
		CountrySelectorInput,
	},
	props: {
		initialIsShown: {
			type: Boolean,
			required: false,
		},

	},
	data() {
		return {
			isHidden: true,
			isAnimated: null,

			// chosenCountry: null,
			// chosenCountryNative: null,
		};
	},
	watch: {
		isShown(newVal) {
			this.isHidden = !newVal;
			if (!this.isHidden) {
				setTimeout(() => {
					this.isAnimated = true;
				}, 500);
			} else {
				this.isAnimated = false;
			}
		},
	},
	computed: {
		...mapState({
			isShown: (state) => state.countrySelectorOpen,
		}),
	},
	methods: {
		...mapActions([
			"openCountrySelector",
			"closeCountrySelector",
			SELECT_COUNTRY.action,
		]),
		close() {
			this.closeCountrySelector();
		},
		updateReceiverMethodProductTile() {
			//placeholder
		},


		// filter(option, label, search) {
		// 	return (label || "").toLowerCase().startsWith(search.toLowerCase());
		// },
	},
	mounted() {
		this.$on("chosen", (value) => {
			this.chosenCountryCode = value;
			// this.changeCountry(value);
			this[SELECT_COUNTRY.action](value);
			// console.log(value);
		});

		if (this.initialIsShown) {
			this.openCountrySelector();
		}
	},
};
