const mapProducts = function(products){

    return products.map(product => {
        return {
			"link": product.url,
			"name": product.primaryName,
			"typeTitle": product.secondaryName,
            "sku": product.buySection.sku,
			"price": product.formattedPrice,
            "imageUrl": product.image ? product.image.url : null,

            altText: product.image ? product.image.alt : null,
            variantName: product.buySection.variantName,
            dispatchInfo: product.buySection.dispatchInfo,

			"quantity": product.buySection.quantity ? product.buySection.quantity : 1,
			"colliProduct": product.buySection.colli && product.buySection.colli > 1 ? true : false,
            "colliAmount": product.buySection.colli ? product.buySection.colli : 1,
            "unitPrice": product.formattedPrice,
            "minimumOrderQuantity": product.buySection.minimumOrderQuantity
		}
    })

}

export default mapProducts