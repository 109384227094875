export default{
    ChangePriceView(url) {
        fetch(url).then(result=>{
            if(result.ok){
                location.reload();
            }               
        }
        ).catch(e=>{
            console.error("ChangePriceView fetch failed", e)
        }
        )
    }
}