export default [
    {
        type: "2-seater",
        imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
        linkText: "More details",
        linkUrl: "#oslo-2seater",
        details: [
            {
                label: "Width",
                title: "150",
                unit: "cm"
            },
            {
                label: "Depth",
                title: "80",
                unit: "cm"
            },
            {
                label: "Seat depth",
                title: "40",
                unit: "cm"
            },
            {
                label: "Seat height",
                title: "50",
                unit: "cm"
            }
        ],
    },
    {
        type: "3-seater",
        imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
        linkText: "More details",
        linkUrl: "#oslo-3seater",
        details: [
            {
                label: "Width",
                title: "50",
                unit: "cm"
            },
            {
                label: "Depth",
                title: "120",
                unit: "cm"
            },
            {
                label: "Seat depth",
                title: "40",
                unit: "cm"
            },
            {
                label: "Seat height",
                title: "60",
                unit: "cm"
            }
        ],
    },
    {
        type: "bench",
        imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
        linkText: "More details",
        linkUrl: "#oslo-bench",
        details: [
            {
                label: "Width",
                title: "100",
                unit: "cm"
            },
            {
                label: "Depth",
                title: "70",
                unit: "cm"
            },
            {
                label: "Seat depth",
                title: "40",
                unit: "cm"
            }
        ],
    },
    {
        type: "pouf",
        imageSrc: "/static/images/product/Five_pouf_Remix_123-low-res.png",
        linkText: "More details",
        linkUrl: "#oslo-pouf",
        details: [
            {
                label: "Width",
                title: "80",
                unit: "cm"
            },
            {
                label: "Depth",
                title: "80",
                unit: "cm"
            }
        ],
    },
    {
        isPlanner: true,
        type: "Planning Tool",
        color: "red",
        imageSrc: "/static/images/family-pencil-drawing.jpg",
        linkText: "Plan your own modular sofa",
        linkUrl: "#planning-tool",
        details: "Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed enteratin sit amet"
    },
]
