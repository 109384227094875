import { CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC } from '../../basics/js/store/static';

import { mapActions } from 'vuex';

export default {
	name: 'request-configuration',
	props: {
		submitRequestUrl: {
			type: String,
			required: false
		},
		productName: {
			type: String,
			required: false
		}
	},
	data(){
		return {
			yourEnquiry: null,
			name: null,
			email: null,
			submissionComplete: false,
			selectedOptions: null,
			isSubmittingForm: false,
		}
	},
	mounted(){
		this.$parent.$on('update-selected-options', this.handleSelectedOptions);
	},
	methods: {
		...mapActions([
			CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.action,
		]),	
		successHandler() {
			this.submissionComplete = true;

			if (this.isSubmittingForm) return;

			this.isSubmittingForm = true;

			const url = this.submitRequestUrl;

			const body = {
				"productName": this.productName,
				"name": this.name,
				"email": this.email,
				"text": this.yourEnquiry,
				"selectedOptions": this.selectedOptions
			};

			this[CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.action]({
				url,
				body
			});
			
			this.isSubmittingForm = false;
		},
		resetForm() {
			this.submissionComplete = false;
		},
		handleSelectedOptions(selectedOptions) {
			this.selectedOptions = selectedOptions;
		}
	}
};
