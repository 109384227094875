<script>
import { mapState, mapActions } from "vuex";
import TabsBar from "../../../experience/tabs-bar/tabs-bar"
import Cookies from 'js-cookie'
import { CookiePrefix } from "../constants";

export default {
    name: "variant-options",
    components: {
        TabsBar,
    },
    props: {
        variantListsInitIndex: {
            type: Number,
            default: 0
        },
        variantLists: {
            type: Array,
            required: true,
        },
        labelText: {
            type: String,
            required: true
        },
        variantFallbackName:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hoverVariantIndex: -1,
            activeVariantCategoryIndex: 0,
            listKey: 0
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            currentProductId: state => state.reconfigurator.currentProductId,
            overlayItems: state => state.reconfigurator.optionsOverlayItems,
        }),
        currentVariantList() {
            return this.variantLists[this.activeVariantCategoryIndex] || null;
        },
        currentVariantListItems() {
            return this.currentVariantList ? this.currentVariantList.items : [];
        },
        selectedVariant() {
            let foundVariant;
            this.variantLists.forEach(list => {
                let fV = list.items.find(variant => variant.isActive);
                if (fV) foundVariant = fV;
            })
            return foundVariant;
        },
        selectedVariantName() {
            if (this.hoverVariantIndex > -1) {
                return this.currentVariantListItems[this.hoverVariantIndex] && this.currentVariantListItems[this.hoverVariantIndex].name ? this.currentVariantListItems[this.hoverVariantIndex].name : "";
            }
            else {
                return this.selectedVariant ? this.selectedVariant.name : this.variantFallbackName;
            }
        }
    },
    watch: {
        overlayItems() {
            this.listKey++;
            if (!this.currentVariantList || this.currentVariantListItems.length === 0) {
                this.activeVariantCategoryIndex = 0;
            }
        }
    },
    mounted() {
        this.activeVariantCategoryIndex = this.variantListsInitIndex;
    },
    methods: {
        ...mapActions([
            "openOptionsOverlay",
            "showOverlayBackdrop",
        ]),
        variantCategoryChangeHandler: function (item, newIndex) {
            this.activeVariantCategoryIndex = newIndex;
        },
        variantHoverHandler: function (newIndex) {
            this.hoverVariantIndex = newIndex;
        },
        variantUnhoverHandler: function () {
            this.hoverVariantIndex = -1;
        },
        showAllVariants: function () {
            //open overlay?

            window.scrollTo({ top: 0 }); //to ensure no overflow hidden on body visual glitches
            this.openOptionsOverlay({ items: this.variantLists, activeListIndex: this.activeVariantCategoryIndex });
            this.showOverlayBackdrop();
        },
        deleteItemHandler: function (item) {

            if (item.variantCode) {

                console.log('cookie',CookiePrefix + this.currentProductId);
                let currentSaved = Cookies.get(CookiePrefix + this.currentProductId);
                console.log("DELETE | cookie before", currentSaved);

                if (currentSaved) {
                    currentSaved = currentSaved.replace(item.variantCode, '');
                    currentSaved = currentSaved.replace('||', '|');
                    Cookies.set(CookiePrefix + this.currentProductId, currentSaved, { expires: 60 });

                    console.log("DELETE | cookie after", Cookies.get(CookiePrefix + this.currentProductId))
                }
            }

            this.currentVariantListItems.splice(this.currentVariantListItems.indexOf(item), 1);

            if (this.currentVariantListItems.length === 0) {
                let index = this.variantLists.indexOf(this.currentVariantList);

                //if list empty, remove it
                this.variantLists.splice(index, 1);
                this.activeVariantCategoryIndex = 0;
            }

            // deleted item is the current variant
            if (item.isActive) {
                if (this.variantLists[0] && this.variantLists[0].items[0]) {
                    window.open(this.variantLists[0].items[0].href);
                }
            } else {
                //TODO: do something?
            }

            this.listKey++;
        }
    }
};

</script>

<template>
    <div class="variant-options">
        <span class="variant-options__inner" v-if="!isMobile">

            <tabs-bar inline-template v-if="variantLists && variantLists.length > 1"
                :tab-click-handler="variantCategoryChangeHandler" :active-index="activeVariantCategoryIndex"
                :items="variantLists">
                <nav class="tabs-bar">
                    <div class="tabs-bar__inner">
                        <button class="tabs-bar__button" v-for="(item, index) in items" :key="index"
                            :class="{ 'is-active': index === activeIndex }" v-on:click="tabClickHandler(item, index)"
                            v-cloak>
                            <svg viewBox="0 0 100 100" class="svg-icon" v-if="!isMobile && index === activeIndex">
                                <use xlink:href="/dist/svg/symbols.svg#svg-angle-arrow"></use>
                            </svg>
                            <span>{{ item.name }}</span>
                        </button>
                    </div>
                </nav>
            </tabs-bar>

            <p class="variant-options__name"><strong>{{ labelText }}:</strong> {{ selectedVariantName }}</p>

            <ul class="variant-options__list" :key="listKey">
                <li v-for="(item, index) in currentVariantListItems" :key="currentVariantList.name + index"
                    class="variant-options__item"
                    :class="{ 'is-active': item.isActive, 'is-hidden': currentVariantListItems.length > 4 && index > 2 }">
                    <a :href="item.href" v-on:mouseover="variantHoverHandler(index)"
                        v-on:mouseout="variantUnhoverHandler(index)">
                        <img :src="item.image.src" />
                    </a>

                    <button class="variant-options__item__delete" v-on:click="deleteItemHandler(item)"
                        v-if="currentVariantList.isEditable">
                        <svg viewbox="0 0 100 100" class="svg-icon">
                            <use xlink:href="/dist/svg/symbols.svg#svg-close"></use>
                        </svg>
                    </button>
                </li>
                <li class="variant-options__item variant-options__item--more" v-if="currentVariantListItems.length > 4">
                    <button v-on:click="showAllVariants">
                        <span>+ {{ currentVariantListItems.length - 3 }}</span>
                    </button>
                </li>
            </ul>
        </span>
        <button class="variant-options__inner" v-else v-on:click="showAllVariants">
            <ul class="variant-options__list">
                <li v-for="(item, index) in currentVariantListItems" :key="index" class="variant-options__item"
                    :class="{ 'is-active': item.isActive, 'is-hidden': currentVariantListItems.length > 3 && index > 3 }">
                    <img :src="item.image.src" />
                </li>
            </ul>
            <p class="variant-options__item variant-options__item--more"><span
                    v-if="currentVariantListItems.length > 3">{{ currentVariantListItems.length - 3 }}</span></p>

            <svg viewbox="0 0 100 100" class="variant-options__icon svg-icon">
                <use xlink:href="/dist/svg/symbols.svg#svg-plus-mini"></use>
            </svg>
        </button>
    </div>
</template>