<script>
    import { mapState, mapActions } from "vuex";
    import { rInterval } from "../../../../basics/js/utils";

    export default {
        name: "material-group-info-overlay",
       data() {
            return {
                intervalClear:-1
            }
        },
        computed: {
            ...mapState({
                windowW: state => state.windowW,
                isMobile: state => state.isMobile,
                bodyFixed: state => state.bodyFixed,
                isOpen: state => state.reconfigurator.materialGroupInfoOverlayOpen,
                overlayData: state => state.reconfigurator.materialGroupInfoOverlayData
            }),
        },
        watch: {
            materialGroupInfoOverlayData(newVal) {
                if ( newVal ) {
		            this.intervalClear = rInterval(this.imageLoadListener.bind(this), 100).clear;
                }
            }
        },
        methods: {
            ...mapActions([
                "closeMaterialGroupInfoOverlay",
                "hideOverlayBackdrop",
            ]),
            close: function() {
                this.closeMaterialGroupInfoOverlay();
            },
        }
    };
</script>


<template>
    <div class="material-group-info-overlay" :class="{'is-active': overlayData && isOpen}" tabindex="0">
        
        <button class="material-group-info-overlay__close" v-on:click="close">
            <svg viewbox="0 0 100 100" class="svg-icon" v-if="isMobile">
                <use xlink:href="/dist/svg/symbols.svg#svg-close"></use>
            </svg>
            <svg viewbox="0 0 100 100" class="svg-icon" v-else>
                <use xlink:href="/dist/svg/symbols.svg#svg-left"></use>
            </svg>
        </button>
        
        <div class="material-group-info-overlay__inner" ref="inner" v-if="overlayData">
            <h3>{{overlayData.headline}}</h3>
            <p v-if="overlayData.bodyText">{{overlayData.bodyText}}</p>
            <dl v-if="overlayData.properties && overlayData.properties.length > 0">
                <span v-for="(item,index) in overlayData.properties" :key="index+'_'+item.name">
                    <dt>{{item.name}}</dt>
                    <dd>{{item.value}}</dd>
                </span>
            </dl>
        </div>
        
    </div>
</template>