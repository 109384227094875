/* eslint-disable no-undef */
import { mapActions, mapState } from "vuex";

import { FETCH_STORES } from "../../../../basics/js/store/static";

import { MarkerClusterer } from "@googlemaps/markerclusterer";

import mapStyles from "./map-styles";

// import Store from '@store/store';

const STORE_ID_NAME = "company_number",
	STORE_FLAGSHIP_NAME = "muuto_experience";

export default {
	name: "store-locator-map",
	data() {
		return {
			ready: false,

			useClusters: true,

			stateRef: null,

			mapContainer: null,
			map: null,

			markers: [],
			markerClusterer: null,
			markerIcons: [],

			icons: {
				default: {
					url: "../../static/images/storelocator/marker-default-idle.png",
					w: 30,
					h: 30,
				},
				defaultSelected: {
					url: "../../static/images/storelocator/marker-default-selected.png",
					w: 50,
					h: 50,
				},
				defaultHover: {
					url: "../../static/images/storelocator/marker-default-hover.png",
					w: 30,
					h: 30,
				},

				flagShip: {
					url: "../../static/images/storelocator/marker-flagship-idle.png",
					w: 30,
					h: 30,
				},
				flagShipSelected: {
					url: "../../static/images/storelocator/marker-flagship-selected.png",
					w: 50,
					h: 50,
				},
				flagShipHover: {
					url: "../../static/images/storelocator/marker-flagship-hover.png",
					w: 30,
					h: 30,
				},

				geolocation: {
					url: "../../static/svg/geolocation-point.svg",
					w: 24,
					h: 24,
				},
				places: { url: "../../static/svg/location.svg", w: 30, h: 30 },

				cluster: { url: "../../static/images/m/m" },
				info: { url: "../../static/images/storelocator/info.svg" },
			},

			locationMarker: null,
		};
	},
	mounted() {
		this.stateRef = this.$store.state.storeLocator;
		this.injectMapsApi();
	},
	watch: {
		stores(newVal) {
			//console.log("MAP | stores watch: newVal",newVal)

			if (!newVal instanceof Array || newVal.length === 0) {
				if (this.$store.state.storeLocator.mapPlaceSearch) {
					this.map.setZoom(this.map.zoom - 1);
				}
				return false;
			}

			this.sortStoresByDistance();
		},
		selectedStore(newVal) {
			//console.log("MAP | selectedStore.",newVal)
			if (this.isObject(newVal)) {
				this.goToLocation(newVal);
				this.updateMarkerIcon(newVal);
			}
		},
		hoveredStore(newVal) {
			this.updateMarkerIcon(newVal);
		},
		chosenPlace(place) {
			if (this.isObject(place)) {
				this.onPlaceChange(place);
			}
		},
		geoLocationChanged(position) {
			this.handleGeoLocation(position);
		},
		sortedStores(newVal) {
			if (newVal.length > 0) {
				this.sortStoresByFilters();
			}
		},
		assortmentKeyChanged() {
			if (this.sortedStores.length > 0) {
				this.sortStoresByFilters();
			}
		},
		onlyFlagShipsChanged() {
			if (this.sortedStores.length > 0) {
				this.sortStoresByFilters();
			}
		},
		searchTerm() {
			if (this.searchTerm.length > 0) {
				this.onSearchEnter(this.searchTerm);
			}
		},
	},
	computed: {
		...mapState({
			stores: (state) => state.storeLocator[FETCH_STORES.stateKey],
			sortedStores: (state) => state.storeLocator.sortedStores,
			filteredStores: (state) => state.storeLocator.filteredStores,
			selectedStore: (state) => state.storeLocator.selectedStore,
			hoveredStore: (state) => state.storeLocator.hoveredStore,
			bypassFetch: (state) => state.storeLocator.bypassFetch,
			chosenPlace: (state) => state.storeLocator.chosenPlace,
			geoLocationChanged: (state) => state.storeLocator.geoLocationCoords,
			assortmentKeyChanged: (state) => state.storeLocator.chosenAssortmentKey,
			onlyFlagShipsChanged: (state) => state.storeLocator.onlyFlagShips,
			searchTerm: (state) => state.storeLocator.searchTerm,
			copy: (state) => state.storeLocator.copy,
		}),
	},
	methods: {
		...mapActions([
			"mapReady",
			"storeLocatorReady",
			"mapZoomChange",
			"mapDragChange",
			"setBypassFetch",
			"getInitialStores",
			"getStoresFromBound",
			"selectStore",
			"hoverStore",
			"statusCheck",
			"storesSortedByDistance",
			"storesSortedByFilters",
		]),
		injectMapsApi() {
			this.mapContainer = this.$refs.storeLocatorMap;

			const initCallBackId = "initClientMap";
			const mapsScript = document.createElement("script");
			const urlString = [];
			urlString.push(window.location.protocol);
			urlString.push(this.stateRef.google.uri + "?");
			urlString.push("libraries=" + this.stateRef.google.libraries.join(",") + "&");
			urlString.push("key=" + this.stateRef.google.apiKey + "&");
			urlString.push("callback=" + initCallBackId);

			if (this.stateRef.google.languageCode) {
				urlString.push("&language=" + this.stateRef.google.languageCode);
			}
			if (this.stateRef.google.countryCode) {
				urlString.push("&region=" + this.stateRef.google.countryCode.toLowerCase());
			}
			mapsScript.setAttribute("src", urlString.join(""));
			mapsScript.setAttribute("async", "");
			document.head.appendChild(mapsScript);

			window[initCallBackId] = this.initMap.bind(this);

			// DEPRECATED
			/*
			if (this.stateRef.google.markerClusterer) {
				const clusterScript = document.createElement("script");
				clusterScript.setAttribute(
					"src",
					"//developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"
				);
				clusterScript.setAttribute("async", "");
				document.head.appendChild(clusterScript);
			}
			*/
		},

		initMap() {
			const clientMapTypeId = "client_style";
			// const icons = this.icons;

			if (!this.mapContainer) {
				console.warn("Missing map container: ", this.mapContainer);
				return;
			}
			this.clientMapType = new google.maps.StyledMapType(mapStyles, {
				name: clientMapTypeId,
			});
			this.map = new google.maps.Map(this.mapContainer, this.stateRef.mapOptions);

			this.map.mapTypes.set(clientMapTypeId, this.clientMapType);

			this.addMapEvents();

			this.setupIcons();

			// maps ready and the application should be ready to fetch stores
			google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
				this.mapReady();
				this.getInitialStores();
			});
		},

		addMapEvents() {
			this.map.addListener("dragend", () => {
				//console.log("MAP | DRAG handler. this.bypassFetch:"+this.bypassFetch)

				if (!this.bypassFetch) {
					this.getStoresFromBound({
						mapCenter: this.getMapCenter(),
						mapBounds: this.getMapBounds(),
					});
					this.mapDragChange(this.getMapCenter());
				}
			});
			this.map.addListener("idle", () => {
				//console.log("MAP | IDLE handler. this.bypassFetch:"+this.bypassFetch)
				if (!this.bypassFetch) {
					this.statusCheck({ mapBounds: this.getMapBounds() });
				}
			});
			this.map.addListener("zoom_changed", () => {
				//console.log("MAP | ZOOM handler. this.bypassFetch:"+this.bypassFetch)

				if (!this.bypassFetch) {
					this.getStoresFromBound({
						mapCenter: this.getMapCenter(),
						mapBounds: this.getMapBounds(),
					});
					this.mapZoomChange(this.map.zoom, this.getMapCenter());
				}
			});
		},

		/**
		 *  Handles an autocomplete request for new place search
		 *  @Private
		 */
		onPlaceChange(newPlace) {
			const place = newPlace;

			// TODO RESET LOCATION
			if (this.locationMarker) {
				this.locationMarker.setMap(null);
				this.locationMarker = null;
			}

			if (place.geometry) {
				const icon = new google.maps.MarkerImage(
					this.icons.places.url,
					null,
					null,
					null,
					new google.maps.Size(this.icons.places.w, this.icons.places.h)
				);
				this.locationMarker = new google.maps.Marker({
					position: place.geometry.location,
					map: this.map,
					icon,
					clickable: true,
					draggable: true,
					animation: google.maps.Animation.DROP,
					title: this.stateRef.copy.yourSearchMarkerLabel,
				});
				this.locationMarker.setAnimation(google.maps.Animation.BOUNCE);
				window.setTimeout(() => {
					this.locationMarker.setAnimation(null);
				}, 300);

				if (place.geometry.viewport) {
					this.map.fitBounds(place.geometry.viewport);
				} else {
					this.map.setCenter(place.geometry.location);
					this.map.setZoom(13);
				}
			}

			// appStore.dispatch(toggleSearch(true));
		},

		/**
		 *  handleGeoLocation
		 */
		handleGeoLocation(position) {
			const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			const mapSize = new google.maps.Size(this.icons.geolocation.w, this.icons.geolocation.h);
			const icon = new google.maps.MarkerImage(this.icons.geolocation.url, null, null, null, mapSize);

			this.geoLocationMarker = new google.maps.Marker({
				position: latlng,
				map: this.map,
				title: this.copy.youAreHere,
				icon,
				draggable: true,
				client_id: "you", // jshint ignore:line
			});

			this.map.setCenter(latlng);
			this.map.setZoom(12);
		},

		/**
		 *  getMapCenter - calculates and returns the center of the map.
		 */
		getMapCenter(useLatLng = false) {
			if (useLatLng) {
				return this.map.getCenter();
			}

			return {
				lat: this.map.center.lat(),
				lng: this.map.center.lng(),
			};
		},

		getMapBounds() {
			let m = this.map,
				b = m.getBounds(),
				j = b.toJSON();

			return j;
		},

		/**
		 * updateMarker is used to update the existing markers already created.
		 * The method automatically generate new markers if they doesn't exist in the cached markers
		 * TODO: filter on existing markers and remove them from the map
		 * @public
		 * @params
		 */
		updateMarkers() {
			//console.log("MAP | updateMarkers | filteredStores length = "+this.filteredStores.length);

			if (this.useClusters) {
				//NOTE: When using clustering, markers + references have to be completely removed before updating
				for (var i = 0; i < this.markers.length; i++) {
					this.markers[i].setMap(null);
				}
				this.markers = [];

				this.filteredStores.forEach((storeData) => {
					this.markers.push(this.createMarker(storeData));
				});
				// console.log("this.markers",this.markers);

				this.handleMarkerClustering();
			} else {
				//NOTE: Without clustering, markers can be kept around and references can stay
				const currentStoresIds = [];

				// create markers if they doesn't exist and add map instance if they already exist
				this.filteredStores.forEach((storeData) => {
					//locate marker matching store data by id
					const selectedMarker = this.markers.find(
						(marker) => marker.storeData[STORE_ID_NAME] === storeData[STORE_ID_NAME]
					);

					if (selectedMarker) {
						// marker exists
						if (!selectedMarker.map) {
							selectedMarker.setMap(this.map);
							selectedMarker.setVisible(true);
						}
					} else {
						// marker doesn't exist so create new
						this.markers.push(this.createMarker(storeData));
					}
					//only allow markers within filter
					currentStoresIds.push(storeData[STORE_ID_NAME]);
				});

				// console.log("currentStoresIds",currentStoresIds)

				// remove the markers that shouldn't be on the map
				this.markers.forEach((marker) => {
					let markerIdPosition = currentStoresIds.indexOf(marker.storeData[STORE_ID_NAME]);
					// console.log("markerIdPosition: "+markerIdPosition)
					if (markerIdPosition === -1) {
						// console.log("HIDE MARKER "+marker.storeData[STORE_ID_NAME])
						// marker.map = null;
						marker.setMap(null);
						marker.setVisible(false);
					}
				});
			}
		},

		clusterRenderer({ count, position }) {
			// use d3-interpolateRgb to interpolate between red and blue
			// create svg url with fill color
			const svg = window.btoa(`
				<svg fill="#EBE7DC" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
				<circle cx="120" cy="120" r="70" />
				</svg>`);

			let dynamicSize = Math.min(50 + count * 2, 100);
			// create marker using svg icon
			return new google.maps.Marker({
				position,
				icon: {
					url: `data:image/svg+xml;base64,${svg}`,
					scaledSize: new google.maps.Size(dynamicSize, dynamicSize),
				},
				label: {
					text: String(count),
					color: "#000000",
					fontSize: "12px",
				},
				// adjust zIndex to be above other markers
				zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
			});
		},

		handleMarkerClustering() {
			console.log("MAP | handleMarkerClustering | this.markerClusterer?", this.markerClusterer);

			if (this.markerClusterer) {
				this.markerClusterer.clearMarkers();
			}
			// let options = { imagePath: this.icons.cluster.url };

			this.markerClusterer = new MarkerClusterer({ map:this.map, markers:this.markers, renderer: {render:this.clusterRenderer} });
		},

		/**
		 * private
		 * setMarkers is called on instantiation
		 *
		 */
		createMarker(storeData) {
			const pos = new google.maps.LatLng(storeData.latitude, storeData.longitude);

			const storeType = this.getStoreType(storeData);

			let icon = this.markerIcons[storeType].idle;
			if (this.selectedStore.data && storeData[STORE_ID_NAME] == this.selectedStore.data[STORE_ID_NAME]) {
				icon = this.markerIcons[storeType].selected;
			}

			const marker = new google.maps.Marker({
				position: pos,
				map: this.map,
				icon: icon,
				animation: false,
				clickable: true,
				storeData: storeData,
				optimized: false,
			});
			google.maps.event.addListener(marker, "click", () => {
				this.selectStore({
					data: storeData,
					select: true,
					scrollTo: true,
				});
			});

			google.maps.event.addListener(marker, "mouseover", () => {
				this.hoverStore({ data: storeData, hover: true });
			});

			google.maps.event.addListener(marker, "mouseout", () => {
				this.hoverStore({ data: storeData, hover: false });
			});

			return marker;
		},

		/**
		 * updateMarkerIcon - updates the icon of a marker
		 * @public
		 * @param state {obj} selected, hover, id
		 */
		updateMarkerIcon(markerRef) {
			let icon = "",
				storeType = this.getStoreType(markerRef.data);

			//console.log("MAP | updateMarkerIcon. this.selectedStore?"+this.isObject(this.selectedStore), markerRef, storeType)

			if (this.selectedStore.data && markerRef.data[STORE_ID_NAME] == this.selectedStore.data[STORE_ID_NAME]) {
				icon = this.markerIcons[storeType].selected;
				this.markers.forEach((marker) => {
					let mst = this.getStoreType(marker.storeData);
					let mi = this.markerIcons[mst].idle;
					marker.setIcon(mi);
				});
			} else if (markerRef.hover) {
				icon = this.markerIcons[storeType].hover;
			} else {
				icon = this.markerIcons[storeType].idle;
			}

			const currentMarker = this.markers.filter(
				(marker) => markerRef.data[STORE_ID_NAME] === marker.storeData[STORE_ID_NAME]
			);
			currentMarker[0].setIcon(icon);
		},

		/**
		 * goToLocation moved the map to a storeModel
		 * @param {obj} position - with latitude and longitude that the map should move to
		 * @param {number} zoomLevel - overwrite the zoomlevel provided in the settings
		 * @param {bool} silent - disable next tick in the storelocators breath
		 */

		goToLocation(markerRef) {
			//console.log("MAP | goToLocation",markerRef)
			const latlng = new google.maps.LatLng(markerRef.data.latitude, markerRef.data.longitude);

			this.map.panTo(latlng);
			// this.map.setZoom(13);

			this.setBypassFetch(false);
		},

		/**
		 * sortStoresByDistance sorts all stores by distance to map center
		 * @public sortStoresByDistance
		 * @param {*} stores
		 *
		 */
		sortStoresByDistance() {
			// let stores = [].concat(this.stores);
			let stores = JSON.parse(JSON.stringify(this.stores));

			// setting distance to center
			stores = stores.map((store) => {
				store.distanceToCenter = this.getDistanceToCenter(store);
				return store;
			});

			// sorting closest to center
			stores.sort((a, b) => parseFloat(a.distanceToCenter) - parseFloat(b.distanceToCenter));
			//console.log("MAP | sortStoresByDistance",stores)

			// sorting closest to center
			stores.sort((a, b) => (a["muuto_experience"] == "1" && b["muuto_experience"] != "1" ? -1 : 1));
			//console.log("MAP | sortStoresByDistance | muuto_experience first",stores)

			this.storesSortedByDistance(stores);
		},

		/**
		 * sortStoresByFilters
		 * @public sortStoresByDistance
		 * @param {*} stores
		 *
		 */
		sortStoresByFilters() {
			if (this.sortedStores.length > 0) {
				let assortmentFilter = this.stateRef.chosenAssortmentKey;
				let flagShipFilter = this.stateRef.onlyFlagShips;

				let stores = JSON.parse(JSON.stringify(this.sortedStores));
				let newStores = [];
				let storesCount = stores.length;

				for (let i = 0; i < storesCount; i++) {
					let store = JSON.parse(JSON.stringify(stores[i]));

					if (flagShipFilter == false && assortmentFilter == "all") {
						// console{.log("no filter")
						newStores.push(store);
					} else if (flagShipFilter == true) {
						if (store["muuto_experience"] == "1") {
							if (assortmentFilter != "all") {
								if (store[assortmentFilter]) {
									// console.log("flagship store + assortment ok")
									newStores.push(store);
								}
							} else {
								// console.log("just flagship store")
								newStores.push(store);
							}
						}
					} else if (assortmentFilter != "all" && store[assortmentFilter]) {
						// console.log("just assortment ok")
						newStores.push(store);
					}
				}

				//console.log("MAP | sortStoresByFilters",newStores)

				this.storesSortedByFilters(newStores);
			}

			this.updateMarkers();
		},

		/**
		 * ToDO check for geoLocation and use that as center if it's available
		 * @private
		 */
		getDistanceToCenter(store) {
			const center = this.getMapCenter(true);

			const markerPos = new google.maps.LatLng(store.latitude, store.longitude);

			let distanceToCenter = google.maps.geometry.spherical.computeDistanceBetween(center, markerPos);

			return distanceToCenter;
		},

		getStoreType(storeData) {
			return storeData[STORE_FLAGSHIP_NAME] === "1" ? "flagShip" : "default";
		},

		isObject(item) {
			return item && typeof item === "object" && !Array.isArray(item);
		},
		/**
		 *  getLocationFromAddress
		 */
		getLocationFromAddress(address) {
			const geocoder = new google.maps.Geocoder();
			return new Promise((resolve, reject) => {
				geocoder.geocode({ address }, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						resolve(results);
					} else {
						reject(new Error("Geocode was not successful: " + status));
					}
				});
			});
		},
		onSearchEnter(value) {
			const service = new google.maps.places.AutocompleteService();
			service.getPlacePredictions({ input: value }, (result) => {
				this.getLocationFromAddress(result[0].description).then((place) => {
					this.onPlaceChange(place[0]);
				});
			});
		},

		setupIcons() {
			this.markerIcons = {
				default: {
					idle: new google.maps.MarkerImage(
						this.icons.default.url,
						null,
						null,
						null,
						new google.maps.Size(this.icons.default.w, this.icons.default.h)
					),
					hover: new google.maps.MarkerImage(
						this.icons.defaultHover.url,
						null,
						null,
						null,
						new google.maps.Size(this.icons.defaultHover.w, this.icons.defaultHover.h)
					),
					selected: new google.maps.MarkerImage(
						this.icons.defaultSelected.url,
						null,
						null,
						null,
						new google.maps.Size(this.icons.defaultSelected.w, this.icons.defaultSelected.h)
					),
				},
				flagShip: {
					idle: new google.maps.MarkerImage(
						this.icons.flagShip.url,
						null,
						null,
						null,
						new google.maps.Size(this.icons.flagShip.w, this.icons.flagShip.h)
					),
					hover: new google.maps.MarkerImage(
						this.icons.flagShipHover.url,
						null,
						null,
						null,
						new google.maps.Size(this.icons.flagShipHover.w, this.icons.flagShipHover.h)
					),
					selected: new google.maps.MarkerImage(
						this.icons.flagShipSelected.url,
						null,
						null,
						null,
						new google.maps.Size(this.icons.flagShipSelected.w, this.icons.flagShipSelected.h)
					),
				},
			};
		},
	},
};
