// import TweenLite from 'gsap/umd/TweenLite';

import ProductFilter from '../../components/product-filter/product-filter';
import TileFilter from '../../components/product-filter/tile-filter';
import ProductTileProd from '../../components/product-tile/product-tile';

import { mapActions, mapState } from 'vuex';
import { uid } from '../../basics/js/utils';
import { GET_PRODUCTS } from '../../basics/js/store/static';

export default {
	name: 'product-list-filter',
	components: {
		ProductFilter,
		TileFilter,
        ProductTileProd
	},
	props: {
		limit: {
			type: Number,
			default: 16
		}

	},
	data() {
		return {
			isDirty: false, // has the list received the first products yet?
			ghostProducts: [1,2],
		}
	},
	computed: {
		...mapState({
			products: state => state.plp[GET_PRODUCTS.stateKey],
			limitProducts: function(state) {
				const products = state.plp[GET_PRODUCTS.stateKey];
				return this.limit ? products.slice(0, this.limit) : this.products;
			},
			isLoading: state => state.plp[GET_PRODUCTS.loadingKey]
		})
	},
	watch: {
		products() {
			if(this.isDirty) return;
			this.isDirty = this.products.length>0;
		}
	},
	methods: {
		...mapActions([
			GET_PRODUCTS.action
		]),
		onFilterChange(event) {
			// TODO: this should be refactored, it's only to show how filters should work
			this[GET_PRODUCTS.action](event.length ? {id: event.lastItem.id } : {id:""});
		},
		getId(){
			return uid();
		},
		onMoreClick(){
			this.limit = null;
		}

	},

	mounted() {
		this[GET_PRODUCTS.action]();
    }
};
