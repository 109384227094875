import { vueVimeoPlayer } from 'vue-vimeo-player';

export default {
	name: 'video-player-vimeo',
	components: { vueVimeoPlayer },
	data() {
		return {
			videoID: '286898202',
			options: {},
			playerReady: false,
			isPlaying: false,
			width: '100%',
			height: '100%'
		}
	},
	methods: {
		onReady() {
			this.playerReady = true;
		},
		play () {
			this.$refs.player.play();
			this.isPlaying = true;
		}
	}
};
