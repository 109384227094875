import Vue from "vue/dist/vue.esm";
import * as types from "../static";
import { getRequest, postRequest } from "../integrations";

const state = {
	configuratorOpen: false,
	configuratorActiveSku: "",
	showPDPSpinner: false,
	[types.CONFIGURATOR_OPTIONS_ASYNC.stateKey]: null,
	[types.CONFIGURATOR_OPTIONS_ASYNC.loadingKey]: null,
	[types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.stateKey]: null,
	[types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.loadingKey]: null,
	[types.CONFIGURATOR_CYLINDO_SKU_ASYNC.stateKey]: null,
	[types.CONFIGURATOR_CYLINDO_SKU_ASYNC.loadingKey]: null,
	[types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.stateKey]: null,
	[types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.loadingKey]: null,
};

function removeFromQuery(key, queryString){
	if (queryString !== "") {
        var params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            var param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
		}
		return params_arr;
	}
	return [];
}

function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],              
		queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
		
	var params_arr = removeFromQuery(key, queryString);
	rtn =   params_arr.length>0 ? rtn + "?" + params_arr.join("&") : rtn;
	return rtn;
}
function addParam(key, value, sourceURL) {
	var rtn = sourceURL.split("?")[0],        
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
	var params_arr = removeFromQuery(key, queryString);
	params_arr.push(key + "=" + value);
	rtn =   params_arr.length>0 ? rtn + "?" + params_arr.join("&") : rtn;
    return rtn;
}

const mutations = {
	openConfigurator(state, sku) {
		// COMMENT: sku = String
		state.configuratorActiveSku = sku;
		state.configuratorOpen = true;
		state.showPDPSpinner = true;
		var newurl = addParam("configure", "true", window.location.href);
		window.history.pushState({ path: newurl }, "", newurl);
	},
	closeConfigurator(state) {
		state.configuratorOpen = false;	
		var newurl = removeParam("configure", window.location.href);
		window.history.replaceState({ path: newurl }, "", newurl);
	},

	setShowPDPSpinner(state, newVal){
		state.showPDPSpinner = newVal;
	},

	[types.CONFIGURATOR_OPTIONS_ASYNC.PENDING](state, status) {
		Vue.set(state, types.CONFIGURATOR_OPTIONS_ASYNC.loadingKey, status);
	},
	[types.CONFIGURATOR_OPTIONS_ASYNC.SUCCESS](state, data) {
		// COMMENT: This is where the configurator response from the server is added to the state
		Vue.set(state, types.CONFIGURATOR_OPTIONS_ASYNC.stateKey, data);
	},
	[types.CONFIGURATOR_CYLINDO_SKU_ASYNC.PENDING](state, status) {
		Vue.set(state, types.CONFIGURATOR_CYLINDO_SKU_ASYNC.loadingKey, status);
	},
	[types.CONFIGURATOR_CYLINDO_SKU_ASYNC.SUCCESS](state, data) {
		// COMMENT: This is where the configurator response from the server is added to the state
		Vue.set(state, types.CONFIGURATOR_CYLINDO_SKU_ASYNC.stateKey, data);
	},
	[types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.PENDING](state, status) {
		Vue.set(
			state,
			types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.loadingKey,
			status
		);
	},
	[types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.SUCCESS](state, data) {
		// COMMENT: This is where the configurator response from the server is added to the state
		Vue.set(state, types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.stateKey, data);
	},
	[types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.PENDING](state, status) {
		Vue.set(
			state,
			types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.loadingKey,
			status
		);
	},
	[types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.SUCCESS](state, data) {
		// COMMENT: This is where the configurator response from the server is added to the state
		Vue.set(
			state,
			types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.stateKey,
			data
		);
	},
};

const actions = {
	async [types.CONFIGURATOR_OPTIONS_ASYNC.action](store, payload) {
		const result = await getRequest(store, {
			url:
				process.env.CONFIGURATOR_OPTIONS +
				"?productCode=" +
				payload.body.productCode +
				"&filters=" +
				payload.body.selectedOptions,
			mutationTypes: types.CONFIGURATOR_OPTIONS_ASYNC,
		});

		store.commit(types.CONFIGURATOR_OPTIONS_ASYNC.SUCCESS, result);
	},

	async [types.CONFIGURATOR_INITIAL_OPTIONS_ASYNC.action](store, payload) {
		const result = await getRequest(store, {
			url:
				process.env.CONFIGURATOR_INITIAL_OPTIONS +
				"?productCode=" +
				payload.body.productCode +
				"&variantCode=" +
				payload.body.sku,
			mutationTypes: types.CONFIGURATOR_OPTIONS_ASYNC,
		});

		store.commit(types.CONFIGURATOR_OPTIONS_ASYNC.SUCCESS, result);
	},

	async [types.CONFIGURATOR_CYLINDO_SKU_ASYNC.action](store, payload) {
		const result = await getRequest(store, {
			url:
				process.env.CONFIGURATOR_CYLINDO_SKU +
				"?productCode=" +
				payload.body.productCode +
				"&filters=" +
				payload.body.selectedOptions,
			mutationTypes: types.CONFIGURATOR_CYLINDO_SKU_ASYNC,
		});

		store.commit(types.CONFIGURATOR_CYLINDO_SKU_ASYNC.SUCCESS, result);
	},
	async [types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.action](
		store,
		payload
	) {
		await postRequest(store, {
			url: process.env.CONFIGURATOR_REQUEST_CONFIGURATION,
			mutationTypes: types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC,
			body: payload.body,
		});
		store.dispatch("showNotificationPanel", payload.notification);
		this.commit(types.CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC.SUCCESS);
	},

	openConfigurator({ commit, dispatch }, sku) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("openConfigurator", sku);
	},
	closeConfigurator({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeConfigurator");
	},


	setShowPDPSpinner({ commit }, newVal) {
		commit("setShowPDPSpinner", newVal);
	},
};

export default { state, actions, mutations };
