
import { TimelineMax, Power1 } from 'gsap';
import ProductLineCart from '../../components/product-line/product-line-cart';
import TextToInput from '../../components/text-to-input/text-to-input';

export default {
	name: 'cart-core',
	components: {
		ProductLineCart,
		TextToInput,
	},
	computed: {
		isLoaded: function(){
			this.isLoading = !this.$store.state.cartLoaded;
			return this.$store.state.cartLoaded;
		},
		subTotal: function(){
			return this.$store.state.cartSubTotal;
		},
		cartItems: function(){
			return this.$store.state.cartItems;
		},
		cartItemsCount: function(){
			return this.$store.state.cartItemsCount;
		},
	},
	methods: {
		// COMMENT: Animation properties below - when cartItems are loaded
		
		transitionEnter: function(el, done){
			let delay = el.dataset.index * 250; // 150 ms delay
			delay = delay / 1000;
			const tl = new TimelineMax();

			tl.set(el, { height: 'auto', autoAlpha: 1 });

			// tl.from(el, 0.45, { delay: delay, height: 0, autoAlpha: 0, ease: Power1.easeOut, clearProps: 'all',
			tl.from(
				el, 0.35, { height: 0, autoAlpha: 0, ease: Power1.easeOut, clearProps: 'all',
				onComplete: ()=>{
					tl.kill();
					done();
				}
			});
		},
	},
};