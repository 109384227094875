
import { TimelineMax, Power1 } from 'gsap';
import { registerRemoveFromCart } from '../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer'
import ProductLineCore from '../../components/product-line/product-line-core';

import { mapActions } from 'vuex';

export default {
	name: 'product-line-cart',
	extends: ProductLineCore,
	props: {		
		cartUrl: {
			type: String,
			required: true
		}
	},
	data() {
		return {
		};
	},
	mounted(){
	},
	methods: {
		...mapActions([
			'removeCartItem',
		]),
		removeProduct(product){
			console.log('removing product: ', product);
			var query = this.cartUrl.indexOf('?') != -1 ? '&' : '?';
			var endpointUrl = this.cartUrl + query + 'code=' + product.sku;	
			console.log('url: ', endpointUrl);

			fetch(endpointUrl,  {method: 'DELETE' })
				.then(() => { 
					var productName = `${product.primaryProductName} ${product.secondaryProductName ? product.secondaryProductName : ""}`
					var variantName = product.variantName
					registerRemoveFromCart(productName, variantName, product.sku, product.unitPrice, product.quantity)
				})
				.catch(error => console.error('Error:', error));

			const tl = new TimelineMax();

			tl.add('start');
			tl.to(this.$el, .25, {
					height: 0,
					ease: Power1.easeOut,
					clearProps: 'all',
					
				},
				'start+=0'
			)
			.to(this.$el, .15, {
					delay: .1,
					borderColor: 'rgba(217, 217, 217, 0)',
					ease: Power1.easeOut,
					clearProps: 'all',
					onComplete: ()=>{
						tl.kill();
						this.removeCartItem(product);
					}
				},
				'start+=0'
			);
		},
	},
};