import Swiper from "swiper/dist/js/swiper.js";

import mockData from "./step-guide-data";

export default {
	name: "step-guide",
	props: {
        stepText: {
            type: String,
            default: "Step"
        },
        labelText: {
            type: String,
            default: "Guide to Product Planer"
        },
        slideList: {
            type: Array,
            default() {
                return mockData.slidesIdeal;
            }
        } 
    },
    data() {
        return {
            slider: null,
			slidesCount: 0,
			slidesRef: null,

            activeSlideIndex: 0,
			
			settings: {
				slidesPerView: 1,
				allowTouchMove: true,
				effect: "fade",
				fadeEffect: {
					crossFade: true,
				},
				pagination: true,
				centeredSlides: false,
				autoHeight: false,
				speed: 600,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				preloadImages: true,
				watchOverflow: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 3,
				},
				on: {
                    slideChange: this.slideChangeHandler.bind(this)
                }    
			},
        }
    },  
    computed: {
		activeSlideStyle: function() {
			let currentSlide = this.slideList[this.activeSlideIndex];
			return {
				'background-color': currentSlide.backgroundColor,
				'--step-guide-bg-color': currentSlide.backgroundColor,
				'color': currentSlide.textColor,
				'--step-guide-text-color': currentSlide.textColor,
			};
		},
	},
	methods: {		
		slideChangeHandler: function(){
			this.activeSlideIndex = this.slider.activeIndex;
		},
		getButtonStyle: function(button) {
            let styles;
            if ( button.backgroundColor ) {
                styles = {};
                styles.backgroundColor = button.backgroundColor;
                styles['--background-color'] = button.backgroundColor;
            }
            if ( button.textColor ) {
                if ( !styles ) styles = {};
                styles.textColor = button.textColor;
                styles['--text-color'] = button.textColor;
            }
            return styles;
        }

	},
    mounted() {
		this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");
		this.slidesCount = this.slidesRef.length;
        let sT = this.stepText;
        console.log("sT",sT)
		if (this.settings.pagination && this.slidesCount > 1) {
			Object.assign(this.settings, {
				pagination: {
					el: this.$refs.pagination,
                    type: "custom",
                    renderCustom: function (swiper, current, total) {
                        return `<strong>${sT}:</strong><small>${current < 10 ? '0'+current : current} / ${total < 10 ? '0'+total : total}</small>`;
                    },
                }
			});
		}
		
		this.slider = new Swiper(this.$refs.slider, this.settings);

    }
}