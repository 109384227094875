import { mapActions, mapState} from 'vuex';

export default {
	name: "explanation-overlay",
	computed: {
		...mapState({
			explanationData: state => state.explanationData,
			explanationOverlayOpen: state => state.explanationOverlayOpen,
		}),
		colorStyle() {
			if ( this.explanationData && this.explanationData.backgroundColor ) {
				return `background-color: ${this.explanationData.backgroundColor}; --material-overlay-bg-color: ${this.explanationData.backgroundColor};color: ${this.explanationData.textColor}; --material-overlay-text-color: ${this.explanationData.textColor}`;
			}
		},
	},
	props: {
		closeText: {
			type: String,
			default: "Close"
		}
	},
    methods: {
		...mapActions([
			'openExplanationOverlay',
			'closeExplanationOverlay',
			'hideOverlayBackdrop',
		]),
		close(){
			this.closeExplanationOverlay();
			this.hideOverlayBackdrop();
		},
	}
}