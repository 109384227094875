const state = {
	activeVimeoVideoId: null,
	activeVideoSrc: null,
};

const mutations = {
	setActiveVimeoVideoId(state, id) {
		state.activeVimeoVideoId = id;
	},
	setActiveVideoSrc(state, src) {
		state.activeVideoSrc = src;
	},
};

const actions = {
	setActiveVideo({ commit }, params) {
		if (!params) {
			commit("setActiveVimeoVideoId", null);
			commit("setActiveVideoSrc", null);
		} else if (params.type == "vimeo") {
			commit("setActiveVimeoVideoId", params.value);
		} else if (params.type == "html") {
			commit("setActiveVideoSrc", params.value);
		} else {
			commit("setActiveVimeoVideoId", null);
			commit("setActiveVideoSrc", null);
		}
	},
};

export default { state, actions, mutations };
