import GuidedTour from "./guided-tour";
import InvitationBanner from "../../components/invitation-banner/invitation-banner";

import Cookies from "../../basics/js/methods/cookies/cookies";

export default {
	name: "guided-tour-controller",
	components: {
		GuidedTour,
		InvitationBanner,
	},
	props: {
		isActive: Boolean,
		delay: {
			type: String,
			default: "2000",
		},
		tour: {
			type: String,
			required: true,
		},
	},
	computed: {
		tourId() {
			return "GuidedTour--" + this.tour;
		},
	},
	mounted() {
		this.isMounted = true;
		const showTour = !Cookies.hasItem(this.tourId);
		if (showTour) {
			setTimeout(() => {
				this.$store.commit("updateGuidedTour", "invitation");
			}, parseInt(this.delay));
		}
	},
};
