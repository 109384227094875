import StoreLocatorFilters from "@components/store-locator/partials/filters/filters";
import StoreLocatorList from "@components/store-locator/partials/list/list";
import StoreLocatorMap from "@components/store-locator/partials/map/map";

export default {
	name: "store-locator",
	components: {
		StoreLocatorFilters,
		StoreLocatorList,
		StoreLocatorMap,
	},
	props: {
		categoryId: {
			type: String,
			required: true,
		},
		lat: {
			type: String,
			required: false,
		},
		lng: {
			type: String,
			required: false,
		},
		zoom: {
			type: String,
			required: false,
		},
		translation: {
			type: String,
			require: false,
			default: "{}",
		},
	},
	mounted() {
		this.$store.commit("setStorelocatorId", this.categoryId);
		const defaults = {
			zoom: parseFloat(this.zoom) || null,
			center:
				this.lat && this.lng
					? { lat: parseFloat(this.lat), lng: parseFloat(this.lng) }
					: null,
			copy: { ...JSON.parse(this.translation) },
		};
		this.$store.commit("setDefaults", defaults);
	},
};
