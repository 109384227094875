import Swiper from "swiper";
import { mapState } from "vuex";

export default {
	name: 'category-tile-raw',
	props: {
		categories: {
			type: Array,
			required: true,
			default: []
		},
		type: {
			type: String,
			required: false,
			default:'links'
		},
	},
	data(){
		return {
			activeCategory: null,
			defaultColor: '',
			slider: null,
            sliderSettings: {
				slidesPerView: 1.25,
                spaceBetween: 10,
				breakpoints: {
					390: {
						slidesPerView: 1.25,
						spaceBetween: 10,
					},
					450: {
						slidesPerView: 1.5,
						spaceBetween: 15,
					},
					768: {
						slidesPerView: 1.75,
						spaceBetween: 20,
					},
				}
            }
		}
	},
	computed: {
		...mapState({
            isMobile: state => state.isMobile,
		}),
	},
	mounted(){
		this.activeCategory = this.categories[0];

		this.defaultColor = window.getComputedStyle(this.$refs.inner).backgroundColor;


		if ( this.$refs.slider ) {
			this.sliderSettings.pagination = {
				el: this.$refs.pagination,
				clickable: true,
			};
			setTimeout(function() {
				this.slider = new Swiper(this.$refs.slider, this.sliderSettings);
			}.bind(this),100);
		}
	},
	methods: {
		hoverCategory(event=null,item) {
			this.activeCategory = item;
			if ( item.color ) this.$refs.inner.style.setProperty('--category-tile-bg-color', item.color);
		},
		resetCategory() {
			this.activeCategory = this.categories[0];
			this.$refs.inner.style.setProperty('--category-tile-bg-color', this.defaultColor);
		}
	}
};
