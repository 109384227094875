export default [
    {
        category: "Comfort",
        rangeStartText: "Soft",
        rangeEndText: "Firm",
        models: [
            {
                name:"Outline",
                description: "Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            },
            {
                name:"Oslo",
                description: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            },
            {
                name:"In Situ",
                description: "Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed enteratin sit amet",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            },
            {
                name:"Connect",
                description: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            }
        ]
    },
    {
        category: "Seat depth",
        rangeStartText: "Shallow",
        rangeEndText: "Deep",
        models: [
            {
                name:"Outline",
                description: "Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            },
            {
                name:"Lorem",
                description: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            },
            {
                name:"Ipsum",
                description: "Form follows function - so whether you need to lounge, enteratin.",
                linkHref: "/#",
                linkText:"Explore sofa",
                imageSrc:"/static/images/product/new-spec-sofa.png"
            }
        ]
    }
];
