import { TimelineMax } from 'gsap';

import { mapActions, mapState } from 'vuex';

export default {
	name: 'dashboard-header',
	props: {
		customerName: {
			type: String,
		},
		startAnimationsDelay: {
			type: Number,
			default: 2000,
		},
		repeats: {
			type: Number,
			default: 2
		}
	},
	computed: {
		...mapState({
			isActive: state => state.dashboardOpen
		}),
		computedStartAnimationsDelay() {
			// COMMENT: Test if seconds or ms are supplied
			let newVal = parseFloat(this.startAnimationsDelay);
			if ( newVal < 100 ) newVal*=1000;
			return newVal;
		},
		computedRepeats() {
			// COMMENT: Test if seconds or ms are supplied
			let newVal = parseInt(this.repeats);
			if ( newVal < 1 ) newVal = 1;
			return newVal;
		},
	},
	data(){
		return {
			tl: new TimelineMax({ paused: true }),

			cssTiming: 0.3, // seconds
			dashboardTitleStillDuration: 2, // seconds // duration of when the "Go to dashboard" is visible

			repeatDelay: 10, // seconds // should be more than "4 x cssTiming + startDelay + dashboardTitleStillDuration"
			repeatCount: 0,
			repeatInterval: null, // will be a "setInterval"
			startTimeout: null
		};
	},
	mounted(){
		this.setupAnimations();

		this.startTimeout = setTimeout(this.startAnimations, this.computedStartAnimationsDelay);
	},
	methods: {
		...mapActions([
			'openDashboard',
			'closeDashboard',
		]),
		startAnimations(){
			this.repeatCount = 0; // COMMENT: If it should be able to be trigged multiple times
			this.showDashboardText();

			if(this.computedRepeats > 0){
				this.repeatInterval = setInterval(()=>{
					this.repeatCount++;
					this.showDashboardText();

					if(this.repeatCount === (this.computedRepeats - 1)){
						clearInterval(this.repeatInterval);
					}
				}, this.repeatDelay * 1000);
			}
		},
		showDashboardText(){
			this.tl.seek('start').play();
			// this.tl.tweenFromTo('start', 'end'); // COMMENT: Tweening cannot be surpressed by the seek/gotoAndStop, but .play() can
		},
		setupAnimations(){
			this.tl
				.addLabel('start')
				.to(this.$refs.nameTextCover, this.cssTiming, { delay: this.startDelay, className: '+=is-active' })
				.to(this.$refs.nameText, 0, { className: '-=is-active' })
				.addLabel('switch-in')
				.to(this.$refs.dashboardText, 0, { className: '+=is-active' })
				.to(this.$refs.dashboardTextCover, this.cssTiming, { className: '-=is-active' })
				.addLabel('middle')
				// This is where the dashboard-title is shown/"paused"
				.to(this.$refs.dashboardTextCover, this.cssTiming, { delay: this.dashboardTitleStillDuration, className: '+=is-active' })
				.to(this.$refs.dashboardText, 0, { className: '-=is-active' })
				.addLabel('switch-out')
				.to(this.$refs.nameText, 0, { className: '+=is-active' })
				.to(this.$refs.nameTextCover, this.cssTiming, { className: '-=is-active' })
				.addLabel('end')
				;
		},
		stopAnimations() {
			clearInterval(this.repeatInterval);
			clearTimeout(this.startTimeout); // COMMENT: If clicked to open before timeout is trigged(don't start animations)
			this.tl.gotoAndStop('start');
		},
		toggle(){
			if (!this.isActive) {
				this.openDashboard();
			}
			else {
				this.closeDashboard();
			}
			this.stopAnimations();
		}
	},
};
