
export default {
	name: 'text-to-input',
	props: {
		initText: { 
			type: String,
			required: false,
			default: ''
		},
		actionType: {
			type: String,
			required: true
		},
		code: {
			type: String,
			required: false
		}
	},
	data(){
		return {
			inputValue: this.initText,
			inputMode: false,
			input: null,
			oldValue: this.initText

		};
	},
	mounted(){
		// console.log('cart top ref input: ', this.$refs.input);
		this.input = this.$refs.input[0] ? this.$refs.input[0] : this.$refs.input;
		this.inputMode = this.initText !== '';
	},
	methods: {
		toggleInputMode() {
			if(this.inputValue.length) return;
			this.inputMode = !this.inputMode;
			if(this.inputMode){ // when showing
				this.input.focus();
			} else {
				this.inputValue = '';
			}
		},
		validate() {

			const valueChanged = this.inputValue !== this.oldValue

			this.oldValue = valueChanged ? this.inputValue : this.oldValue
			this.inputMode = !this.inputValue.length ? !this.inputMode : this.inputMode

			if(valueChanged) this.$store.dispatch(this.actionType,{'refid':this.inputValue,'code':this.code})
			
		},
		clear() {
			this.inputValue = '';
			this.inputMode = false;

			this.$store.dispatch(this.actionType,{'refid':this.inputValue,'code':this.code})
		},
		onEnter(event) {
			if(event.key === 'Enter' || event.key === 'Escape') {
				event.target.blur();
				this.validate();
			}
			if(event.key === 'tab') {
				this.validate();
			}
		}

	
	},
};