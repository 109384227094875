import { mapState } from "vuex";

import ImageProductsButton from "../image-products/image-products-button.vue"

export default {
	name: "hero-fragmented",
    components: {
        ImageProductsButton,
    },
    props: {
        modifierClass: {
            type: String,
            default: "hero-fragmented--default",
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        headlineFull: {
            type: String,
            required: false,
        },
        headlinePartOne: {
            type: String,
            required: false,
        },
        headlinePartTwo: {
            type: String,
            required: false,
        },
        bodyText: {
            type: String,
            required: false,
        },
        media1: {
            type: Object,
			required: true,
            default: () => {return {src: '/static/images/article/IMG_lifestyle3.jpg', type:'image'}}
        },
        media2: {
            type: Object,
            required: false,
        },
        linkText: {
            type: String,
            required: false,
        },
        linkUrl: {
            type: String,
            required: false,
        },
        imageProductsOverlayData: {
            type: Object
        }
    },
    data() {
        return {
            hoverTextVisible: false,
            hoverTextX: 0,
            hoverTextY: 0,
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
        hoverTextStyles: function() {
            if ( this.hoverTextVisible ) return {left: `${this.hoverTextX}px`, top: `${this.hoverTextY}px`};
            else return {};
        }
    },
    methods: {

        mouseOverHandler: function() {
            this.hoverTextVisible = true;
        },
        mouseMoveHandler: function(event) {
			if ( !this.hoverTextVisible ) return;

			//to avoid collision with shop image buttons
			if ( event.target.tagName === "BUTTON" ) {
				this.hoverTextVisible = false;
			}

			// to ensure correct target, and not just the hover label itself
            this.hoverTextX = event.clientX + 1;
            this.hoverTextY = event.clientY + 1;
        },
        mouseOutHandler: function() {
            this.hoverTextVisible = false;
        },
    },
    mounted() {
        this.$el.addEventListener('mouseover', this.mouseOverHandler.bind(this));
        this.$el.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
        this.$el.addEventListener('mouseout', this.mouseOutHandler.bind(this));
        this.$el.addEventListener('mouseleave', this.mouseOutHandler.bind(this));
    }

};
