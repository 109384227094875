var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quantity-selector" }, [
    _c("div", { staticClass: "quantity-selector__inner" }, [
      _c("div", { staticClass: "quantity-selector__inputs" }, [
        _c(
          "button",
          {
            staticClass: "quantity-selector__input__minus",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClick($event, "minus")
              },
            },
          },
          [
            _c(
              "svg",
              { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
              [
                _c("use", {
                  attrs: {
                    "xlink:href": "/dist/svg/symbols.svg#svg-quantity-minus",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "quantity-selector__input__plus",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClick($event, "plus")
              },
            },
          },
          [
            _c(
              "svg",
              { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
              [
                _c("use", {
                  attrs: {
                    "xlink:href": "/dist/svg/symbols.svg#svg-quantity-plus",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "quantity-selector__input__field" }, [
          _vm.colliAmount && _vm.colliText
            ? _c("p", [_vm._v(_vm._s(_vm.colliText))])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quantityValue,
                expression: "quantityValue",
              },
            ],
            ref: "quantityInput",
            staticClass: "quantity-input",
            attrs: { type: "text", maxlength: "3", disabled: "" },
            domProps: { value: _vm.quantityValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.quantityValue = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }