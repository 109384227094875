import { mapState } from "vuex";
import mockData from "./scroll-rotator-data";

const MESSAGE_POSITION = { TOP_RIGHT: "TOP_RIGHT", BOTTOM_LEFT: "BOTTOM_LEFT" };

export default {
	name: "scroll-rotator",
	props: {
		cylindoSku: {
			type: String,
			default: "OSLO_SOFA_2-SEATER",
			// default: "OSLO_LOUNGE_CHAIR"
			// default: "IN_SITU_CORNER_CONFIGURATION_9"
			// default: "OUTLINE_SOFA_3_1_2-SEATER_FABRIC"
			// default: "OUTLINE_SOFA_POUF_FABRIC"
		},
		startAngle: {
			type: String,
			default: "270",
		},
		messageList: {
			type: Array,
			default() {
				return mockData;
			},
		},
		extraCylindoOptions: {
			type: Object,
			default() {
				return {
					hej: "lars",
				};
			},
		},
	},
	computed: {
		...mapState({
			windowH: (state) => state.windowH,
			windowY: (state) => state.windowY,
			bodyFixed: (state) => state.bodyFixed,
		}),
		overallStyle() {
			return `--message-count: ${this.messageList.length};`;
		},
		startAngleConverted() {
			let sA = parseInt(this.startAngle, 0);
			if (sA > 360) sA = 360;
			if (sA < 0) sA = 0;
			return sA;
		},
	},
	watch: {
		bodyFixed(state) {
			if (!state) {
				this.update();
			}
		},
		windowY(newVal) {
			if (newVal) {
				this.update();
			}
		},
		windowW() {
			this.update();
		},
		windowH() {
			this.update();
		},
	},
	data() {
		return {
			messageCount: 0,

			country: this.$store.state.country,
			language: this.$store.state.language,
			cylindoAccountId: this.$store.state.cylindoAccountId,

			cylindoInstance: null,
			cylindoInitDone: false,

			activeMessageIndex: 0,
			offsetTop: 0,
			offsetHeight: 0,

			scrollPosition: 0,
			hasScrolledInto: false,
			hasScrolledPast: false,

			rotationIncrement: 0,

			synchronizedRotation: false,
		};
	},
	methods: {
		initCylindo() {
			// COMMENT: Make sure the cylindo framework has been "installed"

			if (window.cylindo) {
				// do not instantiate the viewer until the framework reports ready.
				window.cylindo.on("ready", () => {
					// COMMENT: The opts object should hold the start properties of the 360 HD Viewer
					let cylindoOptions = {
						accountID: this.cylindoAccountId,
						country: this.country,
						language: this.language,
						SKU: this.cylindoSku,
						encoding: "png",
						debug: false,
						containerID: "cylindo-container",
						format: "png",
						thumbs: false,
						fullscreen: false,
						size: "2048",
						progressBar: false,
						zoom: false,
						title: "",
						backgroundColor: "",
						ARDesktop: false,
						ARQuickLook: false,
					};

					if (this.extraCylindoOptions) {
						Object.assign(cylindoOptions, this.extraCylindoOptions);
					}
					if (cylindoOptions.size) cylindoOptions.size = Number(cylindoOptions.size);
					// console.log("cylindoOptions", cylindoOptions)

					// COMMENT: Create the cylindo-instance
					this.cylindoInstance = window.cylindo.viewer.create(cylindoOptions);
					this.cylindoInitDone = true;
				});
			}
		},
		update: function () {
			if (this.bodyFixed) return false;

			this.offsetTop = this.getOffsetTopRecursive(this.$el);
			this.offsetHeight = this.messageList.length * this.windowH;

			let deckTopRelative = this.windowY - this.offsetTop;
			let deckBottomRelative = this.windowY - (this.offsetTop + this.offsetHeight) + this.windowH;

			this.hasScrolledInto = deckTopRelative > 0;
			this.hasScrolledPast = deckBottomRelative > 0;

			this.scrollPosition = deckTopRelative / (this.offsetHeight - this.windowH);

			if (!this.hasScrolledInto && !this.hasScrolledPast && this.cylindoInstance) {
				this.activeMessageIndex = 0;
				this.cylindoInstance.goToAngle(this.startAngleConverted);
			} else if (this.hasScrolledInto && !this.hasScrolledPast) {
				let newIndex = Math.round(this.scrollPosition * (this.messageCount - 1));

				if (this.synchronizedRotation && this.cylindoInstance ) {
					this.cylindoInstance.goToAngle(360 * this.scrollPosition + this.startAngleConverted);
				} else if (newIndex != this.activeMessageIndex && this.cylindoInstance) {
					if (newIndex < this.activeMessageIndex) {
						this.cylindoInstance.rotate(-this.rotationIncrement, 300);
					} else {
						this.cylindoInstance.rotate(this.rotationIncrement, 300);
					}
					this.activeMessageIndex = newIndex;
				}
			}
		},
		findActiveIndex: function (position) {
			let foundIndex = 0;
			this.messageList.forEach((item, index) => {
				if (position >= item.scrollPosition) {
					//not last element
					if (index < this.messageCount - 1) {
						if (position < this.messageList[index + 1].scrollPosition) {
							//found it
							foundIndex = index;
						} else {
							//not it
						}
					} else {
						//at last element
						foundIndex = index;
					}
				}
			});

			return foundIndex;
		},
		getMessagePositionClass: function (item) {
			if (item.position === MESSAGE_POSITION.TOP_RIGHT) return { "scroll-rotator__message--top-right": true };
			else if (item.position === MESSAGE_POSITION.BOTTOM_LEFT) return { "scroll-rotator__message--bottom-left": true };
		},
		getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;

			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;

				current = current.offsetParent;
			}

			return actualOffset;
		}
	},
	mounted() {
		this.rotationIncrement = 360 / (this.messageList.length + 1);
		this.messageCount = this.messageList.length;

		let initInterval = setInterval(
			function () {
				try {
					this.initCylindo();
				} catch (err) {
					//console.log("INIT CYLINDO ERROR", err)
				}
				if (this.cylindoInitDone) {
					this.cylindoInstance.goToAngle(this.startAngleConverted);

					clearInterval(initInterval);
				}
			}.bind(this),
			500
		);
	},
};
