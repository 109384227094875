import Store from '@store/store'

export const LOGICAL_STATE = {
	INCLUDING: 'INCLUDING',
	EXCLUDING: 'EXCLUDING',
	INDIFFERENT: 'INDIFFERENT'
};


export default (control) => {
	const selectedFiltersStore = Store.state.search.selectedFacets

    const selectedFacet = selectedFiltersStore.find(facet => facet.id === control.facet);

	if ( selectedFacet ) {
		selectedFacet.facetValues = selectedFacet.facetValues || [];

		const selectedFilter = selectedFacet.facetValues.find(filter => filter.id === control.id);

		if ( selectedFilter && selectedFilter.value ) return selectedFilter.value;
	}

	return LOGICAL_STATE.INDIFFERENT;
}
