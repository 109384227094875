import store from '@store/store'

window.onpopstate = function(e){
	if ( !e.state ) return false; //JPL: Null check to avoid errors

	store.commit('updateSelectedFacets',e.state.selectedFacets ? e.state.selectedFacets : [])
	store.commit('updateSearchTerm',e.state.searchTerm ? e.state.searchTerm : '')

	store.dispatch(e.state.action)
}
