<script>
import StorySlide from "../story-slide.vue";

export default {
	name: "story-slide-content",
	extends: StorySlide,
	computed: {
		imageSrc1() {
			return this.item && this.item.imageSrc1;
		},
		videoSrc1() {
			return this.item && this.item.videoSrc1;
		},
		imageSrc2() {
			return this.item && this.item.imageSrc2;
		},
		videoSrc2() {
			return this.item && this.item.videoSrc2;
		},

		titlePart1() {
			return this.item && this.item.titlePart1;
		},
		titlePart2() {
			return this.item && this.item.titlePart2;
		},
		titlePart3() {
			return this.item && this.item.titlePart3;
		},

		description() {
			return this.item && this.item.description;
		},
		isDesigner() {
			return this.item && this.item.isDesigner;
		}
	}
};
</script>


<template>
	<div class="story-slide-content" :style="slideStyle" :class="{ 'story-slide-content--designer': isDesigner }">
		<div class="story-slide-content__content">

			<img v-if="imageSrc1" class="story-slide-content__media story-slide-content__media--1" :src="imageSrc1" />
			<video v-else-if="videoSrc1" class="story-slide-content__media story-slide-content__media--1" autoplay
				playsinline muted loop preload="auto" :src="videoSrc1" />

			<img v-if="imageSrc2" class="story-slide-content__media story-slide-content__media--2" :src="imageSrc2" />
			<video v-else-if="videoSrc2" class="story-slide-content__media story-slide-content__media--2" autoplay
				playsinline muted loop preload="auto" :src="videoSrc2" />


			<h2 class="story-slide-content__title">
				<span>{{ titlePart1 }}</span>
				<span v-if="titlePart2">{{ titlePart2 }}</span>
				<span v-if="titlePart3">{{ titlePart3 }}</span>
			</h2>

			<div v-if="description" class="story-slide-content__description">
				<div v-html="description"></div>

				<a v-if="buttonHref && buttonText" :href="buttonHref" class="link link--persistent">
					{{ buttonText }}
				</a>
			</div>

		</div>
	</div>
</template>
