import mockData from "./model-explorer-data"

import Swiper from "swiper/dist/js/swiper.js";


export default {
    name: "model-explorer",
    props: {
        titleText: {
            type: String,
            default: "The Oslo Family",
        },
        bodyText: {
            type: String,
            default: "The oslo family has a broad range of members. It comes in 3-seater sofa, 2-seater sofa, chair, pouf & bench",
        },
        plannerLinkHref: {
            type: String,
            default: "#planner",
        },
        plannerLinkText: {
            type: String,
            default: "Plan your own",
        },
        plannerLinkTarget: {
            type: String,
            default: "_self",
        },
        readMoreText: {
            type: String,
            default: "More details",
        },
        modelList: {
            type: Array,
            default() {
                return mockData;
            }
        }
    },
    data() {
        return {            
            activeModelIndex: 0,
            activeModel: this.modelList[0],
            
            slider: null,
			slidesCount: 0,
			slidesRef: null,
			
			settings: {
				simulateTouch: true,
				allowTouchMove: true,

                grabCursor: true,
                navigation: {
					
				},
				slidesPerView: 3.85,
                spaceBetween: 20,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				preloadImages: false,
                breakpoints: {
                    767: {
                        slidesPerView: 1.5,
                        spaceBetween: 15
                    },
                    1023: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    1439: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                },
                on: {
                    slideChange: this.slideChangeHandler.bind(this)
                }   
			},
        }
    },
    methods: {
        slideChangeHandler: function() {
            if ( this.slider.activeIndex < this.modelList.length ) {
                this.activeModel = this.modelList[this.slider.activeIndex];
            } 
        },
        slideClickHandler: function(index) {
            this.slider.slideTo(index);
        }
    },
    mounted() {
        
        this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");
        this.slidesCount = this.slidesRef.length;
        
        if (this.slidesCount > 1) {
            Object.assign(this.settings, {
                navigation: {
                    nextEl: this.$refs["nextButton"],
					prevEl: this.$refs["prevButton"],
				}
			});
        }
        
        this.slider = new Swiper(this.$refs.slider, this.settings);
    }
}