import Swiper from "swiper/dist/js/swiper.js";

export default {
    name: "tabs-bar",
    props: {
        items: {
            type: Array,
            default() {
                return [{
                    label:"Lorem"
                }, {
                    label:"Ipsum"
                }]
            }
        },        
        tabClickHandler: {
            type: Function,
            default() {
                return (item,index)=>console.log("click",item,index);
            }
        },
        activeIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            slider: null,
            settings: {
                slidesPerView: "auto",
                spaceBetween: 0,
                freeMode: true,
            }
        }
    },
    mounted() {
        if ( this.$refs.slider ) this.slider = new Swiper(this.$refs.slider, this.settings);
    }
} 
