
export default{
    PushSearchEvent( eventName, query, skip, take){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            searchTerm: query,
            skip: skip,
            take: take
        });
    },
    PushEvent( eventName, category, action, label){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            eventData: {
              category: category,
              action: action,
              label: label   
            }                      
        });
    }
}