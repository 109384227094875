export default {
    name: 'file-upload-field',
    data(){
        return {
            upload: "",
        }
    },
    computed: {
        fieldValue(){
            if (this.upload == "") {
                return this.uploadDefaultLabel
            } else {
                return this.upload.split(/[\\\/]/).pop() //regex that finds and removes "/" and "\" from string 
            }
        },
    },
    methods:{
        addFile(e){
            this.upload = e.target.value
            this.$attrs.incrementfilecount()
        },
        removeFile(){
            this.$attrs.decrementfilecount(this.upload)
            this.upload = ""
        }
    }
}

