// import TweenLite from 'gsap/umd/TweenLite';
import AccordionFlowItem from './accordion-flow-item';

export default {
	name: 'accordion-flow',
	components: {
		AccordionFlowItem,
	},
	methods: {
	},
	data() {
		return {
			isMounted: false,

			index: 0,

			accordions: [
				{
					id: 1001,
					title: "Step 1",
					content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
					stepIsValid: false
				},
				{
					id: 1002,
					title: "Step 2",
					content: "Perspiciatis ipsa officia, fugiat modi necessitatibus adipisci sed, veniam accusantium nemo deleniti corporis!",
					stepIsValid: false
				},
				{
					id: 1003,
					title: "Step 3",
					content: "Debitis quia animi, ea fugiat delectus porro sunt omnis!",
					stepIsValid: false
				},
				{
					id: 1004,
					title: "Step 4",
					content: "Modi necessitatibus adipisci sed, veniam accusantium nemo deleniti.",
					stepIsValid: false
				}
			],
		};
	},
	watch: {
		index: function () {
			this.$refs.accordion[this.index].toggle();
		},
	},
	mounted() {
		this.isMounted = true;

		// Toggle selected one
		this.$refs.accordion[this.index].toggle();

		// Click from accordion-flow-item
		this.$on('nextAccordion', () => {
			// If not last step is requested
			if ((this.index + 1) != this.accordions.length) {
				// Temp: validate step here
				console.log('accordion-flow.js - set step to validated');
				this.accordions[this.index].stepIsValid = true;

				if (this.accordions[this.index].stepIsValid === true) {
					this.index++;
				}
			} else {
				console.log('accordion-flow.js - This is the last step');
			}
		});

		// Click from accordion-flow-item
		this.$on('goToStep', (id) => {
			let goToAccordion = this.accordions.map(function(e) { return e.id; }).indexOf(id);

			if (this.accordions[goToAccordion].stepIsValid === true) {
				this.index = goToAccordion;
			}
		});

		// Close already open accordions when a new one is being toggled from accordion.js
		this.$on('toggleAccordion', () => {
			this.$refs['accordion'].forEach(function (accordion) {
				if (accordion.isOpen) {
					accordion.close();
				}
			});
		});
	},
};
