/* eslint-disable no-undef */
import { mapActions, mapState } from 'vuex'

const STORE_ID_NAME = 'company_number'

export default {
	name: 'store-locator-list-item',
	props: {
		item: null
	},
	data () {
		return {
			ready: false
		}
	},
	computed: {
		...mapState({
			selectedStore: state => state.storeLocator.selectedStore,
			hoveredStore: state => state.storeLocator.hoveredStore,
			copy: state => state.storeLocator.copy
		}),
		itemData () {
			let d = {}
			d.title = this.item['company_name']
			d.city = this.item['city']
			let t = []
			if (this.item['furniture']) t.push('Furniture')
			if (this.item['accessories']) t.push('Accessories')
			if (this.item['lighting']) t.push('Lighting')
			d.tagsString = t.join(', ')

			let ph = this.item['phone'] ? this.item['phone'] : ''

			d.muutoExperience = this.item['muuto_experience'] === '1'

			d.address = `${this.item['street']}<br>${this.item['postcode']} ${this.item['city']}<br/>${ph}`
			d.links = [
				{
					href: this.item['website'],
					title: this.copy.goToStore,
					trackingAction: this.item['company_name'],
					trackingLabel: this.copy.goToStore,
					target: '_blank',
				},
				{
					href: this.item['directions'],
					title: this.copy.directions,
					trackingAction: this.item['company_name'],
					trackingLabel: this.copy.directions,
					target: '_blank',
				}
			]
			return d
		},
		isActive () {
			if (!this.selectedStore.data) return false

			let active =
				this.selectedStore.data[STORE_ID_NAME] ===
				this.item[STORE_ID_NAME]
			if (active) {
				this.$parent.$emit('scroll-to', this)
			}
			return active
		},
		isHighlighted () {
			if (!this.hoveredStore.data) return false

			let highlighted =
				this.hoveredStore.data[STORE_ID_NAME] ===
				this.item[STORE_ID_NAME]
			return highlighted
		},
	},
	mounted () {},
	methods: {
		...mapActions(['setBypassFetch', 'selectStore']),
		listItemClick() {
			window.dataLayer = window.dataLayer || [];
			this.setBypassFetch(true)
			this.selectStore({ data: this.item })
			window.dataLayer.push({
				"event": "trackStorelocator",
				"eventData": {
					"category": "Store locator",
					"action": "Store click",
					"label": this.item['company_name']
				}
			});
		},
		linkClick(e) {
			const { currentTarget } = e;
			const action = currentTarget.dataset.trackingAction;
			const label = currentTarget.dataset.trackingLabel;
			window.dataLayer.push({
				"event": "trackStorelocator",
				"eventData": {
					"category": "Store locator",
					"action": action,
					"label": label
				}
			});
		}
	}
}
