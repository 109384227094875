import Swiper from 'swiper/dist/js/swiper.js';
import CylindoOverlay from '../../components/cylindo-overlay/cylindo-overlay';
import { mapState } from 'vuex';

export default {
	name: 'product-visualization',
	components: {
		CylindoOverlay,
	},
	props: {
		slideCount: 0
	},
	data() {
		return {
			showNavigation: true,
			overlaySlider: '',
			cylindoInstance: null,
		};
	},
	computed: {
		...mapState([
			'isMobile',
		]),
	},
	mounted(){
		const vm = this;
		const productName = vm.$el.getAttribute('data-tracking-title') || 'Unspecified product name';

		let settings = {
			loop: true,
			slidesPerView: 1,
			navigation: {
				nextEl: '.product-swiper-button-next',
				prevEl: '.product-swiper-button-prev',
			},
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
			on: {
				reachEnd: function(){
					// COMMENT: When reaching the last image, open the cylindo-overlay (only if there is more than one product image)
					// "realIndex" is still 1 less than the actual last slide
					if(vm.isMobile && this.slides.length > 1){
						var targetSrc = this.slides[this.realIndex + 1].querySelector('.product-visualization__image').getAttribute('src');
						vm.$refs['product-visualization-cylindo-overlay'].showOverlay(targetSrc);
					}
				},
				click: function(e){
					const { target } = e;
					if (
						!target.classList.contains('product-swiper-button-next') &&
						!target.classList.contains('product-swiper-button-prev')
					) {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							"event": "trackPDP",
							"eventData": {
								"category": "Product detail page",
								"action": "Picture click",
								"label": productName
							}
						});
					}
					if (target.classList.contains('product-visualization__image')) {
						vm.$refs['product-visualization-cylindo-overlay'].showOverlay(target.getAttribute('src'));
					}
				},
				lazyImageReady: function(){
					//this.slider.update();
				},
				slideChange: function(){
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						"event": "trackPDP",
						"eventData": {
							"category": "Product detail page",
							"action": "Picture slider click",
							"label": this.activeIndex + 1
						}
					});
				},
			},
		};

		if (this.slideCount < 2) {
			this.showNavigation = false;

			Object.assign(settings, {
				slidesPerView: 1,
				navigation: {},
				loop: false
			});
		}
		else {
			settings.pagination = {
				el: this.$refs['product-slider-pagination'],
				clickable: true
			}
		}

		this.slider = new Swiper(this.$refs['product-slider-container'], settings);
	}
};
