
import { SHARE_SAVED_LIST_ASYNC } from '../../basics/js/store/static';
import { mapActions } from 'vuex';
import validations from './cart-share-validation';

export default {
	name: 'cart-share',
	props: {
		shareSavedListUrl: {
			type: String,
		},
		shareSavedListTitle: {
			type: String,
		},
		shareSavedListMessage: {
			type: String,
		},
	},
	data() {
		return {
			isSharingSavedList: false,

			form: {
				[validations.validationFields.email]: this.email,
				[validations.validationFields.comment]: this.comment,
			},
		};	
	},
	computed: {
		getCartShareCartIsActive: function() {
			if (this.$store.state.cartShareCartIsActive) {
				return this.$store.state.cartShareCartIsActive
			}
		}
	},
	mounted(){
	},
	validations,
	methods: {
		...mapActions([
			SHARE_SAVED_LIST_ASYNC.action,
		]),		
		shareSavedList() {
			if (this.isSharingSavedList) return;
			if (!this.isValid()) return;
		
			this.isSharingSavedList = true;
			var form = this.$v.form;
			const url = this.shareSavedListUrl;
			const body = {
					"email": form.UserInput_ShareSavedList_Email.$model,
					"comment": form.UserInput_ShareSavedList_Comment.$model
			};
			
			this[SHARE_SAVED_LIST_ASYNC.action]({ url, body, notification: { 
				type: 'info',
				showClose: false, 
				title: this.shareSavedListTitle, 
				message: this.shareSavedListMessage } });
			
			this.closeModal();
			this.isSharingSavedList = false;
		},
		closeModal() {
			this.$refs.shareSavedListModal.close();
		},
		isValid() {
			this.$v.form.$touch()
			if (this.$v.form.$error) {
				console.warn('form error', this.$v);
			}
			return !this.$v.form.$error;
		},
	},
};
