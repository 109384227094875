<script>
import { mapState, mapActions } from "vuex";

import CylindoViewer from "../cylindo-viewer/cylindo-viewer.vue";
import { registerPictureClick } from '../../../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer'
import UspSpot from "../../../usp-spot/usp-spot"

export default {
	name: "presentation-tile",
	components: {
		CylindoViewer,
		UspSpot
	},
	props: {

		isDouble: {
			type: Boolean,
			default: false,
		},
		isPackshot: {
			type: Boolean,
			default: false,
		},
		isCylindo: {
			type: Boolean,
			default: false,
		},
		isContent: {
			type: Boolean,
			default: false,
		},
		isInteractive: {
			type: Boolean,
			default: true
		},

		cylindoSku: {
			type: String,
			required: false
		},
		cylindoFeatures: {
			type: Object,
			required: false
		},
		name: {
			type: String,
			required: false
		},
		description: {
			type: String,
			required: false
		},
		anchor: {
			type: String,
			required: false
		},
		textColor: {
			type: String,
			required: false
		},
		backgroundColor: {
			type: String,
			required: false
		},
		cylindoTexts: {
			type: Object,
			required: false
		},
		hasARCallback: {
			type: Function,
			default: null
		},
		galleryId: {
			type: Number,
		},
		startFrame: {
			type: Number,
			default: 4,
		},
		media: {
            type: Object,
            required: false
        },
        productName: {
            default: false
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
        getSlideStyle: function() {
            let style = '';
            if ( this.backgroundColor ) {
				style += `background-color: ${this.backgroundColor}; --presentation-tile-bg-color: ${this.backgroundColor};`;

			}
			if (this.textColor) {
				style += `color: ${this.textColor}; --presentation-tile-text-color: ${this.textColor}`;
			}
            return style;
        }
    },
    data() {
        return {
            imageLoadSuccess: false,
            imageLoadError: false,
            imageLrgLoadSuccess: false,
            imageLrgLoadError: false,
        }
    },
    methods: {
        ...mapActions([
            "openZoomOverlay",
            "showOverlayBackdrop",
            "openMosaicSliderOverlay",
        ]),
        imageClickHandler() {
            registerPictureClick(this.productName)
            if ( this.isMobile && this.media && this.media.type === 'image') {
                this.openZoomOverlay(this.media);
                this.showOverlayBackdrop();
            } else if (!isNaN(this.galleryId)) {
                this.openMosaicSliderOverlay(this.galleryId);
            }
        },
        imageLoadHandler(largeSrc) {
            if ( largeSrc ) this.imageLrgLoadSuccess = true;
            else this.imageLoadSuccess = true;
        },
        imageErrorHandler(largeSrc) {
            if ( largeSrc ) this.imageLrgLoadError = true;
            else this.imageLoadError = true;
        },
    }
};

</script>

<template>
	<li class="presentation-tile"
		:class="{ 'is-double': isDouble, 'is-packshot': isPackshot, 'is-cylindo': isCylindo, 'is-content': isContent, 'is-disabled': !isInteractive }"
		:id="anchor">
		<div class="presentation-tile__inner" :style="getSlideStyle" v-if="isCylindo">
			<cylindo-viewer :cylindo-sku="cylindoSku" :cylindo-features="cylindoFeatures" :texts="cylindoTexts"
				:use-simple-guide="true" :no-message="true" :no-zoom="true" :start-frame="startFrame"
				:has-a-r-callback="hasARCallback">
			</cylindo-viewer>
		</div>

		<button v-else-if="!isContent" class="presentation-tile__inner" :style="getSlideStyle"
			v-on:click="imageClickHandler">
			<figure class="presentation-tile__media" v-if="media && media.type === 'image' && media.src" :class="{ 'is-dynamic': media.srcLrg, 'has-error': imageLoadError || imageLrgLoadError }
			">
				<img :src="media.src" v-on:load="() => imageLoadHandler()" v-on:error="() => imageErrorHandler()"
					v-show="imageLoadSuccess && !imageLoadError" />
				<img v-if="media.srcLrg" :src="media.srcLrg" v-on:load="() => imageLoadHandler(true)"
					v-on:error="() => imageErrorHandler(true)" v-show="imageLrgLoadSuccess && !imageLrgLoadError" />

				<p v-if="imageLoadError || imageLrgLoadError">⚠</p>
			</figure>
			<figure class="presentation-tile__media" v-else-if="media && media.type === 'video' && media.src">
				<video :src="media.src" muted autoplay loop playsinline></video>
			</figure>
		</button>

		<div v-else class="presentation-tile__inner">
			<usp-spot :name="name" :description="description" :media="media" :text-color="textColor"
				:background-color="backgroundColor" inline-template>
				<div class="usp-spot usp-spot--image-icon">
					<div class="usp-spot__inner" :style="getColorStyle">
						<figure class="usp-spot__media" v-if="media">
							<img v-if="media.type === 'image'" :src="media.src" :alt="media.alt" :srcset="media.srcset" :sizes="media.sizes"/>
							<video v-if="media.type === 'video'" :src="media.src" muted autoplay loop playsinline></video>
						</figure>
						<h3 class="usp-spot__name">{{ name }}</h3>
						<p class="usp-spot__description">{{ description }}</p>
					</div>
				</div>
			</usp-spot>
		</div>
	</li>
</template>
