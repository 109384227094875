import camelCase from "lodash/camelCase";
/**
 *
 * @param {String} type - type of async mutation
 * SUCCESS - mutation key
 * FAILOR - mutation key
 * PENDING - mutation key
 * action - action key
 * loadingKey - key in the store object for pending state
 * stateKey - key in the store object for returned data
 */
const createAsyncMutation = (type) => ({
	SUCCESS: `${type}_SUCCESS`,
	FAILURE: `${type}_FAILURE`,
	PENDING: `${type}_PENDING`,
	action: `${type}_ACTION`,
	loadingKey: camelCase(`${type}_PENDING`),
	stateKey: camelCase(`${type}_DATA`),
});

const createMutation = (type) => ({
	action: camelCase(`${type}_ACTION`),
	mutation: camelCase(`${type}_MUTATION`),
	stateKey: camelCase(type),
});

// General
export const GET_RECOMMENDATION = createAsyncMutation("RECOMMENDATION");


// PLP
// filter
export const GET_PRODUCTS = createAsyncMutation("PRODUCTS");
export const SET_FILTER_COLOR = createMutation("FILTER_CHECKBOX_COLOR");

// PDP
export const GET_OPTION_GROUP_ASYNC = createAsyncMutation("PDP_OPTION_GROUP");
export const CART_ITEM_ADD_ENDPOINT_ASYNC = createAsyncMutation(
	"PDP_CART_ITEM_ADD_ENDPOINT"
);

// Used to open / close the cylyndo overlay on PDP
export const CYLINDO_OVERLAY = createMutation("CYLINDO_OVERLAY");

// CONFIGURATOR
export const CONFIGURATOR_OPTIONS_ASYNC = createAsyncMutation(
	"CONFIGURATOR_OPTIONS"
);
export const CONFIGURATOR_INITIAL_OPTIONS_ASYNC = createAsyncMutation(
	"CONFIGURATOR_INITIAL_OPTIONS"
);
export const CONFIGURATOR_CYLINDO_SKU_ASYNC = createAsyncMutation(
	"CONFIGURATOR_CYLINDO_SKU"
);
export const CONFIGURATOR_REQUEST_CONFIGURATION_ASYNC = createAsyncMutation(
	"CONFIGURATOR_REQUEST_CONFIGURATION"
);

// Order overview
export const EDIT_ORDER_ASYNC = createAsyncMutation("ORDER_EDIT_ORDER");

// Checkout
export const POST_CAMPAIGN_CODE_ASYNC = createAsyncMutation("CAMPAIGN_CODE");

// CART
export const GET_CART_ITEMS = createAsyncMutation("CART_ITEMS");
export const UPDATE_QUANTITY_ASYNC = createAsyncMutation(
	"CART_UPDATE_QUANTITY"
);
export const REQUEST_QUOTE_ASYNC = createAsyncMutation("CART_REQUEST_QUOTE");
export const EMPTY_CART_ASYNC = createAsyncMutation("EMPTY_CART_ASYNC");
export const IMPORT_CART_ASYNC = createAsyncMutation("IMPORT_CART_ASYNC");
export const GET_CART_QUANTITY = createAsyncMutation("CART_QUANTITY");
export const SHARE_SAVED_LIST_ASYNC = createAsyncMutation(
	"SHARE_SAVED_LIST_ASYNC"
);
export const IMPORT_SAVED_LIST_ASYNC = createAsyncMutation(
	"IMPORT_SAVED_LIST_ASYNC"
);

// Store Locator
export const FETCH_STORES = createAsyncMutation("FETCH_STORES");


// Country + user type
export const SELECT_COUNTRY = createAsyncMutation("SELECT_COUNTRY");
export const SELECT_USER_TYPE = createAsyncMutation("SELECT_USER_TYPE");

// Shop image
export const REQUEST_OVERLAY_PRODUCTS = createAsyncMutation("REQUEST_OVERLAY_PRODUCTS");

// BOOL enums
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SHOWN = "SHOWN";
export const HIDDEN = "HIDDEN";

// status
export const STAUS_IDE = "STAUS_IDLE";
export const STAUS_PENDING = "STAUS_PENDING";
export const STAUS_SUCCESS = "STAUS_SUCESS";
export const STAUS_ERROR = "STAUS_ERROR";

// keys
export const ENTER = "Enter";



// CONFIGURATOR V2


export const RECONFIGURATOR_GETSTEP_ASYNC = createAsyncMutation(
	"RECONFIGURATOR_GETSTEP_ASYNC"
);

export const RECONFIGURATOR_GETVARIANT_ASYNC = createAsyncMutation(
	"RECONFIGURATOR_GETVARIANT_ASYNC"
);