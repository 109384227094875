import ProductVisualization from '../product-visualization/product-visualization';
import VariationDropdown from '../variation-dropdown/variation-dropdown';
import BuySection from '../../components/buy-section/buy-section';
import AccordionGroup from '../../components/accordion/accordion-group';
import Slider from '../../components/slider/slider';
import AddonsOverlay from '../../components/addons-overlay/addons-overlay';
import copyTextMixin from '../../components/vue-mixins/copyText';
import recommendation from '../../components/recommendation/recommendation';
import configurator from '../../components/configurator/configurator';
import { registerProductDetails } from '../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer'

import { mapActions, mapState } from 'vuex';

export default {
	name: 'pdp',
	props: {
		variantCode: {
			type: String,
			required: true,
		},
		formattedPrice: {
			type: String,
			required: true,
		},
		productName: {
			type: String,
			required: true,
		},
		variantName: {
			type: String,
			required: true,
		},
	},
	components: {
		ProductVisualization,
		VariationDropdown,
		BuySection,
		AccordionGroup,
		Slider,
		AddonsOverlay,
		recommendation,
		configurator
	},
	data() {
		return {
			sku: this.variantCode,
			price: this.formattedPrice,
			skuCopied: false,
			productName: this.productName,
			variantName: this.variantName
		};
	},	
	computed: {
		...mapState({
			showPDPSpinner: state => state.configurator.showPDPSpinner,
		})
	},
	watch: {
		showPDPSpinner: function(newVal) {
			console.log("showPDPSpinner?",newVal);
		}
	},
	mounted(){
		if ( !this.$refs['pdp-inspiration-slider'] ) return false;

		this.$refs["pdp-inspiration-slider"].$el.classList.add('slider--with-zoom')

		this.$refs['pdp-inspiration-slider'].slider.on('click', function (e) {
			if (e.target.tagName.toLowerCase() == 'img') {
				this.$refs['product-visualization-slider'].$refs['product-visualization-cylindo-overlay'].showOverlay(e.target.getAttribute('src'));
			}
		}.bind(this));

		//Auto open configurator if "configure=true"
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('configure')) this.openConfigurator();

		// TODO RRU, get missing data
		registerProductDetails(this.productName, this.variantName, this.sku, this.price)
	},
	methods: {
		...mapActions([
			'openAddonsOverlay',
			'openConfigurator',
		]),
		updateReceiverMethodProductTile() {},
		onQuantityUpdate() {},
		readMoreClick(event) {
			let targetElement = event.target.dataset ? document.querySelector(event.target.dataset.target) : null;
			console.log('event.target.dataset',event.target.dataset)
			if (targetElement instanceof HTMLElement) {
				targetElement.scrollIntoView({behavior:'smooth',block:'start'});
				let accordionTrigger = targetElement.querySelector('.accordion__trigger');
				try {
					accordionTrigger.click();
				}
				catch(error) {
					//CAN'T TRIGGER
				}
			}
		},
		copyText(event, attr) {
			this.skuCopied = true
			setTimeout(()=> this.skuCopied = false, 2000);

			copyTextMixin.methods.copyText(event,attr);
		}

	},
};
