/* eslint-disable */
export default [
	{
	  "featureType": "administrative",
	  "elementType": "geometry",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "administrative.country",
	  "elementType": "geometry.stroke",
	  "stylers": [
		{
		  "color": "#596f75"
		},
		{
		  "visibility": "on"
		},
		{
		  "weight": 2
		}
	  ]
	},
	{
	  "featureType": "administrative.land_parcel",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "administrative.neighborhood",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "landscape.natural",
	  "stylers": [
		{
		  "color": "#798f95"
		}
	  ]
	},
	{
	  "featureType": "landscape.natural",
	  "elementType": "geometry.fill",
	  "stylers": [
		{
		  "color": "#798f95"
		}
	  ]
	},
	{
	  "featureType": "landscape.man_made",
	  "elementType": "geometry.fill",
	  "stylers": [
		{
		  "color": "#798f95"
		}
	  ]
	},
	{
	  "featureType": "landscape.natural.landcover",
	  "elementType": "geometry.fill",
	  "stylers": [
		{
		  "color": "#798f95"
		}
	  ]
	},
	{
	  "featureType": "landscape.natural.terrain",
	  "elementType": "geometry.fill",
	  "stylers": [
		{
		  "color": "#798f95"
		}
	  ]
	},
	{
	  "featureType": "poi",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "road",
	  "stylers": [
		{
		  "color": "#87a0a6"
		}
	  ]
	},
	{
	  "featureType": "road",
	  "elementType": "labels",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "road",
	  "elementType": "labels.icon",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "road.highway",
	  "stylers": [
		{
		  "color": "#87a0a6"
		}
	  ]
	},
	{
	  "featureType": "road.highway",
	  "elementType": "geometry.fill",
	  "stylers": [
		{
		  "color": "#87a0a6"
		}
	  ]
	},
	{
	  "featureType": "road.highway",
	  "elementType": "geometry.stroke",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "transit",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	},
	{
	  "featureType": "water",
	  "elementType": "geometry.fill",
	  "stylers": [
		{
		  "color": "#bcc9cc"
		}
	  ]
	},
	{
	  "featureType": "water",
	  "elementType": "labels.text",
	  "stylers": [
		{
		  "visibility": "off"
		}
	  ]
	}
];
/* eslint-enable */
