import Store from '@store/store'

if(location.search){
    
    const paramsListUnsorted = location.search.slice(1).split('&').map(pair => ({facet:pair.split('=')[0],value:pair.split('=')[1]}))

    const selectedFacets = paramsListUnsorted.reduce((selectedFacets,urlFacet) => {
    
        let existingSelectedFacet = selectedFacets.find(selectedFacet => selectedFacet.facet === urlFacet.facet)
    
        if(existingSelectedFacet){
            existingSelectedFacet.values.push({value:urlFacet.value})
        } else {
            selectedFacets.push({
                facet: urlFacet.facet,
                values: [{value:urlFacet.value}]
            })
        }
    
        return selectedFacets
    
    },[])
    
    Store.commit('updateSelectedFacets',selectedFacets)
}

