import { render, staticRenderFns } from "./presentation-tile.vue?vue&type=template&id=45944d46&"
import script from "./presentation-tile.vue?vue&type=script&lang=js&"
export * from "./presentation-tile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Code\\dept_muuto_forntend\\master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45944d46')) {
      api.createRecord('45944d46', component.options)
    } else {
      api.reload('45944d46', component.options)
    }
    module.hot.accept("./presentation-tile.vue?vue&type=template&id=45944d46&", function () {
      api.rerender('45944d46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reconfigurator/partials/presentation-tile/presentation-tile.vue"
export default component.exports