<script>

import { mapState } from 'vuex';

export default {
	name: 'mosaic-slider-zoom',
	props: {
		zoomSrc: {
			type: String,
			default: ''
		},
		useCustomCursor: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			imageLoaded: false,

			zoomFactor: 2,

			containerCenterX: null,
			containerCenterY: null,
			imageX: 0,
			imageY: 0,

			imageWidth: 0,
			imageHeight: 0,

			imageContainerWidth: 0,
			imageContainerHeight: 0,

			image: null,
			imageContainer: null,

			noMouse: false,

			cursorX: 0,
			cursorY: 0,
		}
	},
	computed: {
		...mapState({
			windowW: state => state.windowW,
			windowH: state => state.windowH,
		}),
		imageStyle() {
			return `transform: translate(${this.imageX}px, ${this.imageY}px) scale(${this.zoomFactor})`;
		},
		zoomStyle() {
			return `--mosaic-slider-zoom-cursor-x: ${this.cursorX}px; --mosaic-slider-zoom-cursor-y: ${this.cursorY}px;`;
		}
	},
	watch: {
		windowW(newVal) {
			this.resizeHandler();
		},
		windowH() {
			this.resizeHandler();
		}
	},
	methods: {
		resizeHandler() {
			if (this.imageContainer) {
				this.containerWidth = this.imageContainer.offsetWidth;
				this.containerHeight = this.imageContainer.offsetHeight;
			}

			this.zoomFactor = 2;
			if (this.windowW > 1600) {
				this.zoomFactor = 4;
			} else if (this.windowW > 900) {
				this.zoomFactor = 3;
			}
		},
		isTouchDevice() {
			var el = document.createElement('div');
			el.setAttribute('ontouchstart', 'return;'); // or try "ontouchstart"
			return typeof el.ontouchstart === "function";
		},
		mouseMoveHandler(e) {
			if (this.imageLoaded && !this.noMouse) {

				//get initial mouse position
				this.containerCenterX = this.containerWidth / 2;
				this.containerCenterY = this.containerHeight / 2;

				// calculate new image position where
				// moving to x 0% aligns image with left edge of container
				// and moving to x 100% aligns image with right edge of container etc

				const xPos = (e.clientX - this.containerCenterX) / this.containerWidth;
				const yPos = (e.clientY - this.containerCenterY) / this.containerHeight;

				// calculate new image position with an image scale
				this.imageX = -xPos * (this.imageWidth * this.zoomFactor) / 2;
				this.imageY = -yPos * (this.imageHeight * this.zoomFactor) / 2;


				this.cursorX = e.clientX;
				this.cursorY = e.clientY;
			}
		}
	},
	mounted() {
		this.image = this.$refs['image'];
		this.imageContainer = this.$refs['container'];

		this.resizeHandler();

		this.image.addEventListener('load', () => {
			this.imageWidth = this.image.naturalWidth;
			this.imageHeight = this.image.naturalHeight;

			this.imageLoaded = true;
		});

		this.noMouse = this.isTouchDevice();
	},
}
</script>

<template>
	<div class="mosaic-slider-zoom" v-on:click="$emit('close')" :style="zoomStyle" :class="{'mosaic-slider-zoom--custom-cursor': useCustomCursor}">
		<div class="mosaic-slider-zoom__image" ref="container" v-on:mousemove="mouseMoveHandler">
			<img :src="zoomSrc" ref="image" :style="imageStyle" />
			<span v-if="noMouse">Panning only works with a mouse!<br />Tap to close.</span>
		</div>
		<div v-if="useCustomCursor" class="mosaic-slider-zoom__cursor" ref="cursor"></div>
	</div>
</template>
