import { mapActions } from "vuex";
import { SELECT_USER_TYPE } from "../../basics/js/store/static";

export default {
	name: "user-type-selector",
	props: {
		epiBlockId: {
			type: String,
			required: true
		},
		epiParrentPageId: {
			type: String,
			required: true
		},
		headline: {
			type: String,
			default: "new perspectives on scandinavian design"
		},
		bodyText: {
			type: String,
			default: "We have tailormade experiences to create the best possible brand experience, a strong professional's toolbox for architechts and designers, as well as a dedicated platform for partners."
		},
	},
	methods: {
		...mapActions([
			SELECT_USER_TYPE.action,
		]),
		userTypeChosenHandler( userTypeId ) {
			this[SELECT_USER_TYPE.action]({userTypeId: userTypeId, blockId: this.epiBlockId, path: window.location.pathname, epiParrentPageId: this.epiParrentPageId});
		}
	}
}
