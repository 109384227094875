import Accordion from '../../components/accordion/accordion'
import InputWithLabel from '../../components/form-elements/templates/input-with-label';
import CustomDatepicker from '../../components/date-picker/date-picker';

export default {
	name: 'checkout-block',
	props: {
		$v: {
			type: Object,
			default: null,
			required: false
		},
		form: {
			type: Object
		},
		addressIsUnknown: Boolean
	},
	components: {
		Accordion,
		InputWithLabel,
		CustomDatepicker,
	},
	data() {
		return {
			isActive: true,
			referenceId: "",
			useKnownAddress: true
		};
	},
	computed:{
		dispatchDate(){
			return this.$store.state.cart.orderDispatchDate
		},
		showSpinner(){
			return this.$store.state.cart.showOrderSummarySpinner
		}
	},
	mounted() {
		this.isMounted = true;
		this.useKnownAddress = this.addressIsUnknown ? false : true
	},
	methods: {
		changeAddressInput(knownModel) {
			this.useKnownAddress = !this.useKnownAddress
			knownModel.model = ""
		},
		forceKnownAddress(knownModel){
			this.useKnownAddress = true
			knownModel.invalid = false

		},
		activateForm() {
			this.isActive = true;
		},
		accordionToggle() {
			this.isActive = !this.isActive;
		},
		onChange(name) {
			console.log('change', name)
		},
		addReferenceId(){
			const input = this.form['inputData.OwnReference'].model
			this.$store.dispatch("addReferenceId",input)
		}
	}
};
