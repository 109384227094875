import { mapState } from "vuex";
import Swiper from "swiper/dist/js/swiper.js";
import TabsBar from "../tabs-bar/tabs-bar";
import mockData from "./property-explorer-data";


export default {
	name: "property-explorer",
    components: {
        TabsBar
    },
    props: {
        titleNumber: {
            type: String, 
            default: '2.'
        },
        titleText: {
            type: String, 
            default: 'Comfort and seating style'
        },
        bodyText: {
            type: String,
            default: "The oslo family has a broad range of members. It comes in 3-seater sofa, 2-seater sofa, chair, pouf & bench",
        },
        propertyExplorerData: {
            type: Array,
            default() {
                return mockData;
            }
        }
    },
    computed: {
        ...mapState({
            windowW: (state) => state.windowW,
        }),
        getPropertyList: function() {  
            let list = [];
            list = this.propertyExplorerData.map(item=>{ return {label: item.category}; });
            return list;
        },
    },
    watch: {
		windowW: function () {
            this.resizeListener();
        }
    },
    data() {
        return {            
            
            activeCategory: this.propertyExplorerData[0],
            activeCategoryModelCount: 0,
            activeCategoryIndex: 0,
            activeModelIndex: 0,
            
            activeRangePosition: 0,

            slider: null,
			slidesRef: null,
            
			
			settings: {
                simulateTouch: true,
				allowTouchMove: false,
                
                effect: "fade",
                fadeEffect: {
                    crossFade: true
                },

				slidesPerView: 1,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				preloadImages: false,
				watchOverflow: true
			},
        }
    },
    methods: {
        propertyChangeHandler: function(item,index) {
            this.activeCategoryIndex = index;
            this.activeCategory = this.propertyExplorerData[this.activeCategoryIndex];
            this.activeCategoryModelCount = this.activeCategory.models.length;
            this.activeModelIndex = Math.round(this.activeCategoryModelCount/2)-1;
            
            
            setTimeout(function() {
                this.slider.update();
                this.slider.slideTo(this.activeModelIndex);
                this.activeRangePosition = Math.round((this.activeCategoryModelCount * 100) / 2);
            }.bind(this),10);
        },
        rangeChangeHandler: function(event) {
            this.activeModelIndex = Math.ceil(event.target.value / 100)-1;
            
            this.slider.slideTo(this.activeModelIndex);
        },
        resizeListener: function () {         
            this.slider.update();
            this.slider.slideTo(this.activeModelIndex);
		},
    },
    mounted() {
        this.activeCategoryModelCount = this.activeCategory.models.length;
        this.activeModelIndex = Math.round((this.activeCategoryModelCount)/2) - 1;
        this.activeRangePosition = Math.round((this.activeCategoryModelCount * 100) / 2);
        
		this.slider = new Swiper(this.$refs.slider, this.settings);
		this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");
        
        setTimeout(function() {
            this.activeRangePosition = Math.round((this.activeCategoryModelCount * 100) / 2);
            this.resizeListener();
        }.bind(this),500);
    }
}