
import ProductLineCart from '../../components/product-line/product-line-cart';
import CartCore from './cart-core';
import TextToInput from '../../components/text-to-input/text-to-input';
import { REQUEST_QUOTE_ASYNC, EMPTY_CART_ASYNC, GET_CART_ITEMS, IMPORT_CART_ASYNC } from '../../basics/js/store/static';
import { mapActions } from 'vuex';
import CartShare from './cart-share';

export default {
	name: 'cart',
	extends: CartCore,
	components: {
		ProductLineCart,
		TextToInput,
		CartShare,
	},
	props: {
		requestQuoteUrl: {
			type: String,
			required: false
		},
		requestQuoteNotifyTitle: {
			type: String,
			required: false
		},
		requestQuoteNotifyMessage: {
			type: String,
			required: false
		},
		importCartUrl: {
			type: String,
			required: false
		},
		importCartNotifyTitle: {
			type: String,
			required: false,
			default: "The file has been imported into the cart"
		},
		importCartNotifyMessage: {
			type: String,
			required: false
		},
		importCartFailedNotifyTitle: {
			type: String,
			required: false,
			default: "One or more items could not be imported to the cart - "
		},
		importCartFailedtNotifyMessage: {
			type: String,
			required: false,
			default: "This might be caused in formatting errors in the file or products that are not available"
		},
		emptyCartUrl: {
			type: String,
			required: false
		},
		emptyCartNotifyTitle: {
			type: String,
			required: false
		},
		emptyCartNotifyMessage: {
			type: String,
			required: false
		},
	},
	data() {
		return {
			isLoading: true,

			addCommentMode: false,

			numberOfItemsToShow: 3,

			isRequestingQuote: false,
			isImportingCart: false,
			isEmptyingCart: false,
			isSharingSavedList: false,
		};	
	},	
	mounted(){
		this[GET_CART_ITEMS.action]();
	},
	methods: {
		...mapActions([
			REQUEST_QUOTE_ASYNC.action,
			EMPTY_CART_ASYNC.action,
			GET_CART_ITEMS.action,
			IMPORT_CART_ASYNC.action,
		]),		
		requestQuote(){
			if (this.isRequestingQuote) return;

			this.isRequestingQuote = true;
			this.$refs.requestQuoteModal.close();

			const url = this.requestQuoteUrl;
			const body = {
				"comment": this.$refs.requestQuoteComment.value,
			};
			this[REQUEST_QUOTE_ASYNC.action]({
				url,
				body,
				notification: {
					type: 'info',
					showClose: false,
					title: this.requestQuoteNotifyTitle,
					message: this.requestQuoteNotifyMessage
				}
			});
			this.isRequestingQuote = false;
		},
		importCart(){
			if (this.isImportingCart) return;

			this.isImportingCart = true;
			this.$refs.importCartModal.close();

			const url = this.importCartUrl;
			const importFile = this.$refs.importCartModalFile.files[0];
			const body = importFile;
			this[IMPORT_CART_ASYNC.action]({
				url,
				body,
				notification: {
					type: 'info',
					showClose: false,
					title: this.importCartNotifyTitle,
					message: this.importCartNotifyMessage
				},
				notificationOnError: {
					type: 'info',
					showClose: false,
					title: this.importCartFailedNotifyTitle,
					message: this.importCartFailedtNotifyMessage
				}
			});
			this.isImportingCart = false;
		},
		commentAfterEnter () {
			this.$nextTick(() => this.$refs.commentInput.focus());
		},
		toggleCommentMode(){
			this.addCommentMode = !this.addCommentMode;
		},
		emptyCart(){
			if (this.isEmptyingCart) return;
			
			this.isEmptyingCart = true;

			const url = this.emptyCartUrl;
			const body = {};
			
			this[EMPTY_CART_ASYNC.action]({
				url,
				body,
				notification: {
					type: 'info',
					showClose: false,
					title: this.emptyCartNotifyTitle,
					message: this.emptyCartNotifyMessage
				}
			});
			this.isEmptyingCart = false;
		},
	},
};