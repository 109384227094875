<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { mapActions } from "vuex";

import StorySlide from "../story-slide.vue";

export default {
    name: "story-slide-video",
    extends: StorySlide,
    components: {
        vueVimeoPlayer
    },
    props: {
        activeIndex: {
            type: Number,
            default: 0
        }
    },
    computed: {
       titlePart1() {
            return this.item && this.item.titlePart1;
        },
        titlePart2() {
            return this.item && this.item.titlePart2;
        },
        description() {
            return this.item && this.item.description;
        },
        videoSrc() {
            return this.item && this.item.videoSrc;
        },
        videoTitlePart1() {
            return this.item && this.item.videoTitlePart1;
        },
        videoTitlePart2() {
            return this.item && this.item.videoTitlePart2;
        },
        videoDuration() {
            return this.item && this.item.videoDuration;
        },
        videoPosterSrc() {
            return this.item && this.item.videoPosterSrc;
        }
    },
    data() {
        return {
            playerReady: false
		}
    },
    methods: {
		...mapActions(["setActiveVideo"]),
        playClickHandler() {
            this.setActiveVideo({ type: "html", value: this.videoSrc });
        }
    },
};
</script>


<template>
    <div class="story-slide-video" :style="slideStyle">
        <div class="story-slide-video__content">

            <h2 class="story-slide-video__title">
                <span>{{ titlePart1 }}</span>
                <span v-if="titlePart2">{{ titlePart2 }}</span>
            </h2>

            <div v-if="description" class="story-slide-video__description">
                <div v-html="description"></div>


				<a v-if="buttonHref && buttonText" :href="buttonHref" class="link link--persistent">
					{{ buttonText }}
				</a>
            </div>

            <div class="story-slide-video__player video-player video-player--vimeo">
                <div class="video-player__inner">
                    <button v-if="videoSrc" class="video-player__start" v-on:click="playClickHandler">
                        <span class="icon"></span>
                        <div class="byline">
                            <div>{{ videoTitlePart1 }}</div>
                            <div>{{ videoTitlePart2 }}</div>
                        </div>
                        <h5>{{ videoDuration }}</h5>

                        <img v-if="videoPosterSrc" :src="videoPosterSrc" alt="poster" />
                    </button>
					<div class="video-player__start" v-else>
						<img v-if="videoPosterSrc" :src="videoPosterSrc" alt="poster" />
					</div>
                </div>
            </div>
        </div>

    </div>
</template>
