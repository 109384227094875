import { mapActions, mapState} from 'vuex';
import Swiper from "swiper/dist/js/swiper.js";
import TabsBar from '../tabs-bar/tabs-bar';


export default {
    name: "material-overlay",
	components: {
		TabsBar
	},
	watch: {
		activeMaterialIndex: function() {
			if ( this.materialsData && this.activeMaterialIndex >= 0) {
				this.activeCategory = this.materialsData[this.activeMaterialIndex];
			}
		},
		materialsData: function() {
			if ( this.materialsData && this.activeMaterialIndex >= 0) {
				this.categoryTabsList = this.getCategoryList();
				this.activeCategory = this.materialsData[this.activeMaterialIndex];
			}

            setTimeout(function() {
				Object.assign(this.settings, {
					navigation: {
						nextEl: this.$refs["nextButton"],
						prevEl: this.$refs["prevButton"],
					}
				});

				this.slider = new Swiper(this.$refs.slider, this.settings);
            }.bind(this),100);
		}
	},
    computed: {
		...mapState({
			materialsData: state => state.materialsData,
			activeMaterialIndex: state => state.activeMaterialIndex,
			materialOverlayOpen: state => state.materialOverlayOpen,
		}),
		activeCategoryStyle() {
			if ( this.activeCategory && this.activeCategory.backgroundColor ) {
				return `background-color: ${this.activeCategory.backgroundColor}; --material-overlay-bg-color: ${this.activeCategory.backgroundColor};color: ${this.activeCategory.textColor}; --material-overlay-text-color: ${this.activeCategory.textColor}`;
			}
		}
	},
	props: {
		closeText: {
			type: String,
			default: "Close"
		}
	},
	data() {
		return {
			activeCategory: null,
			categoryTabsList: null,
			activeAccordionIndex: -1,
			slider: null,
            settings: {
				slidesPerView: 1.25,
                spaceBetween: 0,
                freeMode: true,
				navigation: { },
            }
		}
	},
    methods: {
		...mapActions([
			'openMaterialOverlay',
			'closeMaterialOverlay',
			'hideOverlayBackdrop',
			'setActiveMaterialCategory',
		]),
		close(){
            this.closeMaterialOverlay();
			this.hideOverlayBackdrop();
		},
		categoryChangeHandler: function(item, index) {
			this.activeAccordionIndex = -1;

            this.setActiveMaterialCategory(index);
			this.$refs.inner.scrollTop = 0;

            setTimeout(function() {
				this.slider = new Swiper(this.$refs.slider, this.settings);
            }.bind(this),100);
		},
		getCategoryList: function() {
            let list = [];
			if ( this.materialsData && this.materialsData.length ) {
				list = this.materialsData.map(item=>{ return {label: item.headline}; });
			}
            return list;
        },
		toggleAccordion: function(index) {
			if ( this.activeAccordionIndex === index ) {
				this.activeAccordionIndex = -1;
			} else {
				this.activeAccordionIndex = index;
				try {this.$refs.accordions.querySelectorAll('li')[this.activeAccordionIndex].scrollIntoView({block: "center", inline: "nearest",behavior:"smooth"});} catch (err) {
					console.log(err);
				}
			}
		}
	}
}
