export default {
	props: {
		hasImageProducts: {
			type: Boolean,
			default: false
		},
		buttonText: {
            type: String,
            default: "Shop this image",
        },
        skuList: {
            type: String,
        },
        backgroundColor: {
            type: String,
            default: "#FFFFFF"
        },
        imageProductsOverlayData: {
            type: Object                        
        }
	},
};
