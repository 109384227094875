import { mapActions } from "vuex";
import { SET_FILTER_COLOR } from '../../basics/js/store/static';


export default {
	name: 'form-logical-filter',
	components: {
	},
	props: {
        color: String,
        cssClass: Object,
        value: String,
        id: [String, Number],
		tooltip: String,
        disabled: Boolean,
        name: String,
        facet: String,
		category: Object
    },
    data() {
        return {
            isActive: false,
            label: this.name
        }
    },
	methods: {
		...mapActions([
			"openToolTip",
		]),
		hover() {
			this.$store.dispatch(SET_FILTER_COLOR.action, this.color || null);
        },
        onInputChange(event) {
			this.$emit('inputchange', event);
		},
		mouseLeave() {
			this.$store.dispatch(SET_FILTER_COLOR.action, null);
		}
	}
};
