import ProductLineAddons from '../../components/product-line/product-line-addons';

// import { mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
	name: 'addons-block',
	components: {
		ProductLineAddons,
	},
	props: {
		productId: {
			type: Number,
			required: false,
		},
		variantId: {
			type: Number,
			required: false,
		}

	},
	data() {
		return {
			// isActive: false,
			isLoading: false,
			isLoaded: false,
		};
	},
	computed: {
		productResults(){
			this.isLoading = false;
			this.isLoaded = true;
			console.log("this.$store",this.$store.state.pdpAddonsProducts)
			return this.$store.state.relevantAddonsProducts;
		}
	},
	// beforeMounted(){},
	mounted(){
		this.isLoading = true;
		this.getAddonsForBlock({ productId: this.productId, variantId: this.variantId}); // action
	},
	methods: {
		...mapActions([
			'getAddonsForBlock'
		])
	},
};
