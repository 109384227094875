var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "story-slide-content",
      class: { "story-slide-content--designer": _vm.isDesigner },
      style: _vm.slideStyle,
    },
    [
      _c("div", { staticClass: "story-slide-content__content" }, [
        _vm.imageSrc1
          ? _c("img", {
              staticClass:
                "story-slide-content__media story-slide-content__media--1",
              attrs: { src: _vm.imageSrc1 },
            })
          : _vm.videoSrc1
          ? _c("video", {
              staticClass:
                "story-slide-content__media story-slide-content__media--1",
              attrs: {
                autoplay: "",
                playsinline: "",
                muted: "",
                loop: "",
                preload: "auto",
                src: _vm.videoSrc1,
              },
              domProps: { muted: true },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.imageSrc2
          ? _c("img", {
              staticClass:
                "story-slide-content__media story-slide-content__media--2",
              attrs: { src: _vm.imageSrc2 },
            })
          : _vm.videoSrc2
          ? _c("video", {
              staticClass:
                "story-slide-content__media story-slide-content__media--2",
              attrs: {
                autoplay: "",
                playsinline: "",
                muted: "",
                loop: "",
                preload: "auto",
                src: _vm.videoSrc2,
              },
              domProps: { muted: true },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("h2", { staticClass: "story-slide-content__title" }, [
          _c("span", [_vm._v(_vm._s(_vm.titlePart1))]),
          _vm._v(" "),
          _vm.titlePart2
            ? _c("span", [_vm._v(_vm._s(_vm.titlePart2))])
            : _vm._e(),
          _vm._v(" "),
          _vm.titlePart3
            ? _c("span", [_vm._v(_vm._s(_vm.titlePart3))])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.description
          ? _c("div", { staticClass: "story-slide-content__description" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } }),
              _vm._v(" "),
              _vm.buttonHref && _vm.buttonText
                ? _c(
                    "a",
                    {
                      staticClass: "link link--persistent",
                      attrs: { href: _vm.buttonHref },
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t")]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }