import Vue from 'vue/dist/vue.esm';
import { getRequest } from '../integrations';

import { GET_RECOMMENDATION } from '../static';

const state = {
    [GET_RECOMMENDATION.loadingKey]: false,
}

const mutations = {
	[GET_RECOMMENDATION.PENDING](state, status){
		Vue.set(state, [GET_RECOMMENDATION.loadingKey], status);
	}
}

const actions = {
	async [GET_RECOMMENDATION.action](store, payload={}){
        const result = await getRequest(store, {
			url: `${process.env.RECOMMENDATIONS}?_id=${payload.id}`,
            mutationTypes: GET_RECOMMENDATION,
            delay: 1000
		})
        return result
	}
}

export default { state, actions, mutations }
