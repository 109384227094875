import Swiper from "swiper";
import { mapState } from "vuex";

export default {
	name: "category-tile",
	props: {
		inputCategories: {
			required: true,
		},
		type: {
			type: String,
			required: false,
			default: "links",
		},
	},
	data() {
		return {
			itemIndex: 0,
			defaultColor: '',
			slider: null,
            sliderSettings: {
				slidesPerView: 1.25,
                spaceBetween: 10,
				breakpoints: {
					390: {
						slidesPerView: 1.25,
						spaceBetween: 10,
					},
					450: {
						slidesPerView: 1.5,
						spaceBetween: 15,
					},
					768: {
						slidesPerView: 1.75,
						spaceBetween: 20,
					},
				}
            }
		};
	},
	computed: {
		...mapState({
            isMobile: state => state.isMobile,
		}),
		parsedCategories: function () {
			// console.log(typeof this.inputCategories);
			if (typeof this.inputCategories === "string") {
				return JSON.parse(this.inputCategories);
			} else if (this.inputCategories && this.inputCategories[0]) {
				return this.inputCategories;
			}
		},
		activeCategory: function () {
			if (this.parsedCategories && this.parsedCategories[0]) {
				return this.parsedCategories[this.itemIndex];
			}
		},
	},
	mounted() {
		this.defaultColor = window.getComputedStyle(this.$refs.inner).backgroundColor;

		if ( this.$refs.slider ) {

			this.sliderSettings.pagination = {
				el: this.$refs.pagination,
				clickable: true,
			};
			setTimeout(function() {
				this.slider = new Swiper(this.$refs.slider, this.sliderSettings);
			}.bind(this),100);
		}
	},
	methods: {
		hoverCategory(event = null, item, index) {
			this.itemIndex = index;
			if (item.color) this.$refs.inner.style.setProperty("--category-tile-bg-color", item.color);
		},
		resetCategory() {
			this.itemIndex = 0;
			this.$refs.inner.style.setProperty("--category-tile-bg-color", this.defaultColor);
		},
	},
};
