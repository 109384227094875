
export default {
	name: 'input-with-label',
	data() {
		return {
			isActive: false,
			hasValue: false,
			inputValue: '',
		}
	},
	mounted(){
		this.$nextTick(this.checkInitialState);
	},
	methods: { 
		checkInitialState(){
			this.checkHasValue();
		},
		handleClickOnInput(){ // date-picker capabilities
			this.$emit('emit-input-clicked');
		},
		handleInput(value){
			this.inputValue = value;
			this.checkHasValue();
		},
		checkHasValue(){
			this.hasValue = this.inputValue != '' ? true : false;
		},
		addCampaignCode(){ // Checkout campaign code
			this.$emit('emit-add-campaign-code', this.inputValue);
		},
		campaignInput(){}
	},
}