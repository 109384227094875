<script>
import { mapState, mapActions } from "vuex";
import { inView } from "../../../../basics/js/methods/inView/inView";
import { rInterval } from "../../../../basics/js/utils";

export default {
    name: 'swatch-selector',
    props: {
		initialSelectedSwatch: {
			type: String,
			required: false,
			default: ''
		},
		rowSize: {
			type: Number,
			default: 4,
		},
		initialRows: {
			type: Number,
			default: 1,
		},
		hasInfo: {
			type: Boolean,
			default: false
		},
		lazyLoading: {
			type: Boolean,
			default: false,
		},
		headline: {
			type: String,
			required: false,
		},
		bodyText: {
			type: String,
			required: false,
		},
		properties: {
			type: Array,
			required: false,
		},
		items: {
			type: Array,
			required: true,
		},
		initialExpanded: {
			type: Boolean,
			default: false,
		},
		featureId: {
			type: String,
			required: true
		}
	},
    data() {
        return {
            isLoaded: false,
			expanded: false,
			expandLoopIndex: 0,
			loopableItems: [],
			remainingItemsCount: 0,
			shownItemsCount: 0,
			itemCount: 0,
			updateKey: 0
        };
    },
	computed: {
		...mapState({
			currentVariantFeatures: state => state.reconfigurator.currentVariantFeatures,
			productId: state => state.reconfigurator.currentProductId,
			currentStep: state => state.reconfigurator.currentStep,
		}),
		selectedSwatch() {
			return this.initialSelectedSwatch || '';
		}
	},
    methods: {
		...mapActions([
            "openMaterialGroupInfoOverlay",
			"showOverlayBackdrop",
			"updateProductFeature"
		]),
        selectSwatch(item) {
			console.log('selectSwatch', item.id, item.cylindoFeatureValue);            
			this.updateProductFeature({id: this.featureId, item:item});		
		},
		expandClickHandler() {
			this.$refs.expandItem.classList.remove('is-shown');
			this.loopableItems = this.items;

			// Ready items for animation
			this.loopableItems.forEach(item => {
				this.expandLoopIndex++;				
			});
			// setTimeout(()=>{
			// 	this.loopableItems.forEach(item => {
			// 	});
			// 	console.log("this.loopableItems",this.loopableItems)
			// }, 500);
			this.expandLoopIndex = 0;
			this.expanded = true;
			this.updateKey++;

			setTimeout( this.loadImages, 1000);

		},
		setVisibleItems() {
			this.loopableItems.forEach((item, index) => {
				item.initialVisible = index > (this.itemCount - this.remainingItemsCount) ? false : true;
			});
		},
		loadImages() {
			this.itemsInDom = this.$el.querySelectorAll('.swatch-selector__list-item');
			this.itemsInDom.forEach(item => {
				if (!item.classList.contains('is-loaded')) {
					const imageEl = item.querySelector('img');
					if ( imageEl.dataset.src ) {
						let imageObject = new Image();
						imageObject.onload = () => {
							item.classList.add('is-loaded');
							imageEl.src = imageEl.dataset.src;
							imageEl.removeAttribute('data-src');
						}
						imageObject.src = imageEl.dataset.src;
					}				
				}
			});
		},
		infoClickHandler() {
			this.openMaterialGroupInfoOverlay({
				headline: this.headline, 
				bodyText: this.bodyText, 
				properties: this.properties
			});
		},
		isItemActive(value) {
			if ( this.currentVariantFeatures ) {

				if ( this.currentVariantFeatures[this.featureId] === value ) console.log('isItemActive found!', this.currentVariantFeatures[this.featureId], value)
				return this.currentVariantFeatures[this.featureId] === value;
			} 
			return false;
		}
    },
	beforeMount() {
		this.itemCount = this.items.length;
		
		if ( this.initialExpanded || this.itemCount <= (this.rowSize * this.initialRows)) {
			this.loopableItems = this.items;
			
			this.expanded = true;
			this.remainingItemsCount = 0;
			this.shownItemsCount = this.itemCount;
			
		} else {
			this.loopableItems = this.items.slice(0,(this.rowSize * this.initialRows) - 1);
			this.shownItemsCount = this.loopableItems.length;
			this.remainingItemsCount = Math.max(0, this.itemCount - this.shownItemsCount);
			this.shownItemsCount = this.itemCount - this.remainingItemsCount;
		}

		this.loopableItems.forEach((item, index) => {
			item.initialVisible = index >= this.shownItemsCount ? this.expanded : true;
			item.index = item.id;
		});
	},
    mounted() {
		this.isLoaded = true;

		if (!this.expanded && this.$refs.expandButton ) {
			if (this.remainingItemsCount > 0) {
				this.$refs.expandItem.classList.add('is-shown');
			}
		}

		inView({
			el: this.$el,
			threshold: .1,
			ratio: .1,
			callback: (inView) => {
				if (inView) {
					this.loadImages();
				}
			}
		});
    }
}
</script>

<template>
	<div class="swatch-selector" :style="`--row-size: ${rowSize}`">
		<h5 class="swatch-selector__headline">
			<span>{{headline}}</span> 
			<button v-if="hasInfo" class="tooltip-button" v-on:click="infoClickHandler">
				<svg viewBox="0 0 100 100" class="svg-icon">
					<use xlink:href="/dist/svg/symbols.svg#svg-question"></use>
				</svg>
			</button>
		</h5>
		<ul class="swatch-selector__list" :key="updateKey">
			<li
				v-for="item in loopableItems"
				:key="item.value+item.index"
				class="swatch-selector__list-item">
				<button class="swatch-selector__button" @click="selectSwatch(item)" :class="{'is-active': isItemActive(item.id)}">
					<div>
						<img :data-src="item.image" :alt="item.value" v-if="lazyLoading">
						<img :src="item.image" :alt="item.value" v-else>
					</div>
					<span>{{ item.value }}</span>
				</button>
			</li>
			<li class="swatch-selector__expand-item" ref="expandItem">
				<button class="swatch-selector__button" ref="expandButton" @click="expandClickHandler">+ {{remainingItemsCount}}</button>
			</li>
		</ul>
	</div>
</template>