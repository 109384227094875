export default {
    slidesIdeal:[
        {
            headline: "Choose between various combinations",
            bodyText: "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
            backgroundColor: "beige",
            textColor: "coral",
            media: {
                imageSrc: "/static/images/frontpage-ipad.png",
            },
            button: {
                type: "button button--primary",
                text: "Try sofa in AR",
                href: "#"               
            }
        },
        {
            headline: "...Or start with a blank canvas",
            bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.",
            backgroundColor: "darkgoldenrod",
            textColor: "yellow",
            media: {
                imageSrc: "/static/images/support-block-image-crop.png",
            },       
            button: {
                type: "button button--secondary",
                text: "Try sofa in AR",
                href: "#"
            }     
        },
        {
            headline: "I can show you the world",
            bodyText: "Adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
            backgroundColor: "burlywood",
            textColor: "firebrick",
            media: {
                imageSrc: "/static/images/frontpage-ipad.png",
            },            
            button: {
                type: "link",
                text: "Try sofa in AR",
                href: "#"
            }
        },
        {
            headline: "...Shining, shimmering, splendid",
            bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem. consectetur adipiscing elit, sed do eiusmod tempor lorem",
            backgroundColor: "black",
            textColor: "silver",
            media: {
                imageSrc: "/static/images/support-block-image-crop.png",
            },
            button: {
                type: "button button--primary",
                text: "Try sofa in AR",
                href: "#",
                backgroundColor: "coral",
                textColor: "black"
            }
        },
        {
            headline: "Tell me, princess, now when did you last let your heart decide?",
            bodyText: "Adipiscing elit, sed do.",
            backgroundColor: "burlywood",
            textColor: "firebrick",
            media: {
                imageSrc: "/static/images/frontpage-ipad.png",
            },
            button: {
                type: "button button--secondary",
                text: "Try sofa in AR",
                href: "#",
                textColor: "white",
                backgroundColor: "tomato"
            }
        },
    ],
    slidesRealistic: [
        {
            headline: "It's dangerous to go alone. Take this.",
            bodyText: "Consectetur adipiscing elit, sed do eiusmod tempor lorem",
            backgroundColor: "lavender",
            textColor: "green",
            media: {
                imageSrc: "/static/images/product/plp-inspirational-image1.jpg",
            },
            button: {
                type: "button button--primary",
                text: "Try sofa in AR",
                href: "#",
                backgroundColor: "goldenrod",
                textColor: "yellow"
            }
        },
        {
            headline: "Choose between various combinations or start with a blank canvas consectetur adipiscing elit, sed do eiusmod tempor lorem",
            bodyText: "Choose between various combinations or start with a blank canvas ",
            backgroundColor: "darkblue",
            textColor: "pink",
            media: {
                imageSrc: "/static/images/portrait-image.jpg",
            },
            button: {
                type: "link",
                text: "Try sofa in AR",
                href: "#"
            }
        },
        {
            headline: "Lorem",
            bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem. ",
            backgroundColor: "cyan",
            textColor: "tomato",
            media: {
                videoSrc: "/static/video/test3.mp4",
            },
            button: {
                type: "button button--secondary",
                text: "Try sofa in AR",
                href: "#"
            }
        },
        {
            headline: "Sit amet",
            bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem.",
            backgroundColor: "darkgreen",
            textColor: "yellow",
            media: {
                imageSrc: "/static/images/master-product-image_4_5.jpg",
            }
        },
    ]
};