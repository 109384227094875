const getSuggestion = function(suggestions,searchTerm){

    let returnSuggestion = ''
    
    if(Array.isArray(suggestions) && suggestions.length){
        returnSuggestion = suggestions[0].suggestion.substring(searchTerm.length)
    }

    return returnSuggestion
}

export default getSuggestion