<script>
import StorySlide from "../story-slide.vue";

export default {
    name: "story-slide-cover",
    extends: StorySlide,
    computed: {
        imageSrc() {
            return this.item && this.item.imageSrc;
        },
        videoSrc() {
            return this.item && this.item.videoSrc;
        },
        titlePart1() {
            return this.item && this.item.titlePart1;
        },
        titlePart2() {
            return this.item && this.item.titlePart2;
        },
        description() {
            return this.item && this.item.description;
        },
        isAssembled() {
            return this.item && this.item.isAssembled;
        }
    }
};
</script>


<template>
    <div class="story-slide-cover" :style="slideStyle" :class="{'story-slide-cover--assembled': isAssembled}">
        <img v-if="imageSrc" class="story-slide-cover__media" :src="imageSrc" />
        <video v-else-if="videoSrc" class="story-slide-cover__media" autoplay playsinline muted loop preload="auto" :src="videoSrc" />

        <div class="story-slide-cover__content">
            <h2 class="story-slide-cover__title">
                <span>{{ titlePart1 }}</span>
                <span v-if="titlePart2">{{ titlePart2 }}</span>

                <a v-if="isAssembled && buttonHref && buttonText" :href="buttonHref" class="button button--secondary button--custom-colors" :style="buttonStyle">
                    <span class="text">{{buttonText}}</span>
                </a>
            </h2>

            <div v-if="description" class="story-slide-cover__description">
                <div v-html="description"></div>

                <a v-if="!isAssembled && buttonHref && buttonText" :href="buttonHref" class="button button--secondary button--custom-colors" :style="buttonStyle">
                    <span class="text">{{buttonText}}</span>
                </a>
            </div>


        </div>
    </div>
</template>
