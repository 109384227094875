
export default {
	name: 'order-summary',


	data(){
		return {
			campaignCode: '',
			buttonStatus: 'idle',
			campaignCodeDisabled: false
		}
	},
	computed: {
		cartLineItems: function(){
			return this.$store.state.cart.cartLineItems;
		},
		couponCodeAccepted(){
			return !!this.cartData.campaign
		},
		cartData(){
			return this.$store.state.cart.calculatedCart
		},
		showSpinner(){
			return this.$store.state.cart.showOrderSummarySpinner
		},
		campaignIdentification() {
			if(this.cartData.campaign.name) {
				return this.cartData.campaign.code + ", " + this.cartData.campaign.name + ""
			}
			return this.cartData.campaign.code
		}
	},
	methods: {
		submitCouponCode(){
			this.$store.dispatch('submitCouponCode',this.campaignCode)
		},
		removeCampaignCode(){
			this.campaignCode = ""
			this.$store.dispatch('submitCouponCode',this.campaignCode)
		}

	},
	mounted(){
		this.$store.dispatch('getCalculatedCart')
	}
};
