import Store from '@store/store'

export default (checkbox) => {

    const selectedFiltersStore = Store.state.search.selectedFacets

    const selectedFilter = selectedFiltersStore.find(facet => facet.id === checkbox.facet)
    const matchingValue = selectedFilter ? selectedFilter.facetValues.find(value => value.id === checkbox.value) : null

    return !!matchingValue
    
}