var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mosaic-slider-zoom",
      class: { "mosaic-slider-zoom--custom-cursor": _vm.useCustomCursor },
      style: _vm.zoomStyle,
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "container",
          staticClass: "mosaic-slider-zoom__image",
          on: { mousemove: _vm.mouseMoveHandler },
        },
        [
          _c("img", {
            ref: "image",
            style: _vm.imageStyle,
            attrs: { src: _vm.zoomSrc },
          }),
          _vm._v(" "),
          _vm.noMouse
            ? _c("span", [
                _vm._v("Panning only works with a mouse!"),
                _c("br"),
                _vm._v("Tap to close."),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.useCustomCursor
        ? _c("div", {
            ref: "cursor",
            staticClass: "mosaic-slider-zoom__cursor",
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }