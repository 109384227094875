import ProductLineAddons from '../../components/product-line/product-line-addons';

// import { mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
	name: 'addons-overlay',
	components: {
		ProductLineAddons,
	},
	props: {
		productId: {
			type: Number,
			required: false,
		},
		variantId: {
			type: Number,
			required: false,
		}
	},
	data() {
		return {
			// isActive: false,
			isLoading: false,
			isLoaded: false,
		};
	},
	computed: {
		isActive: function(){
			if(this.$store.state.addonsOverlayOpen === true){ // COMMENT: When opening
				this.isLoading = true;
				this.$el.focus(); // COMMENT: In order to make esc keypress work
				this.showOverlayBackdrop(); // action				
				this.getAddonsForOverlay({ productId: this.productId, variantId: this.variantId}); // action
			}
			return this.$store.state.addonsOverlayOpen;
		},
		productResults(){
			this.isLoading = false;
			this.isLoaded = true;
			return this.$store.state.pdpAddonsProducts;
		}
	},
	// beforeMounted(){},
	mounted(){
		// console.log("this.$store",this.$store.state.pdpAddonsProducts)
	},
	methods: {
		...mapActions([
			'closeAddonsOverlay',
			'showOverlayBackdrop',
			'hideOverlayBackdrop',
			'getAddonsForOverlay',
		]),
		close(){
			//
			this.hideOverlayBackdrop();
			this.closeAddonsOverlay(); // action
		},
	},
};
