var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "attribute-options" }, [
    _c(
      "button",
      {
        staticClass: "attribute-options__button",
        on: { click: _vm.showAllAttributes },
      },
      [
        _c("p", [
          _c("strong", [
            _vm._v(
              _vm._s(
                _vm.isMobile
                  ? _vm.attributeList.nameShort
                  : _vm.attributeList.name + ":"
              )
            ),
          ]),
          _vm._v(" "),
          _c("em", [_vm._v(_vm._s(_vm.selectedAttributeName))]),
        ]),
        _vm._v(" "),
        !_vm.isMobile
          ? _c(
              "svg",
              {
                staticClass: "attribute-options__icon svg-icon",
                attrs: { viewbox: "0 0 100 100" },
              },
              [
                _c("use", {
                  attrs: {
                    "xlink:href": "/dist/svg/symbols.svg#svg-select-chevron",
                  },
                }),
              ]
            )
          : _c(
              "svg",
              {
                staticClass: "attribute-options__icon svg-icon",
                attrs: { viewbox: "0 0 100 100" },
              },
              [
                _c("use", {
                  attrs: {
                    "xlink:href": "/dist/svg/symbols.svg#svg-plus-mini",
                  },
                }),
              ]
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }