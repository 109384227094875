import Swiper from 'swiper/dist/js/swiper.js';
import MosaicSliderZoom from './mosaic-slider-zoom.vue';
import { inView } from '../../basics/js/methods/inView/inView';

import { mapActions, mapState } from 'vuex';

/**
 * @prop cssClass {String} - (decrecated description) 'slider--inspiration' will turn it into an inspirational slider
 * @prop settings {Object} - swiper option object, will overwrite default sliderOptions
 */

export default {
	name: 'mosaic-slider',
	props: {
		sliderOptions: {
			type: Object,
			required: false,
		},
		hasZoom: false,
		slideCount: 0,
		type: String,
		slidesPerView: String,
	},
	components: {
		MosaicSliderZoom
	},
	data() {
		return {
			activeIndex: 0,
			captions: [],
			slider: null,
			showPagination: false,
			showNavigation: false,
			navButtonsVisible: false,
			zoomSrc: null,
			defaultSliderSettings: {
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				pagination: false,
				init: false,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				navigation: {
					nextEl: this.$refs["nextButton"],
					prevEl: this.$refs["prevButton"],
				},
				preloadImages: false,
				watchOverflow: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 3,
				}
			},
		};
	},
	computed: {
		...mapState({
			mosaicSliderOverlayOpen: state => state.mosaicSliderOverlayOpen,
			mosaicSliderOverlayActiveIndex: state => state.mosaicSliderOverlayActiveIndex
		}),
		settings() {
			return Object.assign({}, this.defaultSliderSettings, this.sliderOptions);
		},
	},
	watch:{
		mosaicSliderOverlayActiveIndex(value) {

			// console.log("mosaic-slider mosaicSliderOverlayActiveIndex | newVal="+value+", this.activeIndex="+this.activeIndex);

			if (value !== this.activeIndex && this.mosaicSliderOverlayOpen) {
				this.thumbEvent = true; // fake thumb event to prevent double slide
				this.activeIndex = value;
				this.captions.forEach(elm => {
					elm.classList.remove('is-active');
				});
				this.captions[value].classList.add('is-active');
				// this.changeCaption(value);
				this.slider.slideTo(parseInt(value) + 1, 0);
			}
		}
	},
	methods: {
		...mapActions([
			"openMosaicSliderOverlay",
			"closeMosaicSliderOverlay",
		]),
		thumbClick(newIndex){
			this.thumbEvent = true;

			// console.log("mosaic-slider mosaicthumbClickliderOverlayActiveIndex | newIndex="+newIndex+", this.activeIndex="+this.activeIndex);
			if (newIndex !== this.activeIndex) {
				this.changeCaption(newIndex);
				this.slider.slideTo(newIndex + 1);
			}
		},
		changeCaption(newIndex, carouselEvent = false){
			// Handle carousel loops
			let direction;
			if (carouselEvent) {
				if (newIndex > this.captions.length - 1) { // "wrap around" to the start
					newIndex = 0;
					direction = 'next';
				} else if (newIndex < 0) {
					newIndex = this.captions.length - 1; // "wrap around" to the end
					direction = 'prev';
				} else {
					direction = newIndex > this.activeIndex ? 'next' : 'prev';
				}
			} else {
				direction = newIndex > this.activeIndex ? 'next' : 'prev';
			}
			const activeCaption = this.captions[this.activeIndex];
			const prevNextCaption = this.captions[newIndex];

			this.captions.forEach(elm => { // cleanup all transition classes
				elm.classList.remove('is-active', 'from-right', 'from-left', 'to-left', 'to-right');
			})

			if (direction === 'next') {
				activeCaption.classList.add('to-left');
				prevNextCaption.classList.add('from-right');
			} else {
				activeCaption.classList.add('to-right');
				prevNextCaption.classList.add('from-left');
			}

			setTimeout(() => {
				prevNextCaption.classList.add('is-active');
			}, 150);

			this.activeIndex = newIndex;
		},
		captionTransitionEnd(event) {
			// Cleanup transition classes
			if (event.target.classList.contains('to-left') || event.target.classList.contains('to-right')) {
				event.target.classList.remove('is-active');
			}
			event.target.classList.remove('from-right', 'from-left', 'to-left', 'to-right');
		},
		handleSwiperSlideChange() {
			if (!this.thumbEvent) {
				const newIndex = this.slider.activeIndex - 1;
				this.changeCaption(newIndex, true);
			}
			this.thumbEvent = false;
		},
		handleSwiperClick(event) {
			if ( event.target.classList.contains('mosaic-slider__image') ) {
				this.zoomSrc = event.target.dataset.srczoom;
			}
		},
		zoomCloseHandler() {
			this.zoomSrc = null;
		},
	},
	mounted() {
		this.activeIndex = this.mosaicSliderOverlayActiveIndex;
		this.captions = this.$el.querySelectorAll('.mosaic-slider__caption');
		this.captions.forEach(caption => {
			caption.addEventListener('transitionend', this.captionTransitionEnd);
		});
		this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");
		this.slidesCount = this.slidesRef.length;

		if (this.slidesCount > 1) {
			Object.assign(this.settings, {
				navigation: {
					nextEl: this.$refs["nextButton"],
					prevEl: this.$refs["prevButton"],
				}
			});
		}
		this.slider = new Swiper(this.$refs.slider, this.settings);

		this.slider.on('slideChange', () => {
			this.handleSwiperSlideChange();
		});
		this.slider.on('click', event => {
			this.handleSwiperClick(event);
		});
		this.$emit('ready', this.slider);
		this.slider.init();

		inView({
			el: this.$refs.slider,
			once: true,
			threshold: 0.2
		});


		let timeoutHandle = setTimeout(() => {
			this.navButtonsVisible = false;
		}, 0);

		this.$refs.slider.addEventListener("mouseleave", () => {
			this.navButtonsVisible = false;
		});
		this.$refs.slider.addEventListener("mousemove", () => {
			this.navButtonsVisible = true;
			clearTimeout(timeoutHandle);
			timeoutHandle = setTimeout(() => {
				this.navButtonsVisible = false;
			}, 1500);
		});
		this.$refs.slider.addEventListener("mouseup", () => {
			this.navButtonsVisible = true;
			clearTimeout(timeoutHandle);
			timeoutHandle = setTimeout(() => {
				this.navButtonsVisible = false;
			}, 1500);
		});
	},
};
