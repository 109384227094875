import CheckoutBlock from '../checkout-block/checkout-block';
import OrderSummaryOriginal from '../order-summary/order-summary-original';
import validations from '../../basics/js/validations';

import { GET_CART_ITEMS } from '../../basics/js/store/static';

export default {
	name: 'checkout-original',
	components: {
		CheckoutBlock,
		OrderSummaryOriginal,
	},
	props: {
		"own-reference": String,
		"comment": String,
		"campaign-code": String,

		"reference-person-external-id": String,
		"reference-person-is-new-person": Boolean,
		"reference-person-name": String,
		"reference-person-email": String,

		"address-external-id": String,
		"address-is-new-address": Boolean,
		"address-address-line": String,
		"address-zipcode": String,
		"address-country": String,
		"address-attention": String,
	},
	data() {
		return {
			form: {
				action: "no-action",

				[validations.validationFields.ownReference]: this.ownReference,
				[validations.validationFields.comment]: this.comment,
				[validations.validationFields.campaignCode]: this.campaignCode,

				[validations.validationFields.referencePerson.externalId]: this.referencePersonExternalId,
				[validations.validationFields.referencePerson.isNewPerson]: this.referencePersonIsNewPerson,
				[validations.validationFields.referencePerson.name]: this.referencePersonName,
				[validations.validationFields.referencePerson.email]: this.referencePersonEmail,

				[validations.validationFields.address.externalId]: this.addressExternalId,
				[validations.validationFields.address.isNewAddress]: this.addressIsNewAddress,
				[validations.validationFields.address.addressLine]: this.addressAddressLine,
				[validations.validationFields.address.zipcode]: this.addressZipcode,
				[validations.validationFields.address.country]: this.addressCountry,
				[validations.validationFields.address.attention]: this.addressAttention
			}
		}
	},
	mounted() {
		this.$store.dispatch(GET_CART_ITEMS.action);
	},
	validations,
	methods: {
		validateForm() {
			console.log("validate")
			this.$v.form.$touch()
			if (this.$v.form.$error) {
				console.warn('form error', this.$v);
				if (this.form.action != 'ActionAddCampaign')
					return;
			}
			this.$refs.form.submit();
		},
		onChange() {
			// onchange
		},
		setAction(actionName) {
			console.log(`set action: ${actionName}`);
			this.form.action = actionName;
		},
		scrollToTopClick() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	},
};
