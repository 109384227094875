<script>
import { mapActions } from "vuex";

export default {
	name: "inspiration-tile-v2",
	props: {
		headline: {
			type: String,
		},
		trumpet: {
			type: String,
		},
		bodyText: {
			type: String,
		},
		media: {
			type: Object,
		},
		cta: {
			type: Object,
		},
		type: {
			type: String,
			required: true,
		},
		alignBottom: {
			type: Boolean,
			default: false,
		},
		reverseOrder: {
			type: Boolean,
			default: false,
		},
		splitContent: {
			type: Boolean,
			default: false,
		},
		overlapHeader: {
			type: Boolean,
			default: false,
		},

		backgroundColor: {
			type: String,
		},
		textColor: {
			type: String,
		},
	},
	computed: {
		tileClass() {
			return (
				`inspiration-tile-v2 inspiration-tile-v2--type-${this.type.toLowerCase()}` +
				` ${this.alignBottom ? "inspiration-tile-v2--align-bottom" : ""}` +
				` ${this.reverseOrder ? "inspiration-tile-v2--reverse-order" : ""}` +
				` ${this.overlapHeader ? "inspiration-tile-v2--overlap-header" : ""}` +
				` ${this.splitContent ? "inspiration-tile-v2--split-content" : ""}`
			);
		},
		tileStyle() {
			return (
				`${this.backgroundColor ? "--inspiration-tile-v2-bg-color:" + this.backgroundColor + ";" : ""}` +
				`${this.textColor ? "--inspiration-tile-v2-text-color:" + this.textColor + ";" : ""}`
			);
		},
	},
	methods: {
		...mapActions(["openShortcutsOverlay", "showOverlayBackdrop"]),

		openConfigureOverlay: function () {
			if ( this.cta.data !== undefined ) {
				this.openShortcutsOverlay(this.cta.data);
				this.showOverlayBackdrop();
			}
		},
		clickHandler() {
			console.log("clickHandler | action=" + this.cta.value);
			switch (this.cta.value) {
				case "configure":
					this.openConfigureOverlay();
					break;
				default:
					console.log("clickHandler | action=" + this.cta.value + " not implemented");
			}
		},
	},
};

</script>
<template>
	<button v-if="cta && cta.type === 'action'" :class="tileClass" :style="tileStyle" v-on:click="clickHandler">
		<figure class="inspiration-tile-v2__media" v-if="media">
			<img v-if="media.type === 'image'" :src="media.src" :alt="media.alt" :srcset="media.srcset" :sizes="media.sizes"/>
			<video v-if="media.type === 'video'" :src="media.src" muted autoplay loop playsinline></video>
		</figure>
		<h6 class="inspiration-tile-v2__trumpet" v-if="trumpet">
			<span>{{ trumpet }}</span>
		</h6>
		<h4 class="inspiration-tile-v2__headline" v-if="headline" v-html="headline"></h4>
		<p class="inspiration-tile-v2__bodytext" v-if="bodyText">{{ bodyText }}</p>
		<div class="inspiration-tile-v2__cta" v-if="cta.text" :class="cta.class" :style="cta.style"><span>{{ cta.text }}</span></div>
	</button>

	<a v-else-if="cta && cta.type === 'navigation'" :class="tileClass" :style="tileStyle" :href="cta.value">
		<figure class="inspiration-tile-v2__media" v-if="media">
			<img v-if="media.type === 'image'" :src="media.src" :alt="media.alt" :srcset="media.srcset" :sizes="media.sizes"/>
			<video v-if="media.type === 'video'" :src="media.src" muted autoplay loop playsinline></video>
		</figure>
		<h6 class="inspiration-tile-v2__trumpet" v-if="trumpet">
			<span>{{ trumpet }}</span>
		</h6>
		<h4 class="inspiration-tile-v2__headline" v-if="headline" v-html="headline"></h4>
		<p class="inspiration-tile-v2__bodytext" v-if="bodyText">{{ bodyText }}</p>
		<div class="inspiration-tile-v2__cta" v-if="cta.text" :class="cta.class" :style="cta.style"><span>{{ cta.text }}</span></div>
	</a>

	<article v-else :class="tileClass" :style="tileStyle">
		<figure class="inspiration-tile-v2__media" v-if="media">
			<img v-if="media.type === 'image'" :src="media.src" :alt="media.alt" :srcset="media.srcset" :sizes="media.sizes"/>
			<video v-if="media.type === 'video'" :src="media.src" muted autoplay loop playsinline></video>
		</figure>
		<h6 class="inspiration-tile-v2__trumpet" v-if="trumpet">
			<span>{{ trumpet }}</span>
		</h6>
		<h4 class="inspiration-tile-v2__headline" v-if="headline" v-html="headline"></h4>
		<p class="inspiration-tile-v2__bodytext" v-if="bodyText">{{ bodyText }}</p>
	</article>
</template>
