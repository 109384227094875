export default {
	name: 'header-logo',
	data() {
		return {
			dir: '',
			scrollPos: 0,
			deviceHeight: 0,
			docHeight: 0,

			stickyItems: {
				logo: {
					height: 0,
					pos: 0,
					lastDirChangePos: 0,
					el: '.header-logo__container',
					stickyFrom: 'top',
				},
			},
		};
	},

	mounted() {
		// Initiate sticky menu
		this.setupStickyItems();

		this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
		this.deviceHeight = window.innerHeight;
		this.docHeight = document.body.scrollHeight;
		this.lastDirChangePos = 0;
		this.dir = (this.scrollPos > this.lastScrollTop) ? 'down' : 'up';

		this.onScroll();

		window.addEventListener('scroll', this.onScroll, false);

		window.addEventListener('resize', function(){
			// Set new core calculations
			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
			this.deviceHeight = window.innerHeight;
			this.docHeight = document.body.scrollHeight;

			// Set new element calculations
			this.setupStickyItems();
		}.bind(this), false);
	},
	methods: {
		onScroll(){
			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
			this.deviceHeight = window.screen.height;
			this.docHeight = document.body.scrollHeight;
			
			this.dir = (this.scrollPos > this.lastScrollTop) ? 'down' : 'up';
			this.lastScrollTop = this.scrollPos;

			if (!this.pauseSticky) {
				this.animateTop(this.stickyItems.logo);
			}
		},
		animateTop(item){
			let currentPos = parseInt(item.el.style.top);

			if (this.scrollPos <= 0) {
				item.el.style.top = '0px';
				return;
			}

			if (this.scrollPos + this.deviceHeight >= this.docHeight) {
				item.el.style.top = - item.height + 'px';
				return;
			}
			
			item.pos = item.lastDirChangePos - this.scrollPos;

			if (item.pos > 0) {
				item.pos = 0;
			}
			if (item.pos < -item.height) {
				item.pos = -item.height;
			}
			
			item.el.style.top = item.pos + 'px';

			if (this.dir == 'up' && item.pos == -item.height && currentPos == -item.height) {
				item.lastDirChangePos = this.scrollPos - item.height;
			}
			if (this.dir == 'down' && item.pos == 0 && currentPos == 0) {
				item.lastDirChangePos = this.scrollPos;
			}
		},
		animateBottom(item){
			let currentPos = parseInt(item.el.style.bottom);

			if (this.scrollPos <= 0) {
				item.el.style.bottom = '0px';
				return;
			}

			if (this.scrollPos >= this.docHeight) {
				item.el.style.bottom = - item.height + 'px';
				return;
			}

			item.pos = item.lastDirChangePos - this.scrollPos;

			if (item.pos > 0) {
				item.pos = 0;
			}
			if (item.pos < -item.height) {
				item.pos = -item.height;
			}
			
			item.el.style.bottom = item.pos + 'px';

			if (this.dir == 'up' && item.pos == -item.height && currentPos == -item.height) {
				item.lastDirChangePos = this.scrollPos - item.height;
			}
			if (this.dir == 'down' && item.pos == 0 && currentPos == 0) {
				item.lastDirChangePos = this.scrollPos;
			}
		},
		setupStickyItems(){
			Object.keys(this.stickyItems).forEach(key => {
				const item = this.stickyItems[key];

				// Setup el
				if (typeof item.el === 'string') {
					if (item.el != '') {
						this.stickyItems[key].el = this.$el.querySelector(item.el);
					} else {
						this.stickyItems[key].el = this.$el;
					}
				}

				// Setup height
				this.stickyItems[key].height = this.stickyItems[key].el.offsetHeight;
				this.stickyItems[key].height += parseInt(window.getComputedStyle(this.stickyItems[key].el).getPropertyValue('margin-top'));
				this.stickyItems[key].height += parseInt(window.getComputedStyle(this.stickyItems[key].el).getPropertyValue('margin-bottom'));

				// Setup el pos
				this.stickyItems[key].pos = this.stickyItems[key].el.style.top;
			});
		},
		animateBackToSticky () {
			this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;

			Object.keys(this.stickyItems).forEach(key => {
				this.stickyItems[key].pos = 0;
			});
		},
	},
};