import Swiper from "swiper/dist/js/swiper.js";
import { inView } from "../../basics/js/methods/inView/inView";

import ImageProductsButton from "../image-products/image-products-button.vue"
import { mapActions } from 'vuex';

const calculateArrowVisibility = (swiper, element) => {
	if (swiper.isBeginning && swiper.isEnd) {
			element.querySelector('.swiper-button-next').classList.add('swiper-button-hidden');
			element.querySelector('.swiper-button-prev').classList.add('swiper-button-hidden');
	} else {
		element.querySelector('.swiper-button-prev').classList.remove('swiper-button-hidden');
		element.querySelector('.swiper-button-next').classList.remove('swiper-button-hidden');
	}
}

/**
 * @prop cssClass {String} - (decrecated description) 'slider--inspiration' will turn it into an inspirational slider
 * @prop settings {Object} - swiper option object, will overwrite default sliderOptions
 */

export default {
	name: "slider-v2",
	components: {
        ImageProductsButton
    },
	props: {
		sliderOptions: {
			type: Object,
			required: false,
		},
		slideCount: Number,
		type: String,
		slidesPerView: String
	},
	data() {
		return {
			slider: null,
			showPagination: false,
			showNavigation: true,

			defaultSliderSettings: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				pagination: false,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				preloadImages: false,
				watchOverflow: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 3,
				},

			},
		};
	},
	computed: {
		settings() {
			return Object.assign({}, this.defaultSliderSettings, this.sliderOptions);
		},

	},
	methods: {
		...mapActions([
			"openMosaicSliderOverlay",
		]),
		handleSlideClick(index) {
			this.openMosaicSliderOverlay(index);
		}
	},
	mounted() {


		const el = this.$el;

		if (this.slideCount < 2) {
			this.showPagination = false;
			this.showNavigation = false;

			Object.assign(this.settings, {
				slidesPerView: 1,
				navigation: {},
				loop: false,
			});
		}

		if (this.showNavigation) {
			// Delayed instancing of prev/next, to get the instance specific buttons
			this.settings.navigation = {
				nextEl: el.querySelector('.swiper-button-next'),
				prevEl: el.querySelector('.swiper-button-prev')
			}
		}

		this.settings.on = Object.assign({}, this.settings.on, {
			resize: function () {
				calculateArrowVisibility(this, el);
			}
		});

		this.slider = new Swiper(this.$refs.slider, this.settings);
		this.$emit("ready", this.slider);

		calculateArrowVisibility(this.slider, el);

		inView({
			el: this.$refs.slider,
			once: true,
			threshold: 0.2
		});
	},
};
