var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "configure-panels",
      class: { "is-loading": _vm.variantLoading },
    },
    [
      _vm.stepData && !_vm.stepData.optionGroup
        ? _c("div", { staticClass: "configure-panels__inner" }, [
            _c("h4", { staticClass: "configure-panels__headline" }, [
              _vm._v("\n            Data error\n        "),
            ]),
            _vm._v(" "),
            _vm.stepData.error
              ? _c("pre", { staticClass: "configure-panels__error" }, [
                  _vm._v(
                    "            " + _vm._s(_vm.stepData.error) + "\n        "
                  ),
                ])
              : _c("pre", { staticClass: "configure-panels__error" }, [
                  _vm._v(
                    "            " +
                      _vm._s(JSON.stringify(_vm.stepData, null, 6)) +
                      "\n        "
                  ),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.stepData &&
      _vm.stepData.optionGroup &&
      !_vm.stepLoading &&
      _vm.showModelSelector
        ? _c(
            "div",
            { staticClass: "configure-panels__inner" },
            [
              _vm.stepData &&
              _vm.stepData.optionGroup &&
              _vm.stepData.optionGroup.label
                ? _c(
                    "h4",
                    {
                      staticClass: "configure-panels__headline",
                      class: { "is-bordered": _vm.headlineIsSticky },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.stepData.optionGroup.label.toLowerCase()) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.stepData.optionGroup
                ? _c("div", {
                    staticClass: "configure-panels__bodytext",
                    domProps: {
                      innerHTML: _vm._s(_vm.stepData.optionGroup.description),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("model-selector", {
                attrs: {
                  items: _vm.stepData.optionGroup.options.default[0].items,
                  label: _vm.stepData.optionGroup.label,
                  "feature-id": _vm.stepData.optionGroup.id,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.stepData &&
      _vm.stepData.optionGroup &&
      !_vm.stepLoading &&
      _vm.showMaterialSelector
        ? _c("div", { staticClass: "configure-panels__inner" }, [
            _vm.stepData &&
            _vm.stepData.optionGroup &&
            _vm.stepData.optionGroup.label
              ? _c(
                  "h4",
                  {
                    staticClass: "configure-panels__headline",
                    class: { "is-bordered": _vm.headlineIsSticky },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.stepData.optionGroup.label.toLowerCase()) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stepData.optionGroup
              ? _c("div", {
                  staticClass: "configure-panels__bodytext",
                  domProps: {
                    innerHTML: _vm._s(_vm.stepData.optionGroup.description),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "configure-panels__controls" }, [
              _c(
                "div",
                { staticClass: "configure-panels__controls__top" },
                [
                  !_vm.isSearchView && !_vm.isFiltersView
                    ? _c(
                        "button",
                        {
                          staticClass: "control-button for-search",
                          on: { click: _vm.searchClickHandler },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-icon",
                              attrs: { viewBox: "0 0 100 100" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/dist/svg/symbols.svg#svg-search",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSearchView && !_vm.isFiltersView
                    ? _c(
                        "button",
                        {
                          staticClass: "control-button for-filters",
                          on: { click: _vm.filtersClickHandler },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-icon",
                              attrs: { viewBox: "0 0 100 100" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/dist/svg/symbols.svg#svg-filter-sliders",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSearchView || _vm.isFiltersView
                    ? _c(
                        "button",
                        {
                          staticClass: "control-button for-exit-filters",
                          on: { click: _vm.exitClickHandler },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-icon",
                              attrs: { viewBox: "0 0 100 100" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/dist/svg/symbols.svg#svg-minus2",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSearchView && _vm.categoryTabsList.length > 1
                    ? _c("tabs-bar", {
                        attrs: {
                          "tab-click-handler": _vm.categoryChangeHandler,
                          "active-index": _vm.activeCategoryIndex,
                          items: _vm.categoryTabsList,
                        },
                        inlineTemplate: {
                          render: function () {
                            var _vm = this
                            var _h = _vm.$createElement
                            var _c = _vm._self._c || _h
                            return _c(
                              "nav",
                              {
                                staticClass:
                                  "configure-panels__controls__category tabs-bar",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tabs-bar__inner" },
                                  _vm._l(_vm.items, function (item, index) {
                                    return _c(
                                      "button",
                                      {
                                        key: index,
                                        staticClass: "tabs-bar__button",
                                        class: {
                                          "is-active":
                                            index === _vm.activeIndex,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.tabClickHandler(
                                              item,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.name))])]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          },
                          staticRenderFns: [],
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.isFiltersView &&
                          (_vm.isSearchView ||
                            _vm.categoryTabsList.length === 1),
                        expression:
                          "!isFiltersView && (isSearchView || categoryTabsList.length === 1)",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchString,
                        expression: "searchString",
                      },
                    ],
                    ref: "searchInput",
                    staticClass: "configure-panels__controls__search",
                    attrs: { type: "text", placeholder: "Search" },
                    domProps: { value: _vm.searchString },
                    on: {
                      focus: _vm.searchFocusHandler,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchString = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isFiltersView &&
                            _vm.categoryTabsList.length === 1,
                          expression:
                            "isFiltersView && categoryTabsList.length === 1",
                        },
                      ],
                      staticClass: "configure-panels__controls__label",
                    },
                    [_vm._v("Filter")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "configure-panels__controls__filters",
                  class: {
                    "is-active":
                      _vm.isFiltersView && _vm.searchString.length === 0,
                  },
                },
                [
                  _c("tabs-bar", {
                    attrs: {
                      "tab-click-handler": _vm.subcategoryChangeHandler,
                      "active-index": _vm.activeSubCategoryIndex,
                      items: _vm.subCategoryTabsList,
                    },
                    inlineTemplate: {
                      render: function () {
                        var _vm = this
                        var _h = _vm.$createElement
                        var _c = _vm._self._c || _h
                        return _c(
                          "nav",
                          {
                            staticClass:
                              "configure-panels__controls__subcategory tabs-bar",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tabs-bar__inner" },
                              _vm._l(_vm.items, function (item, index) {
                                return _c(
                                  "button",
                                  {
                                    key: index,
                                    staticClass: "tabs-bar__button",
                                    class: {
                                      "is-active": index === _vm.activeIndex,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tabClickHandler(item, index)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.name))])]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      },
                      staticRenderFns: [],
                    },
                  }),
                  _vm._v(" "),
                  _vm.extractedColorList && _vm.extractedColorList.size > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "filter-section",
                          class: { "is-active": _vm.activeColorFilter },
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.extractedColorList,
                              function (item, index) {
                                return _c("li", { key: "color" + index }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "filter-button",
                                      class: {
                                        "is-active":
                                          item === _vm.activeColorFilter,
                                      },
                                      on: {
                                        click: function () {
                                          return _vm.colorFilterChosen(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("em", {
                                        style: {
                                          backgroundColor: _vm.colorMap[item],
                                        },
                                      }),
                                      _c("span", [_vm._v(_vm._s(item))]),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            (_vm.searchString.length >= _vm.searchStringMinLength ||
              _vm.activeColorFilter ||
              _vm.activeSubCategoryType) &&
            _vm.activeItems
              ? _c(
                  "div",
                  {
                    staticClass: "configure-panels__result",
                    class: { "is-searching": _vm.isSearchView },
                  },
                  [
                    _vm.activeItems.length > 0
                      ? _c("swatch-selector", {
                          key:
                            _vm.activeSubCategoryType +
                            "_" +
                            _vm.activeColorFilter +
                            "_" +
                            _vm.searchString,
                          attrs: {
                            "initial-selected-swatch": "",
                            "initial-expanded": true,
                            items: _vm.activeItems,
                            "feature-id": _vm.stepData.optionGroup.id,
                          },
                        })
                      : _c("h5", { staticClass: "no-results" }, [
                          _vm._v(_vm._s(_vm.systemText.noResultsText)),
                        ]),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "configure-panels__result",
                    class: { "is-searching": _vm.isSearchView },
                  },
                  _vm._l(
                    _vm.stepData.optionGroup.options[_vm.activeCategoryType],
                    function (group, index) {
                      return _c("swatch-selector", {
                        key: "group_" + index + "_" + group.name,
                        attrs: {
                          "initial-selected-swatch": "",
                          "initial-expanded": false,
                          "initial-rows": _vm.isMobile ? 1 : 2,
                          "row-size": 4,
                          "lazy-loading": true,
                          "has-info": true,
                          headline: group.name,
                          "body-text": group.description,
                          items: group.items,
                          properties: group.properties,
                          "feature-id": _vm.stepData.optionGroup.id,
                        },
                      })
                    }
                  ),
                  1
                ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }