export default {
	name: 'file-upload',
	data() {
		return {
			fileList: [],
			fileCount: 0,
			isOver: false
		};
	},
	methods: {
		changeHandler: function(event) {
			this.isOver = false;
			this.updateFileList([...event.target.files]);
		},
		dragEnterHandler: function() {
			this.isOver = true;
		},
		dragExitHandler: function() {
			this.isOver = false;
		},
		dropHandler: function(event) {
			this.isOver = false;
			this.updateFileList([...event.dataTransfer.files]);
		},
		fileItemDeleteHandler: function(event,index) {
			this.fileList.splice(index,1);
			this.fileCount--;
		},
		updateFileList: function(newFiles) {
			let newFilesLength = newFiles.length;
			for(let i = 0; i < newFilesLength; i++){
				this.fileList.push( {file:newFiles[i], index:this.fileCount} );
				this.fileCount++;
			}
		},
		logFileList: function(){
			console.log("FILE LIST:",this.fileList);
		},
		getFileList: function(){
			return this.fileList;
		}
	},
}
