import Vue from 'vue/dist/vue.esm';
import { getRequest } from '../integrations'; 
import { REQUEST_OVERLAY_PRODUCTS } from '../static';


const state = {
	contentData: null,
	productsData: null,
    overlayOpen: false,
}


const actions = {
   
    openImageProductsOverlay(context, skuList) {
		context.dispatch("closeMainMenu", {root: true});
		context.dispatch("closeMainSearch", {root: true});
		context.dispatch("closeAccountMenu", {root: true});
		context.dispatch("setBodyFixed", {
            transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		}, {root: true});
        context.dispatch(REQUEST_OVERLAY_PRODUCTS.action, {skuList: skuList});
	},
    async [REQUEST_OVERLAY_PRODUCTS.action](store, payload) {
		console.log("payload",payload)
		const response = await getRequest(store, {
			url: process.env.REQUEST_OVERLAY_PRODUCTS + '?skuList=' + payload.skuList,
			mutationTypes: REQUEST_OVERLAY_PRODUCTS
		});
		store.commit(REQUEST_OVERLAY_PRODUCTS.SUCCESS, response.results);		
	},	
	closeImageProductsOverlay(context) {
		context.commit("closeImageProductsOverlay");
	},
	setImageProductsOverlayData(context, data) {
		context.commit("setImageProductsOverlayData", data);
	},
}

const mutations = {	
    [REQUEST_OVERLAY_PRODUCTS.PENDING] (state, status) { // request products (for overlay)
		Vue.set(state, REQUEST_OVERLAY_PRODUCTS.loadingKey, status);
	},
	[REQUEST_OVERLAY_PRODUCTS.SUCCESS] (state, result) { // request products (for overlay)
		state.productsData = result;
        state.overlayOpen = true;
	},
    setImageProductsOverlayData(state, data){
		state.contentData = data;        
    },
    openImageProductsOverlay(state){
		state.overlayOpen = true;        
    },
    closeImageProductsOverlay(state){
		state.overlayOpen = false;
		state.productsData = null;
		state.contentData = null;
    },
    
}
export default { state, actions, mutations }