import Vue from 'vue/dist/vue.esm';

import * as types from './static';
import {minmax} from '../utils';

export default {
	appLoaded(state, loaded){
		state.appLoaded = loaded;
	},

	setIsMobile(state, isMobile){
		state.isMobile = isMobile;
	},


	setIsIOS(state, isIOS){
		state.isIOS = isIOS;
	},

	setWindowH(state, wH){
		state.windowH = wH;
	},
	setWindowW(state, wW){
		state.windowW = wW;
	},
	setWindowY(state, wY){
		state.windowY = wY;
	},


	setBodyFixed(state, payload){
		// COMMENT: payload - { transitionDelay[Number], setClass[Boolean], onlyMobile[Boolean] }
		// 		- transitionDelay: to wait until animation is done (only when applying)
		// 		- setClass: to know if it should be set or not (ex. open or closed configurator)
		// 		- onlyMobile: determines if it only should affect mobile. Mobile size-test is set in app.js

		const htmlTag = document.getElementsByTagName('html')[0];

		state.bodyFixed = payload.setClass;
		const logo = document.querySelector('.main-menu__logo');

		if(payload.setClass){
			if(!payload.onlyMobile || payload.onlyMobile && state.isMobile){
				// COMMENT: Set fixed if "onlyMobile = false" or onlyMobile and mobile is true
				const sniffedScrollPos = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
				state.sniffedScrollPos = sniffedScrollPos;

				setTimeout(()=>{
					if ( state.isIOS ) {
						//only do margin offset on ios
						document.body.style.marginTop = '-' + sniffedScrollPos + 'px';
						if (logo) {
							logo.style.marginTop =  '-' + (sniffedScrollPos-17) + 'px';
						}
					}

					// COMMENT: Set "is-fixed" class on html-tag to prevent further scroll on body (.is-fixed can be found in _general.scss)

					if(!htmlTag.classList.contains('is-fixed')){
						htmlTag.classList.add('is-fixed');
						state.isFixed = true;
					}

				}, payload.transitionDelay);
			}
		} else {
			// COMMENT: Always remove, whether or not its onlyMobile (viewport size could have changed inbetween)
			htmlTag.classList.remove('is-fixed');
			state.isFixed = false;
			setTimeout(()=>{
				document.body.style.removeProperty('margin-top'); // reset applied margin-top
				document.body.scrollTop = document.documentElement.scrollTop = state.sniffedScrollPos;
				if (logo) {
					logo.style.removeProperty('margin-top');
				}
			}, 50);
		}
	},
	showNotificationPanel(state, panel){
		// panel "payload" example can be found in NotificationPanel.js
		panel.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		state.notificationPanels.push(panel);
	},
	removeNotificationPanel(state, panelId){
		state.notificationPanels = state.notificationPanels.filter((panel) => { return panel.id != panelId; });
	},

	[types.GET_OPTION_GROUP_ASYNC.PENDING] (state, status) { // campaignCode
		Vue.set(state, types.GET_OPTION_GROUP_ASYNC.loadingKey, status);
	},
	[types.GET_OPTION_GROUP_ASYNC.SUCCESS] (state, data) { // campaignCode
		Vue.set(state, types.GET_OPTION_GROUP_ASYNC.stateKey, data);
	},

	openMainSearch(state){
		state.searchOverlayOpen = true;
	},
	closeMainSearch(state){
		state.searchOverlayOpen = false;
	},
	openMainServiceNav(state){
		state.mainServiceNavOpen = true;
	},
	closeMainServiceNav(state){
		state.mainServiceNavOpen = false;
	},
	openAddonsOverlay(state){
		state.addonsOverlayOpen = true;
	},
	closeAddonsOverlay(state){
		state.addonsOverlayOpen = false;
	},
	openMiniCart(state){
		state.miniCartActive = true;
	},
	closeMiniCart(state){
		state.miniCartActive = false;
	},
	openMainMenu(state){
		state.mainMenuOpen = true;
	},
	closeMainMenu(state){
		state.mainMenuOpen = false;
	},
	openAccountMenu(state){
		state.accountMenuOpen = true;
	},
	closeAccountMenu(state){
		state.accountMenuOpen = false;
	},
	openDashboard(state){
		state.dashboardOpen = true;
	},
	closeDashboard(state){
		state.dashboardOpen = false;
	},
	openCountrySelector(state){
		state.countrySelectorOpen = true;
	},
	closeCountrySelector(state){
		state.countrySelectorOpen = false;
	},
	openMosaicSliderOverlay(state, activeIndex){
		state.mosaicSliderOverlayOpen = true;
		state.mosaicSliderOverlayActiveIndex = activeIndex;
	},
	closeMosaicSliderOverlay(state){
		state.mosaicSliderOverlayActiveIndex = -1;
		state.mosaicSliderOverlayOpen = false;
	},
	roomExplorerSingleProductChosen(state, data){
		state.roomExplorerSingleProduct = data;
	},
	roomExplorerOverlayProductsChosen(state, data){
		state.roomExplorerOverlayProducts = data;
	},
	showOverlayBackdrop(state, context){
		state.overlayBackdropContext = context;
		state.overlayBackdropActive = true;
	},
	hideOverlayBackdrop(state){
		state.overlayBackdropContext = '';
		state.overlayBackdropActive = false;
	},
	openShortcutsOverlay(state, data){
		state.shortcutsOverlayOpen = true;
		state.shortcutsData = data;
	},
	closeShortcutsOverlay(state){
		state.shortcutsOverlayOpen = false;
		state.shortcutsData = null;
	},
	openExplanationOverlay(state, data){
		state.explanationOverlayOpen = true;
		state.explanationData = data;
	},
	closeExplanationOverlay(state){
		state.explanationOverlayOpen = false;
		state.explanationData = null;
	},
	setTriggerExperienceResize(state, newVal){
		state.triggerExperienceResize = newVal;
	},

	// Cart related mutations/tasks
	[types.GET_CART_ITEMS.SUCCESS](state, items){
		if (!items) return;

		Vue.set(state, 'cartItems', items);

		state.cartItemsCount = items.length;
		state.cartLoaded = true;

		let subTotal = 0;
		state.cartItems.forEach(item => {
			subTotal = item.subTotal;
		});
		state.cartSubTotal = subTotal;
	},
	[types.GET_CART_ITEMS.PENDING] (state, status) { // cart
		Vue.set(state, types.GET_CART_ITEMS.loadingKey, status);
	},
	removeCartItem(state, item){
		const skuToFind = item.sku;
		const found = state.cartItems.filter(el => {
			return el.sku !== skuToFind;
		});
		state.cartItems = found;
		state.cartItemsCount = state.cartItems.length;
	},
	cartItemUpdate(state, item){
		// Should be done by api, which respond with all cart items..
		if(item.sku !== null){
			const skuToFind = item.sku;
			const found = state.cartItems.filter(item => item.sku === skuToFind);
			if(found.length > 0){
				// COMMENT: Update existing item
				found[0].quantity = item.quantity;
				found[0].price = (parseInt(found[0].unitPrice) * found[0].quantity).toFixed(2);
			} else {
				// COMMENT: Add item to cart
				state.cartItems.push({sku: item.sku, quantity: item.quantity, price: item.unitPrice * item.quantity, lineItemId: item.lineItemId});
			}
		} else {
			console.error('No SKU-number provided for cart..');
		}
	},


	// CHECKOUT related mutations
	checkoutAddCampaignCode(state, dataJson){
		state.checkout.campaignCode = dataJson;
	},
	checkoutRemoveCampaignCode(state){
		// TMP - reset campaign code
		Vue.set(state, types.POST_CAMPAIGN_CODE_ASYNC.stateKey, {
			code: '',
			discountAmount: 0,
		});
	},
	updateModalState(state, payload) {
		state.modalStatus = {
			...state.modals,
			status: payload.status,
			name: payload.name
		};

	},

	// PDP ADDONS OVERLAY ASYNC PRODUCT
	getAddonsForOverlay(state, dataJson){
		state.pdpAddonsProducts = dataJson;
	},

	getAddonsForBlock(state, dataJson){
		state.relevantAddonsProducts = dataJson;
	},

	updateGuidedTour(state, newStatus) {
		state.guidedTourStatus = newStatus;
	},


	openMaterialOverlay(state, payload){
		state.materialOverlayOpen = true;

		state.materialsData = payload.data;
		state.activeMaterialIndex = payload.activeIndex;
	},

	setActiveMaterialCategory(state, index) {
		state.activeMaterialIndex = index;
	},
	closeMaterialOverlay(state){
		state.materialOverlayOpen = false;
		state.materialsData = null;
		state.activeMaterialIndex = -1;
	},

	openProductFilters(state){
		state.productFiltersOpen = true;
	},
	closeProductFilters(state){
		state.productFiltersOpen = false;
	},

	openToolTip(state, data){
		state.tooltipText = data.txt;
		let newX = minmax(data.event.clientX, 100, 10, window.innerWidth-10);
		state.tooltipPosX = newX;
		let newY = minmax(data.event.clientY, 50, 10, window.innerHeight-10);

		if (window.innerHeight > 768) {
			newY -= 50;
		}
		state.tooltipPosY = newY;

	},
	closeToolTip(state){
		state.tooltipText = null;
		state.tooltipPosX = -1;
		state.tooltipPosY = -1;
	},

	[types.SET_FILTER_COLOR.mutation] (state, payload){
		Vue.set(state, types.SET_FILTER_COLOR.stateKey, payload.color);
	},
	[types.CART_ITEM_ADD_ENDPOINT_ASYNC.SUCCESS] (state) { // add to cart
		Vue.set(state, types.CART_ITEM_ADD_ENDPOINT_ASYNC.stateKey, true);
	},
	[types.CART_ITEM_ADD_ENDPOINT_ASYNC.PENDING] (state, status) { // add to cart
		Vue.set(state, types.CART_ITEM_ADD_ENDPOINT_ASYNC.loadingKey, status);
	},
	[types.CART_ITEM_ADD_ENDPOINT_ASYNC.FAILURE] (state) { // add to cart fail
		Vue.set(state, types.CART_ITEM_ADD_ENDPOINT_ASYNC.loadingKey, false);
	},
	[types.UPDATE_QUANTITY_ASYNC.SUCCESS] (state) { // update quantity
		Vue.set(state, types.UPDATE_QUANTITY_ASYNC.stateKey, true);
	},
	[types.UPDATE_QUANTITY_ASYNC.PENDING] (state, status) { // update quantiry
		Vue.set(state, types.UPDATE_QUANTITY_ASYNC.loadingKey, status);
	},
	[types.REQUEST_QUOTE_ASYNC.SUCCESS] (state) { // cart - request quote
		Vue.set(state, types.REQUEST_QUOTE_ASYNC.stateKey, true);
	},
	[types.REQUEST_QUOTE_ASYNC.PENDING] (state, status) { // cart - request quote
		Vue.set(state, types.REQUEST_QUOTE_ASYNC.loadingKey, status);
	},
	[types.EMPTY_CART_ASYNC.PENDING] (state, status) { // empty cart
		Vue.set(state, types.EMPTY_CART_ASYNC.loadingKey, status);
	},
	[types.IMPORT_CART_ASYNC.PENDING] (state, status) { // import cart
		Vue.set(state, types.IMPORT_CART_ASYNC.loadingKey, status);
	},
	[types.GET_RECOMMENDATION.PENDING] (state, status) { // empty cart
		Vue.set(state, types.GET_RECOMMENDATION.loadingKey, status);
	},
	[types.SHARE_SAVED_LIST_ASYNC.PENDING] (state, status) { // share cart
		Vue.set(state, types.SHARE_SAVED_LIST_ASYNC.loadingKey, status);
	},
	[types.IMPORT_SAVED_LIST_ASYNC.PENDING] (state, status) { // share cart import
		Vue.set(state, types.IMPORT_SAVED_LIST_ASYNC.loadingKey, status);
	},
	setCartShareCartIsActive(state, status) {
		Vue.set(state, 'cartShareCartIsActive', status);
	},
	[types.EDIT_ORDER_ASYNC.SUCCESS] (state) { // order overview - edit order
		Vue.set(state, types.EDIT_ORDER_ASYNC.stateKey, true);
	},
	[types.EDIT_ORDER_ASYNC.PENDING] (state, status) { // order overview - edit order
		Vue.set(state, types.EDIT_ORDER_ASYNC.loadingKey, status);
	},
	[types.SELECT_COUNTRY.SUCCESS] (state, status) { // country selector
		Vue.set(state, types.SELECT_COUNTRY.stateKey, status);
	},
	[types.SELECT_COUNTRY.PENDING] (state, status) { // country selector
		Vue.set(state, types.SELECT_COUNTRY.loadingKey, status);
	},
	[types.SELECT_USER_TYPE.SUCCESS] (state, status) { // user type selector
		Vue.set(state, types.SELECT_USER_TYPE.stateKey, status);
	},
	[types.SELECT_USER_TYPE.PENDING] (state, status) { // user type selector
		Vue.set(state, types.SELECT_USER_TYPE.loadingKey, status);
	}
};
