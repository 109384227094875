import { TweenLite } from "gsap";
import { uid } from "../../basics/js/utils";
import PreviewTiles from "../../components/preview-tiles/preview-tiles";
import ProductTile from "../../components/product-tile/product-tile";
import InputWithLabel from "../../components/form-elements/templates/input-with-label";

export default {
	name: "accordion",
	components: {
		PreviewTiles,
		InputWithLabel,
		ProductTile,
	},
	props: {
		item: Object,
		isActive: Boolean,
		groupActiveAccordionId: String,
		$v: {
			type: Object,
			required: false,
		},
		alignToSelf: Boolean,
		skipScrollToTop: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isOpen: false,
			isMounted: false,
			uid: "",
		};
	},
	mounted() {
		this.isMounted = true;
		this.uid = uid();
		if (
			this.$refs.inputfield &&
			this.$refs.inputfield.getAttribute("checked") === "checked"
		) {
			// console.log('input', this.$refs.inputfield.checked)
			this.isOpen =
				this.$refs.inputfield.getAttribute("checked") === "checked";
			this.$refs.inputfield.checked = this.isOpen;
			this.$emit("accordion-toggle", this.uid);
		}
		if(this.isActive) {
			this.open();
		}
	},
	watch: {
		isActive() {
			if (this.isActive) {
				// Open accordion item
				this.close();
			}
		},
		groupActiveAccordionId() {
			this.isOpen = this.groupActiveAccordionId === this.uid;
		},
	},
	methods: {
		toggle() {
			this.$parent.$emit("toggleAccordion", this.isOpen);
			if (!this.isOpen) {
				// Open accordion item
				this.open();
			} else {
				this.close();
			}

			this.$emit("accordion-toggle", this.uid);
		},
		open() {
			const toggleContent = this.$refs.content;

			this.isOpen = true;

			TweenLite.set(toggleContent, { height: "auto" });
			TweenLite.from(toggleContent, 0.2, {
				height: 0,
				onComplete: () => {
					let offset = -78;
					let targetY =
						(this.alignToSelf
							? this.$el.getBoundingClientRect().top
							: this.$parent.$el.getBoundingClientRect().top) +
						window.pageYOffset +
						offset;

					if ( !this.skipScrollToTop ) window.scrollTo({ top: targetY, behavior: "smooth" });
					// this.$parent.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
				},
			});
		},
		close() {
			const toggleContent = this.$refs.content;
			if ( !toggleContent ) return;

			TweenLite.to(toggleContent, 0.2, {
				height: 0,
				onComplete: () => {
					this.isOpen = false;
				},
			});
		},
	},
};
