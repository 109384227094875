// https://github.com/charliekassel/vuejs-datepicker#readme
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/dist/locale/";

export default {
	name: 'custom-datepicker',
	components: {
		Datepicker,
	},
	data() {
		return {
			// DATEPICKER -  third party options
			format: "d MMMM yyyy",
			eventMsg: null,
			language: "en",
			languages: lang,
			// ----------------

			hasValue: false,
			date: new Date(),
			disabledDates: {
				to: new Date()
			}

		};
	},
	computed: {
		localDate(){
			return this.date.toString();
		}
	},
	mounted(){
		this.calendar = this.$refs.datepickerelement;
	},
	methods: {
		handleClickOnInput(){
			this.openPicker();
		},
		openPicker() {
			this.calendar.showCalendar();
		},
	}
};