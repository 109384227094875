import { mapActions } from "vuex";
import { SELECT_COUNTRY } from "../../basics/js/store/static";
// import countriesData from "../../../mock/countries";

export default {
	name: "country-selector-input",
	props: {
		initialCountry: {
			type: String,
			default: "DK",
		},
		initialCountryList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			chosenCountry:
				this.initialCountryList.find(
					(c) => c.countryCode === this.initialCountry
				) || "",
			chosenCountryNative:
				this.initialCountryList.find(
					(c) => c.countryCode === this.initialCountry
				) || "",
		};
	},
	methods: {
		...mapActions([SELECT_COUNTRY.action]),

		async changeCountry(countryCode) {
			const body = {
				countryCode: countryCode.value,
			};

			const callback = function () {
				location.reload(true);
			};
			const result = await this[SELECT_COUNTRY.action]({
				body,
				callback,
			});
			console.log(result);
		},

		acceptChosenItem() {
			this.$parent.$emit("chosen", {
				value: this.chosenCountry.countryCode,
			});
		},
		acceptChosenItemNative() {
			this.$parent.$emit("chosen", { value: this.chosenCountryNative });
		},

		filter(option, label, search) {
			return (label || "").toLowerCase().startsWith(search.toLowerCase());
		},
	},
};
