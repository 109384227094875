import { mapState, mapActions } from "vuex";
import AccordionItem from "../../components/accordion/accordion";
import FormCheckboxFilter from "./form-checkbox-filter";
import FormLogicalFilter from "./form-logical-filter";
import Store from "@store/store";

import { SET_FILTER_COLOR } from "../../basics/js/store/static";

import isChecked from "./methods/isChecked";
import getLogicalValue from './methods/getLogicalValue'

export default {
	name: "accordion-item-filter",
	extends: AccordionItem,
	components: {
		FormCheckboxFilter,
		FormLogicalFilter,
	},
	props: {
		color: String,
		filterGroupIndex: Number,
		item: Object,
	},
	methods: {
		...mapActions([
			"openToolTip",
		]),
		showRemainingFilters: function () {
			this.allFiltersShown = true;
		},
		onInputChange: function (event) {
			this.$emit("update", { facet: event.facet, id: event.id, name: event.name, value: event.value });
		},
		isChecked,
		getLogicalValue,
		search: function (event) {
			this.searchQuery = event.target.value;
		},
	},
	data() {
		return {
			checkedFilters: [],
			searchQuery: "",

			allFiltersShown: false,
		};
	},
	computed: {
		...mapState([SET_FILTER_COLOR.stateKey]),
		useShowAll() {
			// console.log("useShowAll?",
			// !!this.item,
			// !!this.item.categories,
			// this.item.categories.length === 1,
			// !!this.item.categories[0],
			// !!this.item.categories[0].filters,
			// this.item.categories[0].filters.length > 10 );

			if ( !!this.item
				&& !!this.item.categories
				&& this.item.categories.length === 1
				&& !!this.item.categories[0]
				&& !!this.item.categories[0].filters
				&& this.item.categories[0].filters.length > 10
				&& this.searchQuery.length === 0 ) return true;
			return false;
		},
		selectedFilters() {
			return Store.state.search.selectedFacets;
		},
		backgroundColor() {
			return this[SET_FILTER_COLOR.stateKey] ? `--product-filter-bg-color-active: ${this[SET_FILTER_COLOR.stateKey]}` : "";
		},
		categories() {
			if (this.item.hasSearch && this.searchQuery.length > 1) {
				console.log("looking for", this.searchQuery);
				let filteredCategory = {filters: [], name: "Search Results"};
				this.item.categories.forEach((category) => {
					category.filters.forEach((filter) => {
						console.log("filter", filter);
						if (filter.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
							filteredCategory.filters.push(filter);
						}
					});
				});
				console.log("filteredCategory", filteredCategory);
				return [filteredCategory];
			} else if (this.useShowAll) {
				if (this.allFiltersShown) {
					return this.item.categories;
				} else {
					// return the first 10 filters in the first category
					return this.item.categories.map((category) => {
						return {
							...category,
							filters: category.filters.slice(0, 10),
						};
					});
				}
			}
			return this.item.categories;
		},
	},
};
