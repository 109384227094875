var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "zoom-overlay",
      class: { "is-active": _vm.overlayImage && _vm.isOpen },
      attrs: { tabindex: "0" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close($event)
        },
      },
    },
    [
      _c("div", { ref: "inner", staticClass: "zoom-overlay__inner" }, [
        _vm.overlayImage
          ? _c("img", {
              ref: "image",
              attrs: {
                src: _vm.overlayImage.srcLrg || _vm.overlayImage.src,
                alt: _vm.overlayImage.title,
              },
            })
          : _c("img", { ref: "image", attrs: { src: "" } }),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "zoom-overlay__close", on: { click: _vm.close } },
        [
          _c(
            "svg",
            { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
            [
              _c("use", {
                attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-close" },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }