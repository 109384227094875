export const CylindoViewMode = {
    DESKTOP: "DESKTOP",
    MOBILE_BLUR: "MOBILE_BLUR",
    MOBILE_FOCUS: "MOBILE_FOCUS",
}

export const ProductType = {
    BASIC: "BASIC",
    CONFIGURABLE: "CONFIGURABLE",
    PLANNABLE: "PLANNABLE",
	COMPOUND: "COMPOUND"
}

//MUST be lowercase
export const OptionsType = {
    DEFAULT: "default",
    SPECIAL: "special",
}

export const ControlsMode = {
    DEFAULT: "DEFAULT",
    SEARCH: "SEARCH",
    FILTERS: "FILTERS",
}

export const CylindoFeature = {
    LEATHER: "LEATHER",
    TEXTILE: "TEXTILE",
    SEAT: "SEAT",
}

export const CookiePrefix = 'muuto-custom-config_';
