const sortSuggestions = function(suggestions) {

    return suggestions.sort((a,b) => {
        if(a.hits < b.hits){
            return 1
        } else if(a.hits > b.hits) {
            return -1
        } else {
            return 0
        }
    });

}

export default sortSuggestions