import { TimelineMax } from "gsap";
import { mapActions } from "vuex";
import InputWithLabel from "../../components/form-elements/templates/input-with-label";
import FileUpload from "../../components/file-upload/file-upload";
import Modal from "../../components/modal/modal";
import AccordionGroup from "../../components/accordion/accordion-group";
import FileUploadContainer from "../../components/file-upload-field/file-upload-container";

export default {
	name: "claim-flow",
	components: {
		InputWithLabel,
		FileUpload,
		Modal,
		AccordionGroup,
		FileUploadContainer,
	},
	data() {
		return {
			claimflowSubmitReady: false,
			tl: null, // COMMENT: GSAP TimelineMax instance
			openCloseTiming: 0.35,

			stepIndexActive: 0,
			stepIndexPrev: null, // COMMENT: Previous active index
			steps: [
				// COMMENT: Predetermined static steps
				{
					index: 0,
					isActive: true,
					ref: "step1",
					contentRef: "step1-content",
					isValidated: false,
					hasError: false,
				},
				{
					index: 1,
					isActive: false,
					ref: "step2",
					contentRef: "step2-content",
					isValidated: false,
					hasError: false,
				},
				{
					index: 2,
					isActive: false,
					ref: "step3",
					contentRef: "step3-content",
					isValidated: false,
					hasError: false,
				},
				{
					index: 3,
					isActive: false,
					ref: "step4",
					contentRef: "step4-content",
					isValidated: false,
					hasError: false,
				},
			],

			// COMMENT: Input vue-models below
			issueTypeRadioModel: "",
			productList: [
				// COMMENT: Contains extra product item ID's (when more than one)
				{
					index: 0,
					isInvalid: false,
					isRemovable: false,
					model: {
						id: "",
						name: "",
						quantity: "",
					},
				},
			],
			productListIndex: 0,
			orderInput: {
				value: "",
				isInvalid: false,
			},

			// issueDescriptionInput // the issue description textarea
			claimsReferenceInput: {
				value: "",
				isInvalid: false,
			},

			// issueDescriptionInput // the issue description textarea
			issueDescriptionValue: "",
		};
	},
	watch: {
		issueDescriptionValue(newValue) {
			// COMMENT: Here we validate the issue description, and enabling the submit button when not empty
			this.claimflowSubmitReady = newValue.length > 0;
		},
	},
	methods: {
		...mapActions(["openModal", "closeModal"]),
		validateStep() {
			var errors = 0;

			if (this.stepIndexActive === 0) {
				// COMMENT: Validate first step - radios
				console.log("validating step 0 - radios");

				if (this.issueTypeRadioModel === "") {
					errors++;
				}
			} else if (this.stepIndexActive === 1) {
				// validate second step
				// COMMENT: Validate order ID and Product inputs
				if (this.orderInput.value) {
					this.orderInput.isInvalid = false;
				} else {
					this.orderInput.isInvalid = true;
					errors++;
				}

				console.log("this.productList: ", this.productList[0]);
				console.log("this.productList: ", typeof this.productList[0]);

				// COMMENT/TODO: Find a way to validate the dynamically added products, from their v-models
				this.productList.forEach((product) => {
					console.log("product: ", product);
					if (
						product.model.id &&
						product.model.name &&
						product.model.quantity
					) {
						if (!product.isInvalid) {
							product.isInvalid = false;
						}
					} else {
						product.isInvalid = true;
						errors++;
					}
				});
			} else if (this.stepIndexActive === 2) {
				// COMMENT: Validate added files, maybe just if any is attached
				console.log("validating step 2 - attached files");

				console.log(
					"this.$refs.fileUpload.fileList: ",
					this.$refs.fileUpload.filesAdded
				);

				// COMMENT: TIGHT COUPLED with fileUpload component
				if (this.$refs.fileUpload.filesAdded === 0) {
					errors++;
				}
			}

			this.steps[this.stepIndexActive].isValidated =
				errors > 0 ? false : true; // tmp
			this.steps[this.stepIndexActive].hasError =
				errors > 0 ? true : false;

			console.log(
				"this.steps[this.stepIndexActive].isValidated: ",
				this.steps[this.stepIndexActive].isValidated
			);

			console.log("errors in validation count: ", errors);

			return errors;
		},

		productListAdd() {
			this.productListIndex++; // Comment: Start by counting up, as the first is already printet
			this.productList.push({
				index: this.productListIndex,
				isInvalid: false,
				isRemovable: true,
				model: {
					id: "", // mapped to id-input
					name: "", // mapped to name-input
					quantity: "",
				},
			});
		},
		productListRemove(itemIndex) {
			// COMMENT: Importance here is, that the :key-index is the products own index, not the for-loop-index
			this.productList.splice(itemIndex, 1);
		},

		headlineClick(e, headlineIndex) {
			if (
				this.steps[headlineIndex].isValidated &&
				this.stepIndexActive != headlineIndex
			) {
				this.animateContent(this.stepIndexActive, headlineIndex);
			}
		},
		nextClick(e, nextIndex) {
			this.validateStep();

			if (this.steps[this.stepIndexActive].isValidated) {
				this.animateContent(this.stepIndexActive, nextIndex);
			}
		},
		animateContent(oldIndex, newIndex) {
			this.tl = new TimelineMax();

			var activeRef = this.$refs[this.steps[oldIndex].ref];
			var activeContentRef = this.$refs[this.steps[oldIndex].contentRef];

			var nextRef = this.$refs[this.steps[newIndex].ref];
			var nextContentRef = this.$refs[this.steps[newIndex].contentRef];

			this.tl.addLabel("start");

			// COMMENT: Hide old active
			this.tl.to(activeContentRef, this.openCloseTiming, {
				height: 0,
				clearProps: "all",
				onComplete: () => {
					if (activeRef.classList.contains("is-active")) {
						activeRef.classList.remove("is-active");
					}
				},
			});

			// COMMENT: New step show
			if (!nextRef.classList.contains("is-active")) {
				nextRef.classList.add("is-active");
			}
			this.tl.set(nextContentRef, { height: "auto" });
			this.tl.from(
				nextContentRef,
				this.openCloseTiming,
				{
					height: 0,
					clearProps: "all",
					onComplete: () => {
						if (activeRef.classList.contains("is-active")) {
							activeRef.classList.remove("is-active");
						}

						this.tl.kill(); // garbage collection
					},
				},
				"start"
			);

			// this.tl.play('start');

			this.stepIndexPrev = oldIndex;
			this.stepIndexActive = newIndex;
		},
		submitClaimflow() {
			if (this.claimflowSubmitReady) {
				// TODO: Submit the form!!
				this.claimflowSubmitReady = false;
				console.log("Submitting stuff here, or redirect...");
			}
		},
	},
};
