import Accordion from './accordion'

export default {
	name: 'accordion-group',
	components: {
		Accordion
	},
	data() {
		return {
			groupActiveAccordionId:''
		}
	},
	mounted() {
		this.isMounted = true;
	},
	methods: {
		toggleAccordionItem: function(uid) {
			this.groupActiveAccordionId = uid;
		}
	}
};
