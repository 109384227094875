
import FeedbackButton from '../feedback-button/feedback-button';

export default {
	name: 'feedback-button-example-wrapper',
	components: {
		FeedbackButton,
	},
	data(){
		return {
			firstExampleButtonStatus: 'idle',
			secondExampleButtonStatus: 'idle',
			thirdExampleButtonStatus: 'idle',
			fourthExampleButtonStatus: 'error',

			thirdButtonExampleTimeout: null,
		};
	},
	methods: {

		// First button examples
		firstExampleButtonRevertCallback(){
			this.firstExampleButtonStatus = 'idle';
		},
		firstExampleButtonClick(){
			console.log("firstExampleButtonClick")
			this.firstExampleButtonStatus = 'loading';

			setTimeout(()=>{
				this.firstExampleButtonStatus = 'done';
			}, 2500)
		},

		// Second button examples
		secondExampleButtonRevertCallback(){
			this.secondExampleButtonStatus = 'idle';
		},
		secondExampleButtonClick(){
			console.log("secondExampleButtonClick")
			this.secondExampleButtonStatus = 'loading';

			setTimeout(()=>{
				this.secondExampleButtonStatus = 'done';
			}, 4000)
		},

		// third button examples - which gets an error
		thirdExampleButtonRevertCallback(){
			this.thirdExampleButtonStatus = 'idle';
		},
		thirdExampleButtonClick(){
			console.log("thirdExampleButtonClick")
			this.thirdExampleButtonStatus = 'loading';

			this.thirdButtonExampleTimeout = setTimeout(()=>{
				this.thirdExampleButtonStatus = 'error';
			}, 2500)
		},
		thirdExampleButtonReset(){
			clearTimeout(this.thirdButtonExampleTimeout);
			this.thirdExampleButtonStatus = 'idle';
		},

		// fourth button examples - starts with an error
		fourthExampleButtonRevertCallback(){
			this.fourthExampleButtonStatus = 'idle';
		},
		fourthExampleButtonClick(){
			this.fourthExampleButtonStatus = 'loading';

			setTimeout(()=>{
				this.fourthExampleButtonStatus = 'done';
			}, 2500)
		},
	},
};
