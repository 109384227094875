
import { TweenMax, Power1 } from 'gsap';

import ProductLineCore from '../../components/product-line/product-line-core';

// import { mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
	name: 'product-line-search',
	extends: ProductLineCore,
	props: {
	},
	data() {
		return {
		};
	},
	mounted(){
	},
	methods: {
		...mapActions([
			'removeCartItem',
		]),
		removeProduct(product){
			TweenMax.to(this.$el, .005, {
				height: 0,
				ease: Power1.easeOut,
				onComplete: ()=>{
					this.removed = true;
					this.removeCartItem(product); // action
				}
			});
		},
		removeAnimationEnd(product){
			this.removed = true;
			this.removeCartItem(product); // action
		},
	},
};