import MosaicSlider from '../../components/mosaic-slider/mosaic-slider';

import { mapActions, mapState} from 'vuex';

export default {
	name: 'mosaic-slider-overlay',
	components: {
		// ProductLineRoomExplorer,
		MosaicSlider
	},
	props: {
		hasZoom: false,
	},
	data() {
		return {
			isActive: false
		}
	},
	computed: {
		...mapState({
			mosaicSliderOverlayOpen: state => state.mosaicSliderOverlayOpen
		})
	},
	watch:{
		mosaicSliderOverlayOpen(newVal) {
			if (newVal) {
				this.open();
			} else {
				this.close();
			}
		}
	},
	methods: {
		...mapActions([
			'showOverlayBackdrop',
			'hideOverlayBackdrop',
			'closeMosaicSliderOverlay',
			// 'roomExplorerOverlayProductsChosen'
		]),
		open() {
			// console.log('this.mosaicSliderOverlayOpen: ', this.mosaicSliderOverlayOpen);
			this.isActive = true;
			// MosaicSlider.initSlider();
			this.showOverlayBackdrop();
		},
		close(){
			this.isActive = false;
			this.hideOverlayBackdrop();
			this.closeMosaicSliderOverlay();
			// this.roomExplorerOverlayProductsChosen(null);
		},
	},
};
