import { mapActions, mapState} from 'vuex';

export default {
    name: "shortcuts-overlay",
    computed: {
		...mapState({
			shortcutsData: state => state.shortcutsData,
			shortcutsOverlayOpen: state => state.shortcutsOverlayOpen,
		}),
	},
	props: {
		closeText: {
			type: String,
			default: "Close"
		}
	},
    methods: {
		...mapActions([
			'openShortcutsOverlay',
			'closeShortcutsOverlay',
			'hideOverlayBackdrop',
		]),
		close(){
            this.closeShortcutsOverlay();
			this.hideOverlayBackdrop();
		},
	}

}