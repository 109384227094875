export default {
    configureData: {
        headline: "Choose Oslo member",
        bodyText: "Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
        items:[
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_blank",
                url: "#PDP-oslo-2seater",
                label: "2-seater"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_self",
                url: "#PDP-oslo-3seater",
                label: "3-seater"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_parent",
                url: "#PDP-oslo-bench",
                label: "Bench"
            },
            {
                isPlanner: true,
                imageSrc: "/static/images/product/chairs.png",
                target: "_parent",
                color: "red",
                url: "#planner-tool",
                label: "Try out planner tool"
            },
        ]
    },
    arData: {
        headline: "Try with AR",
        bodyText: "Form follows function - so whether you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",
        items:[
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_blank",
                url: "#PDP-oslo-2seater",
                label: "2-seater"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_self",
                url: "#PDP-oslo-3seater",
                label: "3-seater"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_parent",
                url: "#PDP-oslo-bench",
                label: "Bench"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_parent",
                url: "#PDP-oslo-bench",
                label: "Bench"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_parent",
                url: "#PDP-oslo-bench",
                label: "Bench"
            },
            {
                imageSrc: "/static/images/product/Outline_3_seater_cognac_Refine_leather_-low-res.png",
                target: "_parent",
                url: "#PDP-oslo-bench",
                label: "Bench"
            },
        ]
    }
}
