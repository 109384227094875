import CheckoutBlock from '../checkout-block/checkout-block';
import OrderSummary from '../order-summary/order-summary';

const formData = function(){
	if ( !document.querySelector('form[ref=checkoutForm]') ) return;

	const form = document.querySelector('form[ref=checkoutForm]').elements
	const formData = {}

	for(let e=0; e<form.length; e++){

		const field = form[e]

		if(field.nodeName !== 'BUTTON' && field.name){
			formData[field.name] = {
				model:field.value ? field.value : '',
				alternate: field.dataset.alternate || null,
				required: field.dataset.required,
				invalid:null}
		}

	}

	return formData
}

const stringifyFormData = function(form){

	let flatObject = {}

	const expand = function(str, value,target)
	{
		var items = str.split(".") // split on dot notation
		var output = target // prepare an empty object, to fill later
		var ref = output // keep a reference of the new object

		//  loop through all nodes, except the last one
		for(var i = 0; i < items.length-1; i ++)
		{
			ref[items[i]] = ref[items[i]] ? ref[items[i]] : {} // reuse existing or create a new element inside the reference
			ref = ref[items[i]] // shift the reference to the newly created object
		}

		ref[items[items.length - 1]] = value // apply the final value

		return output // return the full object
	}

	for(let input in form){
		flatObject[input] = form[input].model
	}

	let expandedObject = {}
	for(let line in flatObject){
		expandedObject = expand(line,flatObject[line],expandedObject)
	}

	return JSON.stringify(expandedObject)
}

export default {
	name: 'checkout',
	components: {
		CheckoutBlock,
		OrderSummary,
	},
	data() {
		return {
			form:formData()
		}
	},
	methods: {
		placeOrder(e){
			let validates = true
			let groupValidates = false

			const groups = {}
			let form = this.form

			if (e.submitter.nodeName !== 'BUTTON'){
				console.error("Unexpected nodeName");
				return false;
			} 

			// Set order type: order, quote
			form["inputData.OrderType"] = {} // undefined error handling
			form["inputData.OrderType"].model = e.submitter.value ;

			// individual field validations deciding if a message should be displayed

			for(let input in form){
				if(form[input].required)[
					form[input].invalid = !form[input].model
				]
			}

			// the user must select EITHER an option in a selector OR fill out a number of required fields that only are required if the dropdown is not selected.

			for(let input in form){
				const field = form[input]

				if(field.alternate){
					groups[field.alternate] = groups[field.alternate] || groups[field.alternate]  === undefined
						? !!field.model
						: groups[field.alternate]

				}
			}

			for(let group in groups){
				if(groups[group]){
					groupValidates = true
					break;
				}
			}

			if(validates && groupValidates){
				this.$store.dispatch("placeOrder",stringifyFormData(this.form))
			}
		},
		onChange() {
			// onchange
		},
		setAction(actionName) {
			this.form.action = actionName;
		},
		scrollToTopClick() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	},
};
