import { mapActions } from "vuex";

import Swiper from "swiper/dist/js/swiper.js";
import TouchScroll from "./touch-scroll";

import Room from "../../components/room-explorer/room";

export default {
	name: "room-explorer",
	props: {
		roomsJson: {
			type: String,
			required: true,
		},
	},
	components: {
		Room,
	},
	data() {
		return {
			slider: null,
			scrollView: null,
			slidesCount: 0,
			slidesRef: null,
			touchScrollers: null,

			settings: {
				simulateTouch: false,
				allowTouchMove: false,
				effect: "fade",
				fadeEffect: {
					crossFade: true,
				},
				slidesPerView: 1,
				centeredSlides: false,
				autoHeight: false,
				speed: 450,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				preloadImages: false,
				watchOverflow: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 3,
				},
			},
		};
	},
	computed: {
		roomsList() {
			let list = this.roomsJson ? JSON.parse(this.roomsJson) : [];
			return list;
		},
	},
	methods: {
		...mapActions([
			"roomExplorerSingleProductChosen",
			"roomExplorerOverlayProductsChosen",
		]),
		productMarkerHandler(roomId, markerId) {
			let product = this.roomsList[roomId].markers[markerId].catalogEntry;
			this.roomExplorerSingleProductChosen(product);
		},
		allProductsHandler(roomId) {
			const room = this.roomsList[roomId];
			const allProducts = room.markers.map((room) => ({
				...room.catalogEntry,

				quantity: 1,
				colliProduct: room.catalogEntry.colli,
				colliAmount: room.catalogEntry.colli,
			}));

			this.roomExplorerOverlayProductsChosen(allProducts);
		},
	},
	mounted() {
		this.settings.navigation = {
			nextEl: this.$refs["nextButton"],
			prevEl: this.$refs["prevButton"],
		};

		this.slider = new Swiper(this.$refs.slider, this.settings);

		this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");
		this.slidesCount = this.slidesRef.length;
		this.touchScrollers = [];

		for (let i = 0; i < this.slidesCount; i++) {
			let ts = new TouchScroll();
			this.touchScrollers.push(ts);

			let tsEl = this.slidesRef[i].querySelector(
				".room__image__container"
			);
			ts.init({
				el: tsEl,
				draggable: true,
				wait: false,
			});
		}
	},
};
