/* eslint-disable no-undef */
import { mapActions, mapState } from "vuex";
import { ENTER } from "../../../../basics/js/store/static";

import StoreLocatorListItem from "@components/store-locator/partials/list-item/list-item";

export default {
	name: "store-locator-list",
	components: {
		StoreLocatorListItem,
	},
	watch: {
		selectedStore() {
			// console.log("list selectedStore(data)",data)
		},
		mapReady(newVal) {
			if (newVal === true) {
				this.initAutoComplete();
			}
		},
	},
	computed: {
		...mapState({
			mapReady: (state) => state.storeLocator.mapReady,
			filteredStores: (state) => state.storeLocator.filteredStores,
			selectedStore: (state) => state.storeLocator.selectedStore,
			copy: (state) => state.storeLocator.copy,
		}),
	},
	data() {
		return {
			ready: false,
			autoComplete: null,
			autoCompleteInput: null,
		};
	},
	mounted() {
		this.$on("scroll-to", (e) => {
			if (this.$el.offsetTop) {
				this.$refs.storeLocatorList.scrollTop = e.$el.offsetTop;
			}
		});
	},
	methods: {
		...mapActions(["placeChosen", "geoLocationChange", "placeSearch"]),
		scrollToTop() {},
		initAutoComplete() {
			this.autoCompleteInput = this.$refs.storeLocatorSearch;
			this.autoComplete = new google.maps.places.Autocomplete(
				this.autoCompleteInput
			);
			this.autoComplete.addListener("place_changed", () => {
				this.onPlaceChange();
			});
		},
		onPlaceChange() {
			let place = this.autoComplete.getPlace();
			this.placeChosen(place);
		},
		onPlaceInputClick(event) {
			if (event.key === ENTER) {
				this.placeSearch(event.target.value);
			}
		},
		geoLocationClick() {
			this.getUserCordsPermission(window.navigator)
				.then((position) => {
					this.geoLocationChange(position);
				})
				.catch(() => {
					console.log("FAILED!");
				});
		},
		getUserCordsPermission(navigator) {
			return new Promise((resolve, reject) => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(
						(pos) => resolve(pos),
						reject,
						{ enableHighAccuracy: true }
					);
				} else {
					reject(new Error(this.copy.gpsFailMessage));
				}
			});
		},
	},
};
