import Swiper from "swiper/dist/js/swiper.js";

import mockData from "./inspiration-slider-data";
import ImageProductsButton from "../../image-products/image-products-button.vue"
import HasImageProducts from "../../image-products/has-image-products";

export default {
    name: "inspiration-slider",
	extends: HasImageProducts,
    components: {
        ImageProductsButton
    },
    props: {
        labelText: {
            type: String,
            default: "Inspiration"
        },
        slideList: {
            type: Array,
            default() {
                return mockData;
            }
        }
    },
    data() {
        return {
            slider: null,
			slidesCount: 0,
			slidesRef: null,

			activeSlideIndex: 0,
			
			settings: {
				slidesPerView: 1,
				allowTouchMove: true,
				effect: "fade",
				fadeEffect: {
					crossFade: true,
				},
				pagination: true,
				centeredSlides: false,
				autoHeight: false,
				speed: 600,
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				navigation: {
					nextEl: this.$refs["nextButton"],
					prevEl: this.$refs["prevButton"],
				},
				preloadImages: true,
				watchOverflow: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 3,
				}, 
				on: {
                    slideChange: this.slideChangeHandler.bind(this)
                }            
			},
        }
    },
	computed: {
		getSlideStyle: function() {
			let currentSlide = this.slideList[this.activeSlideIndex];
			return {
				'background-color': currentSlide.backgroundColor,
				'--inspiration-slider-bg-color': currentSlide.backgroundColor,
				'color': currentSlide.textColor,
				'--inspiration-slider-text-color': currentSlide.textColor,
			};
		},
	},
	methods: {		
		slideChangeHandler: function(){
			this.activeSlideIndex = this.slider.activeIndex;
		}
	},
    mounted() {
		this.slidesRef = this.$refs.slider.querySelectorAll(".swiper-slide");
		this.slidesCount = this.slidesRef.length;

		if (this.slidesCount > 1) {
			Object.assign(this.settings, {
				navigation: {
					nextEl: this.$refs["nextButton"],
					prevEl: this.$refs["prevButton"],
				}
			});
			
			if (this.settings.pagination ) {
				Object.assign(this.settings, {
					pagination: {
						el: this.$refs.pagination
					}
				});
			}
		}
		
		this.slider = new Swiper(this.$refs.slider, this.settings);

    }

};