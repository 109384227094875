<script>
import debounce from 'lodash/debounce';

export default {
	name: 'quantity-mobile',
	props: {
		colliAmount: {
			type: Number,
			default: 0,
		},
		colliProduct: {
			type: Boolean,
			default: false,
		},
		colliText: {
			type: String,
			default: null,
		},
		quantity: {
			type: Number,
			default: 1
		},
		minimumOrderQuantity: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			colliTotal: 1,
			quantityValue: this.colliProduct ? (this.colliAmount < this.minimumOrderQuantity ? this.minimumOrderQuantity : this.colliAmount) : (this.quantity > 1 ? this.quantity : (this.minimumOrderQuantity || this.quantity || 1)),
			isLoaded: false,
			quantityStep: this.colliProduct ? this.colliAmount : 1,
			quantityValueBeforeChange: this.quantity > 1 ? this.quantity : this.colliProduct ? this.colliAmount : 1
		};
	},
	watch: {
		quantityValue() {
			if (this.quantityValue && this.quantityValue.toString().length > 0 && +this.quantityValue % this.quantityStep !== 0) {
				// COMMENT: When deleting charaters in the input
				// Emit event to parent to disabled button or stuff
				this.$emit('emit-quantity-update', true);
			} else {
				this.$emit('emit-quantity-invalid', false);
			}
		},
		quantity() {
			this.quantityValue = this.quantity;
		}
	},
	mounted() {
		this.quantityInput = this.$refs.quantityInput[0] ? this.$refs.quantityInput[0] : this.$refs.quantityInput;

		this.setColliItemsCount();
		this.setupInputKeyEvents();

		this.isLoaded = true;
	},
	methods: {

		setupInputKeyEvents() {
			// COMMENT: Keydown prevents the unwanted charecters from showing in the input
			this.quantityInput.addEventListener('keydown', e => {
				const characterCode = e.which || e.charCode || e.keyCode;
				if (this.isValidKey(characterCode)) {
					// COMMENT: Passthrough to keyup-event, which sets the value
				} else {
					e.preventDefault();
					return;
				}
			});

			// COMMENT: Keyup sets the global "quantity" of which the minus/plus can calculate upon
			this.quantityInput.addEventListener('keyup', debounce(e => {
				const characterCode = e.which || e.charCode || e.keyCode;
				if (this.isValidKey(characterCode)) {
					this.quantityValue = this.quantityInput.value;
					this.setColliItemsCount();
					this.emitQuantityUpdate();
				} else {
					e.preventDefault();
					return;
				}
			}, 1000));
		},
		handleClick(e, clickType) {
			if (clickType === 'plus') {
				this.quantityValue = this.quantityValue + this.quantityStep;
			} else {
				this.quantityValue = this.quantityValue - this.quantityStep;
				if (this.quantityValue < 1) {
					this.quantityValue = this.quantityStep;
				}
			}
			if (this.minimumOrderQuantity && this.quantityValue < this.minimumOrderQuantity) {
				this.quantityValue = this.minimumOrderQuantity
			}
			this.setColliItemsCount();
			this.emitQuantityUpdate(clickType);
		},
		setColliItemsCount() {
			if (this.colliAmount > 0 && this.colliProduct) {
				if (this.quantityValue % this.quantityStep === 0) {
					this.colliTotal = this.quantityValue / this.colliAmount;
				} else {
					this.colliTotal = (this.quantityValue / this.colliAmount).toFixed(1);
				}
			}
		},
		emitQuantityUpdate(clickType) {
			this.quantityValue = this.minimumOrderQuantity && this.minimumOrderQuantity > this.quantityValue
				? this.minimumOrderQuantity
				: this.quantityValue

			const quantity = this.quantityValue ? + this.quantityValue : this.quantityStep;

			if (quantity % this.quantityStep !== 0) {
				this.quantityValue = quantity < this.quantityStep ? this.quantityStep : Math.ceil(quantity / this.quantityStep) * this.quantityStep
				this.setColliItemsCount();
			}
			const quantityChange = this.quantityValue - this.quantityValueBeforeChange
			const quantityChangeAbsolute = Math.abs(quantityChange)
			this.quantityValueBeforeChange = quantity;

			clickType = clickType || (quantityChange > 0 ? 'plus' : 'minus')

			// NOTE: update no matter what as opposed to quantity component, therefore commented out
			// if (quantityChange)
			this.$emit('emit-quantity-update', { "quantity": this.quantityValue, "quantityChange": quantityChangeAbsolute, "actionType": clickType });
		},
		isValidKey(characterCode) {
			if (
				characterCode > 46 && characterCode < 58 || // top keyboard 0-9 and forward backspace(46)
				characterCode > 95 && characterCode < 107 || // numpad 0-9
				characterCode === 8 || // backspace
				characterCode === 13 || // enter
				characterCode === 27 // esc
			) {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>

<template>
	<div class="quantity-selector">
		<div class="quantity-selector__inner">
			<div class="quantity-selector__inputs">
				<button class="quantity-selector__input__minus" v-on:click.prevent.stop="handleClick($event, 'minus')">
					<svg viewbox="0 0 100 100" class="svg-icon">
						<use xlink:href="/dist/svg/symbols.svg#svg-quantity-minus"></use>
					</svg>
				</button>
				<button class="quantity-selector__input__plus" v-on:click.prevent.stop="handleClick($event, 'plus')">
					<svg viewbox="0 0 100 100" class="svg-icon">
						<use xlink:href="/dist/svg/symbols.svg#svg-quantity-plus"></use>
					</svg>
				</button>
				<div class="quantity-selector__input__field">
					<p v-if="colliAmount && colliText">{{ colliText }}</p>
					<input ref="quantityInput" type="text" v-model="quantityValue" maxlength="3" class="quantity-input"
						disabled>
				</div>

			</div>
		</div>
	</div>
</template>
