import DashboardHeader from '../../components/dashboard-header/dashboard-header';
import DataTableLabel from '../../components/data-table/data-table-label';
import TableComponent from '../../components/table-component/table-component'
import AccountMenu from '../../components/account-menu/account-menu';

import { mapState } from 'vuex';

export default {
	name: 'dashboard',
	components: {
		DashboardHeader,
		DataTableLabel,
		AccountMenu,
		TableComponent
	},
	computed: {
		...mapState({
			isActive: state => state.dashboardOpen
		})
	}
}
