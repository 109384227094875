import { mapActions, mapState } from 'vuex'


export default {
	name: 'tooltip',
    computed: {
        ...mapState({
            tooltipText: state => state.tooltipText,
            tooltipPosX: state => state.tooltipPosX,
            tooltipPosY: state => state.tooltipPosY,
		}),        
	},
    data() {
        return {
            isActive: false
        }
    },
    watch: {
        tooltipText(newVal) {
            if ( newVal && newVal.length > 5 ) {
                this.isActive = true;                
            } else {
                this.isActive = false;                
            }
        }
    },
    methods: {
        ...mapActions(['closeToolTip']),
        clickListener(event) {
            if ( this.isActive ) {
                if ( event.target === this.$el ) {
                    this.closeToolTip();
                }
            }
        },
    },
    mounted() {
        window.addEventListener('click', this.clickListener.bind(this))
    }
}