import HasImageProducts from "../../image-products/has-image-products";
import ImageProductsButton from "../../image-products/image-products-button.vue"

export default {
    name: "introduction-block",
    extends: HasImageProducts,
    components: {
        ImageProductsButton
    },
    props: {
        mediaVideoSrc: {
            type: String,
            default: null            
        },
        mediaImageSrc: {
            type: String,
            default: null
        },
    }
}