import InputWithLabel from '../../components/form-elements/templates/input-with-label'
import FormPassword from '../../components/form-elements/templates/form-password'
import { mapActions } from 'vuex';

export default {
	name: 'gateway',
	components: {
		FormPassword,
		InputWithLabel
	},
	methods: {
		...mapActions(['openModal'])
	}
};