// import TweenLite from 'gsap/umd/TweenLite';
import Store from '@store/store';
import {TweenLite} from 'gsap';
import { mapState, mapActions } from 'vuex';

import AccordionItemFilter from './accordion-item-filter';
import AccordionItem from '../../components/accordion/accordion';
import formCheckboxFilter from './form-checkbox-filter'
import { SET_FILTER_COLOR } from '../../basics/js/store/static';
import cloneDeep from 'lodash/cloneDeep'
import updateFilteredResult from '../../basics/js/methods/updateTileFilters/updateTileFilters'
import { registerImpression } from "../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer";

import isChecked from './methods/isChecked'
import getLogicalValue, { LOGICAL_STATE } from './methods/getLogicalValue'


export default {
	name: 'tile-filter',
	components: {
		AccordionItemFilter,
		AccordionItem,
		formCheckboxFilter
	},
	props: {
		initialAvailableTotalResults: {
			type: String,
			default:'0'
		},
		initialFacets : {
			type: String
		},
		initialSelectedFacets: {
			type: String
		},
		initialSearchQuery: {
			type: String
		},
		initialResults: {
			type: String
		},
		pageSize: {
			type: String,
			default: '0'
		},
		initialPageSize: {
			type: String,
			default: '0'
		},
		excludeEditorPicks: {
			type: Boolean,
			default: false
		},
		pageId: {
			type: String,
			required: true
		},
		endpoint: {
			type: String,
			required: false
		},
		hideBar: {
			type: Boolean,
			default: false
		},
		hideContent: {
			type: Boolean,
			default: false
		},
		navigationLinks: {
			type: String,
		},
		makeRoomForSearch: {
			type: Boolean,
		},
		skipFilterEndpoint: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapActions([
			"openProductFilters",
			"closeProductFilters",
		]),
		isChecked,
		getLogicalValue,
		toggle () {
			if (this.isOpen == true) {
				this.closeProductFilters();
			} else {
				this.openProductFilters();
			}
			this.animateContent();
		},
		animateContent () {
			if ( this.hideContent ) return;

			const filters = this.$refs['filter-content'];

			if (this.isOpen == true) {
				TweenLite.set(filters, { height: 'auto' });
				if ( !this.hideBar || this.isMobile ) TweenLite.from(filters, 0.3, { height: 0 });
			} else {
				if ( !this.hideBar || this.isMobile ) TweenLite.to(filters, 0.3, { height: 0});
			}
		},
		clear () {
			this.updateFilteredResult([], this.skipFilterEndpoint)
		},
		removeFilter (filter) {
			this.changeFilter(filter)
		},
		getFilterItems (items, id) {
			return items.filter(item => item.id === id);
		},
		onReadyToShip(event){
			this.changeFilter({ value: event.target.value, label: event.target.name, name: event.target.name })
		},
		changeFilter (checkedFilter) {
			console.log("changeFilter", checkedFilter)

			const checkedFilters = cloneDeep(this.checkedFilters)

			const facet = checkedFilters.find(filter => filter.id === checkedFilter.facet)

			let newFilter = {id: checkedFilter.id, name: checkedFilter.name}
			if ( checkedFilter.value ) newFilter.value = checkedFilter.value;

			if (facet) {

				const existingFilter = facet.facetValues.find(value => value.id === checkedFilter.id)
				if ( existingFilter ) {
					// filter exists
					if ( checkedFilter.value && existingFilter.value !== checkedFilter.value ) {
						// remove filter if it is already checked and the new value is indifferent
						if  ( checkedFilter.value === LOGICAL_STATE.INDIFFERENT ) {
							facet.facetValues = facet.facetValues.filter(value => value.id !== checkedFilter.id)
						} else {
							// update value
							existingFilter.value = checkedFilter.value
							facet.facetValues = facet.facetValues.map(value => {
								if ( value.id === checkedFilter.id ) return existingFilter
								else return value
							});
						}
					}
					else {
						facet.facetValues = facet.facetValues.filter(value => value.id !== checkedFilter.id)
					}
				} else {
					facet.facetValues.push(newFilter);
				}
			} else {
				checkedFilters.push({
					id: checkedFilter.facet,
					facetValues: [newFilter]
				})
			}

			this.updateFilteredResult(checkedFilters, this.skipFilterEndpoint)

		},
		updateFilteredResult,
		linkClass(link){
			return `link ${link.active ? 'link--persistent active' : ''}`;
		}

	},
	data() {
		return {
			searchTerm: '',
			selectedFilters: [],
			navigationLinksParsed: []
		};
	},
	computed: {
		...mapState([SET_FILTER_COLOR.stateKey, 'isMobile', 'productFiltersOpen']),
		isOpen(){
			return this.$store.state.productFiltersOpen
		},
        backgroundColor(){
            return this[SET_FILTER_COLOR.stateKey] ? `--product-filter-bg-color-active: ${this[SET_FILTER_COLOR.stateKey]}` : '--product-filter-bg-color-active: #fff'
		},
		filterGroups(){
			return Store.state.search.facets
		},
		isMounted(){
			return !!Store.state.search.facets.length
		},
		checkedFilters(){
			return Store.state.search.selectedFacets
		},
		countCheckedFilters(){
			return this.checkedFilters.reduce((count,filter)=>{
				return count + (filter.facetValues ? filter.facetValues.length : 0)
			},0)
		},
		availableTotalResults(){
			return Store.state.search.availableTotalResults.toLocaleString('da-dk');
		},
		displayCheckedFilters(){
			return this.checkedFilters.filter(item => item.id !== 'readyToShip');
		},
	},
	watch: {
		isOpen(){
			this.animateContent();
		}
	},
	mounted() {

		// load filters to display
		const initialFacets = this.initialFacets ? JSON.parse(this.initialFacets) : []
		const initialSelectedFacets = this.initialSelectedFacets ? JSON.parse(this.initialSelectedFacets): []
		const initialResults = this.initialResults ? JSON.parse(this.initialResults) : []
		const initialAvailableTotalResults = this.initialAvailableTotalResults ? +this.initialAvailableTotalResults : 30
		const initialTake = +this.pageSize
		const initialPageSize = +this.initialPageSize
		const initialSearchQuery = this.initialSearchQuery
		const excludeEditorPicks = this.excludeEditorPicks


		Store.dispatch('filterInitialLoad',{initialFacets,initialResults,initialAvailableTotalResults,initialSelectedFacets, initialSearchQuery, initialPageSize, excludeEditorPicks,initialTake, pageId: this.pageId, filterEndpoint: this.endpoint})

		console.log("initialSelectedFacets",initialSelectedFacets)

		registerImpression(initialResults, "product-search-tile")

		this.$on('toggleAccordion', () => {
			// Close already open accordions
			this.$refs['accordion'].forEach(function (accordion) {
				if (accordion.isOpen) {
					accordion.close();
				}
			});
		});

		this.navigationLinksParsed = this.navigationLinks ? JSON.parse(this.navigationLinks) : null;

		this.animateContent();


	},
};
