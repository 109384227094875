/* eslint-disable no-undef */
import { mapActions, mapState } from 'vuex'

export default {
	name: 'store-locator-filters',
	watch: {
		filteredStores (newVal) {
			if (newVal.length) {
				this.storeCountText = `${newVal.length} ${
					newVal.length === 1
						? this.copy.storesFound
						: this.copy.storesFoundPlenum
				}`
			} else {
				this.storeCountText = this.copy.noStoresHead
			}
		}
	},
	computed: {
		...mapState({
			filteredStores: state => state.storeLocator.filteredStores,
			copy: state => state.storeLocator.copy
		}),
		assortmentKeys () {
			return this.copy.filterValues.split('|')
		}
	},
	data () {
		return {
			storeCountText: ''
			// assortmentKeys: this.copy.filterValues.split('|')
		}
	},
	mounted () {
		let as = this.$refs.assortmentSelect.querySelector('.form__item'),
			fc = this.$refs.flagShipCheckbox.querySelector('.form__item')

		if (as instanceof HTMLElement)
			as.addEventListener(
				'change',
				this.assortmentSelectChange.bind(this)
			)
		if (as instanceof HTMLElement)
			fc.addEventListener(
				'change',
				this.flagShipCheckboxChange.bind(this)
			)
	},
	methods: {
		...mapActions(['assortmentFilterChange', 'flagShipFilterChange']),
		assortmentSelectChange (event) {
			// console.log(
			// 	'assortmentSelectChange',
			// 	this.assortmentKeys[event.target.selectedIndex]
			// )
			this.assortmentFilterChange(
				this.assortmentKeys[event.target.selectedIndex]
			)
		},
		flagShipCheckboxChange (event) {
			// console.log("flagShipCheckboxChange",event.target.checked)
			this.flagShipFilterChange(event.target.checked)
		}
	}
}
