var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "image-products__button",
      class: { "is-animated": _vm.isAnimated, "in-view": _vm.isInview },
      style: { "--image-products-button-bg-color": _vm.backgroundColor },
      on: { click: _vm.clickHandler },
    },
    [
      _c(
        "svg",
        { staticClass: "svg-icon ", attrs: { viewBox: "0 0 100 100" } },
        [
          _c("use", {
            attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-plus-small" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.buttonText))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }