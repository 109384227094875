
export default {
	name: "news-letter",
	props: {
		triggers: {
			type: String,
			required: false,
			default: "[]",
		},
		labelsProp: {
			type: String,
			required: false,
			default: "{}",
		},
	},
	data() {
		return {
			selected: "",
			email: "",
			shouldShowForm: false,
			triggerValue: "",
			labels: {}
		}
	},
	computed: {
		campaignList() {
			let list = this.triggers ? JSON.parse(this.triggers) : []
			return list
		},
		showForm() {
			return this.shouldShowForm;
		},
		showNewsletterChoices() {
			return this.campaignList ? this.campaignList.length > 1 : false;
		},
	},
	mounted() {
		window.setTimeout(function(that) {
			if (window.SleekNote === undefined || window.sleeknoteSiteData === undefined) {
				console.log("Not displaying newsletter signup - sleeknote has not been loaded");
				return;
			}

			if (!that.campaignList || that.campaignList.length == 0) return;

			that.triggerValue = that.campaignList[0].triggerId;

			that.shouldShowForm = true;
		}, 1500, this);

		this.labels = { ...JSON.parse(this.labelsProp) };
		
	},
	methods: {
		handleSubmit(){

			if (this.email && this.email.length > 0) {
				window.sleeknoteSiteData.push({ "email": this.email }); 
			}

			window.SleekNote.triggerOnclick(this.triggerValue);
		}
	}
};
