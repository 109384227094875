
import { mapActions } from 'vuex';
import { TweenLite } from 'gsap';

export default {
	name: 'variation-dropdown',
	props: {
		initialChosenItemCode: {
			type: String,
			required: false,
		},
		initialChosenItemImg: {
			type: String,
			required: false,
		},
		initialChosenItemLabel: {
			type: String,
			required: false,
		}
	},
	data() {
		return {
			sku: null,
			items: [],
			isActive: false,

			chosenItemImg: this.initialChosenItemImg,
			chosenItemCode: this.initialChosenItemCode,
			chosenItemLabel: this.initialChosenItemLabel,
		};
	},
	methods: {
		...mapActions([
			'openConfigurator',
			'closeConfigurator',
		]),
		toggleAccordion(e){
			// COMMENT: remove old active, and set new(currentTarget)
			const alreadyOpen = this.$el.querySelector('.variation-dropdown__group__heading.is-active');

			if(e.currentTarget === alreadyOpen){
				e.currentTarget.classList.remove('is-active');
			} else {
				if(alreadyOpen){
					alreadyOpen.classList.remove('is-active');
				}
				e.currentTarget.classList.add('is-active');
			}
		},
		showHideDropdown(){ // exposed in template
			if(this.isActive){
				this.hideDropdown();
			} else {
				this.showDropdown();
			}
		},
		showDropdown(){
			this.isActive = true;
			const toggleContent = this.$refs.content;
			TweenLite.set(toggleContent, { height: 'auto' });
			TweenLite.from(toggleContent, 0.25, { height: 0 });
		},
		hideDropdown(){
			this.isActive = false;
			const toggleContent = this.$refs.content;
			TweenLite.to(toggleContent, 0.25, { height: 0 });
		},
		closeIfOpen(){
			if (this.isActive) {
				this.$refs.initiator.blur();
			}
		},
		goToUrl(url){
			window.location = url;
		},
		selectItem( label, img, code) {

			this.chosenItemImg = img;
			this.chosenItemLabel = label;
			this.chosenItemCode = code;

			this.hideDropdown();
			this.$refs.initiator.blur();

		},
		acceptChosenItem() {
			this.$parent.$emit('chosen', {value:this.chosenItemCode});
		},
		updateReceiverMethodProductTile() {
			//JPL: TODO??
		}

	}
};
