export default {
	name: "mega-links",
	props: {
		links: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			activeItem: null,
			activeIndex: -1,
			mediaLoaded: false,

			imageX: 0,
			imageY: 0,
		};
	},
	methods: {
		mouseOverHandler: function (item, index) {
			this.mediaLoaded = false;
			this.activeItem = item;
			this.activeIndex = index;

			if ( item.videoSrc ) {
				this.mediaLoadedHandler();
			}
		},
		mouseOutHandler: function () {
			this.activeItem = null;
			this.activeIndex = -1;
		},
		mediaLoadedHandler: function () {
			this.mediaLoaded = true;
		},
		mouseMoveHandler: function (event) {
			this.imageX = event.clientX;
			this.imageY = event.clientY;
		},
		getXlinkHref: function(icon) {
			return `/Static/dist/svg/symbols.svg#svg-${icon}`;
		}
	},
	computed: {
		imageStyle() {
			return `--mega-links-image-x: ${this.imageX}px; --mega-links-image-y: ${this.imageY}px;`;
		},
	},
	mounted() {
		window.addEventListener("mousemove", this.mouseMoveHandler.bind(this));
	},
};
