import Store from '@store/store';
import updateBrowserHistory from '../updateBrowserHistory/updateBrowserHistory'

export default function(filters, skipFilterEndpoint = false){
    Store.commit('updateSelectedFacets',filters)
    Store.commit('updateFilterSkip',0)
    Store.commit('updateVisibleResults',0)
    Store.commit('updateExcludeEditorPicks',false)
    if ( !skipFilterEndpoint ) Store.dispatch('performProductFiltering')
    updateBrowserHistory({method:'replaceState'})

    // editor picks are not under component control, so we need to manually apply a "hide" class
    const editorPicksDOM = document.querySelector('.js--hide-when-filters-change')
    if(editorPicksDOM){
        editorPicksDOM.classList.add('hide-element')
    }
}
