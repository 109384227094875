
import { GET_CART_QUANTITY, IMPORT_SAVED_LIST_ASYNC } from '../../basics/js/store/static';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'cart-import-link',
	props: {
		cartUrl: {
			type: String,
		},
		cartImportUrl: {
			type: String,
		},
		cartImportId: {
			type: String,
		},
		cartImportTitle: {
			type: String,
		},
		cartImportMessage: {
			type: String,
		}
	},
	data() {
		return {
			isImporting: false,
		};	
	},
	computed: {
		...mapState({
			'hasCartItems': state => state.cart[GET_CART_QUANTITY.stateKey] > 0
		})
	},
	mounted(){
	},
	methods: {
		...mapActions([
			IMPORT_SAVED_LIST_ASYNC.action,
		]),
		importCart() {
			if (this.hasCartItems) {
				this.showModal();
				return;
			}

			this.importJob();
		},	
		importJob() {
			if (this.isImporting) return;
		
			this.isImporting = true;
			const body = {
				"cartId": this.cartImportId
			};
			var url = this.cartImportUrl;

			this[IMPORT_SAVED_LIST_ASYNC.action]({ url, body, notification: { 
				type: 'info',
				autoRemove: false, 
				title: this.cartImportTitle, 
				message: this.cartImportMessage } });
			
			this.isImporting = false;
		},
		importCartAndCloseModal() {
			this.importJob();
			this.closeModal();
		},
		showModal() {
			this.openModal('importCartModal');
		},
		closeModal() {
			this.$refs.importCartModal.close();
		},
	},	
};
