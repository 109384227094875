<script>
import StorySlide from "../story-slide.vue";

export default {
    name: "story-slide-products",
    extends: StorySlide,
    computed: {
        titlePart1() {
            return this.item && this.item.titlePart1;
        },
        titlePart2() {
            return this.item && this.item.titlePart2;
        },
        productList() {
            return this.item && this.item.productList;
        },
		productsHaveVariants() {
			//test for item in productlist with valid productVariant value
			return this.item && this.item.productList && this.item.productList.some(item => item.productVariant);
		},
    },
};
</script>


<template>
    <div class="story-slide-products" :style="slideStyle">

        <div class="story-slide-products__content">

            <h2 class="story-slide-products__title">
                <span>{{ titlePart1 }}</span>
                <span v-if="titlePart2">{{ titlePart2 }}</span>

                <a v-if="buttonHref && buttonText" :href="buttonHref"
                    class="button button--secondary button--custom-colors" :style="buttonStyle">
                    <span class="text">{{ buttonText }}</span>
                </a>
            </h2>

            <nav class="story-slide-products__list" :class="'story-slide-products__list--items-' + (productList.length <= 12 ? productList.length : 'X')">
                <a v-for="product in productList" :key="product.productId" :href="product.productHref">
                    <figure>
                        <img :src="product.imageSrc" />
                    </figure>
                    <div>
                        <h3>{{ product.productName }}</h3>
                        <p>{{ product.productVariant }}<span v-if="productsHaveVariants">&nbsp;</span></p>
                    </div>
                </a>
            </nav>
        </div>
    </div>
</template>
