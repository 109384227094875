export default [
    {
        headline: "Leather", 
        bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
        
        linkText: "Explore leather",
        

        mainImages: [
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/portrait-image.jpg",
                skuList: "98s7df;hjg232;78df0sf"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/roomexplorer-3_4.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/mega-menu-life-style-image.jpg",
                skuList: "98s7df;hjg232;78df0sf"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/master-product-image_4_5.jpg",
                skuList: "98s7df;hjg232;78df0sf"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/IMG_Lifestyle1.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/hero-portrait.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/editorial/product_1_4_3.png",
                skuList: "98s7df;hjg232;78df0sf"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle2.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle3.jpg"
            }
        ],

        backgroundColor: "#E6C4A6",
        textColor: "#282828",
                    
        materialsLabel: "Materials",
        subCategoriesLabel:"Popular solid color materials",
        subCategories: [
            {
                headline: "Solid color 1",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/textiles/turquoise-(150)-thumb.jpg",
                content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                seeMoreLabel: "See more",
                ctaLabel: "Find a sofa with Faux leather",
                ctaHref: "#findasofa",
            },
        ],
        sliderLabel: "Popular solid color sofas",
        sliderItems: [
            {
                headline: "Outline",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_4.jpg",
            },
            {
                headline: "Connect Soft",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_2.jpg",
            },
            {
                headline: "Outline",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/designer-portrait-img2.jpg",
            },
            {
                headline: "Connect Soft",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_2.jpg",
            },
        ]
    },
    {
        headline: "Woven",
        bodyText: "Woven description lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
        linkText: "Explore woven",
        
        mainImages: [
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle1.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle2.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle3.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle1.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle2.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle3.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle1.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle2.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle3.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle1.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle2.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle3.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle1.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle2.jpg" 
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_lifestyle3.jpg"
            },
        ],

        backgroundColor: "#E6C4A6",
        textColor: "#282828",
                    
        materialsLabel: "Materials",
        subCategoriesLabel:"Popular solid color materials",
        subCategories: [
            {
                headline: "Solid color 1",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/textiles/turquoise-(150)-thumb.jpg",
                content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                seeMoreLabel: "See more",
                ctaLabel: "Find a sofa with Faux leather",
                ctaHref: "#findasofa",
            },
        ],
        sliderLabel: "Popular solid color sofas",
        sliderItems: [
            {
                headline: "Outline",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_4.jpg",
            },
            {
                headline: "Connect Soft",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_2.jpg",
            },
            {
                headline: "Outline",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/designer-portrait-img2.jpg",
            },
            {
                headline: "Connect Soft",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_2.jpg",
            },
        ]
    },
    {
        headline: "Velour",
        bodyText: "Velour description lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur.",
        linkText: "Explore velour",
        
        mainImages: [
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/article/IMG_store2.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/mega-menu-life-style-image.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/master-product-image_4_5.jpg"
            },
            {
                headline: "Outline",
                bodyText: "Nulla sagittaris quam in ex aliquat non bibendum",
                imageSrc:"/static/images/IMG_Lifestyle1.jpg" 
            },
        ],

        backgroundColor: "#E6C4A6",
        textColor: "#282828",
                    
        materialsLabel: "Materials",
        subCategoriesLabel:"Popular solid color materials",
        subCategories: [
            {
                headline: "Solid color 1",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/textiles/turquoise-(150)-thumb.jpg",
                content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                seeMoreLabel: "See more",
                ctaLabel: "Find a sofa with Faux leather",
                ctaHref: "#findasofa",
            },
        ],
        sliderLabel: "Popular solid color sofas",
        sliderItems: [
            {
                headline: "Outline",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_4.jpg",
            },
            {
                headline: "Connect Soft",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_2.jpg",
            },
            {
                headline: "Outline",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/designer-portrait-img2.jpg",
            },
            {
                headline: "Connect Soft",
                bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                imageSrc: "/static/images/roomexplorer-3_2.jpg",
            },
        ]

    },
];