import FileUploadField from './file-upload-field'

export default {
    name: 'file-upload-container',
    data(){
        return {
            counter: 1,
            filesAdded: 0,
            maxCount: 10
        }
    },
    computed: {
        showAddMore(){
            return this.counter < this.maxCount ? true : false
        }
    },
    methods:{
        incrementCounter() {
            if (this.counter < this.maxCount) {
                this.counter++
            }
        },
        addFile(){
            const newFileCount = this.filesAdded+1
            this.filesAdded = newFileCount > this.maxCount ? this.maxCount : newFileCount
        },
        removeFile(value){
            const newCount = this.counter-1
            this.counter = newCount > 0 ? newCount : 0

            if(value){
                const newFileCount = this.filesAdded-1
                this.filesAdded = newFileCount < 0 ? 0 : newFileCount
            }
        }
    },
    components: {
        FileUploadField
    },
}