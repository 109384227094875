import { mapActions, mapState } from "vuex";

import TabsBar from "../tabs-bar/tabs-bar"
import mockData from "./inspiration-gallery-data"
import ImageProductsButton from "../../image-products/image-products-button.vue"


export default {
    name: "inspiration-gallery",
    components: {
        ImageProductsButton,    
        TabsBar
    },
    props: {
       titleText: {
            type: String, 
            default: 'Materials & color'
        },
        inspirationGalleryData: {
            type: Array,
            default() {
                return mockData;
            }
        }
    },    
    computed: {
		...mapState({
			windowH: state => state.windowH,
			windowW: state => state.windowW,
			windowY: state => state.windowY,			
		}),
        getCategoryList: function() {  
            let list = [];
            list = this.inspirationGalleryData.map(item=>{ return {label: item.headline}; });
            return list;
        },
        parallaxStyle() {
            return `--parallax: ${0.25 * (Math.max(0, (this.windowY - this.offsetTop) - this.windowTrigger))}; 
                    --item-count: ${this.activeCategoryImageCount}`; 
        }        
    },
    watch: {
        windowH: function() {
            this.resizeListener();
        },
        windowW: function() {
            this.resizeListener();
        },
    },
    data() {
        return {            
            activeCategoryIndex: 0,
            activeCategory: this.inspirationGalleryData[0],            
            activeCategoryImageCount: 0,

            offsetTop: 0,
            windowTrigger: 300,

            randomOffsets: []
        }
    },
    methods: {
        ...mapActions([
            "setActiveVideo",
            "openMaterialOverlay",
			"showOverlayBackdrop",
            "setTriggerExperienceResize"
		]),
        categoryChangeHandler: function(item,index) {
            window.scrollTo({ top: this.$el.offsetTop, behavior: 'smooth' });
            
            if ( this.windowY > this.$el.offsetTop + 100 ) {
                setTimeout( function () {
                    this.changeCategory(index)
                }.bind(this), 1000);
            } else {
                this.changeCategory(index)
            }
        },
        changeCategory: function(index) {
            this.activeCategoryIndex = index;
            this.activeCategory = this.inspirationGalleryData[this.activeCategoryIndex];
            this.activeCategoryImageCount = this.activeCategory.mainImages.length;
            this.setTriggerExperienceResize(true);

            this.randomOffsets = [];
            for (let i=0; i<this.activeCategoryImageCount; i++) {
                this.randomOffsets.push(Math.random() * 0.3);
            }
        },
		resizeListener: function () {
            this.offsetTop = this.getOffsetTopRecursive(this.$el);
            this.windowTrigger = this.$refs.grid.offsetTop;
        },
        categoryClickHandler: function() {
            this.showOverlayBackdrop();
            this.openMaterialOverlay({data: this.inspirationGalleryData, activeIndex: this.activeCategoryIndex});
        },
        getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;
			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;
				current = current.offsetParent;
			}
			return actualOffset;
		},
        imageStyle(index) {
            if ( this.randomOffsets[index] ) {
                return `--adjustment-factor: ${this.randomOffsets[index]}`;
            } else { 
                return 1; 
            }
        }
    },
    mounted() {        
        this.randomOffsets = [];
        for (let i=0; i<this.activeCategory.mainImages.length; i++) {
            this.randomOffsets.push(Math.random() * 0.3);
        }
        setTimeout( function () {
            this.resizeListener();
        }.bind(this), 250);
    }
}