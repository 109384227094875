import { CYLINDO_OVERLAY, SHOWN, HIDDEN } from '@store/static';
import { mapState } from 'vuex';
export default {
	name: 'cylindo-overlay',
	components: {
	},
	data(){
		return {
			imageData: null,
			//show: false,
			mounted: false,
			selectedImageUrl: '',
		}
	},
	computed: {
		...mapState({
			show: state => {
				return state.pdp[CYLINDO_OVERLAY.stateKey] === SHOWN;
			}
		})
	},
	mounted(){
		if(this.$refs.cylindoData && this.$refs.cylindoData.textContent != ''){
			this.imageData = JSON.parse(this.$refs.cylindoData.textContent);
			this.mounted = true;
		}
	},
	methods: {
		initCylindo(){
			const vm = this;
			this.id = this.$refs.container.getAttribute('id');

			if(window.cylindo){
				window.cylindo.on('ready', function(){
					this.cylindoInstance = window.cylindo.viewer.create({
						// COMMENT: This 'SKU' is highly necessary, but it could, in this case, be
						// anything that is valid in Cylindo-database, as the images is not Cylindo dependant, but comes defined in the template.
						SKU: 'CHAISE_LONGUE_FIORD_151',
						accountID: this.$store.state.cylindoAccountId,
						containerID: this.id,
						country: 'us',
						thumbs: true,
						presentation: 'carousel',
						cylindoContent: false,
						language: 'eng',
						title: '',
						alternateContent: this.imageData
					});
					this.cylindoInstance.on('instance:carousel:next', function(event, obj){
						vm.trackSlideChange(obj.frame);
					});
					this.cylindoInstance.on('instance:carousel:prev', function(event, obj){
						vm.trackSlideChange(obj.frame);
					});
				}.bind(this));

			}
		},
		trackSlideChange(frame){
			// COMMENT: In Cylindo the active index is called "frame"
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				"event": "trackPDP",
				"eventData": {
					"category": "Product detail page",
					"action": "Picture slider click",
					"label": frame
				}
			});
		},
		showOverlay(clickedSrc){
			//this.show = true;
			this.$store.dispatch('setBodyFixed', { transitionDelay: 550, setClass: true });
			this.$store.commit(CYLINDO_OVERLAY.mutation, SHOWN);
			if(this.show){
				this.$nextTick(() => {
					// COMMENT: Set data order before initting cylindo
					this.setOverlayActiveSource(clickedSrc);
					this.initCylindo();
				});
			}

		},
		setOverlayActiveSource(clickedSrc){
			// COMMENT: Set the clicked source to be the starting point of the overlay-slideshow
			var splitIndex = 0;
			for (var i = 0; i < this.imageData.length; i++) {
				if(this.imageData[i].image === clickedSrc){
					splitIndex = i;
				}
			}
			var newData = this.imageData;
			if(splitIndex > 0){
				var brokenBits1 = newData.slice(0, splitIndex);
				var brokenBits2 = newData.slice(splitIndex);
				this.imageData = brokenBits2.concat(brokenBits1);
			}
		},
		closeOverlay(){
			//this.show = false;
			this.$store.dispatch('setBodyFixed', { transitionDelay: 550, setClass: false });
			this.$store.commit(CYLINDO_OVERLAY.mutation, HIDDEN);
		},
	},
};
