
import { mapActions, mapState } from 'vuex';

export default {
	name: 'overlay-backdrop',
	data() {
		return {
			behindHeader: false,
			searchMode: ''
		};
	},
	computed: {
		...mapState([
			'searchOverlayOpen',
		]),
		isActive: function(){
			this.behindHeader = this.$store.state.overlayBackdropContext == 'behindHeader' ? true : false;
			return this.$store.state.overlayBackdropActive;
		},
	},
	watch: {
		searchOverlayOpen(newVal) {
			this.searchMode = newVal;
		}
	},
	methods: {
		...mapActions([
			'hideOverlayBackdrop',
		]),
		close(){
			this.hideOverlayBackdrop();
		},
	},
};
