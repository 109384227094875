import { mapState } from "vuex";
import Hero from "../../components/hero/hero";
import MegaLinks from "../../components/mega-links/mega-links";
import CategoryTile from "../../components/category-tile/category-tile";
import SlidingPresentation from "../../components/sliding-presentation/sliding-presentation";
import { rInterval } from "./../../basics/js/utils";

export default {
	name: "sticky-scroll",
	components: {
		Hero,
		MegaLinks,
		CategoryTile,
		SlidingPresentation,
	},
	computed: {
		...mapState({
			windowW: (state) => state.windowW,
			windowH: (state) => state.windowH,
		}),
	},
	watch: {
		windowW: function () {
			this.resizeListener();
		},
		windowH: function () {
			this.resizeListener();
		},
	},
	data() {
		return {
			oldScrollY: 0,
			resizeTimeout: 0,
			scrollPosition: 0,
			containerHeight: 0,
			sectionProps: [],
			sections: [],
		};
	},
	methods: {
		scrollListener: function (forceUpdate) {
			let scroll = window.scrollY;

			// console.log("scroll??",scroll)

			if (this.sections && this.sections.length > 0 && (scroll != this.oldScrollY || forceUpdate)) {
				this.oldScrollY = scroll;

				this.sectionProps.forEach((props, index) => {
					let section = this.sections[index];
					if (scroll > props.y) {
						if (scroll <= props.y + props.h) {
							//scrolled inside
							section.classList.add("is-active");
							section.classList.remove("is-past");

							let prop = `translateY(${props.y}px)`;
							section.style.setProperty("transform", prop);
						} else {
							//scrolled past
							section.classList.remove("is-active");
							section.classList.add("is-past");
							section.style.setProperty("transform", "translateY(0)");
						}
					} else {
						//element not yet reached
						section.classList.remove("is-active");
						section.classList.remove("is-past");
						section.style.setProperty("transform", "translateY(0)");
					}
				});
			}
		},
		resizeListener: function () {
			if (this.sections && this.sections.length > 0) {
				let sectionCount = this.sections.length;

				this.sections.forEach((section) => {
					section.classList.add("is-measuring");
				});
				this.$refs.container.style.setProperty("height", "auto");

				// console.log("before:" + this.containerHeight);
				clearTimeout(this.resizeTimeout);
				this.resizeTimeout = setTimeout(() => {
					this.sectionProps = this.sections.map((section) => {
						return { y: this.getOffsetTopRecursive(section), h: Math.max(section.clientHeight, this.windowH) };
					});
					this.containerHeight = this.$refs.container.offsetHeight;
					// console.log("after:" + this.containerHeight);

					this.sections.forEach((section, index) => {
						section.style.setProperty("z-index", sectionCount - index);
						section.classList.remove("is-measuring");
					});
					this.$refs.container.style.setProperty("height", this.containerHeight + "px");
					this.scrollListener();
				}, 200);
			}
		},
		getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;

			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;

				current = current.offsetParent;
			}

			return actualOffset;
		},
		// rInterval: function (callback, delay) {
		// 	let dateNow = Date.now,
		// 		requestAnimation = window.requestAnimationFrame,
		// 		start = dateNow(),
		// 		stop,
		// 		intervalFunc = function () {
		// 			dateNow() - start < delay || ((start += delay), callback());
		// 			stop || requestAnimation(intervalFunc);
		// 		};
		// 	requestAnimation(intervalFunc);
		// 	return {
		// 		clear: function () {
		// 			stop = 1;
		// 		},
		// 	};
		// },
	},
	mounted() {
		this.sections = [...this.$refs.container.querySelectorAll(".section")];
		let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

		if (this.sections && this.sections.length > 0 && !isIE11) {
			rInterval(this.scrollListener, 50);

			setTimeout(
				function () {
					this.scrollListener(true);
				}.bind(this),
				250
			);

			this.sections[0].classList.add("is-active");
			this.$el.classList.add("is-ready");
		}
	},
};
