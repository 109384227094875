import Vue from 'vue/dist/vue.esm';
import { getRequest } from '../integrations'; 
import { GET_PRODUCTS } from '../static';


const state = {
	[GET_PRODUCTS.stateKey]: []
}

const mutations = {
	[GET_PRODUCTS.SUCCESS](state, payload){ // getting products
		const result = [].concat(payload);
		Vue.set(state, [GET_PRODUCTS.stateKey], result);
    },
    [GET_PRODUCTS.PENDING](state, status){ // getting products
		Vue.set(state, [GET_PRODUCTS.loadingKey], status);
	}
}

const actions = {
	async [GET_PRODUCTS.action](store, payload={}){
		const result = await getRequest(store, {
			url: payload.id ? `${process.env.LIST_PRODUCTS}/${payload.id}` : process.env.LIST_PRODUCTS,
            mutationTypes: GET_PRODUCTS,
            delay: 1000
		})
		store.commit(GET_PRODUCTS.SUCCESS, result);
	}
}

export default { state, actions, mutations }