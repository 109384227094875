import TableComponent from '../../components/table-component/table-component'
import Modal from '../../components/modal/modal'
import { EDIT_ORDER_ASYNC } from '../../basics/js/store/static';
import { mapActions } from 'vuex';

export default {
	name: 'order-overview',
	components: {
		TableComponent,
		Modal
	},
	props: {
		pageId: {
			type: String,
			required: true
		},
		editOrderUrl: {
			type: String,
			required: false
		},
		editOrderNotifyTitle: {
			type: String,
			required: true
		},
		editOrderNotifyMessage: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isEditingOrder: false
		};	
	},	
	methods: {
		...mapActions([
			EDIT_ORDER_ASYNC.action
		]),		
		editOrder(orderNumber, comment){
			if (this.isEditingOrder) return;

			this.isEditingOrder = true;
			const url = this.editOrderUrl;
			const body = {
				"comment": comment,
				"pageId": this.pageId,
				"orderNumber": orderNumber
			};
			this[EDIT_ORDER_ASYNC.action]({
				url,
				body,
				notification: {
					type: 'info',
					showClose: false,
					title: this.editOrderNotifyTitle,
					message: this.editOrderNotifyMessage
				}
			});
			this.isEditingOrder = false;
		}
	}
};