import CountrySelectorInput from "@components/country-selector/country-selector-input";
import { required, email } from "vuelidate/lib/validators";


const checked = (val) => val;
const anyChecked = (vals) => Object.values(vals).some(val=>val===true);

export default {
	name: "newsletter-non-sleek",
	components: { CountrySelectorInput },
	props: {
		newsletterTypes: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			form: {
				name: "",
				email: "",
				consent: false,
				type: this.newsletterTypes || {},
			},
		};
	},
	validations() {
		return {
			form: {
				name: {
					required,
				},
				email: {
					required,
					email,
				},
				type: Object.assign({required, anyChecked}, ...Object.keys(this.form.type).map(key=>({[key]: {}}))),
				consent: {
					required,
					checked,
				},
			},
		}
	},
	methods: {
		setName(value) {
			this.form.name = value;
			this.$v.form.name.$touch();
		},
		setEmail(value) {
			this.form.email = value;
			this.$v.form.email.$touch();
		},
		setType(value, allowMultipleChecked) {
			if (!this.form.type) return;

			if (!allowMultipleChecked) {
				for (var t in this.form.type) {
					this.form.type[t] = false;
				}
			}

			this.form.type[value.id] = value.checked;
			this.$v.form.type.$touch();
		},
		setConsent(value) {
			this.form.consent = value;
			this.$v.form.consent.$touch();
		},
		submit(e) {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				/**
				 * Form validates, submit it
				 */
				e.currentTarget.submit();
			}
		},
	},
	mounted() {},
};
