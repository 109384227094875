import { mapState } from "vuex";
import HasImageProducts from "../../image-products/has-image-products";
import ImageProductsButton from "../../image-products/image-products-button.vue"

export default {
	name: "animated-hero",
    extends: HasImageProducts,
    components: {
        ImageProductsButton
    },
	props: {
        heroType: {
            type: String,
        },
        headlinePartOne: {
            type: String,
        },
        headlinePartTwo: {
            type: String,
        },
        headlinePartThree: {
            type: String,
        },
        goExploreText: {
            type: String,
        },
        labelText: {
            type: String,
        },
        heroVideoSrc: {
            type: String,
            default: null
        },
        heroImageSrc: {
            type: String,
            default: null
        },
        heroHref: {
            type: String,
            default: null
        },
    },
    data: function() {
        return {
            offsetTop: 0,
            windowTrigger: 0,
            scrolledIntoBanner: false,
            scrolledPastBanner: false
        }
    },
    computed: {
		...mapState({
			windowH: state => state.windowH,
			windowW: state => state.windowW,
			windowY: state => state.windowY,
			explanationData: state => state.explanationData,
			explanationOverlayOpen: state => state.explanationOverlayOpen,
		}),
		colorStyle() {
			if ( this.explanationData && this.explanationData.backgroundColor ) {
				return `background-color: ${this.explanationData.backgroundColor}; --material-overlay-bg-color: ${this.explanationData.backgroundColor};color: ${this.explanationData.textColor}; --material-overlay-text-color: ${this.explanationData.textColor}`;
			}
		},
        parallaxStyle() {
            return `--parallax: ${0.25 * (Math.max(0, (this.windowY - this.offsetTop) - this.windowTrigger))};`;
        },
        videoStyle() {
            let speed = 0.25; //0.25 px pr window scroll px
            let windowOffset = speed * (this.windowY - this.windowTrigger); //scroll pos - trigger point * speed
            let overlap = 0;
            let maxOffset = overlap * this.windowH;
            if ( windowOffset < 0 ) windowOffset = 0;
            if ( windowOffset > maxOffset ) windowOffset = maxOffset;

            return `--video-offset: -${windowOffset}px`;
        }
    },
    watch: {
        windowY: function (newVal) {
			let newScrollY = newVal < 0 ? this.windowTrigger : newVal;
            if (newScrollY >= this.windowTrigger) {
                this.scrolledPastBanner = true;
                this.scrolledIntoBanner = false;
            } else {
                this.scrolledPastBanner = false;
                this.scrolledIntoBanner = true;
            }
        },
        windowW: function () {
            this.resizeListener();
        },
        windowH: function () {
            this.resizeListener();
        }
    },
	methods: {
		resizeListener: function () {
            this.offsetTop = this.getOffsetTopRecursive(this.$el);
            this.windowTrigger = this.offsetTop + 10;            
        },
        getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;
			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;
				current = current.offsetParent;
			}
			return actualOffset;
		},
		scrollDownHandler: function() {            
            let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
			if ( !isIE11 ) window.scrollTo({ top: this.windowH, behavior: 'smooth' });
            else window.scroll(0, this.windowH);
		}
    }
};