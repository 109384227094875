import Swiper from "swiper/dist/js/swiper.js";
import Cookies from "../../basics/js/methods/cookies/cookies";

export default {
	name: "guided-tour",

	computed: {
		guidedTourStatus: function () {
			return this.$store.state.guidedTourStatus;
		},
		guidedTourStyle: function () {
			let activeSlideColor = "#FFFFFF";
			if (this.slider && this.slides) {
				let activeSlide = this.slides[this.slider.activeIndex].querySelector(".guided-tour-slide");
				if (activeSlide) {
					activeSlideColor = getComputedStyle(activeSlide).getPropertyValue("--guided-tour-slide-text-color");
				}
			}
			return { "--guided-tour-text-color": activeSlideColor };
		},
	},
	data() {
		return {
			slider: null,
			slides: null,
			isShown: false,
			showClose: false,
		};
	},
	props: {
		paginationMidfix: {
			type: String,
			required: true,
		},
		tourId: {
			type: String,
			required: false,
		},
		autoOpen: {
			type: Boolean,
			required: false,
		},
	},
	mounted() {
		let pmf = this.paginationMidfix;
		this.isMounted = true;

		this.slides = this.$refs["slider"].querySelectorAll(".swiper-slide");
		let slideCount = this.slides.length;

		this.settings = {
			slidesPerView: 1,
			speed: 450,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			keyboard: {
				enabled: true,
			},
			on: {
				fromEdge: () => {
					if (this.showClose) this.showClose = false;
				},
				reachEnd: () => {
					this.showClose = true;
				},
			},
			lazy: {
				loadPrevNext: true,
			},
			watchOverflow: true,
		};

		if (slideCount > 1) {
			this.settings.pagination = {
				el: this.$refs["slider-pagination"],
				type: "custom",
				renderCustom: function (swiper, current, total) {
					return current + " " + pmf + " " + total;
				},
			};
		} else {
			this.$refs["slider-progress"].classList.add("is-disabled");
		}

		this.slider = new Swiper(this.$refs.slider, this.settings);

		if (this.autoOpen) this.isShown = true;
	},
	watch: {
		guidedTourStatus: function () {
			if (this.autoOpen) {
				try {
					if (history.length > 1) {
						history.back();
					} else {
						window.close();
					}
				} catch (err) {
					console.log("Close error", err);
				}
			} else {
				const isShown = this.$store.state.guidedTourStatus == "accepted";
				if (isShown) this.$store.dispatch("setBodyFixed", { transitionDelay: 550, setClass: true });
				this.isShown = isShown;
			}
		},
	},
	methods: {
		close() {
			if (this.autoOpen) {
				try {
					if (history.length > 1) {
						history.back();
					} else {
						window.close();
					}
				} catch (err) {
					console.log("Close error", err);
				}
			} else {
				this.$store.dispatch("setBodyFixed", { transitionDelay: 550, setClass: false });
				this.$store.commit("updateGuidedTour", "done");
				Cookies.setItem(this.tourId, "done", Infinity);
			}
		},
	},
};
