import { mapState } from "vuex";
import { rInterval } from "./../../basics/js/utils";

import UspSpot from "./../usp-spot/usp-spot";

export default {
    name: 'usp-spot-banner',
    components: {
        UspSpot
    },
	props: {
		headline: {
			type: String
		}
	},
    data() {
        return {
            spotEls: null,
            spotCount: 0,
            spotsScrollWidth: 0,
            spotsScrollPos: 0,
            activeSpotIndex: 0,
        };
    },

    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            windowW: state => state.windowW,
        })
    },
    watch: {
        isMobile() {
            this.activeSpotIndex = 0;
        },
    },
    methods: {
        spotsScrollHandler: function () {
            if ( this.isMobile ) {
                this.spotsScrollWidth = this.$refs.spots.scrollWidth;
                this.spotsScrollPos = this.$refs.spots.scrollLeft;
                let pct = (this.spotsScrollPos + this.windowW/2) / (this.spotsScrollWidth);
                this.activeSpotIndex = Math.floor(this.spotCount * pct);
            }
        },
        prevClickHandler: function () {
            let newIndex = this.activeSpotIndex > 0 ? this.activeSpotIndex - 1 : 0;
            this.spotEls[newIndex].scrollIntoView({behavior: 'smooth', block: "nearest"});
        },
        nextClickHandler: function () {
            let newIndex = this.activeSpotIndex < this.spotCount-1 ? this.activeSpotIndex + 1 : this.spotCount-1;
            this.spotEls[newIndex].scrollIntoView({behavior: 'smooth', block: "nearest"});
        }
    },
    mounted: function () {
        this.spotEls = this.$refs.spots.querySelectorAll('.usp-spot, .inspiration-tile');
        this.spotCount = this.spotEls.length;

        rInterval(this.spotsScrollHandler, 100);
    },

}
