
import { mapState } from 'vuex';

import NotificationPanel from './notification-panel';

export default {
	name: 'notification-panel-wrapper',
	components: {
		NotificationPanel,
	},
	data() {
		return {
			hasMounted: false, // COMMENT: Ability to visually hide the component until it's ready
		}
	},
	computed: mapState(['notificationPanels']),
	mounted(){
		this.hasMounted = true;
	},
};