// A minimal Intersection Observer
// .not-seen: before element has appeared in view
// .in-view:  element is in view

// { once }: wont remove .in-view after it's been added

export const inView = ({ el, once = false, threshold = 0, ratio = null, callback = null }) => {
	const intersectionCallback = (entries) => {
		entries.forEach(({ isIntersecting, target, intersectionRatio }) => {
			target.classList.add("not-seen");
			if (isIntersecting) {
				
				target.classList.add("in-view");
				target.classList.remove("not-seen");

				if (ratio && intersectionRatio >= ratio) {
					if ( callback ) callback(true);
				}				
				else if ( callback ) callback(true);
				
				if (once) {
					observer.unobserve(el);
				}
			} else {
				target.classList.remove("in-view");
				if ( callback ) callback(false);
			}
		});
	};
	const observer = new IntersectionObserver(intersectionCallback, {
		threshold,
	});
	observer.observe(el);
};
