const mapSuggestions = function(suggestions, searchResultPageUrl){

    return suggestions.map(suggestion => {
        return {
            tag: suggestion.suggestion,
            count: suggestion.hits,
            tagLink: `${searchResultPageUrl}?q=${encodeURIComponent(suggestion.suggestion)}`
        }
    })

}

export default mapSuggestions