<script>
import { mapState, mapActions } from "vuex";
import { rInterval } from "./../../../../basics/js/utils";

import { OptionsType, ControlsMode } from "../constants"
import { RECONFIGURATOR_GETSTEP_ASYNC, RECONFIGURATOR_GETVARIANT_ASYNC } from "../../../../basics/js/store/static";
import TabsBar from "../../../experience/tabs-bar/tabs-bar"

import SwatchSelector from "../swatch-selector/swatch-selector.vue"
import ModelSelector from "../model-selector/model-selector.vue"

export default {
    name: "configure-panels",
    components: {
        SwatchSelector,
        ModelSelector,
        TabsBar,
    },
    props: {
        systemText: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            searchStringMinLength: 2,

            activeCategoryType: OptionsType.DEFAULT,
            activeCategoryIndex: 0,

            activeSubCategoryIndex: 0,
            activeSubCategoryType: null,

            activeColorFilter: null,

            subCategoryTabsList: [],


            controlsMode: ControlsMode.DEFAULT,
            allItems: [],
            activeItems: [],
            searchString: '',

            headlineIsSticky: false
        };
    },
    watch: {
        stepData(newStepData) {
            if ( newStepData && newStepData.optionGroup && newStepData.optionGroup.options ) {
                this.updateAllItems(newStepData);

                if ( this.stepData.optionGroup.options[this.activeCategoryType]) {
                    this.updateActiveCategoryItems(newStepData);
                }
            }
        },
        activeCategoryType(newActiveCategoryType) {
            if ( this.stepData && this.stepData.optionGroup && this.stepData.optionGroup.options ) {
                const groups = this.stepData.optionGroup.options[newActiveCategoryType] || [];
                const items = [];
                const types = new Set();

                groups.map(group => {
                    group.items.map(item => {
                        items.push(item);
                        if ( item.type ) {
                            let t = item.type.toLowerCase();
                            t = t.charAt(0).toUpperCase() +t.slice(1);
                            types.add(t);
                        }
                    });
                });

                this.activeItems = items;

                if ( types.size > 0 ) {
                    let tabs = [{name: this.systemText.allTypesText, id: 0}];
                    let index = 0;
                    types.forEach(item=>{
                        tabs.push({name: item.charAt(0).toUpperCase() + item.slice(1), id: index+1});
                        index++;
                    });
                     if ( tabs.length > 2 ) {
                        this.subCategoryTabsList = tabs;
                    } else {
                        this.subCategoryTabsList = [];
                    }
                } else {
                    this.subCategoryTabsList = [];
                }
            }
        },
        searchString(newSearchString) {
            this.activeColorFilter = null;
            this.activeSubCategoryType = null;
            this.activeSubCategoryIndex = 0;

            console.log("searchString", newSearchString)
            if ( this.stepData && this.stepData.optionGroup && this.stepData.optionGroup.options && newSearchString.length >= this.searchStringMinLength ) {
                const items = [];
                this.allItems.map(item => {
                    if ( item.value.toLowerCase().indexOf(newSearchString.toLowerCase()) > -1 ) {
                        items.push(item);
                    }
                });

                console.log("searchString, items", items.length);
                this.activeItems = items;
            }
        },
        activeColorFilter(newActiveColorFilter) {
            console.log("activeColorFilter", newActiveColorFilter)

            if ( newActiveColorFilter && newActiveColorFilter.length > 0 ) {
                console.log("activeColorFilter parse")
                const items1 = [];
                this.allItems.map(item1 => {
                    if ( item1.color && item1.color.toLowerCase() === newActiveColorFilter.toLowerCase() ) {
                        items1.push(item1);
                    }
                });
                const items2 = [];
                if ( this.activeSubCategoryType ) {
                    items1.map(item2 => {
                        if ( item2.type && item2.type.toLowerCase() === this.activeSubCategoryType.toLowerCase() ) items2.push(item2);
                    });
                }
                console.log("activeColorFilter parse done", items1,items2)
                this.activeItems = this.activeSubCategoryType ? items2 : items1;
            }
        },
        activeSubCategoryType(newActiveSubCategoryType) {
            console.log("activeSubCategoryType", newActiveSubCategoryType)

            if ( !this.allItems || this.allItems.length === 0 ) return false;

            else if ( newActiveSubCategoryType && newActiveSubCategoryType.length > 0 ) {
                console.log("activeSubCategoryType parse")
                const items1 = [];
                this.allItems.map(item1 => {
                    if ( item1.type && item1.type.toLowerCase() === newActiveSubCategoryType.toLowerCase() ) {
                        items1.push(item1);
                    }
                });
                const items2 = [];
                if ( this.activeColorFilter ) {
                    items1.map(item2 => {
                        if ( item2.color && item2.color.toLowerCase() === this.activeColorFilter.toLowerCase() ) items2.push(item2);
                    });
                }
                console.log("activeSubCategoryType parse done", items1,items2)

                this.activeItems = this.activeColorFilter ? items2 : items1;
            } else {
                //activeSubCategoryType is null
                const items = [];
                if ( this.activeColorFilter ) {
                    this.allItems.map(item => {
                        if ( item.color && item.color.toLowerCase() === this.activeColorFilter.toLowerCase() ) items.push(item);
                    });
                }
                console.log("activeSubCategoryType parse done", items)

                this.activeItems = this.activeColorFilter ? items : this.allItems;
            }
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            bodyFixed: state => state.bodyFixed,
            isConfiguring: state => state.reconfigurator.reconfiguratorOpen,
            stepData: state => state.reconfigurator[RECONFIGURATOR_GETSTEP_ASYNC.stateKey],
            stepLoading: state => state.reconfigurator[RECONFIGURATOR_GETSTEP_ASYNC.loadingKey],
            variantLoading: state => state.reconfigurator[RECONFIGURATOR_GETVARIANT_ASYNC.loadingKey],
        }),
        showModelSelector() {
            if ( !this.stepData || !this.stepData.optionGroup || !this.stepData.optionGroup.groupType ) return false;
            return this.stepData.optionGroup.groupType.toLowerCase() === "radio" || this.stepData.optionGroup.groupType.toLowerCase() === "unknown"
        },
        showMaterialSelector() {
            if ( !this.stepData || !this.stepData.optionGroup || !this.stepData.optionGroup.groupType ) return false;
            return this.stepData.optionGroup.groupType.toLowerCase() === "upholstery"
        },
        categoryTabsList() {
            if ( this.stepData && this.stepData.optionGroup && this.stepData.optionGroup.options ) {
                const options = this.stepData.optionGroup.options;
                let lists = [];

                if ( options.default ) {
                    if ( options.special ) {
                        lists.push({name: this.systemText.specialOptionsText, id:0});
                        lists.push({name:this.systemText.defaultOptionsText, id:1});
                    } else {
                        lists.push({name:this.systemText.defaultOptionsText, id:0});
                    }
                }
                return lists;
            }
            return null;
        },
        isDefaultView() {
            return this.controlsMode === ControlsMode.DEFAULT;
        },
        isSearchView() {
            return this.controlsMode === ControlsMode.SEARCH;
        },
        isFiltersView() {
            return this.controlsMode === ControlsMode.FILTERS;
        },
        extractedColorList() {
            if ( this.allItems.length ) {
                const colors = new Set();
                this.allItems.map(item => {
                    if ( item.color ) colors.add(item.color);
                });
                return colors;
            } else {
                return [];
            }
        },
        colorMap() {
            return {
                'Brown': '#976F38',
                'Black': '#282828',
                'Purple': '#CDC9D9',
                'Yellow': '#FFF19F',
                'Red': '#CB4242',
                'Sand': '#F9F8F2',
                'Grey': '#BFBFBF',
                'Orange': '#DA6E00',
                'Green': '#549F3D',
                'Nature': '#E4E4DC',
                'Blue': '#A9B5D6',
                'Rose': '#FFC0CB',
                'White': '#FFFFFF',
            }
        }
    },
    methods: {
        ...mapActions([
            "hideOverlayBackdrop",
        ]),
        categoryChangeHandler: function (item) {
            this.activeCategoryIndex = item.id;
            if ( this.activeCategoryIndex === 0 ) this.activeCategoryType = OptionsType.SPECIAL
            if ( this.activeCategoryIndex === 1 ) this.activeCategoryType = OptionsType.DEFAULT

            this.searchString = '';
            this.activeColorFilter = null;
            this.activeSubCategoryIndex = 0;
            this.activeSubCategoryType = null;
        },
        subcategoryChangeHandler: function (item) {
            this.activeSubCategoryIndex = item.id;
            if ( this.activeSubCategoryIndex === 0 ) this.activeSubCategoryType = null;
            else this.activeSubCategoryType = item.name;
        },
        filtersClickHandler: function () {
            this.searchString = '';
            this.activeColorFilter = null;

            //TODO: filter
            if ( this.controlsMode === ControlsMode.FILTERS) {
                this.controlsMode = ControlsMode.DEFAULT;
            } else {
                this.controlsMode = ControlsMode.FILTERS;
            }
        },
        searchClickHandler: function () {
            this.controlsMode = ControlsMode.SEARCH;

            setTimeout(()=>{
                console.log("this.$refs.searchInput",this.$refs.searchInput);
                this.$refs.searchInput.focus();
            }, 100);
        },
        searchFocusHandler: function() {
            this.searchString = '';
            this.controlsMode = ControlsMode.SEARCH;

            this.activeColorFilter = null;
            this.activeSubCategoryType = null;
            this.activeSubCategoryIndex = 0;
        },
        exitClickHandler: function () {
            this.controlsMode = ControlsMode.DEFAULT;

            this.searchString = "";
            this.activeColorFilter = null;
            this.activeSubCategoryType = null;
            this.activeSubCategoryIndex = 0;
        },
        colorFilterChosen: function(color) {
            if ( this.activeColorFilter === color ) {
                this.activeColorFilter = null;
            } else {
                this.activeColorFilter = color;
            }
        },
        updateAllItems: function(data) {
            if ( data && data.optionGroup && data.optionGroup.options ) {
                const groups = data.optionGroup.options[OptionsType.DEFAULT] || [];
                const items = [];
                const types = new Set();
                groups.map(group => {
                    group.items.map(item => {
                        items.push(item);
                        if ( item.type ) {
                            let t = item.type.toLowerCase();
                            t = t.charAt(0).toUpperCase() +t.slice(1);
                            types.add(t);
                        }
                    });
                });
                this.allItems = items;
                if ( types.size > 0 ) {
                    let tabs = [{name: this.systemText.allTypesText, id: 0}];
                    let index = 0;
                    types.forEach(item=>{
                        tabs.push({name: item, id: index+1});
                        index++;
                    });
                     if ( tabs.length > 2 ) {
                        this.subCategoryTabsList = tabs;
                    } else {
                        this.subCategoryTabsList = [];
                    }
                } else {
                    this.subCategoryTabsList = [];
                }

                if ( data.optionGroup.options[OptionsType.SPECIAL] ) {
                    this.activeCategoryType = OptionsType.SPECIAL;
                }
            }
        },
        updateActiveCategoryItems: function(data) {
            if ( data && data.optionGroup && data.optionGroup.options ) {
                const groups = data.optionGroup.options[this.activeCategoryType] || [];
                const items = [];
                const types = new Set();
                groups.map(group => {
                    group.items.map(item => {
                        items.push(item);
                        if ( item.type ) {
                            let t = item.type.toLowerCase();
                            t = t.charAt(0).toUpperCase() +t.slice(1);
                            types.add(t);
                        }
                    });
                });
                this.activeItems = items;

                if ( types.size > 0 ) {
                    let tabs = [{name: this.systemText.allTypesText, id: 0}];
                    let index = 0;
                    types.forEach(item=>{
                        tabs.push({name: item, id: index+1});
                        index++;
                    });
                     if ( tabs.length > 2 ) {
                        this.subCategoryTabsList = tabs;
                    } else {
                        this.subCategoryTabsList = [];
                    }
                } else {
                    this.subCategoryTabsList = [];
                }
            }
        },
        panelsScrollHandler: function() {
            if ( this.$el.scrollTop > 20 ) {
                this.headlineIsSticky = true;
            } else {
                this.headlineIsSticky = false;
            }
        }
    },
    mounted: function() {
        if ( this.stepData ){
            this.updateAllItems(this.stepData);
            this.updateActiveCategoryItems(this.stepData);
        }
        rInterval(this.panelsScrollHandler, 100);
    }

}
</script>

<template>
    <div class="configure-panels" :class="{'is-loading': variantLoading}">

        <div class="configure-panels__inner" v-if="stepData && !stepData.optionGroup">
            <h4 class="configure-panels__headline">
                Data error
            </h4>
            <pre class="configure-panels__error" v-if="stepData.error">
                {{stepData.error}}
            </pre>
            <pre class="configure-panels__error" v-else>
                {{JSON.stringify(stepData, null, 6)}}
            </pre>
        </div>

        <div class="configure-panels__inner" v-if="stepData && stepData.optionGroup && !stepLoading && showModelSelector">

            <h4 class="configure-panels__headline" v-if="stepData && stepData.optionGroup && stepData.optionGroup.label" :class="{'is-bordered': headlineIsSticky}">
                {{stepData.optionGroup.label.toLowerCase()}}
            </h4>

            <div class="configure-panels__bodytext" v-if="stepData.optionGroup" v-html="stepData.optionGroup.description"></div>

            <model-selector
                :items="stepData.optionGroup.options.default[0].items"
                :label="stepData.optionGroup.label"
                :feature-id="stepData.optionGroup.id">
            </model-selector>
        </div>


        <div class="configure-panels__inner" v-if="stepData && stepData.optionGroup && !stepLoading && showMaterialSelector">

            <h4 class="configure-panels__headline" v-if="stepData && stepData.optionGroup && stepData.optionGroup.label" :class="{'is-bordered': headlineIsSticky}">
                {{stepData.optionGroup.label.toLowerCase()}}
            </h4>
            <div class="configure-panels__bodytext" v-if="stepData.optionGroup" v-html="stepData.optionGroup.description"></div>


            <div class="configure-panels__controls">

                <div class="configure-panels__controls__top">


                    <button class="control-button for-search" v-on:click="searchClickHandler" v-if="!isSearchView && !isFiltersView">
                        <svg viewBox="0 0 100 100" class="svg-icon">
                            <use xlink:href="/dist/svg/symbols.svg#svg-search"></use>
                        </svg>
                    </button>

                    <button class="control-button for-filters" v-on:click="filtersClickHandler" v-if="!isSearchView && !isFiltersView">
                        <svg viewBox="0 0 100 100" class="svg-icon">
                            <use xlink:href="/dist/svg/symbols.svg#svg-filter-sliders"></use>
                        </svg>
                    </button>
                    <button class="control-button for-exit-filters" v-on:click="exitClickHandler" v-if="isSearchView || isFiltersView">
                        <svg viewBox="0 0 100 100" class="svg-icon">
                            <use xlink:href="/dist/svg/symbols.svg#svg-minus2"></use>
                        </svg>
                    </button>

                    <tabs-bar inline-template :tab-click-handler="categoryChangeHandler" :active-index="activeCategoryIndex" :items="categoryTabsList" v-if="!isSearchView && categoryTabsList.length > 1">
                        <nav class="configure-panels__controls__category tabs-bar">
                            <div class="tabs-bar__inner">
                                <button class="tabs-bar__button" v-for="(item, index) in items" :key="index" :class="{'is-active': index === activeIndex}" v-on:click="tabClickHandler(item,index)" v-cloak>
                                    <span>{{item.name}}</span>
                                </button>
                            </div>
                        </nav>
                    </tabs-bar>

                    <input class="configure-panels__controls__search" v-show="!isFiltersView && (isSearchView || categoryTabsList.length === 1)" type="text" placeholder="Search" v-model="searchString" ref="searchInput" v-on:focus="searchFocusHandler"/>
                    <div class="configure-panels__controls__label" v-show="isFiltersView && categoryTabsList.length === 1">Filter</div>
                </div>

                <div class="configure-panels__controls__filters" :class="{'is-active': isFiltersView && searchString.length === 0}">

                    <tabs-bar inline-template :tab-click-handler="subcategoryChangeHandler" :active-index="activeSubCategoryIndex" :items="subCategoryTabsList">
                        <nav class="configure-panels__controls__subcategory tabs-bar">
                            <div class="tabs-bar__inner">
                                <button class="tabs-bar__button" v-for="(item, index) in items" :key="index" :class="{'is-active': index === activeIndex}" v-on:click="tabClickHandler(item,index)" v-cloak>
                                    <span>{{item.name}}</span>
                                </button>
                            </div>
                        </nav>
                    </tabs-bar>

                    <div class="filter-section" :class="{'is-active': activeColorFilter}" v-if="extractedColorList && extractedColorList.size > 0">
                        <ul>
                            <li v-for="(item,index) in extractedColorList" :key="'color'+index">
                                <button class="filter-button" :class="{'is-active': item === activeColorFilter}" v-on:click="()=>colorFilterChosen(item)"><em :style="{backgroundColor: colorMap[item]}"></em><span>{{item}}</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="configure-panels__result" v-if="(searchString.length >= searchStringMinLength || activeColorFilter || activeSubCategoryType ) && activeItems" :class="{'is-searching': isSearchView}">
                <swatch-selector
                    v-if="activeItems.length > 0"
                    :key="activeSubCategoryType + '_' + activeColorFilter + '_'+searchString"
                    :initial-selected-swatch="''"
                    :initial-expanded="true"
                    :items="activeItems"
                    :feature-id="stepData.optionGroup.id">
                </swatch-selector>
                <h5 class="no-results" v-else>{{systemText.noResultsText}}</h5>
            </div>

            <div class="configure-panels__result" v-else :class="{'is-searching': isSearchView}">
                <swatch-selector
                    v-for="(group,index) in stepData.optionGroup.options[activeCategoryType]"
                    :initial-selected-swatch="''"
                    :initial-expanded="false"
                    :initial-rows="isMobile ? 1 : 2"
                    :row-size="4"
                    :lazy-loading="true"
                    :has-info="true"
                    :key="'group_'+index+'_'+group.name"
                    :headline="group.name"
                    :body-text="group.description"
                    :items="group.items"
                    :properties="group.properties"
                    :feature-id="stepData.optionGroup.id">
                </swatch-selector>
            </div>
        </div>


    </div>

</template>
