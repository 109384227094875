export default {
    categories: [
		{
            headline: "strength, durability & stability",
            bodyText: "Form follows function\nSo wheather you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",

            byline: "9 obtained",
            imageSrc: "/static/images/textiles/textured.jpg",

            backgroundColor: "#F9F8F2",
            textColor: "#282828",

            materialsLabel: "Materials",
            subCategoriesLabel:"Popular Textured materials",
            subCategories: [
                {
                    headline: "Textured 1",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/textiles/textured.jpg",
                    content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Faux leather",
                    ctaHref: "#findasofa",
                },
                {
                    headline: "Textured 2",
                    bodyText: "Consectetur adipiscing elit do eiusmod tempor...",
                    imageSrc: "/static/images/textiles/textured.jpg",
                    content: "<p>Consectetur adipiscing elit, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Refine leather",
                    ctaHref: "#findasofa",
                },
            ],
            sliderLabel: "Popular textured sofas",
            sliderItems: [
                {
                    headline: "Connect Soft",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/designer-portrait-img2.jpg",
                },
                {
                    headline: "Outline",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_4.jpg",
                },
            ]
        },
		{
            headline: "safety & regulations",
            bodyText: "Form follows function - so wheather you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",

            imageSrc: "/static/images/textiles/leather.jpg",
            backgroundColor: "#DBE8F3",
            textColor: "#DA6E00",

            materialsLabel: "Materials",
            subCategoriesLabel:"Popular textured materials",
            subCategories: [
                {
                    headline: "Textured 1",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/textiles/leather.jpg",
                    content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Faux leather",
                    ctaHref: "#findasofa",
                },
                {
                    headline: "Textured 2",
                    bodyText: "Consectetur adipiscing elit do eiusmod tempor...",
                    imageSrc: "/static/images/textiles/leather.jpg",
                    content: "<p>Consectetur adipiscing elit, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Refine leather",
                    ctaHref: "#findasofa",
                },
                {
                    headline: "Textured 3",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/textiles/leather.jpg",
                    content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Faux leather",
                    ctaHref: "#findasofa",
                },
            ],
            sliderLabel: "Popular leather sofas",
            sliderItems: [
                {
                    headline: "Outline",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_4.jpg",
                },
                {
                    headline: "Connect Soft",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_2.jpg",
                },
            ]
        },
        {
            headline: "ecolabels & certificates",
            bodyText: "Form follows function - so wheather you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",

            byline: "5 obtained",
            imageSrc: "/static/images/textiles/smooth.jpg",
            backgroundColor: "#E6C4A6",
            textColor: "#282828",

            materialsLabel: "Materials",
            subCategoriesLabel:"Popular solid color materials",
            subCategories: [
                {
                    headline: "Soft 1",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/textiles/smooth.jpg",
                    content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Faux leather",
                    ctaHref: "#findasofa",
                },
            ],
            sliderLabel: "Popular solid color sofas",
            sliderItems: [
                {
                    headline: "Outline",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_4.jpg",
                },
                {
                    headline: "Connect Soft",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_2.jpg",
                },
                {
                    headline: "Outline",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/designer-portrait-img2.jpg",
                },
                {
                    headline: "Connect Soft",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_2.jpg",
                },
            ]
        },
        {
            headline: "electromagnetic compatability",
            bodyText: "Form follows function - so wheather you need to lounge, enteratin, lorem ipsum dolor sit amet consectetur adipiscing elit sed.",

            byline: "5 obtained",
            imageSrc: "/static/images/textiles/leather.jpg",
            backgroundColor: "#DBE8F3",
            textColor: "#DA6E00",

            materialsLabel: "Materials",
            subCategoriesLabel:"Popular textured materials",
            subCategories: [
                {
                    headline: "Textured 1",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/textiles/leather.jpg",
                    content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Faux leather",
                    ctaHref: "#findasofa",
                },
                {
                    headline: "Textured 2",
                    bodyText: "Consectetur adipiscing elit do eiusmod tempor...",
                    imageSrc: "/static/images/textiles/leather.jpg",
                    content: "<p>Consectetur adipiscing elit, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Refine leather",
                    ctaHref: "#findasofa",
                },
                {
                    headline: "Textured 3",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/textiles/leather.jpg",
                    content: "<p>Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment.</p><table><tbody><tr><th>Material type:</th><td>Aniline leather</td></tr><tr><th>Martindale:</th><td>20.000 (General domestic)</td></tr></tbody></table>",
                    seeMoreLabel: "See more",
                    ctaLabel: "Find a sofa with Faux leather",
                    ctaHref: "#findasofa",
                },
            ],
            sliderLabel: "Popular leather sofas",
            sliderItems: [
                {
                    headline: "Outline",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_4.jpg",
                },
                {
                    headline: "Connect Soft",
                    bodyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
                    imageSrc: "/static/images/roomexplorer-3_2.jpg",
                },
            ]
        },
    ],
    features: [
        {
            headlineNumber: "01",
            headline: "Durability",
            bodyText: "Referring to the durability of the textile, The Martindale Score ranges from 10,000 to 150,000, denoting the number of abrasions before the character of the fabric visibly starts to change.\nAll of Muuto’s materials have high Martindale scores and are suitable for domestic use.",
            ctaText: "Read more",
            ctaHref: null,
            explanationData: {
                backgroundColor: "lavender",
                textColor: "green",
                headline: "Durability",
                content: [
                    {
                        text: "Lorem ipsum dolor sit amet, sed do eiusmod tempor. The Leather is dyed through, chrome tanned and produced without the use of PCP and CFC wich can ve damaging to the environment",
                    },
                    {
                        image: "/static/images/product/master-product-image.jpg",
                    },
                    {
                        text: "Exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes",
                    },
                    {
                        text: "The visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes",
                    },
                    {
                        image: "/static/images/product/plp-inspirational-image1.jpg",
                    },
                    {
                        text: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail.",
                    },
                ]
            }
        },
        {
            headlineNumber: "02",
            headline: "Sunlight Exposure",
            bodyText: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes.\nAll of Muuto’s materials offer a lightfastness of at least 3. ",
            imageSrc: "/static/images/product/master-product-image.jpg",
            ctaText: "Check it out",
            ctaHref: "http://www.deptagency.com",
            explanationData: null
        },
        {
            headlineNumber: "03",
            headline: "Pilling",
            bodyText: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes.\nAll of Muuto’s materials offer a lightfastness of at least 3. ",
            videoSrc: "/static/video/test3.mp4",
            ctaText: "Read more",
            ctaHref: null,
            explanationData: {
                backgroundColor: "brown",
                textColor: "yellow",
                headline: "Pilling",
                content: [
                    {
                        image: "/static/images/product/plp-inspirational-image1.jpg",
                    },
                    {
                        text: "Exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes",
                    },
                    {
                        text: "The visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes",
                    },
                    {
                        text: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail.",
                    },
                ]
            }
        },
        {
            headlineNumber: "02",
            headline: "Sunlight Exposure",
            bodyText: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes.\nAll of Muuto’s materials offer a lightfastness of at least 3. ",
            imageSrc: "/static/images/product/master-product-image.jpg",
            ctaText: "Check it out",
            ctaHref: "http://www.deptagency.com",
            explanationData: null
        },
        {
            headlineNumber: "03",
            headline: "Pilling",
            bodyText: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes.\nAll of Muuto’s materials offer a lightfastness of at least 3. ",
            imageSrc: "/static/images/product/master-product-image.jpg",
            ctaText: "Read more",
            ctaHref: null,
            explanationData: {
                backgroundColor: "brown",
                textColor: "yellow",
                headline: "Pilling",
                content: [
                    {
                        image: "/static/images/product/plp-inspirational-image1.jpg",
                    },
                    {
                        text: "Exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes",
                    },
                    {
                        text: "The visible discoloration that exposure to direct sunlight can entail. The higher the number, the longer it will take before the color of the textile changes",
                    },
                    {
                        text: "Measured on a scale from 1 to 8, lightfastness refers to a material’s ability to withstand the visible discoloration that exposure to direct sunlight can entail.",
                    },
                ]
            }
        }
    ]
}
