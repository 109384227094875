import BuySection from "../../components/buy-section/buy-section";
import InputWithLabel from "../../components/form-elements/templates/input-with-label";
import copyTextMixin from "../../components/vue-mixins/copyText";
import RequestConfiguration from "../../components/configurator/request-configuration";

import { CONFIGURATOR_OPTIONS_ASYNC } from "../../basics/js/store/static";
import { CONFIGURATOR_INITIAL_OPTIONS_ASYNC } from "../../basics/js/store/static";
import { CONFIGURATOR_CYLINDO_SKU_ASYNC } from "../../basics/js/store/static";

// import { mapState } from 'vuex';
import { mapActions, mapState } from "vuex";

export default {
	name: "configurator",
	components: {
		BuySection,
		InputWithLabel,
		RequestConfiguration,
	},
	props: {
		productCode: {
			type: String,
			required: true,
		},
		variantCode: {
			type: String,
			required: true,
		},
		baseUrl: {
			type: String,
			required: true,
		},
		formattedPrice: {
			type: String,
			required: true,
		},
		startFrame: {
			type: Number,
			default: 4,
		},
	},
	mixins: [copyTextMixin],
	data() {
		return {
			// ...mapState([
			// 	types.GET_OPTION_GROUP_ASYNC.stateKey
			// ]),
			isLoaded: false,
			quantity: 1,
			sku: this.variantCode, // COMMENT: Gets populated click-handler, through actions and mutations..
			cylindoSku: null, // COMMENT: Gets populated click-handler, through actions and mutations..
			optionGroups: null,
			activeLevel: "level1", // COMMENT: Either 'level1' or 'level2'
			activeSlide: null,
			activeSlideId: null,
			activeGroupType: null,
			initialOptionGroupItem: null,
			optionGroupIsSaving: false,
			selectedOptions: [],
			selectedOption: null,
			selectedOptionGroupId: null,
			optionGroupConflictActive: false,
			generalConflict: false,
			// COMMENT: Cylindo options
			cylindoAccountId: this.$store.state.cylindoAccountId,
			cylindoSettings: {},
			cylindoInstance: null,
			cylindoIsLoading: true,
			cylindoInitDone: false,
			cylindoSkuChanged: false,
			cylindoGuideActive: true,
			cylindoErrorHandlerAdded: false,

			cylindoZoomMode: false,
			cylindoPanMode: false,

			productInfo: {}, // COMMENT: Gets populated from json..
			country: this.$store.state.country,
			language: this.$store.state.language,

			// COMMENT: Upholstery settings
			upholsteryPreviewStyle: {},
			upholsteryPreviewActive: false,
			upholsteryPreviewForceInactive: false,
			upholsteryPreviewActiveIndex: -1,
			upholsteryPreviewTimeout: null,
			upholsterySelection: null,
			upholsterySelectionInfo: {},
			upholsterySearchModel: {
				search: "",
				type: "", // COMMENT: Now supplied by the server response
				color: "", // COMMENT: Now supplied by the server response
				name: "", // COMMENT: Now supplied by the server response
				typeId: "-1",
				colorId: "-1",
				nameId: "-1",
			},
			headerProductPreviewActive: false, // COMMENT: Where the product preview is shown in the slideGroup header
			headerProductPreviewUrl: "",

			upholsteryDropDownValues: null,
			nameSelected: "-1",
			upholsteryNameFilter: null,
			upholsteryOptions: null,
			showMobileOptions: false, // COMMENT: Force open options on mobile
			mobileShowProductAfterSave: false,
			price: this.formattedPrice.toString(),
			variantUrl: "javascript:;",
			dispatchInfo: "",
			colorMessageShown: false,
			colorMessageTimeout: 0,
			upholsteryInfoExpanded: false,
			upholsteryStickyHeight: 41,
			upholsteryStickyControlsHeight: 41,
		};
	},
	computed: {
		...mapState({
			isActive: (state) => state.configurator.configuratorOpen,
		}),
		configuratorData() {
			return this.$store.state.configurator[CONFIGURATOR_OPTIONS_ASYNC.stateKey];
		},
		variantCylindoSku() {
			return this.$store.state.configurator[CONFIGURATOR_CYLINDO_SKU_ASYNC.stateKey];
		},
		filteredUpholsteryNames() {
			return this.upholsteryDropDownValues.name.options.map((x) => ({
				item: x,
				disabled:
					!this.upholsteryNameFilter || this.upholsteryNameFilter === "-1"
						? false
						: x.group !== this.upholsteryNameFilter,
			}));
		},
	},
	watch: {
		isActive() {
			if (this.$store.state.configurator.configuratorOpen && !this.isLoaded) {
				this.loadInitialOptions();
				// this.colorMessageTimeout = setTimeout(
				// 	function () {
				// 		this.openColorMessage();
				// 	}.bind(this),
				// 	3000
				// );
				this.$el.focus(); // In order to make "esc" keydown work
			}
			if (!this.$store.state.configurator.configuratorOpen) {
				// COMMENT: When the configurator is closing

				if (this.activeLevel === "level2") {
					// COMMENT: If level2 is open, force it closed, to be ready for next "visit"
					// (deprecated comment, for new spec: same logic as when cancelling option-group with option-header back-button)
					setTimeout(() => {
						this.hideAnySlide();
					}, 550); // COMMENT: timing of the open/close animation css-prop
				}
			}
		},
		upholsteryPreviewUrl() {
			// COMMENT: Observer trigger
		},
		optionGroups() {
			// COMMENT: Observer trigger
		},
		productInfo() {
			if (!this.productInfo || !this.productInfo.cylindoSku) {
				return;
			}
			if (this.cylindoInitDone && !this.cylindoSkuChanged) {
				this.updateCylindo();
			} else {
				this.initCylindo();
			}
		},
		configuratorData() {
			this.optionGroups = this.configuratorData.optionGroups;
			this.upholsteryOption = this.configuratorData.optionGroups;
			this.productInfo = this.configuratorData.productInfo;
			this.sku = this.configuratorData.productInfo.sku;
			if (this.sku) {
				this.cylindoFeatures = this.configuratorData.productInfo.features;
				if (this.configuratorData.productInfo.cylindoSku) {
					if (this.cylindoSku != this.configuratorData.productInfo.cylindoSku) {
						this.cylindoSkuChanged = true;
						this.cylindoSku = this.configuratorData.productInfo.cylindoSku;
					}
				}
			}
			this.price = this.configuratorData.productInfo.formattedPrice;
			this.isLoaded = true;
			this.variantUrl = this.configuratorData.productInfo.variantUrl;
			this.upholsteryDropDownValues = this.getUpholsteryOptions("dropdownContents");
			this.upholsteryOptions = this.getUpholsteryOptions("options");

			this.setInitiallySelectedOptions();
			this.apiResponseSuccess();
			this.dispatchInfo = this.configuratorData.productInfo.dispatchInfo;

			const selectedOptionValues = this.optionGroups
				.filter(function (optionGroup) {
					return optionGroup.initialSelectedOption ? true : false;
				})
				.map((item) => {
					const container = {};

					container.label = item.label;
					container.value = item.initialSelectedOption.name;

					return container;
				});
				
			this.$emit("update-selected-options", selectedOptionValues);
		},
		variantCylindoSku() {
			if (
				typeof this.variantCylindoSku != "undefined" &&
				this.variantCylindoSku.cylindoSku &&
				this.cylindoSku != this.variantCylindoSku.cylindoSku
			) {
				this.cylindoSku = this.variantCylindoSku.cylindoSku;
				this.cylindoSkuChanged = true;
				this.initCylindo();
			}
		},
	},
	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('showproduct')) this.showMobileOptions = urlParams.get('showproduct') === 'true' ? false : true;
	},
	methods: {
		...mapActions([
			"openConfigurator",
			"closeConfigurator",
			CONFIGURATOR_OPTIONS_ASYNC.action,
			CONFIGURATOR_INITIAL_OPTIONS_ASYNC.action,
			CONFIGURATOR_CYLINDO_SKU_ASYNC.action,
		]),
		loadOptions() {
			this[CONFIGURATOR_OPTIONS_ASYNC.action]({
				body: {
					sku: this.sku,
					productCode: this.productCode,
					selectedOptions:
						this.selectedOptions != null
							? this.selectedOptions
									.map(function (item) {
										return [item.groupId, item.id].join("¤");
									})
									.join(";")
							: "",
				},
			});
		},
		loadInitialOptions() {
			this[CONFIGURATOR_INITIAL_OPTIONS_ASYNC.action]({
				body: {
					sku: this.sku,
					productCode: this.productCode,
				},
			});
		},
		loadCylindoSku(selectedOptionGroupId, selectedOptionId) {
			this[CONFIGURATOR_CYLINDO_SKU_ASYNC.action]({
				body: {
					sku: this.sku,
					productCode: this.productCode,
					selectedOptions:
						this.selectedOptions != null
							? this.selectedOptions
									.map(function (item) {
										if (selectedOptionGroupId && selectedOptionId && item.groupId === selectedOptionGroupId) {
											return [selectedOptionGroupId, selectedOptionId].join("¤");
										}
										return [item.groupId, item.id].join("¤");
									})
									.join(";")
							: "",
				},
			});
		},
		setInitiallySelectedOptions() {
			const initialSelectedOptions = [];
			this.optionGroups.forEach((group) => {
				if (group.initialSelectedOption) {
					initialSelectedOptions.push({
						groupId: group.id,
						id: group.initialSelectedOption.id,
						value: group.initialSelectedOption.id,
					});
				}
			});
			this.selectedOptions = initialSelectedOptions;
		},
		updateSelectedOptions(newSelectedOption) {
			// FILTERING FIX: "quick fix" for Fiber family products
			var combiIds = newSelectedOption.id.split("-");
			var doFilter = combiIds.length > 1;

			this.selectedOptions.forEach((group) => {
				if (group.groupId === newSelectedOption.groupId) {
					group.value = newSelectedOption.value;
					group.id = newSelectedOption.id;

					if (doFilter && group.groupId == "seat-color-material-id"){
						group.id = combiIds[0];
					}
				}
			});

			if (doFilter){
				this.selectedOptions.forEach((group) =>{
					if (group.groupId == "base-color-material-id"){
						group.id = combiIds[1];
					}
				});
			}

			this.optionGroups.forEach((group) => {
				if (group.id === newSelectedOption.groupId) {
					group.initialSelectedOption.id = newSelectedOption.id;
					group.initialSelectedOption.value = newSelectedOption.value;
					group.initialSelectedOption.name = newSelectedOption.name;

					if (doFilter && group.id == "seat-color-material-id"){
						group.initialSelectedOption.id = combiIds[0];
					}
				}
			});

			if (doFilter){
				this.optionGroups.forEach((group) =>{
					if (group.id == "base-color-material-id"){
						group.initialSelectedOption.id = combiIds[1];
					}
				});
			}
		},
		makeFeaturesTuples(featureData) {
			const featureKeys = Object.keys(featureData);
			const featuresTuples = [];

			featureKeys.forEach((key) => {
				const value = featureData[key];
				if (value) {
					featuresTuples.push(key);
					featuresTuples.push(value);
				}
			});

			return featuresTuples;
		},
		getUpholsteryOptions(key) {
			const optionGroup = this.optionGroups.find(({ id }) => id === "upholsteryId");

			return optionGroup ? optionGroup[key] : [];
		},
		optionClick(e, optionGroupType, optionGroupId, option = null) {
			this.setUpholsteryStickyHeight();			
			// COMMENT: Present on all radios inputs
			if (optionGroupType === "upholstery") {
				const optionItemId = option.id;
				this.setActiveUpholsteryItem(optionItemId, optionGroupId);
			}

			if (optionGroupId === "seat-finish-id" && option != null && option.value === "No Upholstery") {
				this.cylindoFeatures["TEXTILE"] = "";
				this.cylindoFeatures["LEATHER"] = "";
			}

			if(optionGroupId === "seat-finish-id" && option != null && option.value === "Full Upholstery"){				
				this.cylindoFeatures["SEAT"] = "";
			}

			this.selectedOption = option;
			this.selectedOptionGroupId = optionGroupId;
			const boolConflict = this.selectedOption.conflicting;
			const isConflicting = boolConflict === "true" ? true : false;

			if (isConflicting) {
				this.optionGroupConflictActive = true;
				this.SetGeneralConflict(true);
			} else {
				this.optionGroupConflictActive = false;
				this.SetGeneralConflict(false);
			}

			if (
				option !== null &&
				option.cylindoProductData !== null &&
				typeof option.cylindoProductData != "undefined" &&
				option.cylindoProductData.feature &&
				option.cylindoProductData.value
			) {
				// FILTERING FIX: Set feature of both seat and base option for Fiber family
				var combiIds = option.id.split("-");
				var doFilter = combiIds.length > 1;
				if (doFilter){
					var features = option.cylindoProductData.feature.split("<-/-/->");
					var values = option.cylindoProductData.value.split("<-/-/->");
					this.cylindoFeatures[features[0]] = values[0];
					this.cylindoFeatures[features[1]] = values[1];
				} else {
					// COMMENT: Set feature of the option
					this.cylindoFeatures[option.cylindoProductData.feature] = option.cylindoProductData.value;
				}

				//Ensure that leather and textile are not send at the same time to cylindo
				if (option.cylindoProductData.feature == "LEATHER") {
					this.cylindoFeatures["TEXTILE"] = "";
				} else if (option.cylindoProductData.feature == "TEXTILE") {
					this.cylindoFeatures["LEATHER"] = "";
				}

				// COMMENT: If the cylindo SKU is changes as a result of an option, the cylindo control needs to be reinitialized
				if (option.cylindoProductData.cylindoSku && this.cylindoSku != option.cylindoProductData.cylindoSku) {
					this.cylindoSku = option.cylindoProductData.cylindoSku;
					this.initCylindo();
				} else {
					this.updateCylindo();
					if (!this.productInfo.isModularVariant) {
						this.loadCylindoSku(optionGroupId, option.id);
					}
				}
				this.upholsteryPreviewActive = false;
				this.upholsteryPreviewForceInactive = true;
			} else if (!this.productInfo.isModularVariant) {
				this.loadCylindoSku(optionGroupId, option.id);
			}
		},
		setActiveUpholsteryItem(optionItemId, optionGroupId) {
			// COMMENT: Find group and the the item
			const optionGroup = this.optionGroups.filter((group) => group.id === optionGroupId)[0];

			const item = optionGroup.options.filter((item) => item.id === optionItemId)[0];

			// COMMENT: Set groups initial selected option to found item
			this.upholsterySelectionInfo = item;
			this.upholsterySelection = true;

			this.setUpholsteryStickyHeight();
		},
		optionGroupSave() {
			this.optionGroupIsSaving = true; // COMMENT: Sent to toggleSlide/hideSlide

			if (this.selectedOption == undefined || this.selectedOption == this.initialOptionGroupItem) {
				this.hideSlide();
			} else {
				const selectedItemInfo = {
					value: this.selectedOption.value,
					id: this.selectedOption.id,
					groupId: this.selectedOptionGroupId,
					name: this.selectedOption.name,
				};
				this.updateSelectedOptions(selectedItemInfo);
				if (this.productInfo.isModularVariant) {
					var data = {
						productInfo: {
							isModularVariant: this.productInfo.isModularVariant,
						},
						optionGroups: this.configuratorData.optionGroups,
					};
					this.$store.state.configurator[CONFIGURATOR_OPTIONS_ASYNC.stateKey] = data;
				} else {
					this.loadOptions();
				}
			}
		},
		apiResponseSuccess() {
			// COMMENT: Below are functions to be called after server roundtrip
			this.updateBuySectionAfterFetch();
			
	
			if (this.isMobile && this.mobileShowProductAfterSave) {
				// COMMENT: Close options and change text
				// If mobile options should hide and main product image should be shown after save, set showMobileOptions to false
				this.showMobileOptions = false;
				this.mobileShowProductAfterSave = false;
			}
			this.hideAnySlide();
		},
		updateUpholsteryOptions(searchModel) {
			var upholsteryOptions = this.getUpholsteryOptions("options");

			if (searchModel.search !== "") {
				upholsteryOptions = upholsteryOptions.filter(
					(x) => x.name.toLowerCase().indexOf(searchModel.search.toLowerCase()) !== -1
				);
			}

			if (searchModel.colorId !== "-1") {
				upholsteryOptions = upholsteryOptions.filter((x) => x.colorId === searchModel.colorId);
			}
			if (searchModel.typeId !== "-1") {
				upholsteryOptions = upholsteryOptions.filter((x) => x.typeId === searchModel.typeId);
			}
			if (searchModel.nameId !== "-1") {
				upholsteryOptions = upholsteryOptions.filter((x) => x.nameId === searchModel.nameId);
			}
			this.upholsteryOptions = upholsteryOptions;
		},
		upholsteryTypeFiltering(e) {
			this.upholsterySearchModel.typeId = e.target.value;

			this.upholsteryNameFilter = e.target.value;

			this.nameSelected = "-1";
			this.upholsterySearchModel.nameId = "-1";

			this.updateUpholsteryOptions(this.upholsterySearchModel);
		},
		upholsteryNameFiltering(e) {
			this.upholsterySearchModel.nameId = e.target.value;
			this.updateUpholsteryOptions(this.upholsterySearchModel);
		},
		upholsteryColorFiltering(e) {
			this.upholsterySearchModel.colorId = e.target.value;
			this.updateUpholsteryOptions(this.upholsterySearchModel);
		},
		upholsterySearch(e) {
			this.updateUpholsteryOptions(this.upholsterySearchModel);
			e.preventDefault();
		},
		updateBuySectionAfterFetch() {
			// If one or more of the currently selected options has resulted in a conflict, the buy button is disabled
			var conflict = false;
			this.optionGroups.forEach((group) => {
				if (group.hasConflict) {
					conflict = true;
				}
			});

			this.SetGeneralConflict(conflict);
		},
		SetGeneralConflict(conflict) {
			// TODO - Show a contact form for the user instead of a conflict text
			this.generalConflict = conflict;

			if (this.sku) {
				this.$emit("update-buy-button-disabled", false);
			} else {
				this.$emit("update-buy-button-disabled", true);
			}
		},
		mobileSaveAndShowProduct() {
			this.mobileShowProductAfterSave = true; // COMMENT: Force show product after save on mobile
			this.optionGroupSave();
		},
		updateCylindo() {
			const featuresTuples = this.makeFeaturesTuples(this.cylindoFeatures);
			if (this.cylindoInstance) {
				this.cylindoInstance.setFeatures(featuresTuples);
			}
			this.fetchHeaderProductPreview();
		},
		fetchHeaderProductPreview() {
			// COMMENT: Only shown on mobile.. Styles only apply to mobile, there's no desktop styling..
			if (this.isMobile && this.cylindoInstance) {
				// COMMENT: Preview url can only be set after the cylindo instance is updated!!
				const isHttps = window.location.protocol.indexOf("https") > -1 ? true : false;
				this.cylindoInstance.getCurrentFrameUrl(
					80,
					(url) => {
						// COMMENT: Along side url, and error message can be returned from the Cylindo service "(url, errorMsg)"
						this.setHeaderProductPreview(url);
					},
					isHttps
				);
			}
		},
		setHeaderProductPreview(url) {
			// COMMENT: Callback function for fetchHeaderProductPreview > getCurrentFrameUrl(cylindo-callback)
			if (url !== "") {
				this.headerProductPreviewActive = true;
				this.headerProductPreviewUrl = url;
			} else {
				// COMMENT: In case of an error hide the image.. Could maybe be doing something else..
				this.headerProductPreviewActive = false;
				this.headerProductPreviewUrl = "";
			}
		},
		initCylindo() {
			const featuresTuples = this.makeFeaturesTuples(this.cylindoFeatures);

			// COMMENT: The opts object should hold the start properties of the 360 HD Viewer
			var opts = {
				debug: false,
				containerID: "cylindo-container",
				accountID: this.cylindoAccountId,
				SKU: this.cylindoSku,
				features: featuresTuples,
				format: "jpg",
				country: this.country,
				language: this.language,
				thumbs: false,
				fullscreen: false,
				startFrame: this.startFrame,
				backgroundColor: "ffffff", // COMMENT: CANNOT BE DEFINED WITH A "#"
				size: 1024, // Not working
				progressBar: false,
				zoom: true,
				title: "",
				customZoomContainer: "cylindo-zoom",
				ARDesktop: true,
				ARQuickLook: true,
				QRCodeTitleTxt: "View in AR",
				QRCodeTxt: "Scan the QR code with your mobile device to view the product in your room.",
				ARLoadingTxt: "Loading",
				ARNotAvailableTxt: "Not available",
				ARBackToViewTxt: "",
				ARBtnTxt: "View in AR"
			};

			// COMMENT: Make sure the cylindo framework has been "installed"
			if (window.cylindo) {
				// do not instantiate the viewer until the framework reports ready.
				window.cylindo.on("ready", () => {	
					// COMMENT: Create the cylindo-instance
					this.cylindoInstance = window.cylindo.viewer.create(opts);
					this.cylindoIsLoading = false;
					this.cylindoInitDone = true;
					this.addCylindoEvents();
				});
			}

			this.cylindoSkuChanged = false;
		},
		addCylindoEvents() {
			this.cylindoInstance.on(this.cylindoInstance.events.THREESIXTY_COMPLETE, () => {
				this.fetchHeaderProductPreview();
			});
			this.cylindoInstance.on(this.cylindoInstance.events.SPIN_BEGIN, () => {
				this.cylindoGuideActive = false;
				this.cylindoPanMode = true;
			});
			this.cylindoInstance.on(this.cylindoInstance.events.SPIN_END, () => {
				this.cylindoGuideActive = true;
				this.cylindoPanMode = false;
			});

			this.cylindoInstance.on(this.cylindoInstance.events.ZOOM_ENTER, () => {
				this.cylindoGuideActive = false;
				this.cylindoZoomMode = true;
			});
			this.cylindoInstance.on(this.cylindoInstance.events.ZOOM_EXIT, () => {
				this.cylindoGuideActive = true;
				this.cylindoZoomMode = false;
			});

			this.cylindoInstance.on(this.cylindoInstance.events.VIEWER_READY, () => {
				try {
					if (!this.cylindoErrorHandlerAdded) {
						var ct = document.querySelector(".cylindo-tooltip");
						ct.addEventListener("mousedown", function (e) {
							e.preventDefault();
							e.stopPropagation();
						});
						ct.addEventListener("touchstart", function (e) {
							e.preventDefault();
							e.stopPropagation();
						});
						this.cylindoErrorHandlerAdded = true;

						//Auto rotate product on load
						this.cylindoInstance.rotate( 360, 1600 );
					}
				} catch (err) {
					console.warn("COULDN'T FIND TOOLTIP, NO LISTENER");
				}
			});
		},
		openCloseMobileOptions() {
			this.showMobileOptions = !this.showMobileOptions;
		},
		showAuxilliarySlide(e, toggleId) {
			// COMMENT: Used in markup
			this.activeLevel = "level2";
			this.activeSlideId = toggleId;
			this.activeSlide = this.$refs["slideLevel2-" + toggleId][0];

			this.setUpholsteryStickyHeight();
		},
		showContact(e, toggleId) {
			// COMMENT: Used in markup
			this.activeLevel = "level2";
			this.activeSlideId = toggleId;
			this.activeSlide = this.$refs["slideLevel2-" + toggleId][0];
		},
		hideAuxilliarySlide() {
			this.activeLevel = "level1";
			this.activeSlideId = null;
		},
		showSlide(e, toggleId, groupType) {
			this.setUpholsteryStickyHeight();

			// COMMENT: Used in markup
			this.activeLevel = "level2";
			this.activeSlideId = toggleId;
			this.activeSlide = this.$refs["slideLevel2-" + toggleId][0];

			// COMMENT: To be able to reset on "cancel"/back-arrow (when selected option is the save as the initalSelected, then do nothing)
			this.initialOptionGroupItem = this.activeSlide.querySelector("input:checked")
				? this.activeSlide.querySelector("input:checked")
				: [];

			this.activeGroupType = groupType;
		},
		hideAnySlide() {
			if (this.activeSlideId === "slide-certificates" || this.activeSlideId === "slide-contact") {
				this.hideAuxilliarySlide();
			} else {
				this.hideSlide();
			}
		},
		hideSlide() {
			// COMMENT: "cancel"/back-button click (save is done beforehand and triggers this)
			this.activeLevel = "level1";
			this.upholsterySelection = false;
			if (this.optionGroupConflictActive) {
				// COMMENT: Set "conflict" styling on option > THIS IS DONE BY THE RESPONSE OF THE API(and vue re-renders)
				this.optionGroupConflictActive = false;
			}

			if (this.activeGroupType === "upholstery") {
				// TODO: Reset search/filtering parameters to initial values in API response-json..
				this.upholsterySearchModel.search = "";
				this.upholsterySearchModel.color = "Color";
				this.upholsterySearchModel.name = "Name";
				this.upholsterySearchModel.type = "Type";
			}
			this.activeSlideId = null;
			this.optionGroupIsSaving = false;
		},
		showUpholsteryExample(e, newIndex) {
			clearTimeout(this.upholsteryPreviewTimeout);

			if (newIndex != this.upholsteryPreviewActiveIndex) {
				// COMMENT: Reset "force inactive", set in click handler, if active index differ
				this.upholsteryPreviewForceInactive = false;
			}
			this.upholsteryPreviewActiveIndex = newIndex;

			// COMMENT: For Vue to understand the image-url and insert it correctly, url needs to be quoted!!!! F##%!/!#!
			this.upholsteryPreviewStyle = {
				backgroundImage: `url('${e.currentTarget.getAttribute("data-large-src")}')`,
			};

			if (!this.upholsteryPreviewForceInactive) {
				this.upholsteryPreviewActive = true;
			}

			this.setUpholsteryStickyHeight();
		},
		hideUpholsteryExample() {
			clearTimeout(this.upholsteryPreviewTimeout);
			this.upholsteryPreviewTimeout = setTimeout(() => {
				this.upholsteryPreviewActive = false;
				this.upholsteryPreviewActiveIndex = -1;
			}, 150); // COMMENT: This tiny Timeout will give a buffer when hovering on the next item
		},
		getDummyUpholstery() {
			return {
				imageThumb: "/static/images/textiles/f-1221-c0746_(150)-thumb.jpg",
				imageLarge: "/static/images/textiles/f-1221-c0746_(150).jpg",
				imageAlt: "Remix, 062",
				id: "remix-062",
				value: "remix-062-value",
				text: "Remix, 062",
				itemLabel: "Textile name",
				name: "Remix, 062",
				conflicting: false,
				info: {
					extra: [
						{
							label: "Lorem ipsum",
							text: "Dijkmeier & Ridolfo",
						},
						{
							label: "Composition",
							text: "90% Nylon",
						},
						{
							label: "Maintenance & care",
							images: [
								{
									url: "/static/svg/geolocation-point.svg",
									alt: "Hand wash",
								},
								{
									url: "/static/svg/geolocation-point.svg",
									alt: "No iron",
								},
							],
						},
						{
							label: "Lorem",
							text: "Ipsum dolor",
						},
					],
				},
			};
		},
		closeColorMessage() {
			this.colorMessageShown = false;
			clearTimeout(this.colorMessageTimeout);
		},
		openColorMessage() {
			this.colorMessageShown = true;
			clearTimeout(this.colorMessageTimeout);
		},
		toggleColorMessage() {
			if (this.colorMessageShown) this.closeColorMessage();
			else this.openColorMessage();
		},
		toggleUpholsteryInfo() {
			this.upholsteryInfoExpanded = !this.upholsteryInfoExpanded;

			setTimeout(() => {
				this.setUpholsteryStickyHeight();
			}, 300);
		},
		setUpholsteryStickyHeight() {
			let upholsteryStickyRef = this.$refs["upholsterySticky"];
			if (Array.isArray(upholsteryStickyRef) && upholsteryStickyRef.length) {
				this.upholsteryStickyHeight = upholsteryStickyRef[0].offsetHeight;
			}
		},
	},
};
