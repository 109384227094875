import Vue from "vue/dist/vue.esm";
import { RECONFIGURATOR_GETSTEP_ASYNC, RECONFIGURATOR_GETVARIANT_ASYNC } from "../static";
import { getRequest, postRequest } from "../integrations";

import { CylindoFeature, CylindoViewMode } from "../../../../components/reconfigurator/partials/constants";

////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
///// S T A T E ////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

const state = {
	[RECONFIGURATOR_GETSTEP_ASYNC.loadingKey]: null,
	[RECONFIGURATOR_GETSTEP_ASYNC.stateKey]: null,

	[RECONFIGURATOR_GETVARIANT_ASYNC.loadingKey]: null,

	reconfiguratorOpen: false,
	reconfiguratorActiveSku: "",

	optionsOverlayOpen: false,
	optionsOverlayItems: null,
	optionsOverlayActiveListIndex: -1,

	zoomOverlayOpen: false,
	zoomOverlayImage: null,

	materialGroupInfoOverlayOpen: false,
	materialGroupInfoOverlayData: null,

	currentStep: -1,
	totalSteps: -1,

	currentCylindoFeatures: null,
	currentVariantFeatures: null,

	currentCylindoSku: null,
	currentProductId: null,
	currentVariantCode: null,
	currentProductUrl: null,

	cylindoViewMode: CylindoViewMode.DESKTOP,

	noVisualUpdate: false,

	finalProductId: null,
	finalVariantCode: null,
};

////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
///// A C T I O N S ////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

const actions = {
	openReConfigurator({ commit, dispatch }, sku) {
		console.log("openReConfigurator");
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: true,
		});
		commit("openReConfigurator", sku);
	},
	closeReConfigurator({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: true,
		});
		commit("closeReConfigurator");
	},

	openOptionsOverlay({ commit, dispatch }, data) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("openOptionsOverlay", data);
	},

	closeOptionsOverlay({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeOptionsOverlay");
	},

	openZoomOverlay({ commit, dispatch }, image) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("openZoomOverlay", image);
	},

	closeZoomOverlay({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeZoomOverlay");
	},

	openMaterialGroupInfoOverlay({ commit, dispatch }, data) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: true,
			onlyMobile: false,
		});
		commit("openMaterialGroupInfoOverlay", data);
	},

	closeMaterialGroupInfoOverlay({ commit, dispatch }) {
		dispatch("setBodyFixed", {
			transitionDelay: 550,
			setClass: false,
			onlyMobile: false,
		});
		commit("closeMaterialGroupInfoOverlay");
	},

	setCylindoViewMode({ commit }, mode) {
		commit("setCylindoViewMode", mode);
	},

	setCurrentCylindoSku({ commit }, sku) {
		commit("setCurrentCylindoSku", sku);
	},
	setCurrentProductId({ commit }, id) {
		commit("setCurrentProductId", id);
	},
	setCurrentVariantCode({ commit }, id) {
		commit("setCurrentVariantCode", id);
	},
	setStepValues({ commit }, stepValues) {
		commit("setStepValues", stepValues);
	},

	getNextStepData({ dispatch, state }) {
		const postData = {
			productId: state.currentProductId,
			variantCode: state.currentVariantCode,
			step: state.currentStep + 1,
		};

		if (state.currentVariantFeatures) {
			let allFilters = Object.entries(state.currentVariantFeatures).map((feature) => {
				return { id: feature[0], value: feature[1] };
			});

			//remove all filters after current step
			// postData.filters = allFilters.slice(0, state.currentStep + 1);

			postData.filters = allFilters;
		}
		dispatch(RECONFIGURATOR_GETSTEP_ASYNC.action, postData);
	},
	getPreviousStepData({ dispatch, state }) {
		// state.noVisualUpdate = true;

		const postData = {
			productId: state.currentProductId,
			variantCode: state.currentVariantCode,
			step: state.currentStep - 1,
		};

		if (state.currentVariantFeatures) {
			let allFilters = Object.entries(state.currentVariantFeatures).map((feature) => {
				return { id: feature[0], value: feature[1] };
			});

			//remove all filters after current step
			// postData.filters = allFilters.slice(0, state.currentStep);

			postData.filters = allFilters;
		}
		dispatch(RECONFIGURATOR_GETSTEP_ASYNC.action, postData);
	},
	getStepData({ dispatch, state }, step) {
		const postData = {
			productId: state.currentProductId,
			variantCode: state.currentVariantCode,
			step: step,
		};

		if (state.currentVariantFeatures) {
			let allFilters = Object.entries(state.currentVariantFeatures).map((feature) => {
				return { id: feature[0], value: feature[1] };
			});

			//remove all filters after current step
			// postData.filters = allFilters.slice(0,step);

			postData.filters = allFilters;
		}
		dispatch(RECONFIGURATOR_GETSTEP_ASYNC.action, postData);
	},

	updateProductFeature({ dispatch, state }, newFeature) {
		const postData = {
			productId: state.currentProductId,
			variantCode: state.currentVariantCode,
			step: state.currentStep + 1, //get next step
		};

		let allFilters = Object.entries(state.currentVariantFeatures).map((feature) => {
			return { id: feature[0], value: feature[1] };
		});

		//remove all filters after current step
		// postData.filters = allFilters.slice(0,state.currentStep);

		postData.filters = allFilters;

		let newFeatureId = newFeature.id;
		let newFeatureValue = newFeature.item.id;

		//update the current step filter with new feature
		postData.filters[state.currentStep] = { id: newFeatureId, value: newFeatureValue };

		dispatch(RECONFIGURATOR_GETVARIANT_ASYNC.action, postData);
	},

	async [RECONFIGURATOR_GETSTEP_ASYNC.action](store, payload) {
		let result;
		if (payload.method === "GET") {
			result = await getRequest(store, {
				url: process.env.RECONFIGURATOR_GETSTEP_ENDPOINT,
				mutationTypes: RECONFIGURATOR_GETSTEP_ASYNC,
			});
		} else {
			result = await postRequest(store, {
				url: process.env.RECONFIGURATOR_GETSTEP_ENDPOINT,
				body: payload,
				mutationTypes: RECONFIGURATOR_GETSTEP_ASYNC,
			});
		}
		store.commit(RECONFIGURATOR_GETSTEP_ASYNC.SUCCESS, result);
	},

	async [RECONFIGURATOR_GETVARIANT_ASYNC.action](store, payload) {
		let result;
		if (payload.method === "GET") {
			result = await getRequest(store, {
				url: process.env.RECONFIGURATOR_GETVARIANT_ENDPOINT,
				mutationTypes: RECONFIGURATOR_GETVARIANT_ASYNC,
			});
		} else {
			result = await postRequest(store, {
				url: process.env.RECONFIGURATOR_GETVARIANT_ENDPOINT,
				body: payload,
				mutationTypes: RECONFIGURATOR_GETVARIANT_ASYNC,
			});
		}
		store.commit(RECONFIGURATOR_GETVARIANT_ASYNC.SUCCESS, result);
	},
};

////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
///// M U T A T I O N S ////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

const mutations = {
	// open close configure
	openReConfigurator(state, sku) {
		state.reconfiguratorOpen = true;
		state.reconfiguratorActiveSku = sku;

		var newurl = addParam("configure", "true", window.location.href);
		window.history.pushState({ path: newurl }, "", newurl);
	},
	closeReConfigurator(state) {
		state.reconfiguratorOpen = false;
		state.reconfiguratorActiveSku = null;
		state.currentCylindoFeatures = null;
		state.currentVariantFeatures = null;
		state.currentStep = -1;
		state.totalSteps = -1;

		var newurl = removeParam("configure", window.location.href);
		window.history.replaceState({ path: newurl }, "", newurl);
	},

	// overlays
	openOptionsOverlay(state, data) {
		state.optionsOverlayOpen = true;
		state.optionsOverlayItems = data.items;
		state.optionsOverlayActiveListIndex = data.activeListIndex > -1 ? data.activeListIndex : -1;
	},
	closeOptionsOverlay(state) {
		state.optionsOverlayOpen = false;
		state.optionsOverlayItems = null;
		state.optionsOverlayActiveListIndex = -1;
	},
	openZoomOverlay(state, image) {
		state.zoomOverlayImage = image;
		state.zoomOverlayOpen = true;
	},
	closeZoomOverlay(state) {
		state.zoomOverlayOpen = false;
		state.zoomOverlayImage = null;
	},
	openMaterialGroupInfoOverlay(state, data) {
		state.materialGroupInfoOverlayData = data;
		state.materialGroupInfoOverlayOpen = true;
	},
	closeMaterialGroupInfoOverlay(state) {
		state.materialGroupInfoOverlayOpen = false;
		state.materialGroupInfoOverlayData = null;
	},

	// replace data
	setStepValues(state, data) {
		state.currentStep = data.current;
		state.totalSteps = data.total;
	},
	setCurrentCylindoSku(state, sku) {
		state.currentCylindoSku = sku;
	},
	setCurrentProductId(state, id) {
		state.currentProductId = id;
	},
	setCurrentVariantCode(state, id) {
		state.currentVariantCode = id;
	},
	setCylindoViewMode(state, mode) {
		state.cylindoViewMode = mode;
	},

	// parse response
	updateProductFeature(state, newFeature) {
		let newFeatureId = newFeature.id;
		let newFeatureValue = newFeature.item.id;
		let newFeatureItem = newFeature.item;

		let newFeatureCylindoKey = newFeature.item.cylindoFeatureKey;
		let newFeatureCylindoValue = newFeature.item.cylindoFeatureValue;

		if (!newFeatureId || !newFeatureItem) return false;

		// find item with id matching selector's featureId
		let newVData = state.currentVariantFeatures;
		newVData[newFeatureId] = newFeatureValue;
		state.currentVariantFeatures = newVData;

		// find item with is matching cylindoFeatureKey value for cylindo
		let newCData = state.currentCylindoFeatures;
		newCData[newFeatureCylindoKey] = newFeatureCylindoValue;

		delete newCData[""];

		if (newFeatureId === "seat-finish-id" && newFeatureValue === "No Upholstery") {
			delete newCData[CylindoFeature.TEXTILE];
			delete newCData[CylindoFeature.LEATHER];
		}
		if (newFeatureId === "seat-finish-id" && newFeatureValue === "Full Upholstery") {
			delete newCData[CylindoFeature.SEAT];
		}

		// decommisioned
		// if (newFeatureItem.cylindoFeatureKey === CylindoFeature.LEATHER) {
		// 	newCData[CylindoFeature.TEXTILE] = "";
		// } else if (newFeatureItem.cylindoFeatureKey === CylindoFeature.TEXTILE) {
		// 	newCData[CylindoFeature.LEATHER] = "";
		// }

		state.currentCylindoFeatures = newCData;
	},

	[RECONFIGURATOR_GETVARIANT_ASYNC.PENDING](state, status) {
		Vue.set(state, RECONFIGURATOR_GETVARIANT_ASYNC.loadingKey, status);
	},

	[RECONFIGURATOR_GETVARIANT_ASYNC.SUCCESS](state, data) {
		if (data.numberOfSteps && data.numberOfSteps !== state.totalSteps) {
			state.totalSteps = data.numberOfSteps;
		}

		let vI = data.variantInfo;
		if (vI.formattedPrice && vI.formattedPrice !== state[RECONFIGURATOR_GETSTEP_ASYNC.stateKey].formattedPrice) {
			state[RECONFIGURATOR_GETSTEP_ASYNC.stateKey].variantInfo.formattedPrice = vI.formattedPrice;
		}
		if (vI.cylindoSku && vI.cylindoSku !== state[RECONFIGURATOR_GETSTEP_ASYNC.stateKey].variantInfo.cylindoSku) {
			state.currentCylindoSku = vI.cylindoSku;
		}

		let newVData = {};
		let newCData = {};

		if (data && vI.options) {
			vI.options.forEach((option) => {
				newVData[option.optionId] = option.optionValue;
				newCData[option.cylindoFeatureKey] = option.cylindoFeatureValue;
			});

			//remove empty features
			delete newCData[""];
			delete newCData[null];

			//make sure features exist in feature set, so Vue watchers work!
			if (!newCData[CylindoFeature.TEXTILE]) newCData[CylindoFeature.TEXTILE] = "";
			if (!newCData[CylindoFeature.LEATHER]) newCData[CylindoFeature.LEATHER] = "";

			state.currentCylindoSku = vI.cylindoSku;
			state.currentProductUrl = vI.variantUrl;

			state.finalVariantCode = vI.code;
			state.finalProductId = vI.productCode || state.productId;

			state.currentVariantFeatures = newVData;
			state.currentCylindoFeatures = newCData;
		}
	},

	[RECONFIGURATOR_GETSTEP_ASYNC.PENDING](state, status) {
		Vue.set(state, RECONFIGURATOR_GETSTEP_ASYNC.loadingKey, status);
	},
	[RECONFIGURATOR_GETSTEP_ASYNC.SUCCESS](state, data) {
		Vue.set(state, RECONFIGURATOR_GETSTEP_ASYNC.stateKey, data);

		state.currentStep = data.currentStep;
		state.totalSteps = data.numberOfSteps;

		let newVData = {};
		let newCData = {};

		if (data && data.variantInfo && data.variantInfo.options) {
			if (state.noVisualUpdate) {
				state.noVisualUpdate = false;
			} else {
				data.variantInfo.options.forEach((option) => {
					newVData[option.optionId] = option.optionValue;
					newCData[option.cylindoFeatureKey] = option.cylindoFeatureValue;
				});

				state.currentVariantFeatures = newVData;

				//remove empty features
				delete newCData[""];
				delete newCData[null];

				//make sure features exist in feature set, so watchers work!
				if (!newCData[CylindoFeature.TEXTILE]) newCData[CylindoFeature.TEXTILE] = "";
				if (!newCData[CylindoFeature.LEATHER]) newCData[CylindoFeature.LEATHER] = "";

				state.currentCylindoSku = data.variantInfo.cylindoSku;
				state.currentProductUrl = data.variantInfo.variantUrl;

				state.finalVariantCode = data.variantInfo.code;
				state.finalProductId = data.variantInfo.productCode || state.productId;

				state.currentCylindoFeatures = newCData;
			}
		}
	},
};

function removeFromQuery(key, queryString) {
	if (queryString !== "") {
		var params_arr = queryString.split("&");
		for (var i = params_arr.length - 1; i >= 0; i -= 1) {
			var param = params_arr[i].split("=")[0];
			if (param === key) {
				params_arr.splice(i, 1);
			}
		}
		return params_arr;
	}
	return [];
}

// eslint-disable-next-line no-unused-vars
function removeParam(key, sourceURL) {
	var rtn = sourceURL.split("?")[0],
		queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";

	var params_arr = removeFromQuery(key, queryString);
	rtn = params_arr.length > 0 ? rtn + "?" + params_arr.join("&") : rtn;
	return rtn;
}
// eslint-disable-next-line no-unused-vars
function addParam(key, value, sourceURL) {
	var rtn = sourceURL.split("?")[0],
		queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
	var params_arr = removeFromQuery(key, queryString);
	params_arr.push(key + "=" + value);
	rtn = params_arr.length > 0 ? rtn + "?" + params_arr.join("&") : rtn;
	return rtn;
}

export default { state, actions, mutations };
