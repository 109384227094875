export default function({endpoint,facets,skip,take,searchQuery,excludeEditorPicks}) {

    endpoint = endpoint ? endpoint : ''

    const queryPrefix = function(totalString){
        return totalString.indexOf('?') > -1 ? '&' : '?'
    }

    const endpointWithQueryString = facets.reduce((totalString,facet) => {
        return facet.facetValues.reduce((facetString,filter) => {
            return facet.id ? facetString+queryPrefix(facetString)+`${facet.id}=${filter.value ? filter.value+'-' : ''}${encodeURIComponent(filter.id)}` : ''
        },totalString)
    }, endpoint)

    const skipAndTake = (skip !== undefined && take) ? queryPrefix(endpointWithQueryString) + `skip=${skip}&take=${take}` : ''

    const queryString = (searchQuery !== undefined && searchQuery) ? queryPrefix(endpointWithQueryString+skipAndTake) + `q=${searchQuery}` : ''

    const excludeEditorpicksString = (excludeEditorPicks !== undefined && excludeEditorPicks) ? queryPrefix(endpointWithQueryString+skipAndTake+queryString) + `excludeEditorPicks=true` : ''

    return endpointWithQueryString+skipAndTake+queryString+excludeEditorpicksString

}
