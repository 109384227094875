<script>
    import { mapState, mapActions } from "vuex";
    
    export default {
        name: "model-selector",
        props: {
            label: {
                type: String,
            },
            items: {
                type: Array,
                required: true
            },
            featureId: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapState({
                currentVariantFeatures: state => state.reconfigurator.currentVariantFeatures,
                productId: state => state.reconfigurator.currentProductId,
                currentStep: state => state.reconfigurator.currentStep,
            }),            
        },
        methods: {
            ...mapActions([
                "updateProductFeature"
            ]),
            selectModel(item) {
                this.updateProductFeature({id: this.featureId, item:item});
            },
            isItemActive(value) {
                return this.currentVariantFeatures ? this.currentVariantFeatures[this.featureId] === value : false;
            }
        }
    };
</script>


<template>
    <div class="model-selector">        
        <ul class="model-selector__list">
			<li
				v-for="item in items"
				:key="item.id"
				class="model-selector__list-item">
				<button class="model-selector__button" v-on:click="selectModel(item)" :class="{'is-active': isItemActive(item.id)}">
					<img v-if="item.image" :src="item.image" :alt="item.imageAlt">
                    <svg viewBox="0 0 100 100" class="svg-icon">
                        <use xlink:href="/dist/svg/symbols.svg#svg-angle-arrow"></use>
                    </svg>
					<span>{{ item.name || item.value }}</span>					
				</button>
			</li>
        </ul>
    </div>
</template>