var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "options-overlay",
      class: { "is-active": _vm.isOpen && _vm.currentOptionList },
      attrs: { tabindex: "0" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close($event)
        },
      },
    },
    [
      _c(
        "button",
        { staticClass: "options-overlay__close", on: { click: _vm.close } },
        [
          _c(
            "svg",
            { staticClass: "svg-icon", attrs: { viewbox: "0 0 100 100" } },
            [
              _c("use", {
                attrs: { "xlink:href": "/dist/svg/symbols.svg#svg-close" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.overlayItems && _vm.overlayItems.length > 1
        ? _c("tabs-bar", {
            attrs: {
              "tab-click-handler": _vm.optionListChangeHandler,
              "active-index": _vm.activeOptionListIndex,
              items: _vm.overlayItems,
            },
            inlineTemplate: {
              render: function () {
                var _vm = this
                var _h = _vm.$createElement
                var _c = _vm._self._c || _h
                return _c("nav", { staticClass: "tabs-bar" }, [
                  _c(
                    "div",
                    { staticClass: "tabs-bar__inner" },
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "button",
                        {
                          key: index,
                          staticClass: "tabs-bar__button",
                          class: { "is-active": index === _vm.activeIndex },
                          on: {
                            click: function ($event) {
                              return _vm.tabClickHandler(item, index)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(item.name))])]
                      )
                    }),
                    0
                  ),
                ])
              },
              staticRenderFns: [],
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "options-overlay__inner" }, [
        _vm.currentOptionListItems && _vm.currentOptionListItems.length > 0
          ? _c(
              "ul",
              {
                key: _vm.listKey,
                staticClass: "options-overlay__list",
                class: { "is-editable": _vm.currentOptionList.isEditable },
              },
              _vm._l(_vm.currentOptionListItems, function (item) {
                return _c(
                  "li",
                  {
                    key: item.name + "_" + item.id,
                    staticClass: "options-overlay__list-item",
                    class: {
                      "is-active": item.isActive,
                      "is-deleting":
                        _vm.isDeleting &&
                        _vm.currentDeleteItem.variantCode === item.variantCode,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "options-overlay__button",
                        attrs: { href: item.href },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon",
                            attrs: { viewBox: "0 0 100 100" },
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "/dist/svg/symbols.svg#svg-angle-arrow",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("img", {
                          attrs: { src: item.image.src, alt: item.name },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentOptionList.isEditable &&
                    (_vm.isMobile ||
                      !_vm.isDeleting ||
                      (_vm.isDeleting &&
                        _vm.currentDeleteItem.variantCode === item.variantCode))
                      ? _c(
                          "button",
                          {
                            staticClass: "options-overlay__list-item__delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteItemHandler(item)
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "svg-icon",
                                attrs: { viewbox: "0 0 100 100" },
                              },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/dist/svg/symbols.svg#svg-close",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isDeleting &&
                    _vm.currentDeleteItem.variantCode === item.variantCode
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "options-overlay__list-item__confirmation",
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.systemText.variantDeleteHeadline)
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              { on: { click: _vm.deleteConfirmHandler } },
                              [
                                _vm._v(
                                  _vm._s(_vm.systemText.variantDeleteConfirm)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              { on: { click: _vm.deleteCancelHandler } },
                              [
                                _vm._v(
                                  _vm._s(_vm.systemText.variantDeleteCancel)
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }