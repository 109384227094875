<script>
import { mapState, mapActions } from "vuex";
import { CylindoViewMode } from "../constants";
import { inView } from "../../../../basics/js/methods/inView/inView";
import { swipeDetect } from "../../../../basics/js/utils";

export default {
	name: "cylindo-viewer",
	props: {
		cylindoSku: {
			type: String,
			required: false
		},
		cylindoFeatures: {
			type: Object,
			required: false
		},
		texts: {
			type: Object,
			required: true
		},
		noZoom: {
			type: Boolean,
			default: false
		},
		useGuide: {
			type: Boolean,
			default: true
		},
		useSimpleGuide: {
			type: Boolean,
			default: false
		},
		useFocusToggle: {
			type: Boolean,
			default: false
		},
		noMessage: {
			type: Boolean,
			default: false
		},
		modeShiftCallback: {
			type: Function,
			default: null
		},
		hasARCallback: {
			type: Function,
			default: () => { }
		},
		startFrame: {
			type: Number,
			default: 4,
		}
	},
	computed: {
		...mapState({
			isMobile: state => state.isMobile,
			cylindoAccountId: state => state.cylindoAccountId,
			isConfiguring: state => state.reconfigurator.reconfiguratorOpen,
			currentCylindoFeatures: state => state.reconfigurator.currentCylindoFeatures,
			viewMode: state => state.reconfigurator.cylindoViewMode,
			currentCylindoSku: state => state.reconfigurator.currentCylindoSku,
		}),
		isBlurred() {
			return this.isMobile && this.viewMode === CylindoViewMode.MOBILE_BLUR
		},
		isFocused() {
			return this.isMobile && this.viewMode === CylindoViewMode.MOBILE_FOCUS
		},
		guideVisible() {
			return this.useGuide
		},
	},
	watch: {
		isMobile(newVal) {
			if (newVal) {
				this.setCylindoViewMode(CylindoViewMode.MOBILE_BLUR)
			} else {
				this.setCylindoViewMode(CylindoViewMode.DESKTOP)
			}
		},
		currentCylindoFeatures: {
			handler: function (features) {
				if (this.isConfiguring) {
					const newFeatures = this.makeFeaturesTuples(features);

					if (this.cylindoInstance) {

						let oldFeatures = this.cylindoInstance.getFeatures();
						let identicalFeatures = this.compareArrayVals(oldFeatures, newFeatures);

						//Also test for no old features as it will be an empty array if the product is not renderable at page load, such as illusion material products
						//Problem originated from https://jira.immeo.dk/browse/MUU0011-2275
						if (!identicalFeatures || oldFeatures.length === 0) {
							this.cylindoIsLoading = true;
							this.cylindoInstance.setFeatures(newFeatures);
						}
					}
				}
			},
			deep: true
		},
		currentCylindoSku(newVal) {
			if (this.cylindoInstance && this.cylindoInstance.setProduct) {
				this.cylindoIsLoading = true;
				this.cylindoInstance.setProduct(this.currentCylindoSku);
			}
		}
	},
	data() {
		return {
			country: this.$store.state.country,
			language: this.$store.state.language,

			containerID: 'cylindo-container-' + (Math.random() + new Date().toISOString()).replace(/[^a-zA-Z0-9]/g, ''),

			rotationDone: false,

			cylindoSettings: {},
			cylindoInstance: null,
			cylindoIsLoading: true,
			cylindoInitDone: false,
			cylindoSkuChanged: false,
			cylindoGuideActive: true,
			cylindoErrorHandlerAdded: false,

			cylindoZoomMode: false,
			cylindoPanMode: false,

			zoomAllowed: !this.noZoom,

			messageShown: false
		}
	},
	methods: {
		...mapActions([
			"setCylindoViewMode",
		]),
		makeFeaturesTuples(featureData) {
			const featureKeys = Object.keys(featureData);
			const featuresTuples = [];

			featureKeys.forEach((key) => {
				const value = featureData[key];
				if (value) {
					featuresTuples.push(key.toUpperCase());
					featuresTuples.push(value.toUpperCase());
				}
			});
			return featuresTuples;
		},
		compareArrayVals(arr1, arr2) {
			if (!arr1 || !arr2) return false;

			let arraysMatch = true;
			arr1.forEach((item, index) => {
				if (item !== arr2[index]) arraysMatch = false;
			});
			return arraysMatch;
		},
		initCylindo() {

			if (window.cylindo) {
				// do not instantiate the viewer until the framework reports ready.
				window.cylindo.on("ready", () => {
					// COMMENT: The opts object should hold the start properties of the 360 HD Viewer
					let cylindoOptions = {
						accountID: this.cylindoAccountId,
						country: this.country,
						language: this.language,
						SKU: this.cylindoSku || this.currentCylindoSku,
						encoding: "png",
						debug: false,
						containerID: this.containerID,
						customZoomContainer: this.containerID + "-zoom",
						format: "png",
						thumbs: false,
						fullscreen: false,
						size: 1024,
						progressBar: true,
						zoom: this.zoomAllowed,
						title: "",
						backgroundColor: "F9F8F2",
						ARDesktop: true,
						ARQuickLook: true,
						ARBtnTxt: this.texts.tryAR,
						startFrame: this.startFrame,
					};

					if (this.cylindoFeatures) {
						cylindoOptions.features = this.makeFeaturesTuples(this.cylindoFeatures);
					}

					// COMMENT: Create the cylindo-instance

					// console.log("cylindoOptions",cylindoOptions,"currentCylindoSku: "+this.currentCylindoSku)

					this.cylindoInstance = window.cylindo.viewer.create(cylindoOptions);
					this.cylindoInitDone = true;

					this.addCylindoEvents();


				});
			}
		},
		addCylindoEvents() {
			this.cylindoInstance.on(this.cylindoInstance.events.THREESIXTY_COMPLETE, () => {

			});
			this.cylindoInstance.on(this.cylindoInstance.events.SPIN_BEGIN, () => {
				this.cylindoGuideActive = false;
				this.cylindoPanMode = true;
			});
			this.cylindoInstance.on(this.cylindoInstance.events.SPIN_END, () => {
				this.cylindoGuideActive = true;
				this.cylindoPanMode = false;
			});

			this.cylindoInstance.on(this.cylindoInstance.events.ZOOM_ENTER, () => {
				this.cylindoGuideActive = false;
				this.cylindoZoomMode = true;
			});
			this.cylindoInstance.on(this.cylindoInstance.events.ZOOM_EXIT, () => {
				this.cylindoGuideActive = true;
				this.cylindoZoomMode = false;
			});
			this.cylindoInstance.on(this.cylindoInstance.events.FEATURES_ERROR, () => {
				this.cylindoIsLoading = false;
			});

			this.cylindoInstance.on(this.cylindoInstance.events.AR_BUTTON_READY, (event, data) => {
				console.log("AR READY", data.url, this.hasARCallback)
				if (this.hasARCallback) {
					console.log("AR READY CALLBACK")
					this.hasARCallback(data.url);
				}
			});

			this.cylindoInstance.on(this.cylindoInstance.events.VIEWER_READY, () => {
				try {
					if (!this.cylindoErrorHandlerAdded) {
						var ct = document.querySelector(".cylindo-tooltip");
						ct.addEventListener("mousedown", function (e) {
							e.preventDefault();
							e.stopPropagation();
						});
						ct.addEventListener("touchstart", function (e) {
							e.preventDefault();
							e.stopPropagation();
						});
						this.cylindoErrorHandlerAdded = true;
					}
				} catch (err) {
					console.warn("COULDN'T FIND TOOLTIP, NO LISTENER");
				}

				//Auto rotate product on load

				inView({
					el: this.$el,
					threshold: 0.8,
					ratio: 1,
					callback: (inView) => {
						if (inView && !this.rotationDone) {
							this.rotationDone = true;
							// console.log("ROTATE THE SUCKER!")
							this.cylindoInstance.rotate(360, 1600);
						}
					}
				});

				// console.log("ALL IMGS LOADED!")
				this.cylindoIsLoading = false;
			});
		},

		closeMessage() {
			this.messageShown = false;
		},
		openMessage() {
			this.messageShown = true;
		},
		toggleMessage() {
			if (this.messageShown) this.closeMessage();
			else this.openMessage();
		},
		focusViewer() {
			if (this.isFocused) return;

			if (this.isMobile) {
				this.setCylindoViewMode(CylindoViewMode.MOBILE_FOCUS);
			}
			if (this.modeShiftCallback) this.modeShiftCallback(this.viewMode);
		},
		blurViewer() {
			if (this.isMobile) {
				this.setCylindoViewMode(CylindoViewMode.MOBILE_BLUR);
				this.cylindoInstance.exitZoom();
			}
			if (this.modeShiftCallback) this.modeShiftCallback(this.viewMode);
		},


	},
	mounted() {

		let initInterval = setInterval(function () {
			try {
				this.initCylindo();
			} catch (err) {
				console.log("INIT CYLINDO ERROR", err)
			}
			if (this.cylindoInitDone) {
				clearInterval(initInterval);
			}
		}.bind(this), 500);

		this.setCylindoViewMode(this.isMobile ? CylindoViewMode.MOBILE_BLUR : CylindoViewMode.DESKTOP);

		if (this.isConfiguring) {
			swipeDetect(this.$refs.toggler, () => this.focusViewer());
		}
	}
}
</script>


<template>
	<div class="cylindo-viewer"
		:class="{ 'is-zooming': cylindoZoomMode, 'is-panning': cylindoPanMode, 'is-focused': isMobile && isFocused, 'is-loading': cylindoIsLoading }">


		<!-- Container for the Cylindo 360 viewer -->
		<div class="cylindo-viewer__container" :id="containerID"></div>

		<!-- Container for the Cylindo zoom view -->
		<div class="cylindo-viewer__zoom" :id="containerID + '-zoom'"></div>


		<!-- Button for switching from default mobile to focused mode -->
		<button class="cylindo-viewer__toggler cylindo-viewer__toggler--focus" v-if="useFocusToggle && isBlurred"
			v-on:click="focusViewer" ref="toggler">
			<svg viewbox="0 0 100 100" class="svg-icon">
				<use xlink:href="/dist/svg/symbols.svg#svg-plus"></use>
			</svg>
		</button>

		<!-- Button for switching back to default mobile mode -->
		<button class="cylindo-viewer__toggler cylindo-viewer__toggler--blur" v-if="useFocusToggle && isFocused"
			v-on:click="blurViewer">
			<svg viewbox="0 0 100 100" class="svg-icon">
				<use xlink:href="/dist/svg/symbols.svg#svg-close"></use>
			</svg>
		</button>


		<!-- Guide text -->
		<span v-if="guideVisible">

			<p class="cylindo-viewer__guide" :class="{ 'is-active': cylindoGuideActive }" v-if="useSimpleGuide">
				<span>{{ texts.dragToRotate }}</span>
			</p>

			<div class="cylindo-viewer__guide" :class="{ 'is-active': cylindoGuideActive }" v-else>
				<span v-if="!isMobile">{{ texts.dragToRotate }} / {{ texts.clickToZoom }}</span>
				<span v-if="isMobile && !isFocused">{{ texts.tapToInteract }}</span>
				<span v-if="isMobile && isFocused">{{ texts.dragToRotate }} / {{ texts.doubleTapToZoom }}</span>
			</div>
		</span>


		<div class="configurator-message" v-if="!noMessage" :class="{ 'is-active': messageShown }">
			<button class="configurator-message__icon" v-on:click="toggleMessage" v-if="!messageShown">
				<span>{{ texts.messageLabel }}</span>
				<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 17.97 17.97">
					<path d="M9,.8A8.19,8.19,0,1,1,.8,9,8.2,8.2,0,0,1,9,.8M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0Z" />
					<path
						d="M8.09,11.8a.9.9,0,0,1,.37-.73h1a.93.93,0,0,1,.38.73.9.9,0,0,1-1.79,0Zm.32-3.58V5.28H9.56V8.22l-.22,2h-.7Z" />
				</svg>
			</button>

			<div class="configurator-message__inner" v-if="messageShown" v-on:click="closeMessage">
				<p>{{ texts.messageBody }}</p>
				<button>
					<span>{{ texts.messageButton }}</span>
				</button>
			</div>
		</div>

		<div class="cylindo-viewer__loader" v-if="cylindoIsLoading">
			<div class="spinner spinner--large"></div>
		</div>
</div></template>
